/* eslint-disable @typescript-eslint/no-unsafe-return */
import { SavingsPlan } from 'types/savings';
import * as ActionTypes from '../actions/saving_old';
import { AnyAction, combineReducers } from 'redux';
import { SavingsState } from 'store/types/savings';

const initialState: SavingsState = {
  isFetching: false,
  savingsPlans: [],
  loadMarkers: [],
  deleteSavingsPlan: null,
};

function isFetching(state = false, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.FETCH_SAVINGS_PLANS_REQUEST:
      return true;
    case ActionTypes.FETCH_SAVINGS_PLANS_SUCCESS:
      return false;
    case ActionTypes.FETCH_SAVINGS_PLANS_FAILURE:
      return false;
    default:
      return state;
  }
}

function savingsPlans(state = [], action: AnyAction) {
  switch (action.type) {
    case ActionTypes.FETCH_SAVINGS_PLANS_SUCCESS: {
      return action?.payload?.response
        .slice() // copy
        .reverse();
    }
    case ActionTypes.FETCH_SAVINGS_PLANS_FAILURE:
      return state.map((item: SavingsPlan) => {
        return {
          ...item,
          updating: false,
        };
      });
    case ActionTypes.PUT_SAVINGS_PLAN:
      return state.map((item: SavingsPlan) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            updating: true,
          };
        }
        return item;
      });
    case ActionTypes.PUT_SAVINGS_PLAN_SUCCESS:
      return state.map((item: SavingsPlan) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            status: action.payload.status,
            updating: false,
          };
        }
        return item;
      });
    default:
      return state;
  }
}

function deleteSavingsPlan(state = initialState.deleteSavingsPlan, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.SAVE_DELETING_SAVINGS_PLAN:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default combineReducers({ isFetching, savingsPlans, deleteSavingsPlan });
