import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './styles';

const DownloadButton: React.FC<React.HTMLProps<HTMLButtonElement>> = ({
  children,
  ...props
}): JSX.Element => {
  return <Button {...props}>{children}</Button>;
};

DownloadButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DownloadButton;
