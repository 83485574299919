import { AnyAction } from 'redux';
import * as ActionTypes from 'store/actions/settings';
import { SettingsState } from 'store/types/settings';
import { CRYPTO_TERMS_AND_CONDITIONS_CLICKED_AWAY } from 'store/actions/termsAndConditions';

const initialState: SettingsState = {
  themeName: 'light',
  // Remove this
  hasNotAgreedTo247Trading: true,
  selectedTechIndicators: '',
  // Remove this
  hasClosedSavingsPlanBonusBanner: false,
  acceptedSavingsPlanBonusTermsAt: null,
  stocksSwitch: true,
  hasClosedStocksRaffle: false,
  hasJoinedStocksRaffle: false,
  isStocksRafflePopupVisible: true,
  acceptedKansasCityChiefsRaffleTermsForWeeks: null,
  rejectedKansasCityChiefsRaffleTermsForWeeks: null,
  doNotAskForKansasCityChiefsRaffleTerms: false,
  isEligibleCandidateForStocks: false,
  tncPopupClosedAt: null,
  // Uncomment when stocks TNCs are live.
  // tncStocksPopupClosedAt: null,
  hasSeenStocksWinnersLosersModal: null,
  isEligibleForTopUps: false,
  hasClosedEidPopup: false,
  hasSeenTopUpsDataPrivacyPopup: false,
};

function root(state: SettingsState = initialState, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.CHANGE_THEME:
      return { ...state, themeName: action.themeName };

    case ActionTypes.SET_SELECTED_TECH_INDICATORS:
      return {
        ...state,
        selectedTechIndicators: action.payload.indicators,
      };

    case ActionTypes.ACCEPT_SAVINGS_PLAN_BONUS_TERMS:
      return {
        ...state,
        acceptedSavingsPlanBonusTermsAt: action.payload,
      };
    case ActionTypes.STOCKS_SWITCH:
      return {
        ...state,
        stocksSwitch: !state.stocksSwitch,
      };
    case ActionTypes.CLOSE_STOCK_RAFFLE:
      return {
        ...state,
        hasClosedStocksRaffle: true,
      };
    case ActionTypes.JOIN_STOCK_RAFFLE:
      return {
        ...state,
        hasJoinedStocksRaffle: true,
      };
    case ActionTypes.SET_WINNER_LOSER_MODAL_VISITED:
      return {
        ...state,
        hasSeenStocksWinnersLosersModal: true,
      };
    case ActionTypes.TOGGLE_STOCK_RAFFLE_VISIBILITY:
      return {
        ...state,
        isStocksRafflePopupVisible: !state.isStocksRafflePopupVisible,
      };
    case ActionTypes.setAcceptedKansasCityChiefsRaffleTermsForWeeks.type:
      return {
        ...state,
        acceptedKansasCityChiefsRaffleTermsForWeeks: action.payload,
      };
    case ActionTypes.setRejectedKansasCityChiefsRaffleTermsForWeeks.type:
      return {
        ...state,
        rejectedKansasCityChiefsRaffleTermsForWeeks: action.payload,
      };
    case ActionTypes.setDoNotAskForKansasCityChiefsRaffleTerms.type:
      return {
        ...state,
        doNotAskForKansasCityChiefsRaffleTerms: action.payload,
      };
    case CRYPTO_TERMS_AND_CONDITIONS_CLICKED_AWAY:
      return {
        ...state,
        tncPopupClosedAt: new Date().toISOString(),
      };
    // Uncomment when stocks TNCs are live.
    /* case STOCKS_TERMS_AND_CONDITIONS_CLICKED_AWAY:
      return {
        ...state,
        tncStocksPopupClosedAt: new Date().toISOString(),
      }; */
    case ActionTypes.USER_CLOSED_EID_PHONE_POPUP:
      return {
        ...state,
        hasClosedEidPopup: true,
      };
    case ActionTypes.SET_SEEN_TOP_UPS_DATA_PRIVACY_POPUP:
      return {
        ...state,
        hasSeenTopUpsDataPrivacyPopup: true,
      };
    default: {
      if (!state) return initialState;
      const { selectedTechIndicators = '' } = state;
      if (selectedTechIndicators === '') return state;

      return { ...state, selectedTechIndicators };
    }
  }
}

export default root;
