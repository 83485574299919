import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ModalTemplate from 'common/components/Modal';
import { Trans, useTranslation } from 'react-i18next';
import {
  PhoneText,
  SupportText,
  RepeatSendingText,
  Headline,
  InputWrapper,
  SupportLink,
  SendAgainText,
} from 'common/components/TwoFaFlow/2FAModal/styles';
import Button from 'common/components/Button';
import SecurityCodeInput from 'common/components/SecurityCodeInput';
import { getUser } from 'store/selectors/auth';
import {
  getHiddenPhone,
  validateInput,
  SECURITY_CODE_LENGTH,
} from 'common/components/TwoFaFlow/2FAModal/utils';
import { linkToSupportForm } from 'common/utils/mailAndHelpCenter';

type Props = {
  open: boolean;
  resendSms: () => void;
  handleSendCode?: () => void;
  error: string | undefined;
  setError: (newValue: string | undefined) => void;
  inputValue: string;
  setInputValue: (newValue: string) => void;
  handleClose: () => void;
  mainTextOrElement: string | ReactElement;
  buttonTextOrAction: string | ReactElement;
  customTitle?: string | undefined;
  isEmail?: boolean | undefined;
  actionInProgress?: boolean;
};

const TwoFAModal = ({
  open,
  resendSms,
  handleSendCode = () => null,
  inputValue,
  setInputValue,
  error,
  setError,
  handleClose,
  mainTextOrElement,
  buttonTextOrAction,
  customTitle,
  isEmail = false,
  actionInProgress,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const user = useSelector(getUser);

  const handleNextStepClick = () => {
    if (!validateInput(inputValue)) {
      setError(t('settings.taxExemption.twoFaModal.error'));
      return;
    }

    handleSendCode();
  };

  useEffect(() => {
    const inputWithoutSpaces = inputValue
      .split('')
      .filter((symbol) => symbol !== ' ')
      .join('');

    if (inputWithoutSpaces.length === SECURITY_CODE_LENGTH) {
      handleNextStepClick();
    }
  }, [inputValue]);

  const eraseError = () => setError(undefined);

  const hiddenPhone = user.mobileNumber && getHiddenPhone(user.mobileNumber);

  const definedTitle = customTitle || t('settings.taxExemption.twoFaModal.title');

  const definedActions =
    typeof buttonTextOrAction === 'string' ? (
      <Button
        isFullWidth
        title={t(buttonTextOrAction)}
        onClick={handleNextStepClick}
        size="modal"
        inProgress={actionInProgress}
      />
    ) : (
      buttonTextOrAction
    );
  const definedMainText =
    typeof mainTextOrElement === 'string' ? (
      <PhoneText>
        <Trans i18nKey={mainTextOrElement} values={{ phone: hiddenPhone }} components={{ 1: <b /> }} />
      </PhoneText>
    ) : (
      mainTextOrElement
    );

  return (
    <ModalTemplate
      modalId="common.2FAModal"
      defaultOpen={open}
      onCancel={handleClose}
      header={<Headline>{definedTitle}</Headline>}
      actions={definedActions}
    >
      {definedMainText}

      <InputWrapper>
        <SecurityCodeInput
          value={inputValue}
          label={t('settings.taxExemption.twoFaModal.inputLabel')}
          inputLength={SECURITY_CODE_LENGTH}
          setValue={setInputValue}
          error={error}
          eraseError={eraseError}
        />
      </InputWrapper>
      <SupportText>
        {`${t('settings.taxExemption.twoFaModal.supportText')} `}
        <SupportLink target="_blank" href={linkToSupportForm(user)}>
          {t('common.support')}
        </SupportLink>
      </SupportText>
      <RepeatSendingText>
        <Trans i18nKey={`settings.taxExemption.twoFaModal.repeatSending.${isEmail ? 'email' : 'sms'}`}>
          part0<SendAgainText onClick={resendSms}>part1</SendAgainText>
        </Trans>
      </RepeatSendingText>
    </ModalTemplate>
  );
};

TwoFAModal.defaultProps = {
  customTitle: undefined,
  handleSendCode: () => null,
  isEmail: false,
  actionInProgress: false,
};

export default TwoFAModal;
