import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitDeleteAccountBody } from 'common/api-types';
import { submitDeleteAccount as submitDeleteAccountAction } from 'store/actions/auth';
import { Action, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Button from '../../Button';
import { Wrapper } from './delete-account-styles';

type Props = {
  onCancelHandle: () => void;
  onSuccessHandle: () => void;
  onFailHandle: () => void;
  deleteReasonText: string;
};

type DispatchProps = {
  submitDeleteAccount: (payload: SubmitDeleteAccountBody, onSuccess: () => void, onFail: () => void) => void;
};

export const DeleteAccountActionsView = ({
  onCancelHandle,
  onSuccessHandle,
  onFailHandle,
  submitDeleteAccount,
  deleteReasonText,
}: Props & DispatchProps): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sendSubmitDeleteAccount = () => {
    setIsLoading(true);
    submitDeleteAccount(
      {
        reason: deleteReasonText,
      },
      () => {
        setIsLoading(false);
        onSuccessHandle();
      },
      () => {
        setIsLoading(false);
        onFailHandle();
      },
    );
  };

  const { t } = useTranslation();
  return (
    <Wrapper>
      <Button
        title={t('settings.terminateAccount.real.checkboxStep.body.confirm')}
        onClick={() => sendSubmitDeleteAccount()}
        inProgress={isLoading}
      />
      <Button title={t('common.cancel')} inverted onClick={() => onCancelHandle()} />
    </Wrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  submitDeleteAccount: bindActionCreators(submitDeleteAccountAction, dispatch),
});

export const DeleteAccountActions = connect(null, mapDispatchToProps)(DeleteAccountActionsView);
