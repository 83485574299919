import { createAction } from '@reduxjs/toolkit';
import {
  AssetDetailRequestPayload,
  AssetDetailSuccessPayload,
  AssetsRequest,
  AssetsSuccess,
  FetchSingleAssetPayload,
} from 'store/actions/assets';
import { Asset } from 'types/assets';
import { ApiError } from 'types/error';

// FETCH SINGLE ASSET
export const fetchSingleAsset = createAction<FetchSingleAssetPayload>('asset/request');

export const fetchSingleAssetSuccess = createAction<Asset | undefined>('asset/success');

export const fetchSingleAssetFailure = createAction<ApiError>('asset/error');

// FETCH MULTIPLE ASSETS
export const fetchAssets = createAction<AssetsRequest>('assets/request');

export const fetchAssetsSuccess = createAction<AssetsSuccess>('assets/success');

export const fetchAssetsFailure = createAction<ApiError>('assets/error');

// FETCH ALL CRYPTOS
export const fetchAvailableCryptos = createAction('cryptos/request');

// FETCH ASSET DETAIL
export const fetchAssetDetailRequest = createAction<AssetDetailRequestPayload>('assetDetail/request');

export const fetchAssetDetailSuccess = createAction<AssetDetailSuccessPayload>('assetDetail/success');

export const fetchAssetDetailFailure = createAction<ApiError>('assetDetail/error');
