import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { daysRemaining } from 'common/utils/formatting';
import { LANGUAGES } from 'common/const';
import { SummaryText, SummaryTextDiv } from '../styles';
import { format } from 'date-fns';

type Props = {
  price: string;
  amount: string;
  euroTotal: string;
  date: string;
  isBuy: boolean;
};

const StopOrderSummaryText = ({ price, amount, euroTotal, date, isBuy }: Props): ReactElement => {
  const { t, i18n } = useTranslation();
  const shownDate = format(new Date(date), t('l10n.dateFormat'));
  const translationText = `stopOrders.stopOrderDescription.${isBuy ? 'buy' : 'sell'}`;
  const { language } = i18n;
  const shouldAmountAndEuroBeSwitched = language === LANGUAGES.de && isBuy;
  return (
    <SummaryTextDiv>
      <SummaryText>
        {t(`${translationText}.a`)}
        <strong>{price} </strong>
        {t(`${translationText}.b`)}
        <strong>{shouldAmountAndEuroBeSwitched ? euroTotal : amount} </strong>
        {t(`${translationText}.c`)}
        <strong>{shouldAmountAndEuroBeSwitched ? amount : euroTotal} </strong>
        {t(`${translationText}.d`, {
          date: `${shownDate} ${t('stopOrders.detail.validInDays', {
            days: daysRemaining(new Date(date)),
          })}`,
        })}
      </SummaryText>
    </SummaryTextDiv>
  );
};

export default StopOrderSummaryText;
