import { State } from 'store/types/store';
import { createSelector } from '@reduxjs/toolkit';
import { getSelectedTimePeriod } from 'store/selectors/currency';

export const getPortfolioChart = (state: State) => state.portfolioChart;

export const getPortfolioChartBySelectedTimePeriod = createSelector(
  [getPortfolioChart, getSelectedTimePeriod],
  (portfolioChart, timePeriod) => portfolioChart?.[timePeriod],
);
