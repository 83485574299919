import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import Button from 'common/components/Button';
import ModalTemplate from 'common/components/Modal';
import { Text } from 'scenes/Kyc/SecuritiesKyc/IntroModal/styles';
import { Title, Actions } from './styles';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const PendingRenewKycModal: FC<Props> = ({ open, setOpen }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      defaultOpen={open}
      header={<Title>{t('kyc.renewDocs.pending.modal.title')}</Title>}
      onCancel={() => setOpen(false)}
      actions={
        <Actions>
          <Button isFullWidth size="modal" onClick={() => setOpen(false)} title={t('common.close')} />
        </Actions>
      }
    >
      <Text>
        <ReactMarkdown>{t('kyc.renewDocs.pending.modal.text')}</ReactMarkdown>
      </Text>
    </ModalTemplate>
  );
};

export default PendingRenewKycModal;
