import { all, call, fork, put, select, take } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { paths } from 'common/urls';
import * as currencyActions from 'store/actions/currency';
import * as portfolioActions from 'store/slices/portfolio/actions';
import { getCurrentPath, getKeepScroll, isFirstRendered } from 'store/selectors/navigation';
import { getAfterLoginFlag } from 'store/selectors/localSettings';
import { setAfterLoginFlag } from 'store/actions/localSettings';
import { BUY_SELL_VALUES } from 'common/const';
import { priceAlertTypes } from 'common/components/PriceAlertsForm/helpers';
import { generatePath, matchPath } from 'react-router-dom';
import { LOCATION_CHANGE } from 'store/actions/navigation';
import { getAssetsIds } from 'store/slices/assets/selectors';
import { ASSET_CLASSES } from 'types/assets';
import { State } from 'store/types/store';

// ******************************* SCREEN CHANGES ******************************
const screensMap = {
  // roots
  [paths.PORTFOLIO_CRYPTO]: [portfolioActions.syncPortfolio],
  [paths.MARKET_OVERVIEW_CRYPTO]: [currencyActions.syncTechIndicatorData],
  [paths.TECH_INDICATORS]: [currencyActions.syncTechIndicatorData],
};

// Base views with their corresponding detail-views
const detailBaseUrls = [
  {
    base: paths.MARKET_OVERVIEW,
    detail: paths.MARKET_OVERVIEW_CRYPTO,
  },
  {
    base: paths.TECH_INDICATORS,
    detail: paths.TECH_INDICATORS_CRYPTO,
  },
];

const types = {
  buySellType: ':type',
  priceAlertType: ':priceAlertType',
};

const typeValuesMap = {
  [types.buySellType]: BUY_SELL_VALUES,
  [types.priceAlertType]: priceAlertTypes,
};

// Use the same screenMap values for detail screens as well

const getScreenMap = (path: string) => {
  const screenKey = Object.keys(screensMap).find((key: string) => !!matchPath({ path: key }, path));

  return screenKey ? screensMap[screenKey] : [];
};

export function* changeScreenOnce(): Saga<void> {
  // Scroll to top on each navigation
  const keepScroll = yield select(getKeepScroll);
  const afterLoginFlag = yield select(getAfterLoginFlag);
  if (!keepScroll) {
    window.scrollTo(0, 0);
  }
  const fromScreenName = yield select(getCurrentPath);
  const isFirstRendering = yield select(isFirstRendered);

  const ids = yield select((state: State) => getAssetsIds(state, ASSET_CLASSES.CRYPTO));

  ids.reduce((acc: typeof screensMap, crypto: string) => {
    detailBaseUrls.forEach((url) => {
      if (Object.values(types).some((type) => url.detail.includes(type))) {
        Object.values(types).forEach((type) => {
          if (url.detail.includes(type)) {
            Object.values(typeValuesMap[type]).forEach((typeValue) => {
              const typeKey = type.replace(':', '');
              const detailUrl = generatePath(url.detail, { currency: crypto, [typeKey]: typeValue });
              acc[detailUrl] = screensMap[url.base];
            });
          }
        });
        return;
      }

      const detailUrl = generatePath(url.detail, { currency: crypto });

      if (acc[detailUrl]) return;
      acc[detailUrl] = screensMap[url.base];
    });
    return acc;
  }, screensMap);

  const fromScreenSyncs = getScreenMap(fromScreenName);

  if (isFirstRendering || afterLoginFlag) {
    const currentSync = fromScreenSyncs.map((sync) => put(sync.START));
    if (currentSync.length) {
      yield put(setAfterLoginFlag(false));
    }
    yield all(currentSync);
  }
  const { location } = yield take(LOCATION_CHANGE);
  const toScreenName = location.pathname;
  const toScreenSyncs = screensMap[toScreenName] || [];

  const fromMinusToSyncs = fromScreenSyncs
    .filter((x) => !toScreenSyncs.includes(x))
    .map((sync) => put(sync.STOP));
  yield all(fromMinusToSyncs);

  // B - A
  const toMinusFromSyncs = toScreenSyncs
    .filter((x) => !fromScreenSyncs.includes(x))
    .map((sync) => put(sync.START));
  yield all(toMinusFromSyncs);
}

// on screen changes, cancels previous screen's sagas and starts new ones
function* watchChangeScreen(): Saga<void> {
  while (true) {
    yield call(changeScreenOnce);
  }
}

export default function* root(): Saga<void> {
  yield all([fork(watchChangeScreen)]);
}
