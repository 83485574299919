import { AnyAction } from 'redux';
import * as ActionTypes from '../actions/orders';
import { LimitOrderState } from 'store/types/limitOrders';

const initialState: LimitOrderState = {
  isFetching: false,
  tradingRules: [],
  deleteTradingRuleDetails: {
    uniqueId: '',
    type: null,
    entity: '',
    priceLimit: '',
    cryptoAmount: '',
    euroAmount: '',
    validUntil: null,
  },
};

export default function limitOrders(state: LimitOrderState = initialState, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.FETCH_TRADING_RULES:
      return {
        ...state,
        isFetching: true,
      };
    case ActionTypes.FETCH_TRADING_RULES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        tradingRules: action.payload.response
          .filter(({ status }) => status === 'Created' || status === 'Confirmed')
          .slice() // copy
          .reverse(), // mutate in-place
      };
    }
    case ActionTypes.FETCH_TRADING_RULES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case ActionTypes.SAVE_DELETING_TRADING_RULE:
      return {
        ...state,
        deleteTradingRuleDetails: action.payload,
      };
    default:
      return state;
  }
}
