import { themes } from 'common/const';

export const colors = {
  // Coin colors
  eur: '#5262af',
  xrp: '#a8cced',
  ltc: '#5684b7',
  eth: '#8ff6df',
  btc: '#56b7a1',
  bch: '#478559',

  // Bison custom colors
  pureBlack: '#000000',
  blackSecondary: '#4D4D4D',
  altBlack: '#252525',
  blackTransparent: 'rgba(0, 0, 0, 0.12)',
  pureWhite: '#FFFFFF',
  whiteOpacity: '#FFFFFFCC',
  main: '#74CDDC',
  darkMain: '#336474',
  orange: '#e09f36',
  red: '#E53B47',
  white: '#F9F9F9',
  gray: '#F1F2F3',
  dark: '#153243',
  gray1: '#cbcbcb',
  gray2: '#8E8E93',
  gray3: '#F2F2F2',
  gray4: '#CCCCCC',
  gray5: '#ced3d666',
  gray6: '#DADADA',
  gray7: '#757575',
  gray8: '#CED3D6',
  gray9: '#fcfcfd',
  green: '#72BF00',
  lightMain: '#d8ecf2',
  transparent: 'transparent',
  grayTransparent: 'rgba(17, 51, 83, 0.6)',
  yellow: 'rgba(255, 206, 43, 0.95)',
  yellowDeep: 'rgba(255, 199, 0, 1)',
  yellowTransparent: 'rgba(255, 206, 43, 0.2)',
  yellowLight: '#FFF8DF',
  yellowDeepTransparent: 'rgba(247, 147, 26, 0.12)',
  textBlue: '#B6C6DA',
  textBlueOpacity: '#B6C6DA33',
  mainTransparent: 'rgba(116, 205, 220, 0.12)',
  footerColor: '#03121B',
  footerLogo: '#a4a4a5',
  inactiveCell: 'rgba(255, 255, 255, 0.12)',
  whiteWithOpacity: 'rgba(255, 255, 255, 0.4)',
  iconBgDark: '#E6F3F9',
  iconBgLight: '#153243',
  // charts
  portfolioDarkChartLine: '#B4E6EE',
  chartLine: '#FFFFFF',
  marketChartLine: '#74CDDC',
  chartBg: '#D8ECF2',
  chartGradient: ['rgba(8, 56, 101, 0.64)', 'rgba(9, 57, 102, 0.512)', 'rgba(0, 43, 63, 0)'],
  marketChartGradient: ['rgba(115, 205, 221, 0.6)', 'rgba(59, 187, 210, 0.444)', 'rgba(115, 205, 221, 0.06)'],
  positiveGradient: ['rgba(114, 191, 0, 0.4)', 'rgba(114, 191, 0, 0.4)', 'rgba(255, 255, 255, 0)'],
  negativeGradient: ['rgba(229, 59, 71, 0.4)', 'rgba(229, 59, 71, 0.4)', 'rgba(255, 255, 255, 0)'],
  boxShadowGradientDark: `0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)`,
  boxShadowGradientLight: `0px 5px 5px -3px rgb(116 205 220 / 20%), 0px 8px 10px 1px rgb(116 205 220 / 14%), 0px 3px 14px 2px rgb(116 205 220 / 12%)`, // the rgb color is #74CDDC
  defaultTechIndicator: '#8884d8',
  chipsRGBBase: '115, 205, 221',
  grayDisabled: '#A4A4A5',
  techIndicators: [
    {
      label: 'SMA (10)',
      color: '#f26fe4',
    },
    {
      label: 'SMA (20)',
      color: '#72BF00',
    },
    {
      label: 'SMA (50)',
      color: '#F2A900',
    },
    {
      label: 'SMA (100)',
      color: '#F0E68C',
    },
    {
      label: 'RSI (10)',

      color: '#f26fe4',
    },
    {
      label: 'RSI (20)',

      color: '#F2A900',
    },
    {
      label: 'RSI (50)',

      color: '#72BF00',
    },
    {
      label: 'RSI (100)',
      color: '#FFC0CB',
    },
    {
      label: 'POS',
      color: '#b5f5b5',
    },
    {
      label: 'NEG',
      color: '#fcd2d8',
    },
  ],
  rsiStopColor: '82ca9d',

  // Dark mode

  darkShade3: '#001D2A',
  darkShade4: '#002B3F',
  darkShade5: '#356876',
  darkBlue06: '#1F3D51',
  darkBlue: '#A8CED8',

  loadingBackgroundGray: '#F5F5F6',
  highlighted: '#50A2E0',

  newSuccess: '#00C14D',
  newCritical: '#F83A3A',
  newWarning: '#FFC700',
  newInfo: '#3F94FF',

  shadowColors: {
    success: 'rgba(0, 193, 77, 0.12)',
    error: 'rgba(248, 58, 58, 0.12)',
    info: 'rgba(63, 148, 255, 0.12)',
    warning: 'rgba(255, 199, 0, 0.24)',
  },
};

const themeColors = {
  [themes.light]: {
    foreground: {
      primary: colors.pureBlack,
      primaryLight: colors.gray2,
      primaryInverted: colors.pureWhite,
      secondary: colors.gray2,
      critical: colors.red,
      positive: colors.green,
      caution: colors.yellow,
      cautionIcon: colors.yellow,
      focused: colors.main,
      footer: {
        text: colors.gray6,
        header: colors.textBlue,
        logo: colors.footerLogo,
      },
      formAccent: colors.pureWhite,
      formAccentInverted: colors.pureBlack,
      inactive: colors.textBlue,
      tradingBanner: colors.blackSecondary,
      highlighted: colors.highlighted,
      disabledInput: colors.footerLogo,
      disabledText: colors.grayDisabled,
    },
    background: {
      primary: colors.white,
      primaryInverted: colors.pureBlack,
      secondary: colors.pureWhite,
      info: colors.gray3,
      caution: colors.yellowTransparent,
      coutionLight: colors.yellowLight,
      emph: colors.lightMain,
      positive: colors.green,
      input: colors.transparent,
      formAccent: colors.altBlack,
      formAccentInverted: colors.transparent,
      footer: colors.footerColor,
      critical: colors.red,
      criticalLight: '#FFEDEE',
      overlay: colors.grayTransparent,
      tableHeader: colors.gray3,
      depositOption: colors.gray9,
      switcher: colors.pureBlack,
      selected: colors.gray3,
      action: colors.gray,
      light: colors.mainTransparent,
      tradingBanner: colors.gray5,
      chipsBase: colors.chipsRGBBase,
      loading: colors.loadingBackgroundGray,
      progressIndicator: colors.gray4,
      boxShadow: colors.boxShadowGradientDark,
      radioButton: colors.main,
      stockRaffleBanner: '#071C28',
      stockRaffleCloseBanner: '#2a5562',
      moneyButton: colors.main,
      bankCard: colors.gray,
      bankCardLine: colors.gray8,
      notifications: {
        success: {
          mainColor: colors.newSuccess,
          boxShadow: colors.shadowColors.success,
        },
        error: {
          mainColor: colors.newCritical,
          boxShadow: colors.shadowColors.error,
        },
        info: {
          mainColor: colors.newInfo,
          boxShadow: colors.shadowColors.info,
        },
        warning: {
          mainColor: colors.newWarning,
          boxShadow: colors.shadowColors.warning,
        },
      },
      cookieBanner: colors.gray3,
      cryptoShortButton: colors.blackTransparent,
      cryptoShortBtcButton: colors.yellowDeepTransparent,
    },
    border: {
      input: colors.gray1,
      standard: colors.gray1,
      inputFocused: colors.main,
      formAccent: colors.altBlack,
      formAccentInverted: colors.altBlack,
      critical: colors.red,
      table: colors.gray6,
    },
    charts: {
      tooltipText: colors.darkMain,
      chartLine: colors.chartLine,
      chartGradient: colors.chartGradient,
      marketChartLine: colors.marketChartLine,
      marketChartGradient: colors.marketChartGradient,
      positiveGradient: colors.positiveGradient,
      negativeGradient: colors.negativeGradient,
      defaultTechIndicator: colors.defaultTechIndicator,
      techIndicators: colors.techIndicators,
      rsiStopColor: colors.rsiStopColor,
      background: colors.chartBg,
      emptyCell: colors.textBlue,
      donutData: colors.blackSecondary,
      donutCryptoLabel: colors.pureWhite,
      donutStockLabel: colors.pureBlack,
      lineChartStroke: colors.pureBlack,
    },
    icons: {
      neutral: colors.gray,
      white: colors.pureWhite,
      svgBg: colors.iconBgDark,
    },
    toggleSwitch: {
      enabled: colors.green,
      disabled: colors.gray,
      switchBase: colors.pureWhite,
      switchBaseDisabled: colors.pureWhite,
      border: colors.gray1,
    },
  },
  [themes.dark]: {
    foreground: {
      primary: colors.white,
      primaryLight: colors.white,
      primaryInverted: colors.pureBlack,
      secondary: colors.darkBlue,
      critical: colors.red,
      positive: colors.green,
      caution: colors.yellow,
      cautionIcon: colors.pureBlack,
      focused: colors.main,
      footer: {
        text: colors.gray6,
        header: colors.textBlue,
        logo: colors.footerLogo,
      },
      formAccent: colors.pureBlack,
      formAccentInverted: colors.main,
      inactive: colors.darkBlue,
      tradingBanner: colors.whiteOpacity,
      footerSectionHeader: colors.textBlue,
      highlighted: colors.highlighted,
      disabledInput: colors.gray5,
      disabledText: colors.grayDisabled,
    },
    background: {
      primary: colors.darkShade3,
      primaryInverted: colors.pureWhite,
      secondary: colors.darkShade4,
      input: colors.darkShade3,
      inputDisabled: colors.darkShade3,
      inputFocused: colors.darkShade3,
      info: colors.gray3,
      caution: colors.yellow,
      coutionLight: colors.yellow,
      emph: colors.darkBlue,
      positive: colors.green,
      formAccent: colors.main,
      formAccentInverted: colors.transparent,
      footer: colors.footerColor,
      overlay: colors.grayTransparent,
      critical: colors.red,
      criticalLight: colors.red + '0D',
      tableHeader: colors.darkBlue06,
      depositOption: colors.darkBlue06,
      switcher: colors.main,
      selected: colors.main,
      action: colors.dark,
      light: colors.grayTransparent,
      tradingBanner: colors.textBlueOpacity,
      chipsBase: colors.chipsRGBBase,
      loading: colors.loadingBackgroundGray,
      progressIndicator: colors.gray5,
      boxShadow: colors.boxShadowGradientLight,
      radioButton: colors.darkShade4,
      stockRaffleBanner: '#6432FA',
      stockRaffleCloseBanner: '#b7e0e9',
      moneyButton: colors.main,
      bankCard: colors.gray,
      bankCardLine: colors.gray8,
      notifications: {
        success: {
          mainColor: colors.newSuccess,
          boxShadow: colors.shadowColors.success,
        },
        error: {
          mainColor: colors.newCritical,
          boxShadow: colors.shadowColors.error,
        },
        info: {
          mainColor: colors.newInfo,
          boxShadow: colors.shadowColors.info,
        },
        warning: {
          mainColor: colors.newWarning,
          boxShadow: colors.shadowColors.warning,
        },
      },
      cookieBanner: colors.darkBlue06,
      cryptoShortButton: colors.main,
      cryptoShortBtcButton: colors.main,
    },
    border: {
      input: colors.darkShade3,
      standard: colors.darkShade5,
      inputFocused: colors.main,
      formAccent: colors.transparent,
      formAccentInverted: colors.main,
      critical: colors.red,
      table: colors.gray6,
    },
    charts: {
      tooltipText: colors.darkMain,
      chartLine: colors.portfolioDarkChartLine,
      chartGradient: colors.chartGradient,
      marketChartLine: colors.marketChartLine,
      marketChartGradient: colors.marketChartGradient,
      positiveGradient: colors.positiveGradient,
      negativeGradient: colors.negativeGradient,
      defaultTechIndicator: colors.defaultTechIndicator,
      techIndicators: colors.techIndicators,
      rsiStopColor: colors.rsiStopColor,
      background: colors.darkShade4,
      emptyCell: colors.inactiveCell,
      donutData: colors.whiteWithOpacity,
      donutCryptoLabel: colors.pureWhite,
      donutStockLabel: colors.pureBlack,
      lineChartStroke: colors.pureBlack,
    },
    icons: {
      neutral: colors.gray,
      white: colors.pureWhite,
      svgBg: colors.iconBgLight,
    },
    toggleSwitch: {
      enabled: colors.main,
      disabled: colors.gray,
      switchBase: colors.darkMain,
      switchBaseDisabled: colors.gray1,
      border: colors.gray1,
    },
  },
};

export default themeColors;
