import { action } from './_common';

export const FETCH_SAVINGS_PLANS_REQUEST = 'FETCH_SAVINGS_PLANS_REQUEST';
export const fetchSavingsPlansRequest = () => action(FETCH_SAVINGS_PLANS_REQUEST);

export const INVALIDATE_SAVINGS_PLANS_REQUEST = 'INVALIDATE_SAVINGS_PLANS_REQUEST';
export const invalidateSavingsPlansRequest = () => action(INVALIDATE_SAVINGS_PLANS_REQUEST);

export const FETCH_SAVINGS_PLANS_SUCCESS = 'FETCH_SAVINGS_PLANS_SUCCESS';
export const fetchSavingsPlansSuccess = (payload) => action(FETCH_SAVINGS_PLANS_SUCCESS, { payload });

export const FETCH_SAVINGS_PLANS_FAILURE = 'FETCH_SAVINGS_PLANS_FAILURE';
export const fetchSavingsPlansFailure = (error) => action(FETCH_SAVINGS_PLANS_FAILURE, { error });

export const PUT_SAVINGS_PLAN = 'PUT_SAVINGS_PLAN';
export const putSavingsPlanRequest = (payload) => action(PUT_SAVINGS_PLAN, { payload });

export const PUT_SAVINGS_PLAN_SUCCESS = 'PUT_SAVINGS_PLAN_SUCCESS';
export const putSavingsPlanSuccess = (payload) => action(PUT_SAVINGS_PLAN_SUCCESS, { payload });

export const PUT_SAVINGS_PLAN_FAILURE = 'PUT_SAVINGS_PLAN_FAILURE';
export const putSavingsPlanFailure = (error, payload) => action(PUT_SAVINGS_PLAN_FAILURE, { payload });

export const SAVE_DELETING_SAVINGS_PLAN = 'SAVE_DELETING_SAVINGS_PLAN';
export const saveDeletingSavingsPlan = (payload) => action(SAVE_DELETING_SAVINGS_PLAN, { payload });

export const CREATE_SAVINGS_PLAN = 'CREATE_SAVINGS_PLAN';
export const createSavingsPlan = (payload, onSuccess, onFail) =>
  action(CREATE_SAVINGS_PLAN, {
    payload,
    onSuccess,
    onFail,
  });

export const CREATE_SAVINGS_PLAN_SUCCESS = 'CREATE_SAVINGS_PLAN_SUCCESS';
export const createSavingsPlanSuccess = (payload) => action(CREATE_SAVINGS_PLAN_SUCCESS, { payload });

export const CREATE_SAVINGS_PLAN_FAILURE = 'CREATE_SAVINGS_PLAN_FAILURE';
export const createSavingsPlanFailure = (error) => action(CREATE_SAVINGS_PLAN_FAILURE, { error });

export const DELETE_SAVINGS_PLAN = 'DELETE_SAVINGS_PLAN';
export const deleteSavingsPlan = (payload, navigate) => action(DELETE_SAVINGS_PLAN, { payload, navigate });

export const DELETE_SAVINGS_PLAN_SUCCESS = 'DELETE_SAVINGS_PLAN_SUCCESS';
export const deleteSavingsPlanSuccess = (payload) => action(DELETE_SAVINGS_PLAN_SUCCESS, { payload });

export const DELETE_SAVINGS_PLAN_FAILURE = 'DELETE_SAVINGS_PLAN_FAILURE';
export const deleteSavingsPlanFailure = (payload) => action(DELETE_SAVINGS_PLAN_FAILURE, { payload });
