import styled from 'styled-components';
import { Theme } from 'types/theme';
import { getBodyFontStyles, getHeaderFontStyles, getTextStyles } from 'common/styles/global';
import { devices, dimensions } from 'common/styles';
import { devicesHeight } from 'common/styles/devices';
import {
  ModalDimensionsType,
  MODAL_DIMENSIONS,
  ModalActionsMarginType,
  MODAL_ACTIONS_MARGIN,
} from 'common/const/modals';
import { Modal } from '../../lib/semantic-ui';
import Icon from '../Icon';

const getModalDimensions = (dimension: ModalDimensionsType) => {
  switch (dimension) {
    case MODAL_DIMENSIONS.SMALL:
      return `max-height: calc(100% - 32px);
              
              @media${devices.mobileL} {
                max-width: 328px;
              }
              
              @media${devices.maxMobileL} {
                max-width: unset;
                width: calc(100% - 32px) !important;
                }`;
    case MODAL_DIMENSIONS.WIDE:
      return `height: calc(100vh - 32px);

              @media${devices.mobileL} {
                 width: 88%;;
              }
              
              @media${devices.laptopL} {
              width: 900px;
              }
              
              @media${devices.maxMobileL} {
                max-width: unset;
                width: calc(100% - 32px) !important;
                }`;
    default:
      return `height: auto;
              max-height: calc(100% - 32px);
  
              @media${devices.mobileL} {
                max-width: ${dimensions.modalMaxWidth}px;
              }
              
              @media${devices.maxMobileL} {
                max-width: unset;
                width: calc(100% - 32px) !important;
                }`;
  }
};

const getModalActionsMargin = (margin: ModalActionsMarginType) => {
  switch (margin) {
    case MODAL_ACTIONS_MARGIN.BIG:
      return `@media${devices.mobileL} {
                padding: 101px ${dimensions.modalPadding}px ${dimensions.modalPadding}px ${dimensions.modalPadding}px !important;
              }
              @media${devices.maxMobileL} {
                padding: 101px ${dimensions.mediumPadding}px ${dimensions.mediumPadding}px ${dimensions.mediumPadding}px !important;
              }`;
    default:
      return `@media${devices.mobileL} {
                padding: 27px ${dimensions.modalPadding}px ${dimensions.modalPadding}px ${dimensions.modalPadding}px !important;
              }
              @media${devices.maxMobileL} {
                padding: 27px ${dimensions.mediumPadding}px ${dimensions.mediumPadding}px ${dimensions.mediumPadding}px !important;
              }`;
  }
};

const getModalHeaderPadding = (invertedHeader: boolean) => {
  if (invertedHeader) {
    return `@media${devices.mobileL} {
              padding: 0px ${dimensions.modalPadding}px 0 ${dimensions.modalPadding}px !important;
            }
            @media${devices.maxMobileL} {
              padding: 0px ${dimensions.mediumPadding}px 0 ${dimensions.mediumPadding}px !important;
            }`;
  }

  return `@media${devices.mobileL} {
            padding: ${dimensions.modalPadding}px ${dimensions.modalHeaderPaddingRight}px 0 ${dimensions.modalPadding}px !important;
          }
          @media${devices.maxMobileL} {
            padding: ${dimensions.mediumPadding}px ${dimensions.modalHeaderMediumPaddingRight}px 0 ${dimensions.mediumPadding}px !important;
          }`;
};

export const ModalWrapper = styled(Modal).attrs({
  dimmer: 'blurring',
})<{
  $isHeaderHidden?: boolean;
  $noRoundedBorders?: boolean;
  $invertedHeader?: boolean;
  $modalDimensions?: ModalDimensionsType;
  $actionsMargin: ModalActionsMarginType;
}>`
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.secondary} !important;

  border-radius: ${({ $noRoundedBorders }: { $noRoundedBorders: boolean }) =>
    $noRoundedBorders ? 0 : `${dimensions.modalBorderRadius}px`} !important;

  &.visible.transition {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
  }

  &.ui.modal > .header {
    border: none !important;
    font-family: 'Open Sans', sans-serif !important;
    ${({
      theme,
      $invertedHeader,
      $noRoundedBorders,
      $modalDimensions,
    }: {
      theme: Theme;
      $invertedHeader: boolean;
      $noRoundedBorders: boolean;
      $modalDimensions: ModalDimensionsType;
    }) =>
      `background:  ${$invertedHeader ? theme.color.background.formAccent : 'none'} !important;
       height: ${$invertedHeader ? '50px' : 'unset'};
        ${
          $invertedHeader
            ? `display: flex;
               align-items: center;`
            : 'display: block;'
        }
       
       ${getModalHeaderPadding($invertedHeader)}

       ${
         $invertedHeader &&
         `color: ${theme.color.foreground.primaryInverted};
         ${
           $noRoundedBorders
             ? ''
             : ` border-top-left-radius: ${dimensions.modalBorderRadius}px !important;
             border-top-right-radius: ${dimensions.modalBorderRadius}px !important;`
         }
      `
       }
    `}
  }

  &.ui.modal > .content {
    max-height: unset !important;

    ${({ $isHeaderHidden, $invertedHeader }: { $isHeaderHidden: boolean; $invertedHeader: boolean }) => `
     padding: ${$isHeaderHidden ? '20px' : 0} 5px 0 0 !important;
     
     @media${devices.mobileL} {
       padding: ${$isHeaderHidden ? dimensions.modalPadding : 0}px ${dimensions.modalPadding}px 0 ${
      dimensions.modalPadding
    }px !important;
     }
     @media${devices.maxMobileL} {
       padding: ${$isHeaderHidden ? dimensions.modalPadding : 0}px ${dimensions.mediumPadding}px 0 ${
      dimensions.mediumPadding
    }px !important;
     }
     
     ${
       $isHeaderHidden &&
       !$invertedHeader &&
       `
        & > *:first-child {
          & > *:first-child, &:not(:has(*)) {
            @media${devices.mobileL} {
              padding-right: ${dimensions.modalContentPaddingRight}px !important;
            }
          
            @media${devices.maxMobileL} {
              padding-right: ${dimensions.modalContentMediumPaddingRight}px !important;
            }
          }
        }
     `
     }
    `}
  }

  &.ui.modal > .actions {
    background: none !important;
    border: none !important;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media${devices.mobileL} {
      padding: 0 ${dimensions.modalPadding}px ${dimensions.modalPadding}px ${dimensions.modalPadding}px !important;
    }
    @media${devices.maxMobileL} {
      padding: 0 ${dimensions.mediumPadding}px ${dimensions.mediumPadding}px ${dimensions.mediumPadding}px !important;
    }

    &.actions:not(:empty) {
      ${({ $actionsMargin }: { $actionsMargin: ModalActionsMarginType }) =>
        getModalActionsMargin($actionsMargin)}
    }
  }

  margin: 0 auto !important;

  min-height: 50vh;

  ${({ $modalDimensions }: { $modalDimensions: ModalDimensionsType }) =>
    getModalDimensions($modalDimensions)} @media${devices.maxMobileL} {
    margin: 0 !important;

    &.ui.modal > .actions {
      flex-direction: column;
      justify-content: space-around;
      padding-top: 20px !important;
      @media${devices.mobileS} {
        > div {
          margin-bottom: 10px;
        }
      }
    }
  }

  @media ${devices.tablet} {
    margin: 1rem auto !important;
    height: unset;
    min-height: ${dimensions.modalMinHeight}px;
    max-height: ${dimensions.modalMaxHeight}px;
  }
`;

export const ModalContent = styled(Modal.Content).attrs({
  scrolling: true,
  className: 'body-text',
})`
  ${({ theme }: { theme: Theme }): any => `
      align-self: stretch;
      flex-grow: 1;
      padding: 0 5px 0 0 !important;
      
      background: transparent !important;

      /* width */
      ::-webkit-scrollbar {
        width: 5px;
        background: transparent;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        background: ${theme.color.background.primary};
        border-radius: 3px;
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${theme.color.foreground.footer.text};
        border-radius: 3px;
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: ${theme.color.foreground.secondary};
      }
      
      p,
      li {
        ${getBodyFontStyles(theme)};
        color: ${theme.color.foreground.primary};
      }
     
      
      h1 {
        font-weight: ${theme.typography.heading.weight.regular};
        ${getHeaderFontStyles('4', theme)};
        
         @media ${devices.tablet} {
          ${getHeaderFontStyles('1', theme)};
          }
      }

      h2 { 
      font-weight: ${theme.typography.heading.weight.regular};
      ${getTextStyles('xlarge', theme)};
      
        @media ${devices.tablet} {
        ${getHeaderFontStyles('2', theme)}; 
        }
      }

      h3 {
          font-weight: ${theme.typography.heading.weight.regular};
           ${getTextStyles('large', theme)};
           
          @media ${devices.tablet} { 
           ${getHeaderFontStyles('3', theme)}; 
           }
          
      }

      h4 {
          font-weight: ${theme.typography.heading.weight.regular};
           ${getTextStyles('standard', theme)};
           
          @media ${devices.tablet} {  
           ${getHeaderFontStyles('4', theme)};
            }
          
      }
  `};

  img {
    max-width: 100%;
  }

  a {
    text-decoration: underline;
  }
`;

export const ModalContentSticky = styled.div`
  position: sticky;
  display: inline-block;
  width: 100%;
  top: 0;
  z-index: 1;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.secondary};

  @media ${devicesHeight.maxSmall} {
    position: static;
  }
`;

export const ModalContentStickyBottom = styled(ModalContentSticky)`
  top: unset;
  bottom: -1px;
`;

export const ModalHeader = styled(Modal.Header)`
  @media${devices.maxMobileL} {
    & > * {
      font-size: 20px;
      line-height: 27px;
    }
  }
`;

export const ModalActions = styled(Modal.Actions)`
  justify-content: space-evenly !important;
  margin-top: auto;

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media ${devicesHeight.maxSmall} {
    margin-top: 20px;
  }
`;

export const CloseIcon = styled(Icon)<{ $invertedHeader?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  padding: ${({ $invertedHeader }) => ($invertedHeader ? 17 : 29)}px;
  cursor: pointer;
  z-index: 2;

  @media${devices.maxMobileL} {
    padding: 21px;
    font-size: 24px;
  }
`;

export const BackIcon = styled(CloseIcon)`
  left: 0;
  right: unset;
`;
