import I18n from 'common/lib/I18n';
import I18next from 'i18next';
import { KB_SUFFIX, MB_SUFFIX } from 'common/const/fileSizes';
import { Asset, Crypto, CryptoAssets } from 'types/assets';
import { differenceInDays } from 'date-fns/fp';
import { format as dateFnsformat } from 'date-fns';

type HelperOptions = {
  precision?: number;
  strip_insignificant_zeros?: boolean;
  sign_first?: boolean;
  customPrecision?: number;
  separator?: string;
  delimiter?: string;
  disableSign?: boolean;
  prefixPositiveNumberWithPlusSign?: boolean;
};

type NumberOrString = number | string;

export const separators = (): { decimalSeparator: string; groupingSeparator: string } => {
  const decimalSeparator = I18next.t('l10n.delimiter');
  const groupingSeparator = I18next.t('l10n.separator');
  return {
    decimalSeparator,
    groupingSeparator,
  };
};

export const defaultFiatSymbol = '€';
export const fiatFormat = (): string => I18next.t('l10n.cuFormat');
export const cryptoPrecision = 8;
export const fiatPrecision = 2;
export const inputIntegerCount = 9;

export const capitalize = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1);

const defaultOptions = (): { separator: string; delimiter: string; strip_insignificant_zeros: boolean } => {
  const { groupingSeparator, decimalSeparator } = separators();
  return {
    separator: decimalSeparator,
    delimiter: groupingSeparator,
    strip_insignificant_zeros: false,
  };
};

const toNumberLocale = (number: NumberOrString, options?: HelperOptions): string => {
  const args = I18n.prepareOptions(options ?? {}, { precision: 2 }, defaultOptions());
  return I18n.toNumber(number, args);
};

export const helpers = {
  toCurrencyLocale: (
    number: NumberOrString,
    options?: HelperOptions,
    unit?: string,
    format?: string,
  ): string => {
    const args = I18n.prepareOptions(options, { precision: fiatPrecision, unit, format }, defaultOptions());
    return I18n.toCurrency(number, args);
  },
  toCurrencyLocaleNoSymbol: (number: NumberOrString, options?: HelperOptions): string => {
    const precision = options?.customPrecision !== undefined ? options?.customPrecision : fiatPrecision;
    const args = I18n.prepareOptions(options, { precision }, defaultOptions());
    return toNumberLocale(number, args);
  },
  toNumberLocale,
  toPercentageLocale: (number: NumberOrString, options?: HelperOptions): string => {
    const args = I18n.prepareOptions(options ?? {}, { precision: 2 }, defaultOptions());
    return I18n.toPercentage(number, args);
  },
  cryptoPrecision,
  fiatPrecision,
};

export const calculateAdaptivePrecision = (number: NumberOrString): number => {
  const n = typeof number === 'string' ? parseFloat(number) : number;

  const absoluteNumber = Math.abs(n);

  if (absoluteNumber < 0.01) return 8;
  if (absoluteNumber < 1) return 5;
  if (absoluteNumber < 5) return 3;

  return 2;
};

const adaptivePrecisionOptions = (input: NumberOrString, customPrecision?: number): HelperOptions => {
  const n = typeof input === 'string' ? parseFloat(input) : input;
  const options = {
    strip_insignificant_zeros: false,
    precision: customPrecision ?? calculateAdaptivePrecision(n),
  };

  return options;
};

const formatSameAsOptions = (n: NumberOrString, formatSameAs: NumberOrString): HelperOptions => {
  return adaptivePrecisionOptions(formatSameAs);
};

const integerDecimalsSplit = (amount: string, fillDecimalsIfEmpty?: boolean): string[] => {
  const { decimalSeparator } = separators();
  const decimalSplit = amount.split(decimalSeparator);
  let integerAndDecimals;
  if (decimalSplit.length < 2) {
    integerAndDecimals = [decimalSplit[0]].concat(fillDecimalsIfEmpty ? '' : []);
  } else {
    const integerPart = decimalSplit[0];
    const decimalPart = decimalSplit.slice(1).join('');
    integerAndDecimals = [integerPart, decimalPart];
  }
  return integerAndDecimals;
};

type Options = {
  symbol?: boolean;
  adaptivePrecision?: boolean;
  formatSameAs?: number;
  customPrecision?: number;
  unit?: string;
  strip_insignificant_zeros?: boolean;
};

/*

   Main function for formatting fiat values

 */
export const fiat = (n: NumberOrString, options?: Options): string => {
  // Include the currency symbol as well
  const symbol = options?.symbol !== undefined ? options.symbol : true;
  // Use precision formatting based on the number n
  const adaptivePrecision = options?.adaptivePrecision !== undefined ? options.adaptivePrecision : false;
  // Use the same formatting as the give example number, this overrides every other option
  const formatSameAs = options?.formatSameAs !== undefined ? options.formatSameAs : undefined;
  // sometimes we need to define precision ourseleves
  const customPrecision = options?.customPrecision !== undefined ? options.customPrecision : false;
  const defaultFiatFormat = fiatFormat();
  let extraOptions = {};
  if (adaptivePrecision) {
    extraOptions = adaptivePrecisionOptions(n);
  }
  if (customPrecision) {
    extraOptions = adaptivePrecisionOptions(n, customPrecision);
  }
  if (formatSameAs) {
    extraOptions = formatSameAsOptions(n, formatSameAs);
  }
  const mergeOptions = { ...extraOptions, ...options };
  return symbol
    ? helpers.toCurrencyLocale(n, mergeOptions, defaultFiatSymbol, defaultFiatFormat)
    : helpers.toCurrencyLocaleNoSymbol(n, mergeOptions);
};

export const toIntAndDecimal = (
  x: NumberOrString,
  options?: Options,
): { integerPart: string; decimalPart: string } => {
  const { decimalSeparator } = separators();
  const adaptivePrecision = options?.adaptivePrecision !== undefined ? options.adaptivePrecision : false;
  const xString = fiat(
    x,
    adaptivePrecision
      ? {
          adaptivePrecision: true,
          symbol: false,
          strip_insignificant_zeros: false,
        }
      : { symbol: false },
  );
  const sepIdx = xString.indexOf(decimalSeparator);
  const integerPart = xString.substring(0, sepIdx);
  const decimalPart = xString.substring(sepIdx);
  return { integerPart, decimalPart };
};

const getCryptoPrecision = (decimalPlaces?: number): number => {
  let precision = decimalPlaces;
  if (!Number.isInteger(precision)) {
    precision = helpers.cryptoPrecision;
  }
  return precision ?? 0;
};

const getCryptoIntegerCount = (cryptoName: string): number => {
  //TODO there is no integerCount from endpoint at the moment, figure out later
  let integerCount;
  if (cryptoName === 'Shib') {
    integerCount = 13;
  } else {
    integerCount = inputIntegerCount;
  }
  return integerCount;
};

/*

   Main function for formatting crypto values

 */
export const crypto = (n: NumberOrString, decimalPlaces?: number, options?: HelperOptions): string => {
  return helpers.toNumberLocale(n, { precision: getCryptoPrecision(decimalPlaces), ...options });
};

export const zeroFiat = (): string => fiat(0);

export const zeroCrypto = (decimalPlaces?: number): string => crypto(0, decimalPlaces);

export const getDecimalsString = (length: number): string => {
  return new Array(length).fill(0).join('');
};

/*
 Formatting for currency input strings
 */
export const inputFormatCurrency = (
  amountParam: string,
  decimals: number = fiatPrecision,
  integerCount: number = inputIntegerCount,
  options?: { fillDecimals?: boolean },
): string => {
  const { fillDecimals } = options ?? {};
  const amount = amountParam || '0';
  const { decimalSeparator, groupingSeparator } = separators();
  const normalizedAmount = amount.replace(new RegExp(`[^${decimalSeparator}\\d]`, 'g'), '');
  return integerDecimalsSplit(normalizedAmount, fillDecimals)
    .map(
      (val, idx) =>
        idx === 0
          ? helpers.toNumberLocale(val.substr(0, integerCount), {
              precision: 0,
              separator: decimalSeparator,
              delimiter: groupingSeparator,
            })
          : val.substr(0, decimals).concat(fillDecimals ? getDecimalsString(decimals - val.length) : ''), // 2 decimal points
    )
    .join(decimalSeparator);
};

/*

 Formatting for crypto input strings

 */
export const inputFormatCrypto = (
  amount: string,
  code: string,
  decimalPlaces?: number,
  options?: {
    fillDecimals?: boolean;
  },
): string => {
  return inputFormatCurrency(amount, getCryptoPrecision(decimalPlaces), getCryptoIntegerCount(code), options);
};

export const inputFormatCryptoWithSymbol = (amount: string): string =>
  `${defaultFiatSymbol} ${inputFormatCurrency(amount)}`;

export const numberFromDecimalString = (amount: string): number => {
  const { decimalSeparator } = separators();
  const normalizedAmount = amount.replace(new RegExp(`[^${decimalSeparator}\\d]`, 'g'), '');
  const result =
    decimalSeparator === '.' ? normalizedAmount : normalizedAmount.replace(new RegExp(`[^\\d]`, 'g'), '.');
  return parseFloat(result);
};

export const removeHyphens = (code: string): string => code.replace(new RegExp(`-`, 'g'), '');

/* Format numbers w/abbr */
export const abbreviateNumber = (number: number): string => {
  const SI_POSTFIXES = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
  // eslint-disable-next-line
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;
  if (tier === 0) return number.toString();
  const postfix = SI_POSTFIXES[tier];
  const scale = 10 ** (tier * 3);
  const scaled = number / scale;
  let formatted = `${scaled.toFixed(1)}`;
  if (/\.0$/.test(formatted)) formatted = formatted.substr(0, formatted.length - 2);
  return formatted + postfix;
};

export const daysRemaining = (endDate: Date): string => `${differenceInDays(new Date(), endDate) + 1}`;

export function formatScreenProps(
  data: { [key: string]: string },
  key_preffix: string,
): { [key: string]: string } {
  const screenProps: { [key: string]: string } = {};
  if (data) Object.keys(data).forEach((key) => (screenProps[`${key_preffix}_${key}`] = data[key]));
  return screenProps;
}

export const timeFormatters = {
  formatSeconds: (durationInSeconds: number): string => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor(durationInSeconds / 60) % 60;
    const seconds = durationInSeconds % 60;

    return [hours, minutes, seconds]
      .map((v: number) => (v < 10 ? `0${v}` : v))
      .filter((v, i) => v !== '00' || i > 0)
      .join(':');
  },
  formatShortTime: (date?: string | number | Date): string | undefined => {
    if (!date) return;

    const dateObject = new Date(date);

    return dateObject.toLocaleTimeString([], { timeStyle: 'short' });
  },
};

export const dateFormatters = {
  formatMediumLocalDate: (date: string | number | Date): string => {
    return dateFnsformat(new Date(date), I18next.t('l10n.dateTimeFormatShort'));
  },

  toDayName: (date: string | number | Date): string => {
    return dateFnsformat(new Date(date), I18next.t('l10n.weekdayFormat'));
  },
};

export const fileSizeFormatters = {
  format: (sizeInBytes?: number): string => {
    if (!sizeInBytes) return `0 ${KB_SUFFIX}`;

    if (sizeInBytes > 1000000) {
      const sizeInMB = Math.round(sizeInBytes / 1000000);

      return `${sizeInMB} ${MB_SUFFIX}`;
    } else {
      const sizeInKB = Math.round(sizeInBytes / 1000);

      return `${sizeInKB} ${KB_SUFFIX}`;
    }
  },
};

export const abbreviateFiat = (number: NumberOrString): string => {
  if (number == null) {
    return '';
  } else if (Math.abs(Number(number)) >= 1000000000) {
    return `${helpers.toCurrencyLocale(
      Number(number) / 1000000000,
      { sign_first: false },
      '€',
      I18next.t('l10n.cuFormat'),
    )} ${I18next.t('abbreviations.billion')}`;
  } else if (Math.abs(Number(number)) >= 1000000) {
    return `${helpers.toCurrencyLocale(
      Number(number) / 1000000,
      { sign_first: false },
      '€',
      I18next.t('l10n.cuFormat'),
    )} ${I18next.t('abbreviations.million')}`;
  } else return helpers.toCurrencyLocale(number, { sign_first: false }, '€', I18next.t('l10n.cuFormat'));
};
