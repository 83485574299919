import styled from 'styled-components';
import { devices, dimensions } from 'common/styles';
import _Button from 'common/components/Button';
import personImg from 'assets/img/referal.svg';
import { Theme } from 'types/theme';

export const SideListContainer = styled.div.attrs({ className: 'background-secondary' })`
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
`;

export const NavItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${dimensions.mediumPadding}px 0;
`;

export const NavItem = styled.span.attrs<{ active: boolean }>((props) => ({
  className: `large-text ${props.active ? 'medium-weight' : ''}`,
}))`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
  &.active {
    font-weight: ${({ theme }: { theme: Theme }) => theme.typography.fontWeight.medium};
    background-color: ${({ theme }: { theme: Theme }) => theme.color.background.secondary};
  }
`;

export const PersonImage = styled.img.attrs({ src: personImg })`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.positive} !important;
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

export const MenuItemWrapper = styled.div.attrs<{ disabled: boolean }>((props) => ({
  className: `${props.disabled ? 'disabled ' : ''}`,
}))`
  padding: 13px 30px;
  position: relative;
  display: flex;
  width: 100%;
  border: none;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
  justify-content: space-between;
  border-left: 2px solid transparent;
  &:hover {
    border-left: ${({ theme }: { theme: Theme }) =>
      `${theme.border.width.large} solid ${theme.color.foreground.focused}`};
    background-color: ${({ theme }: { theme: Theme }) => theme.color.background.primary};
  }

  ${({ active, theme }: { active: boolean; theme: Theme }) =>
    active &&
    `border-left: ${`${theme.border.width.large} solid ${theme.color.foreground.focused}`};
    background-color: ${theme.color.background.primary};
  `}
`;

export const MoneyTransferButtonsWrapper = styled.div`
  padding: 0 30px;
  margin-bottom: 17px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  @media${devices.tablet} and ${devices.maxLaptopL} {
    gap: 10px;
  }
`;

export const MoneyTransferButton = styled(_Button)`
  margin: 0;

  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.moneyButton};
  flex: 1;
  padding: 0 10px;
  & > * {
    color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
    font-weight: 600;
    font-size: ${({ theme }: { theme: Theme }) => theme.typography.text.standard.fontSize};
  }

  @media${devices.tablet} and ${devices.maxLaptopL} {
    padding: 0 5px;
    min-width: unset;

    & > * {
      font-size: ${({ theme }: { theme: Theme }) => theme.typography.text.small.fontSize};
    }
  }

  @media${devices.maxMobileS} {
    padding: 0;
    min-width: unset;
    & > * {
      font-size: ${({ theme }: { theme: Theme }) => theme.typography.text.xxsmall.fontSize};
    }
  }
`;

export const KycButton = styled(_Button)`
  margin: 0;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
`;

export const WrappedNavButton = styled(_Button)`
  text-align: left;
`;

export const MenuButton = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: left;
  align-items: left;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.critical};
  cursor: pointer;
  text-decoration: none;

  ${({ disabled }: { disabled: boolean }) =>
    (!disabled &&
      `
    &:hover {
      opacity: 0.7;
    }
  `) ||
    ''};
`;

export const LineSeparator = styled.div`
  border-top: ${({ theme }: { theme: Theme }) =>
    `${theme.border.width.standard} solid ${theme.color.border.standard}`};
  margin: 25px 0;
  height: 1px;
`;

export const Headline = styled.div.attrs({ className: 'strong-weight xlarge-text' })`
  letter-spacing: 0em;
  margin-bottom: 6px;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
`;

export const InviteFriendsWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.stockRaffleBanner};
  border-radius: ${({ theme }: { theme: Theme }) => theme.border.radius.standard};
  padding: ${dimensions.regularMobilePadding}px;
`;

export const TextContentWrapper = styled.div`
  width: 60%;
  & > span {
    color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryInverted};
  }
`;

export const InviteFriendsMenuItemWrapper = styled(MenuItemWrapper)`
  display: block;
`;
