import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getPhishingLink } from 'common/const';
import { MODAL_DIMENSIONS } from 'common/const/modals';
import ModalTemplate from 'common/components/Modal/index';
import { CustomLink } from '../Modal';
import { PhishingImg, CustomImgContainer, CustomTextContainer, HeadlineText } from './styles';
import { ContentWrapper, HeaderText, SubText } from '../DefaultFeaturesModalStyles/styles';
import { ModalProps } from 'types/modals';

type Props = {
  addUserToUsersWhoSawPhishingBanner: (userHash: string) => void;
  userHash: string;
} & ModalProps;

const PhishingModal: FC<Props> = ({ addUserToUsersWhoSawPhishingBanner, onClose, userHash }: Props) => {
  const { t } = useTranslation();
  const phishingLink = getPhishingLink();

  return (
    <ModalTemplate
      onCancel={() => {
        addUserToUsersWhoSawPhishingBanner(userHash);

        onClose();
      }}
      defaultOpen={true}
      header={<HeaderText>{t('phishing.header_title')}</HeaderText>}
      modalDimensions={MODAL_DIMENSIONS.WIDE}
      invertedHeader
      disableActionsBlock
    >
      <ContentWrapper>
        <CustomImgContainer>
          <PhishingImg />
        </CustomImgContainer>
        <CustomTextContainer>
          <HeadlineText>{t('phishing.headline')}</HeadlineText>
          <SubText>{t('phishing.text.a')}</SubText>
          <SubText>{t('phishing.text.b')}</SubText>
          <SubText>
            {t('phishing.text.c')}{' '}
            <CustomLink target="_blank" href={phishingLink}>
              {t('phishing.text.linkText')}.
            </CustomLink>
          </SubText>
        </CustomTextContainer>
      </ContentWrapper>
    </ModalTemplate>
  );
};

export default PhishingModal;
