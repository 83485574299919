import { Action } from 'redux';
import { action } from 'store/actions/_common';
import { TradingRule, TradingRuleDetails } from 'types/limitOrders';
import { SubmitLimitOrderBody, SubmitStopOrderBody } from 'common/api-types';

export const FETCH_TRADING_STOP_RULES = 'FETCH_TRADING_STOP_RULES_REQUEST';
export const fetchTradingStopRules = (): Action => action(FETCH_TRADING_STOP_RULES);

export const FETCH_TRADING_STOP_RULES_SUCCESS = 'FETCH_TRADING_STOP_RULES_SUCCESS';
export const fetchTradingStopRulesSuccess = (payload: { response: TradingRule[] }): Action =>
  action(FETCH_TRADING_STOP_RULES_SUCCESS, { payload });

export const FETCH_TRADING_STOP_RULES_FAILURE = 'FETCH_TRADING_STOP_RULES_FAILURE';
export const fetchTradingStopRulesFailure = (error: string): Action =>
  action(FETCH_TRADING_STOP_RULES_FAILURE, { error });

export const SAVE_DELETING_TRADING_STOP_RULE = 'SAVE_DELETING_TRADING_STOP_RULE';
export const saveDeletingTradingStopRules = (payload: TradingRuleDetails): Action =>
  action(SAVE_DELETING_TRADING_STOP_RULE, { payload });

export const DELETE_TRADING_STOP_RULE = 'DELETE_TRADING_STOP_RULE';
export const deleteTradingStopRule = (payload: { uniqueId: string }, navigate?: () => void): Action =>
  action(DELETE_TRADING_STOP_RULE, { payload, navigate });

export const DELETE_TRADING_STOP_RULES_SUCCESS = 'DELETE_TRADING_STOP_RULES_SUCCESS';
export const deleteTradingStopRulesSuccess = (payload: { uniqueId: string }): Action =>
  action(DELETE_TRADING_STOP_RULES_SUCCESS, { payload });

export const DELETE_TRADING_STOP_RULES_FAILURE = 'DELETE_TRADING_STOP_RULES_FAILURE';
export const deleteTradingStopRulesFailure = (payload: { uniqueId: string }): Action =>
  action(DELETE_TRADING_STOP_RULES_FAILURE, { payload });

export const SUBMIT_TRADING_STOP_RULE = 'SUBMIT_TRADING_STOP_RULE';
export const submitTradingStopRule = (
  payload: SubmitStopOrderBody,
  onSuccess: () => void,
  onFail: () => void,
  onError: () => void,
): Action =>
  action(SUBMIT_TRADING_STOP_RULE, {
    payload,
    onSuccess,
    onFail,
    onError,
  });

export const SUBMIT_TRADING_STOP_RULES_SUCCESS = 'SUBMIT_TRADING_STOP_RULES_SUCCESS';
export const submitTradingStopRulesSuccess = (payload: SubmitStopOrderBody): Action =>
  action(SUBMIT_TRADING_STOP_RULES_SUCCESS, { payload });

export const SUBMIT_TRADING_STOP_RULES_FAILURE = 'SUBMIT_TRADING_STOP_RULES_FAILURE';
export const submitTradingStopRulesFailure = (error: string): Action =>
  action(SUBMIT_TRADING_STOP_RULES_FAILURE, { error });

export const STOP_ORDER_SELECT_CRYPTO = 'STOP_ORDER_SELECT_CRYPTO';
export const stopOrderSelectCrypto = (selectedCrypto: string): Action =>
  action(STOP_ORDER_SELECT_CRYPTO, { selectedCrypto });

export const STOP_ORDER_SEND = 'STOP_ORDER_SEND';
export const stopOrderSend = (selectedCrypto: string, isBuy: boolean): Action =>
  action(STOP_ORDER_SEND, { selectedCrypto, isBuy });
