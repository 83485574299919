import { AnyAction } from 'redux';
import * as ActionTypes from 'store/actions/referral';
import { ReferralState } from 'store/types/referral';

const initialState: ReferralState = {
  hasActiveCampaign: false,
  refreshing: false,
  openingShare: false,
};

export default function referral(state = initialState, action: AnyAction): ReferralState {
  switch (action.type) {
    case ActionTypes.FETCH_REFERRAL_REQUEST:
      return {
        ...state,
        refreshing: true,
      };
    case ActionTypes.FETCH_REFERRAL_SUCCESS:
      return {
        ...state,
        refreshing: false,
        hasActiveCampaign: true,
        reward: action.response,
      };
    case ActionTypes.FETCH_REFERRAL_FAILURE:
      return {
        ...state,
        refreshing: false,
        hasActiveCampaign: false,
      };

    case ActionTypes.INVITE_FRIENDS_REQUEST:
      return {
        ...state,
        openingShare: true,
      };
    case ActionTypes.INVITE_FRIENDS_SUCCESS:
    case ActionTypes.INVITE_FRIENDS_FAILURE:
      return {
        ...state,
        openingShare: false,
      };

    default:
      return state;
  }
}
