import { paths } from 'common/urls';

export const CONSENT_MODELS = {
  UNSPECIFIED: 'Unspecified',
  EXPLICIT: 'Explicit',
  OPTOUT: 'OptOut',
  OPTIN: 'OptIn',
} as const;

export const CONSENT_STATES = {
  UNSPECIFIED: 'Unspecified',
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
} as const;

export const CONSENT_TYPES = {
  TOP_UPS: 'topups',
} as const;

export const REDIRECT_AFTER_CONSENT_ACCEPTED: Record<string, string> = {
  [CONSENT_TYPES.TOP_UPS]: paths.DEPOSIT_INSTANT,
} as const;
