import { NewsFeedActions } from 'types/news';
import { cryptoCodes } from 'common/utils/assets';
import { NewsState } from 'store/types/news';

const initialState: NewsState = Object.keys(cryptoCodes).reduce(
  (acc, key) => {
    if (!acc.data[key]) {
      acc.data[key] = { data: [], isFetching: false };
    }
    return acc;
  },
  {
    data: {},
    lang: 'de',
  } as NewsState,
);

export default function news(state = initialState, action: NewsFeedActions): NewsState {
  switch (action.type) {
    case 'FETCH_NEWS_FEED_REQUEST':
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.crypto]: {
            ...(state.data[action.payload?.crypto] ? state.data[action.payload.crypto] : {}),
            isFetching: state.data[action.payload?.crypto]?.data?.length === 0,
          },
        },
      };
    case 'FETCH_NEWS_FEED_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.crypto]: {
            data: action.payload.newsFeed,
            isFetching: false,
          },
        },
      };
    case 'FETCH_NEWS_FEED_FAILURE':
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.crypto]: {
            ...(state.data[action.payload?.crypto] ? state.data[action.payload.crypto] : {}),
            isFetching: false,
          },
        },
      };

    case 'CHANGE_NEWS_LANGUAGE':
      return {
        ...initialState,
        lang: action.payload.lang,
      };

    default:
      return state;
  }
}
