/* eslint-disable @typescript-eslint/no-unsafe-return */
import { all, call, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';
import I18n from 'i18next';
import isEqual from 'is-equal';
import { Saga } from 'redux-saga';
import * as api from 'common/api';
import { errorCodes } from 'common/apiErrors';
import { crypto, fiat } from 'common/utils/formatting';
import { getDeviceData } from 'common/utils/seonSdk';
import { authFetchEntity, authRequest } from 'store/sagas/auth';
import * as formActions from 'store/actions/forms';
import * as authActions from 'store/actions/auth';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import { getUser } from 'store/selectors/auth';
import { getTaxResidency as getTaxResidencySelector } from 'store/selectors/forms';
import initGruffalo from '../../common/utils/init';
import { checkDeviceConsentNeeded } from './deviceMonitoring';
import { getAssetEntities } from 'store/slices/assets/selectors';
import { formatDistanceWithLocale } from 'common/utils/date';
import { kycStatuses } from 'common/const';
import { State } from 'store/types/store';
import { ASSET_CLASSES } from 'types/assets';

const withoutError = (response: any) => {
  return !response.error;
};

export const mobileNumberDelete = authFetchEntity.bind(
  null,
  formActions.mobileNumberDelete,
  api.mobileNumberDelete,
  false,
);

export const mobileNumberAssign = authFetchEntity.bind(
  null,
  formActions.mobileNumberAssign,
  api.mobileNumberAssign,
  false,
);

export const getTaxResidency = authFetchEntity.bind(
  null,
  formActions.getTaxResidency,
  api.getTaxResidency,
  false,
);

export const createTaxResidency = authFetchEntity.bind(
  null,
  formActions.createTaxResidency,
  api.createTaxResidency,
  false,
);

export const putTaxResidency = authFetchEntity.bind(
  null,
  formActions.putTaxResidency,
  api.putTaxResidency,
  false,
);

export const deleteTaxResidency = authFetchEntity.bind(
  null,
  formActions.deleteTaxResidency,
  api.deleteTaxResidency,
  false,
);

// Calls token validation (/user update) to retrieve the current kyc status
function* refreshUserData(): Saga<void> {
  const { username } = yield select(getUser);
  yield fork(authFetchEntity, authActions.tokenValidation, api.validateToken, true, username);
  yield take([authActions.TOKEN_VALIDATION.SUCCESS, authActions.TOKEN_VALIDATION.FAILURE]);
}

function* refreshTaxResidency(): Saga<void> {
  yield call(getTaxResidency);
}

// Our SUBMIT action passes along the form values and form actions.
// This allows us to not only use the values to do whatever API calls and such
// we need, but also to maintain control flow here in our saga.

function* submitLoginUser({ values, actions }): Saga<void> {
  const { resetForm, setSubmitting } = actions ?? {};
  const { email, password, rememberMe } = values;
  // trigger login authFlow
  yield put(authActions.loginUser(email, password, rememberMe));

  const action = yield race({
    success: take(authActions.TOKEN_VALIDATION.SUCCESS),
    logout: take(authActions.LOGOUT_USER),
    authFailure: take(authActions.AUTH.FAILURE),
    validationFailure: take(authActions.TOKEN_VALIDATION.FAILURE),
    refreshFailure: take(authActions.TOKEN_REFRESH.FAILURE),
  });

  if (action.success) {
    // Reset the form just to be clean
    if (resetForm) yield call(resetForm);
  } else if (action.logout) {
    // user logged out
    yield call(setSubmitting, false);
  }
}

function* submitRegisterUser({ values, actions }): Saga<void> {
  const { resetForm, setErrors, setSubmitting } = actions;
  yield fork(initGruffalo);
  // replacing of '-' is requirement from po for cases when referral codes will be distributed with those symbols
  const formattedReferralCode = values.referralCode.replace('-', '');

  if (values.referralCode !== '') {
    const referralCodeCheckResponse = yield call(api.validateReferralCode, formattedReferralCode);

    if (referralCodeCheckResponse.error || referralCodeCheckResponse.response === false) {
      yield put(
        notificationBannerActions.notifyError({
          message: I18n.t('registration.form.referralCodeError'),
        }),
      );
      yield call(setSubmitting, false);
      return;
    }
  }

  const request: api.RegisterResponse = yield call(api.register, {
    username: values.email,
    password: values.password,
    newsletter: values.newsletter,
    referralCode: formattedReferralCode,
  });

  if (request.response) {
    yield call(resetForm);
    yield put(authActions.startRegistration());
    yield put(authActions.loginUser(values.email, values.password));

    // if registration fails we need to enable the button
    yield take(notificationBannerActions.NOTIFY_ERROR);
    yield call(setSubmitting, false);
  } else {
    const { error } = request;
    if (error.code === errorCodes.SIGNUP_ERR_PWDINVALID) {
      yield call(setErrors, { password: error.message });
    } else {
      yield put(
        notificationBannerActions.notifyError({
          message: I18n.t('registration.form.errorTitle'),
          subTitle: error.message,
        }),
      );
    }
    yield call(setSubmitting, false);
  }
}

function* submitResetPassword({ values, actions }): Saga<void> {
  const { setSubmitting } = actions;
  const request: api.ResetPasswordResponse = yield call(api.resetPassword, {
    username: values.email,
  });

  if (!request.error) {
    // yield put(notificationBannerActions.notifySuccess({ message: I18n.t('forgotPassword.successTitle') }));
    yield put(formActions.submitResetPasswordStatus({ status: 'success' }));
  } else {
    const { error } = request;
    yield put(formActions.submitResetPasswordStatus({ status: 'error' }));
    yield put(
      notificationBannerActions.notifyError({
        message: I18n.t('forgotPassword.errorTitle'),
        subTitle: error.message,
      }),
    );
  }
  yield call(setSubmitting, false);
}

function* submitChangePassword({ values, actions }): Saga<void> {
  const { resetForm, setErrors, setSubmitting } = actions;
  const { username } = yield select(getUser);

  const request: api.ChangePasswordResponse = yield call(authRequest, api.changePassword, {
    username,
    oldPassword: values.currentPassword,
    newPassword: values.newPassword,
  });

  if (!request.error) {
    // Reset the form just to be clean
    yield call(resetForm);
    yield put(notificationBannerActions.notifySuccess({ message: I18n.t('settings.changePass.success') }));
  } else {
    const { error } = request;
    if (request.error.code === errorCodes.PWDCHANGE_ERR_OLDPWDWRONG) {
      yield call(setErrors, { currentPassword: error.message });
    } else {
      yield put(
        notificationBannerActions.notifyError({
          message: I18n.t('settings.changePass.errorTitle'),
          subTitle: error.message,
        }),
      );
    }
    yield call(setSubmitting, false);
  }
}

function* submitFeatureRequest({
  values,
  actions,
}: {
  values: {
    request: { request: string };
  };
  actions: {
    setSubmitting: (isSubmitting: boolean) => void;
  };
}): Generator<any, any, any> {
  const { feature } = values;
  const { setSubmitting, resetForm } = actions;
  const apiRequest = yield call(authRequest, api.submitFeatureRequest, feature, undefined);

  yield call(setSubmitting, false);
  if (withoutError(apiRequest)) {
    yield put(
      notificationBannerActions.notifySuccess({
        message: I18n.t('settings.submitFeature.notification.success.title'),
      }),
    );
    yield call(resetForm);
  } else {
    yield put(
      notificationBannerActions.notifyError({
        message: I18n.t('settings.submitFeature.notification.error.title'),
      }),
    );
  }
}

// Orchestrate API calls
function* processChangeUsername({ newUsername, actions }: { newUsername: string; actions: any }): Saga<void> {
  const { response: initResponse, error: initError } = yield call(authRequest, api.changeUsernameInit, {
    newUsername,
  });

  if (initError) {
    yield put(
      notificationBannerActions.notifyError({
        message: initError.message,
      }),
    );
    return; // return to dismiss modal
  }
  yield call(actions.setSubmitting, false);
  yield call(actions.continue);

  // Wait for confirm
  while (true) {
    const {
      values: { code2fa },
      actions: confirmActions,
    } = yield take(formActions.CONFIRM_USERNAME_CHANGE);

    const { error } = yield call(
      authFetchEntity,
      formActions.changeUsernameConfirm,
      api.changeUsername,
      false,
      {
        data: initResponse,
        code2fa,
      },
    );

    if (error) {
      yield put(
        notificationBannerActions.notifyError({
          message: error.message,
        }),
      );
      // reset submitting status
      yield call(confirmActions.setSubmitting, false);
    } else {
      yield put(authActions.invalidateTokens({ usernameChanged: true }));
      // wait for refresh to finish before returning
      yield take([authActions.TOKEN_REFRESH.SUCCESS, authActions.TOKEN_REFRESH.FAILURE]);
      // notify user of success
      yield put(
        notificationBannerActions.notifySuccess({
          message: I18n.t('settings.personalInfo.success.changeEmail'),
        }),
      );
      yield call(confirmActions.setSubmitting, false);
      yield call(confirmActions.finished);
      return; // we're done, return
    }
  }
}

// Change username
function* startChangeUsername(payload: { newUsername: string; actions: any }): Saga<void> {
  yield race({
    cancel: take(formActions.USERNAME_CHANGE_CANCEL),
    startProcess: call(processChangeUsername, payload),
  });
}

// Orchestrate API calls
function* processChangeTaxResidency({ values, actions }) {
  const currTaxResidency = yield select(getTaxResidencySelector);
  const newTaxResidency = values.taxResidency;

  // Detect changes and fire appropriate api calls to sync
  const syncCalls = {};

  // Detect deletes

  currTaxResidency.map((tr) => {
    const index = newTaxResidency.findIndex((el) => el.id === tr.id);
    const newTr = newTaxResidency[index];
    if (!newTr) {
      // Check if the user deleted and then added a tax id for the same country
      const newTrSameCountry = newTaxResidency.find((el) => el.country === tr.country);

      if (!newTrSameCountry) {
        // We need to delete
        syncCalls[`DELETE_${tr.id}`] = call(deleteTaxResidency, tr);
      } else {
        // This is actually a modify, so we assign the known id
        // The change will be detected as an edit in the next pass below
        newTrSameCountry.id = tr.id;
      }
    }
    return true;
  });

  // Detect edits
  currTaxResidency.map((tr) => {
    const index = newTaxResidency.findIndex((el) => el.id === tr.id);
    if (index !== -1) {
      const newTr = newTaxResidency[index];
      if (!isEqual(newTr, tr)) {
        syncCalls[index] = call(putTaxResidency, newTr);
      }
    }
    return true;
  });

  // Detect creates
  newTaxResidency.map((tr, index) => {
    if (!tr.id) {
      syncCalls[index] = call(createTaxResidency, tr);
    }
    return true;
  });

  function findErrors(results: [any]) {
    return Object.entries(results).map(([index, { error }]) => {
      let fieldName = `taxResidency.${index}.taxId`;
      if (error) {
        if (error.code === 'taxresidency_err_countryalreadyexists') {
          fieldName = `taxResidency.${index}.country`;
        }
        return { fieldName, errorMessage: error.message };
      }
      return null;
    });
  }

  const results = yield all(syncCalls);
  const errors = Object.values(results).filter((res) => !!res.error);

  if (errors.length > 0) {
    yield all(
      results
        ? findErrors(results).map(({ fieldName, errorMessage }) =>
            call(actions.setFieldError, fieldName, errorMessage),
          )
        : null,
    );
    yield call(actions.setSubmitting, false);
  } else {
    yield put(
      notificationBannerActions.notifySuccess({
        subTitle: I18n.t('settings.personalInfo.changeTaxId'),
        message: I18n.t('settings.personalInfo.success.changeTaxId'),
      }),
    );
    yield call(actions.setSubmitting, false);
    yield call(getTaxResidency);
  }

  return true; // we're done, return
}

// Change tax Id
function* startChangeTaxId(data: any): Saga<void> {
  yield race({
    cancel: take(formActions.TAX_ID_CHANGE_CANCEL),
    startProcess: call(processChangeTaxResidency, data),
  });
}

// Orchestrate API calls
function* processChangeAddress({
  values: newAddress,
  actions,
  isResendCode,
}: {
  values: any;
  actions: any;
  isResendCode?: boolean;
}): Saga<void> {
  for (let i = 1; i <= 2; i++) {
    // foor loop with try/catch logic is only ment for retrying init request when device consent id is missing
    try {
      const { deviceData, error: deviceDataError } = yield call(getDeviceData);
      const { response: initResponse, error: initError } = yield call(authRequest, api.changeAddressInit, {
        newAddress,
        deviceData,
      });
      if (initResponse && isResendCode) {
        // To handle resend sms succes notification
        yield put(
          notificationBannerActions.notifySuccess({
            message: I18n.t('common.twofa.resentCode'),
          }),
        );
      }

      if (initError) {
        yield call(checkDeviceConsentNeeded, initError);

        yield put(
          notificationBannerActions.notifyError({
            message: I18n.t('settings.personalInfo.changeAddress'),
            subTitle: initError.message,
          }),
        );
        return; // return to dismiss modal
      }
      yield call(actions.setSubmitting, false);
      yield call(actions.continue);

      // Wait for confirm
      while (true) {
        const {
          values: { code2fa },
          actions: confirmActions,
        } = yield take(formActions.CONFIRM_ADDRESS_CHANGE);

        const { error, response } = yield call(authRequest, api.changeAddress, {
          data: initResponse,
          code2fa,
          deviceData,
        });

        if (error) {
          if (error?.code !== errorCodes.CHANGEPROCESS_ERR_WRONG2FACODE) {
            // we only want to show the error message if it's not a 2fa error
            // invalid code message is displayed under inputs
            yield put(
              notificationBannerActions.notifyError({
                message: error.message,
              }),
            );
          }

          // reset submitting status
          yield call(confirmActions.setSubmitting, false);
          yield call(confirmActions.failed);
        } else {
          yield put(
            notificationBannerActions.notifySuccess({
              message: I18n.t('settings.personalInfo.success.changeAddress'),
            }),
          );
          if (response) {
            yield put(formActions.putNewAddressData(response));
          }
          yield call(confirmActions.finished);
          return; // we're done, return
        }
      }
    } catch (err) {
      // console.log('Error', err);
    }
  }
}

// Change address
function* startChangeAddress(payload: { values: any; actions: any; isResendCode?: boolean }): Saga<void> {
  yield race({
    cancel: take(formActions.ADDRESS_CHANGE_CANCEL),
    startProcess: call(processChangeAddress, payload),
  });
}

function* processDeleteMobile({
  actions: initActions,
  isResendCode,
}: {
  actions: any;
  isResendCode?: boolean;
}): Saga<void> {
  for (let i = 1; i <= 2; i++) {
    // foor loop with try/catch logic is only ment for retrying init request when device consent id is missing
    try {
      // delete current mobile number
      const user = yield select(getUser);
      const currMobileNumber = user.mobileNumber;
      const { deviceData, error: deviceDataError } = yield call(getDeviceData);

      const { response: initResponse, error: initError } = yield call(
        authRequest,
        api.mobileNumberDeleteInit,
        {
          mobileNumber: currMobileNumber,
          deviceData,
        },
      );

      if (initResponse && isResendCode) {
        // To handle resend sms success notification
        yield put(
          notificationBannerActions.notifySuccess({
            message: I18n.t('common.twofa.resentCode'),
          }),
        );
      }

      if (initError) {
        yield call(checkDeviceConsentNeeded, initError);

        yield put(
          notificationBannerActions.notifyError({
            message: I18n.t('settings.mobileNumberChange.errors.deleteGenericError'),
          }),
        );
        return; // return to dismiss modal
      }

      // This shouldn't really happen, due to UI limitations,
      // but if for some reason we get this flag, we handle it appropriately
      if (!initResponse.needsConfirmation) {
        yield fork(refreshUserData);
        return;
      }

      yield call(initActions.setSubmitting, false);
      yield call(initActions.continue);

      // Wait for confirm
      while (true) {
        const {
          values: { code2fa },
          actions: confirmActions,
        } = yield take(formActions.CONFIRM_DELETE_MOBILE);

        const { error } = yield call(mobileNumberDelete, {
          data: initResponse,
          code2fa,
          deviceData,
        });

        if (error) {
          if (error?.code === errorCodes.CHANGEPROCESS_ERR_WRONG2FACODE) {
            yield call(confirmActions.wrongTwoFaCode);
          } else {
            yield put(
              notificationBannerActions.notifyError({
                message: I18n.t('settings.mobileNumberChange.errors.deleteGenericError'),
              }),
            );
          }
        } else {
          yield put(
            notificationBannerActions.notifySuccess({
              message: I18n.t('settings.mobileNumberChange.success.deleteMobileNumber'),
            }),
          );
          yield call(confirmActions.continue);
        }
        yield call(confirmActions.setSubmitting, false);
        yield call(confirmActions.finished);
      }
    } catch (err) {
      // console.log('Error', err);
    }
  }
}

// Change mobile
function* startDeleteMobile(payload: { values: any; actions: any; isResendCode?: boolean }): Saga<void> {
  yield race({
    cancel: take(formActions.MOBILE_CHANGE_CANCEL),
    startProcess: call(processDeleteMobile, payload),
  });

  // const componentId = yield select(getComponentId);
  // Navigation.dismissModal(componentId);
}

function* processAssignMobile({
  values,
  actions: initActions,
}: {
  values: { mobileNumber: string };
  actions: any;
}): Saga<void> {
  for (let i = 1; i <= 2; i++) {
    // foor loop with try/catch logic is only ment for retrying init request when device consent id is missing
    try {
      const { deviceData, error: deviceDataError } = yield call(getDeviceData);

      const { response: initResponse, error: initError } = yield call(
        authRequest,
        api.mobileNumberAssignInit,
        {
          mobileNumber: values.mobileNumber,
          deviceData,
        },
      );

      if (initError) {
        yield call(checkDeviceConsentNeeded, initError);

        yield put(
          notificationBannerActions.notifyError({
            message: I18n.t('settings.mobileNumberChange.errors.assignGenericError'),
          }),
        );
        return; // return to dismiss modal
      }

      // This shouldn't really happen, due to UI limitations,
      // but if for some reason we get this flag, we handle it appropriately
      if (!initResponse.needsConfirmation) {
        yield put(
          notificationBannerActions.notifyInfo({
            message: I18n.t('settings.mobileNumberChange.success.assignMobileNumber'),
          }),
        );
        yield call(refreshUserData);
        yield call(initActions.resetForm);
        return; // return to dismiss modal
      }

      yield call(initActions.setSubmitting, false);
      yield call(initActions.continue);

      // Wait for confirm
      while (true) {
        const {
          values: { code2fa },
          actions: confirmActions,
        } = yield take(formActions.CONFIRM_ASSIGN_MOBILE);

        const { error } = yield call(mobileNumberAssign, {
          data: initResponse,
          code2fa,
          deviceData,
        });

        if (error) {
          if (error?.code === errorCodes.CHANGEPROCESS_ERR_WRONG2FACODE) {
            yield call(confirmActions.wrongTwoFaCode);
          } else {
            yield put(
              notificationBannerActions.notifyError({
                message: I18n.t('settings.mobileNumberChange.errors.assignGenericError'),
              }),
            );
          }
        } else {
          yield put(
            notificationBannerActions.notifySuccess({
              message: I18n.t('settings.mobileNumberChange.success.assignMobileNumber'),
            }),
          );
          yield call(confirmActions.continue);
        }
        yield call(confirmActions.setSubmitting, false);
        yield call(confirmActions.finished);
      }
    } catch (err) {
      // console.log('Error', err);
    }
  }
}

function* startAssignMobile(payload: { values: any; actions: any }): Saga<void> {
  yield race({
    cancelChange: take(formActions.MOBILE_CHANGE_CANCEL),
    startProcess: call(processAssignMobile, payload),
  });
}

function* redeemCode({ values, actions, onSuccess }): Saga<void> {
  const { resetForm, setErrors, setSubmitting } = actions;
  const { code } = values;
  yield call(setSubmitting, true);
  const request = yield call(authRequest, api.redeemCode, { code });
  const cryptoAssets = yield select((state: State) => getAssetEntities(state, ASSET_CLASSES.CRYPTO));
  if (!request.error) {
    const { response } = request;
    yield call(resetForm);
    yield call(setSubmitting, false);
    if (response.result === 'Earned') {
      const {
        earnedReward: { amount: earnedAmount, currency: earnedCurrency },
      } = response;
      const entity = (earnedCurrency || '').toUpperCase() as string;
      const formatAmount = crypto(earnedAmount, cryptoAssets[entity]?.cryptoDetails?.decimalPlaces);
      yield call(onSuccess, {
        headerText: I18n.t('redeemCode.success.headerText', { amount: `${formatAmount} ${entity}` }),
        mainText: I18n.t('redeemCode.success.mainText'),
        buttonText: I18n.t('common.toPortfolio'),
      });
    } else if (response.result === 'Pending') {
      const {
        pendingReward: {
          isExpired,
          currency: pendingCurrency,
          amount: pendingAmount,
          expiresAt,
          payoutCurrency,
          conditionSatisfied,
        },
      } = response;
      const entity = (pendingCurrency || '').toUpperCase();
      const formatAmount = fiat(pendingAmount, entity);

      if (isExpired) {
        yield put(
          notificationBannerActions.notifyError({ message: I18n.t('redeemCode.notClaimed.expired') }),
        );
      } else if (conditionSatisfied) {
        // the reward is being processed
        yield put(
          notificationBannerActions.notifyWarn({ message: I18n.t('redeemCode.notClaimed.processing') }),
        );
      } else {
        yield call(onSuccess, {
          headerText: I18n.t('redeemCode.notClaimed.notExpired.title', {
            amount: `${formatAmount} ${entity}`,
            payoutCurrency,
          }),
          mainText: I18n.t('redeemCode.notClaimed.notExpired.mainText', {
            deadline: formatDistanceWithLocale(new Date(expiresAt), new Date()),
          }),
          buttonText: I18n.t('common.toPortfolio'),
        });
      }
    } else if (response.result !== 'Earned') {
      yield put(
        notificationBannerActions.notifyError({
          message: I18n.t('redeemCode.error.notWon'),
        }),
      );
    }
  } else {
    const { error } = request;
    yield call(setSubmitting, false);
    if (request.error.code === errorCodes.CLAIMCOUPON_ERR_NOTVALID) {
      yield call(setErrors, { code: error.message });
    } else {
      yield put(
        notificationBannerActions.notifyError({
          message: error.message,
        }),
      );
    }
  }
  yield call(setSubmitting, false);
}

// ********************************* WATCHERS **********************************

function* watchSubmitLoginUser(): Saga<void> {
  yield takeLatest(formActions.SUBMIT_LOGIN_USER, submitLoginUser);
}

function* watchRedeemCode(): Saga<void> {
  yield takeLatest(formActions.REDEEM_CODE, redeemCode);
}

export function* watchSubmitRegisterUser(): Saga<void> {
  yield takeLatest(formActions.SUBMIT_REGISTER_USER, submitRegisterUser);
}

function* watchSubmitResetPassword(): Saga<void> {
  yield takeLatest(formActions.SUBMIT_RESET_PASSWORD, submitResetPassword);
}

function* watchSubmitFeatureRequest(): Generator<any, any, any> {
  yield takeLatest(formActions.SUBMIT_FEATURE_REQUEST, submitFeatureRequest);
}

function* watchStartChangeUsername(): Saga<void> {
  while (true) {
    const { newUsername, actions } = yield take(formActions.START_CHANGE_USERNAME);
    yield call(startChangeUsername, { newUsername, actions });
  }
}

function* watchStartChangeTaxId(): Saga<void> {
  while (true) {
    const { values, actions } = yield take(formActions.TAX_ID_CHANGE);

    yield call(startChangeTaxId, { values, actions });
  }
}

function* watchStartChangeAddress(): Saga<void> {
  while (true) {
    const { values, actions, isResendCode } = yield take(formActions.START_CHANGE_ADDRESS);
    yield call(startChangeAddress, { values, actions, isResendCode });
    yield call(actions.setSubmitting, false);
  }
}

function* watchSubmitChangePassword(): Saga<void> {
  yield takeLatest(formActions.SUBMIT_CHANGE_PASSWORD, submitChangePassword);
}

function* watchStartDeleteMobile(): Saga<void> {
  while (true) {
    const { values, actions, isResendCode } = yield take(formActions.START_DELETE_MOBILE);
    yield call(startDeleteMobile, { values, actions, isResendCode });
  }
}

function* watchStartAssignMobile(): Saga<void> {
  while (true) {
    const { values, actions } = yield take(formActions.START_ASSIGN_MOBILE);
    yield call(startAssignMobile, { values, actions });
    yield call(actions.setSubmitting, false);
  }
}

function* watchInvalidateTaxResidency(): Saga<void> {
  yield takeLatest(formActions.INVALIDATE_TAX_RESIDENCY, refreshTaxResidency);
}

function* watchInvalidateUserInfo(): Saga<void> {
  while (true) {
    const {
      payload: { kycStatus },
    } = yield take(authActions.FETCH_USER_KYC_STATUS_SUCCESS);

    if (kycStatus === kycStatuses.Confirmed) {
      yield call(refreshUserData);
    }
  }
}

export default function* root(): Saga<void> {
  yield all([
    fork(watchRedeemCode),
    fork(watchSubmitLoginUser),
    fork(watchSubmitRegisterUser),
    fork(watchSubmitResetPassword),
    fork(watchSubmitFeatureRequest),
    fork(watchStartChangeUsername),
    fork(watchStartChangeAddress),
    fork(watchSubmitChangePassword),
    fork(watchStartDeleteMobile),
    fork(watchStartAssignMobile),
    fork(watchStartChangeTaxId),
    fork(watchInvalidateTaxResidency),
    fork(watchInvalidateUserInfo),
  ]);
}
