import styled from 'styled-components';
import { Form as _Form, Dropdown as _Dropdown } from 'common/lib/semantic-ui';
import { CenteredColumn } from 'common/components/Column';
import Icon from 'common/components/Icon';
import { devices, dimensions } from 'common/styles';
import { colors } from 'common/utils/theme/colors';
import { Theme } from 'types/theme';

export const Wrapper = styled(CenteredColumn)``;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${dimensions.mediumPadding}px;
`;

export const FillColumn = styled.div.attrs({ className: 'background-secondary' })`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 15px 26px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 6px;

  @media ${devices.maxMobileL} {
    padding: ${dimensions.smallIndent}px;
  }
`;

export const ChangeRiskRow = styled.div`
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 12px 18px 12px 26px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'inherit')};

  &:after {
    width: 100%;
    height: 62px;
    top: 0;
    left: 0;
    content: '';
    position: absolute;
    background-color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
    opacity: 0.08;
    border-radius: 4px;
  }
`;

export const ChangeRiskCaptionText = styled.div.attrs({
  className: 'small-text primary-color-text strong-weight',
})`
  margin-right: 36px;
`;

export const ChangeRiskValueText = styled.div.attrs({
  className: 'small-text medium-weight',
})`
  display: flex;
  align-items: center;
  gap: 46px;
`;

export const EsmaValue = styled.div.attrs({
  className: 'small-text medium-weight',
})`
  border: ${({ theme }: { theme: Theme }) => `2px solid ${theme.color.foreground.focused}`};
  border-radius: 18px;
  padding: 9px 14px;
  text-align: center;
`;

export const CaptionText = styled.div.attrs({
  className: 'large-text primary-color-text strong-weight',
})`
  margin-bottom: 4px;
`;

export const ValueText = styled.div.attrs({
  className: 'standard-text',
})`
  transition: all 0.2s ease;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'inherit')};

  &:hover {
    color: ${(props) => (props.onClick ? colors.gray7 : 'inherit')};
  }
`;

export const AddrText = styled.div`
  display: inline-block;
`;

export const IconEdit = styled(Icon).attrs({
  className: 'custom-icon-edit tiniest',
})`
  position: absolute;
  top: 14px;
  right: 18px;
  z-index: 1;
  line-height: inherit !important;
  cursor: pointer;
`;

export const IconEsmaWrapper = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    width: 32px;
    height: 32px;
    top: 0;
    left: 0;
    content: '';
    position: absolute;
    border-radius: 4px;
    background-color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
    opacity: 0.16;
  }
`;

export const IconEsmaChange = styled(Icon).attrs(({ theme }: { theme: Theme }) => ({
  className: 'custom-icon-esma-settings',
  customColor: theme.color.foreground.focused,
}))``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media ${devices.maxTablet} {
    flex-direction: column;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: auto;
`;

export const Form = styled(_Form)`
  margin: 0 auto;
  flex-direction: column;
`;

export const Label = styled.span.attrs({
  className: 'large-text',
})`
  display: inline-block;
  text-align: left;
  width: 100%;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
  ${({ addBottomPadding }: { addBottomPadding: boolean }) => addBottomPadding && 'padding-bottom: 20px;'}
`;

export const GreenText = styled.span`
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
  text-decoration: underline;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.positive};
`;

export const TrashIcon = styled(Icon).attrs({
  className: 'custom-icon-trash tiniest',
  color: 'black',
  cursor: 'pointer',
})`
  font-size: ${({ theme }: { theme: Theme }) => theme.typography.heading.size['4'].fontSize} !important;
  float: right !important;
  cursor: pointer;
`;

export const FooterWrapper = styled.div`
  width: 100%;
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media ${devices.maxTablet} {
    flex-direction: column-reverse;
    gap: 15px;
    > div {
      height: ${dimensions.valueHeight}px;
      margin: 0;
    }
  }
`;

export const InputWrapper = styled.div`
  margin-top: ${dimensions.mediumPadding}px;

  & #country {
    padding-bottom: 10px;
    padding-top: 10px;
  }
`;

export const AddressRow = styled(Row)`
  justify-content: space-between;
`;

export const LongInput = styled(InputWrapper)`
  width: 50%;
  margin-right: ${dimensions.mediumPadding}px;

  @media ${devices.maxTablet} {
    width: 100%;
  }
`;

export const Details = styled.div`
  margin-top: ${dimensions.mediumPadding}px;
  margin-bottom: ${dimensions.mediumPadding}px;

  ${({ modalBodyMinHeight = false }: { modalBodyMinHeight?: boolean }) =>
    modalBodyMinHeight && `min-height: ${dimensions.modalMinBodyHeight}px;`}
`;
export const Line = styled.div.attrs({ className: 'standard-text' })``;
export const BoldText = styled.span.attrs({ className: 'medium-weight ' })``;
export const CurrentNumber = styled.div`
  .react-tel-input {
    height: ${({ theme }: { theme: Theme }) => theme.assets.input.standard.height};

    .form-control {
      height: ${({ theme }: { theme: Theme }) => theme.assets.input.standard.height};
      background-color: ${({ theme }: { theme: Theme }) => theme.color.background.input};
      color: ${({ theme }) => `${theme.color.foreground.primaryLight}`};

      @media ${devices.maxTablet} {
        width: 100%;
      }
    }

    .selected-flag {
      background-color: ${({ theme }: { theme: Theme }) => theme.color.background.input} !important;
    }

    .flag-dropdown ul.country-list {
      position: fixed;
    }
  }
`;
export const MobileInputWrapper = styled.div``;
export const LinkButton = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

export const NoAccess = styled.div.attrs({ className: 'xxsmall-text' })``;

export const Dropdown = styled(_Dropdown)`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryInverted} !important;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.input} !important;
  border-color: ${({ theme }: { theme: Theme }) => theme.color.border.inputFocused} !important;
  border-radius: ${({ theme }: { theme: Theme }) => theme.border.radius.standard} !important;
  height: ${({ theme }: { theme: Theme }) => theme.assets.input.standard.height};
  width: 100% !important;
  padding: 9px 10px !important;

  .menu {
    border-color: ${({ theme }: { theme: Theme }) => theme.color.border.inputFocused};
  !important;
    max-height: unset !important;
  }

  .text {
    color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
  !important;
    font-size: ${({ theme }: { theme: Theme }) => theme.typography.text.standard.fontSize};
    font-weight: ${({ theme }: { theme: Theme }) => theme.typography.fontWeight.regular};
    height: 30px;
    line-height: 30px;
  }

  input.search {
    color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
  !important;
    font-size: ${({ theme }: { theme: Theme }) => theme.typography.text.standard.fontSize};
    height: 100%;
    padding: 9px 10px !important;
  }

  .menu .item {
    background-color: ${({ theme }: { theme: Theme }) => theme.color.background.primary} !important;
    height: ${({ theme }: { theme: Theme }) => theme.assets.input.standard.height} !important;
    border: none !important;
    display: flex !important;
    align-items: center;
    border-top: solid 1px ${({ theme }: { theme: Theme }) => theme.color.foreground.primary} !important;
    padding: 5px 10px;

    &.selected {
      background-color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused} !important;

      .text {
        color: ${({ theme }: { theme: Theme }) => theme.color.foreground.formAccent} !important;
      }
    }

    .text {
      line-height: unset;
      height: unset;
      padding: 0 !important;
    }
  }
`;
