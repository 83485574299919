import { Theme } from 'types/theme';
import styled from 'styled-components';
import { dimensions, devices } from 'common/styles';
import _Icon from 'common/components/Icon';

export const BodyWrapper = styled.div.attrs({ className: 'background-primary' })``;

export const FormOuterWrapper = styled.div`
  margin: 0 auto;

  @media ${devices.tablet} {
    flex-wrap: nowrap;
  }
  @media ${devices.laptopL} {
    max-width: ${dimensions.maxContentWidth}px;
  }
`;

export const MainContentWrapper = styled.div.attrs({ className: 'background-secondary' })`
  display: flex;
  padding: 0;
  flex-direction: column;
  @media ${devices.tablet} {
    flex-direction: column;
  }
  @media ${devices.laptop} {
    flex-direction: column;
  }
`;

export const FormWrapper = styled.div``;

export const NarrowColumn = styled.div`
  flex: 0 0 100%;
  @media ${devices.tablet} {
    flex: 0 0 46%;
  }
  @media ${devices.laptop} {
    flex: 0 0 38%;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Text = styled.div.attrs({ className: 'xxsmall-text regular-weight' })`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryLight};
  font-style: normal;
  letter-spacing: -0.02em;
`;

export const TitleText = styled.h1`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 12px;
  padding-bottom: 20px;

  & > *:first-child {
    margin-right: 12px;
    width: inherit;
  }
`;

export const CourseText = styled.div.attrs({ className: 'standard-text' })``;

export const FittedCourseText = styled(CourseText)`
  white-space: normal;
`;

export const CourseAmountText = styled.span.attrs({ className: 'xlarge-text strong-weight' })<{
  $isPriceMaxDeviated?: boolean;
  theme: Theme;
}>`
  ${({ $isPriceMaxDeviated = false, theme }) =>
    $isPriceMaxDeviated && `color: ${theme.color.foreground.caution}`}
`;

export const Separator = styled.div`
  border-bottom: ${({ theme }: { theme: Theme }) =>
    `${theme.border.width.standard} solid ${theme.color.border.standard}`};
  margin: 20px 0;
`;

export const TitleBox = styled.div`
  padding-bottom: 30px;
`;

export const SummaryWrapper = styled.div`
  flex: 1;
  padding-top: 6px;

  & div {
    height: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding-top: 35px;
  gap: 15px;

  & div {
    margin: 0;
  }

  & > * {
    flex: 1;
  }

  @media ${devices.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    gap: unset;
    & > * {
      padding-bottom: 0;
    }
  }
`;

export const ButtonWrapper = styled.div`
  flex: 0 0 45%;
`;

export const DeleteButtonWrapper = styled.div`
  & > div {
    margin: 0;
  }

  padding-top: 15px;

  @media ${devices.tablet} {
    padding-top: 20px;
  }
`;

export const Icon = styled(_Icon)`
  cursor: pointer;
  margin-left: 8px;
`;

export const ButtonsActionText = styled(Text)`
  font-size: ${({ theme }: { theme: Theme }) => theme.typography.text.small.fontSize};
  line-height: ${({ theme }: { theme: Theme }) => theme.typography.text.small.lineHeight};
  letter-spacing: 0em;
  display: block;
  flex: 0 0 100%;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.secondary};
  padding-bottom: 8px;
`;

export const Form = styled.form`
  & > div {
    padding-bottom: 24px;
  }
`;

export const TypeSwitchWrapper = styled.div`
  padding: 24px 0;
  @media ${devices.maxMobileS} {
    padding: ${dimensions.smallIndent}px 0;
  }
`;
