import React from 'react';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { phoneLocales } from 'locales/locales';

type Props = {
  name: string;
  error: string;
  touched: boolean;
  children: JSX.Element;
  valueMap: (country: string) => string;
  onSelect: () => void;
  initialCountry: string;
};

export const PhoneNumberInput = ({
  name,
  error,
  touched,
  valueMap,
  onSelect,
  initialCountry,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const pickerLanguage = phoneLocales[t('l10n.lang')] || {};
  return (
    <>
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <PhoneInput
            countryCodeEditable={false}
            localization={pickerLanguage}
            country={initialCountry}
            placeholder="12345..."
            value={field.value}
            onChange={(value) => form.setFieldValue(name, value)}
            {...rest}
          />
        )}
      </Field>
    </>
  );
};
