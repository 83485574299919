/* eslint-disable */
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { parseQueryString } from 'common/utils/location';
import { loginRetryDelays } from '../const/auth';
import * as keychain from 'services/auth';

export const getClientId = (): string => {
  const { error, response } = keychain.loadDeviceId();
  if (error) {
    // No device id assigned yet, generate and store it
    const deviceId = uuid() as string;
    keychain.storeAuthTokens({
      deviceId,
    });

    return deviceId;
  } else {
    return response?.deviceId ?? '';
  }
};

export const getAuthParams = (): any => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return parseQueryString();
  } else {
    return window?.__proxyParams ?? {};
  }
};

export const setWindowParams = (key: string, value: any): void => (window[key] = value);

export const useAuthParams = (property: string = '') => {
  const [query, setQueryString] = React.useState(property ? null : {});

  React.useEffect(() => {
    const authParams = getAuthParams();
    const parsed = property ? authParams[property] : authParams;

    setQueryString(parsed);
  }, []);

  return query;
};

/**
 * Determines the delay time before the next login retry attempt based on the number of previous attempts.
 *
 * @param {number} reps - The number of previous retry attempts.
 * @returns {number} The delay time in milliseconds.
 */
export const getLoginRetryDelayTime = (reps: number) => {
  // in loginRetryDelays find the value of reps that
  const delay = Object.values(loginRetryDelays).find((item) => item.maxReps && item.maxReps >= reps)?.delay;
  return delay || loginRetryDelays.max.delay;
};
