import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getSelectedCryptoCurrency, getSelectedFiatCurrency } from 'store/selectors/currency';
import { getPortfolio } from 'store/slices/portfolio/selectors';
import { invalidatePortfolio } from 'store/slices/portfolio/actions';
import { Fiat } from 'common/const';
import { State } from 'store/types/store';
import OrderBodyView from 'common/components/TradingRulesCommonComponents/OrderBody/OrderBodyView';
import withRouter from 'common/hoc/WithRouter';
import { WithRouterType } from 'types/withRouter';

const mapStateToProps = (state: State, ownProps: WithRouterType) => {
  return {
    selectedCrypto: getSelectedCryptoCurrency(state, ownProps.params) as unknown as Crypto,
    portfolio: getPortfolio(state),
    selectedFiatCurrency: getSelectedFiatCurrency(state) as Fiat,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    invalidatePortfolio: bindActionCreators(invalidatePortfolio, dispatch),
  };
};

export const OrderBody = withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderBodyView));
