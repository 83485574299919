import { createSelector } from 'reselect';
import { Fiat } from 'common/const';
import { TechIndicatorItem, TechIndicatorResponse, TimePeriod } from 'types/currency';
import { ASSET_CLASSES, Crypto } from 'types/assets';
import { State } from 'store/types/store';
import { CurrencyState } from 'store/types/currency';
import { Params } from 'react-router-dom';
import { getAssetEntities } from 'store/slices/assets/selectors';
import { getPriceDiff } from 'common/utils/math';
import { getQuotePreview } from 'store/selectors/orders';
import { getAllPricesByAssetClass } from 'store/slices/prices/selectors';
import { getAllPriceHistorySmallChartsForSelectedTimePeriod } from 'store/slices/priceHistorySmallChart/selectors';
import { PriceHistorySmallChartEntity } from 'store/types/priceHistory';
import { getCryptoMaxDeviation } from 'common/utils/assets';
import { Dictionary } from '@reduxjs/toolkit';

export const getLastSelectedCurrency = ({ currency }: { currency: CurrencyState }) =>
  currency.lastSelectedCryptoCurrency;

export const getSelectedFiatCurrency = ({ currency }: { currency: CurrencyState }) =>
  currency.selectedFiatCurrency;

// URL is the primary source of truth - if unavailable on the current route, fallback to last selected crypto
export const getSelectedCryptoCurrency = (state: State, routeParams?: Readonly<Params<string>>): string =>
  routeParams?.currency || routeParams?.assetCode || state.currency.lastSelectedCryptoCurrency;

export const getSelectedTimePeriod = ({ currency }: { currency: CurrencyState }) =>
  currency.selectedTimePeriod;

export const getTradingVolume = (state: State) => state.currency.tradingVolume;

export const getSelectedEntity = ({ currency }: { currency: CurrencyState }): string =>
  currency.selectedEntity;

export const getTechIndicatorDataForPairAndPeriod = (
  state: State,
  pair: string,
  timePeriod: TimePeriod,
  indicator: TechIndicatorItem,
): TechIndicatorResponse => {
  const key = indicator.value as string;
  const techIndicatorData = state.currency.techIndicatorData?.[key]?.[pair]?.[timePeriod];
  // We don't use epochs for x-values for the 3H chart
  // so we need filter out potential older entries, since the charting lib cannot match via epochs
  return techIndicatorData;
};

/*
   Memoize the price history object calculation, otherwise it would get recomputed
   on every state change and trigger a re-render
*/

export const getCurrPair = (state: State, selectedFiatCurrency: Fiat, crypto: string) => {
  const cryptos: Dictionary<Crypto> = getAssetEntities(state, ASSET_CLASSES.CRYPTO);
  // ugly flow 'force unwrap' :)
  return Object.values(cryptos).find(
    (c) => c?.pair?.includes(crypto) && c.pair.includes(selectedFiatCurrency),
  )?.pair as string;
};

export const getIsSelectedCryptoDeviated = createSelector(
  [
    (state: State) => getAllPricesByAssetClass(state, ASSET_CLASSES.CRYPTO),
    getQuotePreview,
    (state: State, params: Params<string>) => getSelectedCryptoCurrency(state, params),
  ],
  (cryptoPrices, quotePreview, selectedCrypto) => {
    const { price: orderPrice, value } = quotePreview;

    const priceEntity = cryptoPrices.find(
      (price) => price.baseEntity.toLowerCase() === selectedCrypto.toLowerCase(),
    );

    const { relative, absolute } = getPriceDiff([
      { close: orderPrice },
      {
        close: priceEntity?.midPrice ?? 0,
      },
    ]);

    const absRelative = Math.abs(relative);

    const { maxDeviation, deviationTriggerCondition } = getCryptoMaxDeviation(value ?? 0, selectedCrypto);

    return {
      isPriceMaxDeviated: absRelative > maxDeviation,
      deviationTriggerCondition: deviationTriggerCondition,
    };
  },
);

export const getCryptosDetails = createSelector(
  [getAllPriceHistorySmallChartsForSelectedTimePeriod],
  (smallChartsEntities: PriceHistorySmallChartEntity[]) => {
    return smallChartsEntities.map((chartEntity) => {
      const { items: priceHistory, assetCode } = chartEntity;

      let price = 0.0;
      let priceDiff = 0.0;

      if (!!priceHistory?.length) {
        price = priceHistory[priceHistory.length - 1].y || 0;

        const firstNotEmptyPrice = priceHistory.find(({ y }) => y !== 0)?.y ?? 0;
        priceDiff = price - firstNotEmptyPrice;
      }

      return {
        assetCode,
        priceDiff,
        priceHistory,
      };
    });
  },
);
