import AndroidBanner from 'common/components/AndroidBanner';
import AuditProofModals from 'common/components/Modal/AuditProofModals';
import { isAmlRequired } from 'common/utils/auditProof';
import { useModalProvider } from 'providers/Modal/useModalProvider';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsBannerShown } from 'store/selectors/androidBanner';
import { getAml } from 'store/selectors/auth';
import { getIsSolarisDownErrorOccurred, getWasAuditProofModalClosed } from 'store/selectors/localSettings';
import TermsAndConditionsModal from 'common/components/Modal/TermsAndConditionsModal';
import { useTnCPopUpDisplay } from './useTnCPopUpDisplay';
import { TermsAndConditionsType } from 'types/termsAndConditions';
import { usePhishingModalDisplay } from './usePhishingModalDisplay';
import PhishingModal from 'common/components/Modal/PhishingModal';
import { useImportantMessagesModal } from './useImportantMessagesModal';
import InboxImportantMessages from 'common/components/Modal/InboxImportantMessages';
import { getIsDeviceMonitoringConsentOpen } from 'store/selectors/deviceMonitoring';
import DeviceMonitoringConsentModal from 'common/components/Modal/DeviceMonitoringConsentModal';
import { getIsWinnerLoserModalShown } from 'store/slices/experiments/winnersLosers/selectors';
import WinnersLosersModal from 'common/components/Modal/WinnersLosersModal';
import { useAddPhoneNumberFlowDisplay } from './useAddPhoneNumberFlowDisplay';
import AddPhoneNumberFlow from 'common/components/Modal/AddPhoneNumberFlow';
import FeatureSpecificTncConsentModal from 'common/components/Modal/FeatureSpecificTncConsentModal';
import { getIsFeatureTermsAndConditionsShown } from 'store/selectors/termsAndConditions';
import { ModalType } from 'providers/Modal/types';
import StakingPhasedRolloutModal from 'common/components/Modal/StakingPhasedRolloutModal';
import { useStakingPhasedRolloutModal } from 'common/hooks/useStakingPhasedRolloutModal';
import { setIsImportantMessagesModalWasDisplayed } from 'common/utils/importantMessagesSessionStorage';

const PRIVATE_PAGE_MODAL_TYPE: ModalType = 'private';

/**
 * Hook that watches state and displays initial modals.
 */
export function usePrivatePageInitialPopups(): any {
  const dispatch = useDispatch();
  const modalProvider = useModalProvider();
  const { displayCryptoTnc, displayStocksTnc } = useTnCPopUpDisplay();
  const isPhishingModalDisplayed = usePhishingModalDisplay();
  const isImportantMessagesModalDisplayed = useImportantMessagesModal();
  const isAddPhoneNumberModalDisplayed = useAddPhoneNumberFlowDisplay();

  const isWinnersAndLoosersModalDisplayed = useSelector(getIsWinnerLoserModalShown);
  const isDeviceMonitoringConsentOpen = useSelector(getIsDeviceMonitoringConsentOpen);
  const isAndroidBannerDisplayed = useSelector(getIsBannerShown);
  const aml = useSelector(getAml);
  const wasAuditProofModalClosed = useSelector(getWasAuditProofModalClosed);
  const isTopUpsTermsAndConditionsShown = useSelector(getIsFeatureTermsAndConditionsShown);
  const isSolarisDownErrorOccurred = useSelector(getIsSolarisDownErrorOccurred);
  const { isStakingPhasedRolloutModalShown, onCloseStakingPhasedRollout } = useStakingPhasedRolloutModal();

  useEffect(() => {
    if (isAndroidBannerDisplayed) {
      modalProvider.addModal({
        component: <AndroidBanner onClose={() => {}}></AndroidBanner>,
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [isAndroidBannerDisplayed]);

  useEffect(() => {
    if (!isAmlRequired(aml.confirmationStatus) || isSolarisDownErrorOccurred || wasAuditProofModalClosed)
      return;

    modalProvider.addModal({
      component: <AuditProofModals onClose={() => {}}></AuditProofModals>,
      type: PRIVATE_PAGE_MODAL_TYPE,
    });
  }, [aml.confirmationStatus, isSolarisDownErrorOccurred, wasAuditProofModalClosed]);

  useEffect(() => {
    if (displayCryptoTnc) {
      modalProvider.addModal({
        component: (
          <TermsAndConditionsModal
            type={TermsAndConditionsType.CRYPTO}
            onClose={() => {}}
          ></TermsAndConditionsModal>
        ),
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [displayCryptoTnc]);

  useEffect(() => {
    if (displayStocksTnc) {
      modalProvider.addModal({
        component: (
          <TermsAndConditionsModal
            type={TermsAndConditionsType.STOCKS}
            onClose={() => {}}
          ></TermsAndConditionsModal>
        ),
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [displayStocksTnc]);

  useEffect(() => {
    if (isPhishingModalDisplayed) {
      modalProvider.addModal({
        component: <PhishingModal onClose={() => {}}></PhishingModal>,
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [isPhishingModalDisplayed]);

  useEffect(() => {
    if (isImportantMessagesModalDisplayed) {
      modalProvider.addModal({
        component: <InboxImportantMessages onClose={() => {}}></InboxImportantMessages>,
        type: PRIVATE_PAGE_MODAL_TYPE,
        onCloseCallback: () => {
          setIsImportantMessagesModalWasDisplayed(true);
        },
      });
    }
  }, [isImportantMessagesModalDisplayed]);

  useEffect(() => {
    if (isDeviceMonitoringConsentOpen) {
      modalProvider.addModal({
        component: <DeviceMonitoringConsentModal onClose={() => {}}></DeviceMonitoringConsentModal>,
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [isDeviceMonitoringConsentOpen]);

  useEffect(() => {
    if (isWinnersAndLoosersModalDisplayed) {
      modalProvider.addModal({
        component: <WinnersLosersModal onClose={() => {}}></WinnersLosersModal>,
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [isWinnersAndLoosersModalDisplayed]);

  useEffect(() => {
    if (isAddPhoneNumberModalDisplayed) {
      modalProvider.addModal({
        component: <AddPhoneNumberFlow onClose={() => {}}></AddPhoneNumberFlow>,
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [isAddPhoneNumberModalDisplayed]);

  useEffect(() => {
    if (isTopUpsTermsAndConditionsShown) {
      modalProvider.addModal({
        component: <FeatureSpecificTncConsentModal onClose={() => {}} />,
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [isTopUpsTermsAndConditionsShown]);

  useEffect(() => {
    if (isStakingPhasedRolloutModalShown) {
      modalProvider.addModal({
        component: <StakingPhasedRolloutModal onClose={() => {}} />,
        onCloseCallback: onCloseStakingPhasedRollout,
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [isStakingPhasedRolloutModalShown]);
}
