import React from 'react';
import Switch, { SwitchProps } from '@mui/material/Switch';

interface Props extends SwitchProps {
  checked: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SwitchGreen = ({ checked, onClick }: Props) => {
  return <Switch checked={checked} onClick={onClick} disableRipple />;
};
