import Button from 'common/components/Button';
import IconButton from 'common/components/IconButton/IconButton';
import { devices } from 'common/styles';
import styled from 'styled-components';
import { Theme } from 'types/theme';

export const StockRaffleBannerContainer = styled.div`
  padding: 8px 8px 15px 16px;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.stockRaffleBanner};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  row-gap: 16px;

  @media ${devices.tablet} {
    padding: 8px 8px 12px 24px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;

export const RaffleButton = styled(Button)`
  border-color: white;
  border-width: 1px;
  margin: 0;

  span {
    color: white;
  }

  @media ${devices.maxTablet} {
    align-self: flex-end;
  }
`;

export const RaffleButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const RaffleCloseButton = styled(Button)`
  background-color: ${({ theme }: { theme: Theme; $raffleJoined: boolean }) =>
    theme.color.background.stockRaffleCloseBanner};
  height: fit-content;
  margin: 0;
  padding: 3px;
  min-width: 0;
  border-radius: 4px;
  align-self: flex-start;
  span::before {
    color: ${({ theme }: { theme: Theme; $raffleJoined: boolean }) => theme.color.foreground.focused};
  }

  @media ${devices.tablet} {
    margin-left: 24px;
    flex-shrink: 0;
  }

  @media ${devices.laptop} {
    margin-left: ${({ $raffleJoined }: { theme: Theme; $raffleJoined: boolean }) =>
      $raffleJoined ? 'auto' : '24px'};
  }
`;

export const RaffleTitle = styled.span.attrs({
  className: 'large-text strong-weight',
})`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
  @media ${devices.maxTablet} {
    flex: 0 0 85%;
  }
`;

export const RaffleText = styled.div`
  @media ${devices.maxTablet} {
    flex: 1 1 40%;
    padding-bottom: 10px;
  }

  @media ${devices.tablet} {
    padding-top: 9px;
    padding-bottom: 5px;
  }

  @media ${devices.laptop} {
    margin: ${({ raffleJoined }: { theme: Theme; raffleJoined: boolean }) =>
      raffleJoined ? '0 auto' : 'none'};
  }
`;
