/* eslint-disable @typescript-eslint/no-unsafe-return */
import I18n from 'i18next';
import { activityAdapter } from './adapter';
import { formatWithLocale, parseISOUTCStringToUTC } from 'common/utils/date';
import { Order } from 'types/currency';
import { State } from 'store/types/store';

const activitySelectors = activityAdapter.getSelectors<State>((state) => state.activity);

export const getAllActivityItemsCount = (state: State) => activitySelectors.selectAll(state)?.length ?? 0;

export const getActivityItemsReference = (state: State) => state.activity.reference;

export const getAllActivityGroupedItems = (state: State) =>
  activitySelectors.selectAll(state).reduce<Record<string, Order[]>>((acc, val) => {
    const localDate = formatWithLocale(
      parseISOUTCStringToUTC(val.accountedAt || val.placedAtUtc),
      I18n.t('l10n.transactionHistoryGroupedDateFormat'),
    );
    acc[localDate] = [...(acc[localDate] ?? []), val];
    return acc;
  }, {});

export const getActivityItemsLoading = (state: State) => state.activity.loading;

export const getActivityItemsFullyLoaded = (state: State) => state.activity.fullyLoaded;

export const getActivityFilters = (state: State) => state.activity.filters;
