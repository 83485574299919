// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { isSubPage as checkIsSubPage } from 'common/urls';
import { useDesktopBackArrow } from 'common/hooks/useDesktopBackArrow';
import Body from 'common/components/Body';
import Footer from 'common/components/Footer';
import TopBar from 'common/components/TopBar';
import Content from 'common/components/Content';
import { SideMenu } from 'common/components/SideMenu';
import { useDesktop } from 'common/hooks/useMedia';
import MobileMenu from 'common/components/MobileMenu/MobileMenu';
import { LayoutRegularWrapper } from '../../../common/components/LayoutContainer/LayoutRegularWrapper/LayoutRegularWrapper';
import { getIsUserInPaperTrading } from 'store/selectors/auth';
import { useSelector } from 'react-redux';
import {
  AppHeader,
  BackgroundDiv,
  Container,
  DefaultWrapper,
  MainContent,
  SideContent,
  PageHeadline,
} from './styles';
import { useMainPages } from 'common/hooks/useMainPages';
import PageBanners from 'common/components/PageBanners';
import IconButton from 'common/components/IconButton/IconButton';
import Icon from 'common/components/Icon';
import BetaSettings from 'common/components/BettaSettings';

type DefaultLayoutProps = {
  children: React.Node;
  title: string;
  user?: User;
  loggedIn?: boolean;
  noTabBar?: boolean;
  mainPage?: boolean;
  kycPage?: boolean;
  withoutSideBar?: boolean;
  disableBack?: boolean;
  fullWidth?: boolean;
  isKYCDone?: boolean;
  privatePage?: boolean;
  logout?: typeof logoutUser;
  pageHeadlinei18nKey: string;
};
const DefaultLayout = ({
  children,
  title,
  mainPage,
  kycPage,
  withoutSideBar,
  noTabBar,
  isKYCDone,
  disableBack,
  fullWidth,
  privatePage,
  user,
  loggedIn,
  logout,
  pageHeadlinei18nKey,
  customHeadlineComponent,
  ...rest
}: DefaultLayoutProps) => {
  const { pathname: locationPathname } = useLocation();

  const match = useMatch(locationPathname);

  const showPaperTradingBanner = useSelector(getIsUserInPaperTrading);
  const isSubPage = checkIsSubPage(match?.pattern?.path ?? '');
  const enableBack = disableBack ? false : !isSubPage; // disableBack overrides isSubPage when defined
  const navigate = useNavigate();
  const { backUrl } = useDesktopBackArrow();

  const desktopLayout = useDesktop();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const { isMainCategory, isShowFooter } = useMainPages();
  const toggleMenu = (newState?: boolean) =>
    setOpenMenu((state) => (newState !== undefined ? newState : !state));

  React.useEffect(() => {
    setOpen(desktopLayout);
    setOpenMenu(desktopLayout);
  }, [desktopLayout]);

  React.useEffect(() => {
    if (open) {
      setOpenMenu(false);
    }
  }, [open]);

  React.useEffect(() => {
    if (openMenu) {
      setOpen(false);
    }
  }, [openMenu]);

  const renderChildren = React.Children.map(children, (child) => React.cloneElement(child, { ...rest }));

  const sideBarContent = <SideMenu />;

  const wrappedChildren = desktopLayout ? (
    <>
      {pageHeadlinei18nKey && (
        <PageHeadline>
          {backUrl && (
            <IconButton onClick={() => navigate(backUrl)}>
              <Icon name="icon-arrow" sizeValue="tiny" />
            </IconButton>
          )}
          {t(pageHeadlinei18nKey)}
        </PageHeadline>
      )}
      {customHeadlineComponent}
      <DefaultWrapper>
        {!kycPage && !withoutSideBar && <SideContent>{sideBarContent}</SideContent>}
        <MainContent>
          <Container>{renderChildren}</Container>
        </MainContent>
      </DefaultWrapper>
      {window.env.ENVIRONMENT !== 'production' && !kycPage && !withoutSideBar && <BetaSettings />}
    </>
  ) : (
    <>
      <Container>
        <LayoutRegularWrapper withMobileMenu={isMainCategory && !isShowFooter}>
          {pageHeadlinei18nKey && <PageHeadline>{t(pageHeadlinei18nKey)}</PageHeadline>}
          {customHeadlineComponent}
          {renderChildren}
        </LayoutRegularWrapper>
      </Container>
      {isShowFooter && <Footer withMobileMenu />}
    </>
  );

  return (
    <>
      <Body>
        <AppHeader>
          <TopBar
            title={title}
            enableBack={enableBack}
            mainPage={mainPage}
            toggleMobileMenu={toggleMenu}
            mobileMenuOpen={openMenu}
          />
        </AppHeader>
        <BackgroundDiv>
          {desktopLayout ? (
            <LayoutRegularWrapper>
              <PageBanners />
              <Content showPaperTradingBanner={showPaperTradingBanner}>{wrappedChildren}</Content>
            </LayoutRegularWrapper>
          ) : (
            <>
              <PageBanners />
              <Content showPaperTradingBanner={showPaperTradingBanner}>{wrappedChildren}</Content>
            </>
          )}
        </BackgroundDiv>
        {desktopLayout ? <Footer /> : <MobileMenu opened={openMenu} toggleMobileMenu={toggleMenu} />}
      </Body>
    </>
  );
};
DefaultLayout.defaultProps = {
  mainPage: false,
  noTabBar: false,
  isKYCDone: false,
  disableBack: undefined,
  privatePage: false,
  fullWidth: false,
  user: {},
};

export default DefaultLayout;
