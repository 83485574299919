/* eslint-disable @typescript-eslint/no-var-requires */
import I18n from 'i18next';
import { buyOrSellOrderTypes } from 'common/const';
import { generatePath } from 'react-router-dom';
import { currencies } from 'common/utils/assets';
import { ASSET_CLASSES } from 'types/assets';

const mobile = require('is-mobile');

const isMobile = mobile();

let idNowVideoidentUrl;
let idNowAutoidentUrl;
if (window.env.ENVIRONMENT === 'production') {
  idNowVideoidentUrl = isMobile
    ? 'https://go.idnow.de/solarisbankvideoidentbison/identifications/:code/mobile'
    : 'https://go.idnow.de/solarisbankvideoidentbison/identifications/:code/webcam';

  idNowAutoidentUrl = isMobile
    ? 'https://go.idnow.de/solarisbankautobison/identifications/:code/mobile'
    : 'https://go.idnow.de/solarisbankautobison/identifications/:code/webcam';
} else {
  idNowVideoidentUrl = isMobile
    ? 'https://go.test.idnow.de/solarisbankvideoidentbison/identifications/:code/mobile'
    : 'https://go.test.idnow.de/solarisbankvideoidentbison/identifications/:code/webcam';

  idNowAutoidentUrl = isMobile
    ? 'https://go.test.idnow.de/solarisbankautobison/identifications/:code/mobile'
    : 'https://go.test.idnow.de/solarisbankautobison/identifications/:code/webcam';
}

export const seonSdkDomain = 'deviceinf.com';
export const seonSdkUrl = `https://cdn.${seonSdkDomain}/js/v5/agent.js`;

export const publicPaths = {
  LOGIN: '/login',
  SIGNUP: '/signup',
  SIGNUP_CONFIRM: '/signup/confirm',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: `/reset-password/:token`,
  EMAIL_CONFIRM: `/email-confirm/:token`,
  EMAIL_CHANGE_CONFIRM: `/email-change/:token`,
};

export const privatePaths = {
  PORTFOLIO: '/portfolio',
  PORTFOLIO_CRYPTO: '/portfolio/:currency',
  LIMIT_ORDER: '/trade-manager/limit-order',
  TRADE_MANAGER: '/trade-manager',
  WATCH_LIST: '/trade-manager/watch-list',
  MARKET_OVERVIEW: '/market-overview',
  MARKET_OVERVIEW_CRYPTO: '/market-overview/crypto/:currency',
  MARKET_OVERVIEW_SECURITY: '/market-overview/security/:class/:isin',
  TRANSACTION_HISTORY: '/transaction-history',
  TRANSACTION_HISTORY_CRYPTO: '/transaction-history/:currency',
  TRADE_CURRENCY: '/trading',
  TRADE_CURRENCY_CRYPTO: '/trading/:type/:currency',
  TRADE_ASSET: '/trading/:type/:assetClass/:assetSecurityClass?/:assetCode',
  REFERRAL: '/referral',
  TECH_INDICATORS: '/tech-indicators',
  TECH_INDICATORS_CRYPTO: '/tech-indicators/:currency',
  DESIGN: '/design',

  // crypto withdraw
  CRYPTO_WITHDRAW: '/crypto-withdraw',
  CRYPTO_WITHDRAW_AMOUNT: '/crypto-withdraw/amount',
  CRYPTO_WITHDRAW_PASSWORD: `/crypto-withdraw/password`,
  CRYPTO_WITHDRAW_WALLET: `/crypto-withdraw/wallet/:currency`,
  CRYPTO_WITHDRAW_CONFIRMATION: `/crypto-withdraw/confirmation`,
  CRYPTO_WITHDRAW_SUCCESS: `/crypto-withdraw/success`,
  CRYPTO_WITHDRAW_FAIL: `/crypto-withdraw/fail`,
  CRYPTO_WITHDRAW_LEGAL: '/crypto-withdraw/legal/:currency',

  CRYPTO_DEPOSIT: '/crypto-deposit',

  // withdraw
  WITHDRAW: '/withdraw',
  WITHDRAW_CONFIRMATION: `/withdraw/confirmation`,
  WITHDRAW_SUCCESS: `/withdraw/success`,
  DEPOSIT: `/deposit`,
  DEPOSIT_TRANSFER: '/deposit-transfer',
  DEPOSIT_INSTANT: '/deposit-instant',

  // settings
  ACCOUNT: '/account',
  SETTINGS: '/settings',
  PERSONAL_INFO: '/personal-info',
  ACCOUNT_INFO: '/account-info',
  OFFSET_ACCOUNTS: '/offset-accounts',
  TAX_EXEMPTION: '/tax-exemption',
  KYC: '/kyc',
  KYC_STEP: '/kyc/:step?',
  KYC_IDENT_OPTIONS: '/kyc/ident-options',
  REDEEM_CODE: '/redeem-code',
  PAIRED_DEVICES: '/paired-devices',
  INFO_REPORT: '/reports',
  INVITE_FRIENDS: '/invite-friends',
  INBOX: '/inbox',
  FIRST_PAGE_AFTER_LOGIN: '/',
  LIMIT_ORDER_CREATION_BASE: '/limit-order-creation',
  LIMIT_ORDER_CREATION: '/limit-order-creation/:type/:currency',
  LIMIT_ORDER_DELETE: '/limit-order-delete',
  STOP_ORDER_CREATION_BASE: '/stop-order-creation',
  STOP_ORDER_CREATION: '/stop-order-creation/:type/:currency',
  STOP_ORDER_DELETE: '/stop-order-delete',
  PRICE_ALERTS: '/trade-manager/price-alerts',
  PRICE_ALERTS_CREATION_BASE: '/price-alerts-creation',
  PRICE_ALERTS_CREATION: '/price-alerts-creation/:priceAlertType/:currency',
  SAVINGS_PLAN_CREATION_BASE: '/savings-plan-creation',
  SAVINGS_PLAN_CREATION: '/savings-plan-creation/:currency',
  MOBILE_NOTIFICATION: '/mobile-notification',
  DELETE_ACCOUNT: '/delete-account',
  SAVINGS_PLAN: '/trade-manager/savings-plan',
  SAVINGS_PLAN_DELETE: '/savings-plan-delete',
  HELP_SECTION: '/help',
  FEATURE_REQUEST: '/help/feature-request',
  STOCKS_KYC: '/stocks-kyc',
  RENEW_KYC_DOCS: '/renew-kyc-documents',
  RISK_SETTINGS: '/risk-settings',
  AUDIT_PROOF: '/audit-proof',
};

export const externalPaths = {
  BISON_LANDING: 'https://bisonapp.com',
  BISON_FAQ: `https://bisonapp.com/faq`,
  BISON_BANK_PROCESSING: 'https://bisonapp.com/bankenabwicklung/',
  IDNOW_VIDEOIDENT: idNowVideoidentUrl,
  IDNOW_AUTOIDENT: idNowAutoidentUrl,
  BOERSE: 'https://www.boerse-stuttgart.de/',
  BISON_TRADE: 'trade.bisonapp.com',
  ID_NOW_KYC: `https://go${
    window.env.ENVIRONMENT === 'production' ? '' : '.test'
  }.idnow.de/solarisbankvideoidentbison/identifications/:code${isMobile ? '/webcam' : ''}`,
};

export const featureStocksPaths = [
  privatePaths.STOCKS_KYC,
  privatePaths.RISK_SETTINGS,
  privatePaths.MARKET_OVERVIEW_SECURITY,
  privatePaths.TAX_EXEMPTION,
];

export const paths = {
  HOME: '/',
  ROOT: '/',
  ...publicPaths,
  ...privatePaths,
  ...externalPaths,
};

const notCompleteUrls = [paths.PORTFOLIO, paths.TRADE_CURRENCY, paths.MARKET_OVERVIEW, paths.KYC];

export const stripTrailingSlash = (path: string) => path.replace(/\/$/g, '');

// see if redirecting into any of cryptoDependentPaths
export const notCompleteUrl = (url: string) => {
  const filtered = notCompleteUrls.filter((path) => stripTrailingSlash(url) === path);
  return filtered.length > 0;
};

export const isPublic = (url: string) => {
  const filtered = Object.keys(publicPaths).filter((path) => stripTrailingSlash(url) === publicPaths[path]);
  return filtered.length > 0;
};

export const customerSupportEmail = 'support@bisonapp.com';
export const baseURL = 'https://bisonapp.com';
export const defaultJoinURL = 'https://join.bisonapp.com';
export const documentsURL = `${baseURL}/dokumente`;

const pageURLsDe = {
  website: `${baseURL}`,
  agb: `${documentsURL}/agb`,
  datenschutz: `${documentsURL}/datenschutz`,
  impressum: `${documentsURL}/impressum`,
  risiko: `${documentsURL}/risikoinformationen`,
  faq: `${baseURL}/faq`,
  gutschein: `${baseURL}/gutschein`,
  join: `${defaultJoinURL}`,
  freundeEinladen: `${baseURL}/freunde-einladen`,
  referralLottery: `${baseURL}/freunde-einladen/gewinnspiel`,
  cryptoWithdrawInfo: 'https://bisonapp.com/faq/#was-beachten-bei-auszahlung-von-kryptowaehrungen',
  cookies: 'https://bisonapp.com/cookie-hinweise-eu-updated/',
  privacyPolicy: 'https://bisonapp.com/dokumente/datenschutz/',
  savingsPlanBonus: 'https://bisonapp.com/sparplan-bonus/',
};

const pageURLsEn = {
  ...pageURLsDe,
  gutschein: `${baseURL}/coupon`,
  freundeEinladen: `${baseURL}/invite-friends/`,
  referralLottery: `${baseURL}/friends-invite/lottery`,
  cryptoWithdrawInfo: 'https://bisonapp.com/en/faq/#things-to-know-when-paying-out-my-cryptocurrencies',
  cookies: 'https://bisonapp.com/en/cookie-notice-eu-updated/',
  privacyPolicy: 'https://bisonapp.com/en/documents/data-privacy/',
  savingsPlanBonus: 'https://bisonapp.com/en/sparplan-bonus/',
};

export const pageURLs = () => {
  const lang = I18n.t('l10n.lang');
  switch (lang) {
    case 'de':
      return pageURLsDe;
    default:
      return pageURLsEn;
  }
};

/**
 * replace placeholders inside the paths with the values,
 * so that we get the live paths
 */

const isCryptoWtdSubpage = (pathname: string) => {
  const formatPathname = pathname.replace(/\/$/, '');
  return (
    [
      paths.CRYPTO_WITHDRAW_AMOUNT,
      paths.CRYPTO_WITHDRAW_PASSWORD,
      paths.CRYPTO_WITHDRAW_WALLET,
      paths.CRYPTO_WITHDRAW_CONFIRMATION,
      paths.CRYPTO_WITHDRAW_SUCCESS,
    ].indexOf(formatPathname) > -1
  );
};

const isWtdSubpage = (pathname: string) => {
  const formatPathname = pathname.replace(/\/$/, '');
  return [paths.WITHDRAW_CONFIRMATION, paths.WITHDRAW_SUCCESS].indexOf(formatPathname) > -1;
};

export const isSubPage = (pathname: string) => isWtdSubpage(pathname) || isCryptoWtdSubpage(pathname);

export const getParentPage = (pathname: string) => {
  // if the pathname is a child page of one of the main menu pages
  if (pathname.indexOf(paths.PORTFOLIO) === 0) {
    return paths.PORTFOLIO;
  }
  if (pathname.indexOf(paths.MARKET_OVERVIEW) === 0) {
    return paths.MARKET_OVERVIEW;
  }
  if (pathname.indexOf(paths.TRADE_CURRENCY) === 0) {
    return paths.TRADE_CURRENCY;
  }
  return pathname;
};

// generate app possible page titles
export const cryptoUrlsTitles = Object.keys(currencies).reduce((acc, crypto) => {
  const params = { currency: crypto };

  const portfolioUrl = generatePath(paths.PORTFOLIO_CRYPTO, params);
  const marketUrl = generatePath(paths.MARKET_OVERVIEW_CRYPTO, params);
  const buyUrl = generatePath(paths.TRADE_ASSET, {
    assetClass: ASSET_CLASSES.CRYPTO,
    assetCode: params.currency,
    type: buyOrSellOrderTypes.Buy.toLowerCase(),
  });
  const sellUrl = generatePath(paths.TRADE_ASSET, {
    assetClass: ASSET_CLASSES.CRYPTO,
    assetCode: params.currency,
    type: buyOrSellOrderTypes.Sell.toLowerCase(),
  });
  const transactionsUrl = generatePath(paths.TRANSACTION_HISTORY_CRYPTO, params);

  acc[portfolioUrl] = 'mainScreenTitles.portfolio';
  acc[marketUrl] = 'mainScreenTitles.market';
  acc[buyUrl] = 'mainScreenTitles.trading';
  acc[sellUrl] = 'mainScreenTitles.trading';
  acc[transactionsUrl] = 'mainScreenTitles.orderHistory';
  return acc;
}, {});

export const urlTitleCodes = {
  [paths.LOGIN]: 'metaTags.signin.title',
  [paths.SIGNUP]: 'metaTags.signup.title',
  [paths.SIGNUP_CONFIRM]: 'metaTags.signup.title',
  [paths.FORGOT_PASSWORD]: 'forgotPassword.title',
  [paths.RESET_PASSWORD]: 'forgotPassword.title',
  [paths.EMAIL_CONFIRM]: 'emailConfirm.title',
  [paths.EMAIL_CHANGE_CONFIRM]: 'emailChangeConfirm.title',
  [paths.PORTFOLIO]: 'mainScreenTitles.portfolio',
  [paths.LIMIT_ORDER]: 'mainScreenTitles.limitOrder',
  [paths.TRADE_MANAGER]: 'mainScreenTitles.tradeManager',
  [paths.SAVINGS_PLAN]: 'mainScreenTitles.savingPlan',
  [paths.PRICE_ALERTS]: 'mainScreenTitles.priceAlerts',
  [paths.MARKET_OVERVIEW]: 'mainScreenTitles.market',
  [paths.SETTINGS]: 'settings.account',
  [paths.OFFSET_ACCOUNTS]: 'mainScreenTitles.offsetAccounts',
  [paths.TAX_EXEMPTION]: 'settings.taxExemption.title',
  [paths.TRANSACTION_HISTORY]: 'mainScreenTitles.transactionHistory',
  [paths.TRADE_CURRENCY]: 'mainScreenTitles.trading',
  [paths.CRYPTO_WITHDRAW]: 'mainScreenTitles.cryptoWithdraw',
  [paths.CRYPTO_WITHDRAW_LEGAL]: 'mainScreenTitles.cryptoWithdrawLegal',
  [paths.CRYPTO_DEPOSIT]: 'mainScreenTitles.cryptoDeposit',
  [paths.WITHDRAW]: 'mainScreenTitles.withdraw',
  [paths.INFO_REPORT]: 'mainScreenTitles.infoReport',
  [paths.DEPOSIT]: 'mainScreenTitles.depositSelect',
  [paths.DEPOSIT_INSTANT]: 'mainScreenTitles.depositTopUp',
  [paths.DEPOSIT_TRANSFER]: 'mainScreenTitles.depositTransfer',
  [paths.PERSONAL_INFO]: 'settings.personalInfo.title',
  [paths.ACCOUNT_INFO]: 'settings.accountInfo.title',
  [paths.KYC]: 'settings.verification',
  [paths.REDEEM_CODE]: 'mainScreenTitles.redeemCode',
  [paths.PAIRED_DEVICES]: 'devices.title',
  [paths.REFERRAL]: 'mainScreenTitles.referral',
  [paths.INBOX]: 'settings.inbox',
  [paths.LIMIT_ORDER_DELETE]: 'mainScreenTitles.limitOrder',
  [paths.LIMIT_ORDER_CREATION]: 'metaTags.app.title',
  [paths.STOP_ORDER_DELETE]: 'stopOrders.newStopOrder',
  [paths.STOP_ORDER_CREATION]: 'metaTags.app.title',
  [paths.PRICE_ALERTS_CREATION]: 'metaTags.app.title',
  [paths.DELETE_ACCOUNT]: 'mainScreenTitles.deleteAccount',
  [paths.SAVINGS_PLAN_CREATION]: 'metaTags.app.title',
  [paths.SAVINGS_PLAN_DELETE]: 'metaTags.app.title',
  [paths.TECH_INDICATORS]: 'mainScreenTitles.techIndicators',
  [paths.FEATURE_REQUEST]: 'settings.submitFeature.title',
  [paths.HELP_SECTION]: 'settings.help.title',
  [paths.DESIGN]: 'mainScreenTitles.design',
  [paths.RISK_SETTINGS]: 'mainScreenTitles.riskSettings',
  ...cryptoUrlsTitles,
};

export const fiatUrls = new Set([paths.PORTFOLIO_CRYPTO]);

/**
 * matchPathToUrl returns root pathname from available paths and extracts matched variables
 * @param {*} pathname Has to be pure pathname without query at the end
 */
export function matchPathToUrl(pathname: string): { path: string; match: { [key: string]: string } } {
  const splittedPathname = pathname?.split('/').filter((item) => item);

  if (!splittedPathname) return false;

  let match: { [key: string]: string } = {};
  const matchedPath = Object.values(paths).find((path) => {
    if (path.includes('/:')) {
      // filter out paths that do not include variables (the indicator for variable is "/:")
      const splittedPath = path.split('/').filter((item) => item);
      if (splittedPath.length === splittedPathname.length && splittedPath[0] === splittedPathname[0]) {
        // immediatelly check if the length and the begginning match
        for (let i = 0; i <= splittedPath.length - 1; i++) {
          // loop through both arrays, values have to be identical or key/value pair. Otherwise break the loop and clear match object
          if (splittedPath[i] !== splittedPathname[i]) {
            if (splittedPath[i][0] === ':') {
              match[splittedPath[i].replace(':', '')] = splittedPathname[i];
            } else {
              match = {};
              return false;
            }
          }
        }
        return true;
      }
    }
    return false;
  });

  return { path: matchedPath || pathname, match };
}
