import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Action, AnyAction } from 'redux';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { isOfType, isPresent } from 'safetypings';
import { from, of, race } from 'rxjs';
import I18n from 'i18next';
import * as api from 'common/api';
import { State } from 'store/types/store';
import { Alert } from 'types/alerts';
import { paths } from 'common/urls';
import { handleAjaxError } from './auth';
import {
  CREATE_ALERT,
  CREATE_ALERT_FAILURE,
  CREATE_ALERT_SUCCESS,
  createAlertFailure,
  createAlertSuccess,
  DELETE_ALERT,
  DELETE_ALERT_FAILURE,
  DELETE_ALERT_SUCCESS,
  deleteAlertSuccess,
  EDIT_ALERT,
  EDIT_ALERT_FAILURE,
  EDIT_ALERT_SUCCESS,
  editAlertFailure,
  editAlertSuccess,
  FETCH_ALERTS_REQUEST,
  fetchAlertsFailure,
  fetchAlertsSuccess,
  INVALIDATE_ALERTS_REQUEST,
  invalidateAlertsRequest as invalidateAlertsAction,
  PUT_ALERT,
  putAlertSuccess,
} from '../actions/alerting';
import * as notificationBannerActions from '../actions/notificationBanner';
import { getAccessToken } from '../selectors/auth';

export const fetchAlertsEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType([FETCH_ALERTS_REQUEST, INVALIDATE_ALERTS_REQUEST])),
    switchMap(() =>
      race(
        state$.pipe(
          map(getAccessToken),
          filter(isPresent),
          take(1),
          switchMap((accessToken) =>
            from(api.fetchPriceAlerts(accessToken)).pipe(
              map(fetchAlertsSuccess),
              catchError(
                handleAjaxError(
                  action$,
                  fetchAlertsFailure,
                  {},
                  {
                    message: I18n.t('alerts.banners.fetchingError'),
                  },
                ),
              ),
            ),
          ),
        ),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
        action$.pipe(ofType(PUT_ALERT), take(1), ignoreElements()),
      ),
    ),
  );

export const putAlertEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(PUT_ALERT)),
    pluck('payload'),
    mergeMap((alert: Alert) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(api.putPriceAlert(alert, accessToken)).pipe(
            map(putAlertSuccess),
            catchError(
              handleAjaxError(
                action$,
                invalidateAlertsAction,
                {},
                {
                  message: I18n.t('alerts.banners.genericError'),
                },
              ),
            ),
          ),
        ),
      ),
    ),
  );

export const createAlertsEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(CREATE_ALERT)),
    pluck('payload'),
    mergeMap((alert: Alert) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) => from(api.putPriceAlert(alert, accessToken)).pipe(map(createAlertSuccess))),
        catchError(
          handleAjaxError(
            action$,
            createAlertFailure,
            {},
            {
              message: I18n.t('alerts.banners.genericError'),
            },
          ),
        ),
      ),
    ),
  );

const getAlertNotificationsObservable = (
  responseAction: { payload: { response: { data: { code: string } } } },
  actions: { setSubmitting: (newValue: boolean) => void },
) =>
  responseAction?.payload?.response?.data?.code === 'same_alert_already_exists'
    ? of(
        notificationBannerActions.notifyError({
          message: I18n.t('alerts.banners.alreadyExists'),
        }),
      ).pipe(
        tap(() => {
          actions.setSubmitting(false);
        }),
      )
    : of(
        notificationBannerActions.notifyError({
          message: I18n.t('alerts.banners.genericError'),
        }),
      ).pipe(
        tap(() => {
          actions.setSubmitting(false);
        }),
      );

// TODO rework banner for code
export const createAlertEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(CREATE_ALERT)),
    switchMap(({ actions, navigate }) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      action$.pipe(
        filter(isOfType([CREATE_ALERT_SUCCESS, CREATE_ALERT_FAILURE])),
        take(1),
        switchMap((responseAction: { type: string; payload: any }) =>
          // eslint-disable-next-line no-nested-ternary
          responseAction.type === CREATE_ALERT_SUCCESS
            ? of(
                notificationBannerActions.notifySuccess({
                  message: I18n.t('alerts.banners.successSaved'),
                }),
              ).pipe(
                tap(() => {
                  navigate(paths.PRICE_ALERTS);
                }),
              )
            : getAlertNotificationsObservable(responseAction, actions),
        ),
      ),
    ),
  );

export const editAlertsEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(EDIT_ALERT)),
    pluck('payload'),
    mergeMap((alert: Alert) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(api.putPriceAlert(alert, accessToken)).pipe(
            map(editAlertSuccess),
            catchError(
              handleAjaxError(
                action$,
                editAlertFailure,
                {},
                {
                  message: I18n.t('alerts.banners.genericError'),
                },
              ),
            ),
          ),
        ),
      ),
    ),
  );

// TODO rework banner for code
export const editAlertEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(EDIT_ALERT)),
    switchMap(({ actions, navigate }) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      action$.pipe(
        filter(isOfType([EDIT_ALERT_SUCCESS, EDIT_ALERT_FAILURE])),
        take(1),
        switchMap((responseAction: { type: string; payload: any }) =>
          // eslint-disable-next-line no-nested-ternary
          responseAction.type === EDIT_ALERT_SUCCESS
            ? of(
                notificationBannerActions.notifySuccess({
                  message: I18n.t('alerts.banners.successSaved'),
                }),
              ).pipe(
                tap(() => {
                  navigate(paths.PRICE_ALERTS);
                }),
              )
            : getAlertNotificationsObservable(responseAction, actions),
        ),
      ),
    ),
  );

export const deleteAlertEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): void =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(DELETE_ALERT)),
    switchMap(({ navigate }) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      action$.pipe(
        filter(isOfType([DELETE_ALERT_SUCCESS, DELETE_ALERT_FAILURE])),
        take(1),
        switchMap((responseAction: { type: string }) =>
          responseAction.type === DELETE_ALERT_SUCCESS
            ? of(
                notificationBannerActions.notifySuccess({
                  message: I18n.t('alerts.banners.successDelete'),
                }),
              ).pipe(
                tap(() => {
                  navigate(`${paths.PRICE_ALERTS}`);
                }),
              )
            : of(
                notificationBannerActions.notifyError({
                  message: I18n.t('alerts.banners.genericError'),
                }),
              ),
        ),
      ),
    ),
  );

export const deleteAlertsEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(DELETE_ALERT)),
    pluck('payload'),
    mergeMap((id: string) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(api.deletePriceAlert(id, accessToken)).pipe(
            mapTo(deleteAlertSuccess(id)),
            catchError(
              handleAjaxError(action$, invalidateAlertsAction, id, {
                message: I18n.t('alerts.banners.deleteError'),
              }),
            ),
          ),
        ),
      ),
    ),
  );
