import React, { ReactElement, useContext, useMemo } from 'react';
import StopOrderConfirmModal from 'common/components/Modal/StopOrderModals/StopOrderConfirmModal/StopOrderConfirmModal';
import { StopOrderCreationContext } from 'scenes/StopOrderCreation/StopOrderCreationContext';
import { OrderBody } from 'common/components/TradingRulesCommonComponents/OrderBody/OrderBody';
import StopOrderForm from 'common/components/StopOrderForm/StopOrderForm';

const StopOrderCreationView = (): ReactElement => {
  const { isConfirm, isBuy, stopPrice, spendAmount, cryptoAmount, currencyPrecision } =
    useContext(StopOrderCreationContext);
  return (
    <>
      <OrderBody specificKey="stopPrice">
        <StopOrderForm isBuy={isBuy} />
      </OrderBody>
      <StopOrderConfirmModal
        isConfirm={isConfirm}
        stopPrice={stopPrice}
        cryptoAmount={cryptoAmount}
        euroAmount={spendAmount}
        isBuy={isBuy}
        currencyPrecision={currencyPrecision}
      />
    </>
  );
};

export default StopOrderCreationView;
