// @flow
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeGetlNotification, getNotificationMap } from 'store/selectors/notification';
import { clearNotification } from 'store/actions/notificationBanner';

import NotificationBanner from './view';
import { State } from 'store/types/store';

const mapStateToProps = (state: State, props: any) => {
  const getAllNotifications = makeGetlNotification();
  const notifications = getAllNotifications(state, props);
  return {
    notifications,
    lastNotification: notifications[0],
    notificationsMap: getNotificationMap(state),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  clearNotification: bindActionCreators(clearNotification, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBanner);
