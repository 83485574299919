import { useContext } from 'react';
import { ModalContext } from './context';

export const useModalProvider = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('`useModalProvider` must be used within a ModalProvider');
  }

  return context;
};
