/**
 * Create main store
 */
import { configureStore } from '@reduxjs/toolkit';
import createSentryMiddleware from 'redux-sentry-middleware';
import * as Sentry from '@sentry/react';
import { compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import { createEpicMiddleware } from 'redux-observable';
import * as api from 'common/api';
import { ajax } from 'rxjs/ajax';
import { StoreConfiguration } from 'types/common';
import rootReducer from './rootReducer';
import { actionTransformer, stateTransformer } from './stateTransformForSentry';
import { getNavigate } from 'common/utils/router';

const sentryOptions = { actionTransformer, stateTransformer };

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

type ResolveFunction = (a: unknown) => void;

const configuration = <StoreConfiguration>{};

let rehydrationComplete: ResolveFunction = (value) => {};

const rehydrationPromise = new Promise((resolve: ResolveFunction) => {
  rehydrationComplete = resolve;
});

export async function rehydration(): Promise<any> {
  return rehydrationPromise;
}

export default function setupStore(): StoreConfiguration {
  const sagaMiddleware = createSagaMiddleware();
  /* const composeEnhancers =
    (window?.env?.ENVIRONMENT !== 'production' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : undefined) ||
    compose; */
  const epicMiddleware = createEpicMiddleware({
    dependencies: {
      ajax,
      api,
      navigate: getNavigate,
    },
  });

  const sentryMiddleware = createSentryMiddleware(Sentry, sentryOptions);

  configuration.runSaga = (rootSaga) => sagaMiddleware.run(rootSaga);
  configuration.runEpic = (rootEpic) => epicMiddleware.run(rootEpic);

  configuration.store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sagaMiddleware, epicMiddleware, sentryMiddleware),
    devTools: window?.env?.ENVIRONMENT !== 'production',
  });

  configuration.persistor = persistStore(configuration.store, undefined, () =>
    rehydrationComplete(undefined),
  );
  configuration.rehydration = rehydration;
  return configuration;
}

export async function purgeStore(): Promise<any> {
  // // purge persisted data
  await configuration.persistor.purge();
  // re-persist store
  await new Promise((resolve) => persistStore(configuration.store, null, resolve));
}
