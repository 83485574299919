import { LocalSettingsState } from 'store/types/localSettings';
import * as ActionTypes from '../actions/localSettings';
import { AnyAction } from 'redux';
import * as DeviceMonitoringActionTypes from '../actions/deviceMonitoring';
import { LOGOUT_USER } from 'store/actions/auth';

const initialState: LocalSettingsState = {
  showPassword: false,
  usersWhoSawPhishingBanner: [],
  usersWhoSawPhasedRolloutModal: [],
  showPhasedRolloutModalAgain: false,
  afterLoginFlag: false,
  pageTitle: '',
  isSecondAttemptModalOpened: false,
  isStillInStocksKyc: false,
  wasAuditProofModalClosed: false,
  isSolarisDownErrorOccurred: false,
  isImportantMessagesModalWasDisplayed: false,
};

function root(state: LocalSettingsState = initialState, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.SET_SHOW_PASSWORD:
      return { ...state, showPassword: action.payload };
    case ActionTypes.ADD_USER_TO_USERS_WHO_SAW_PHISHING_BANNER:
      return {
        ...state,
        usersWhoSawPhishingBanner: [...state.usersWhoSawPhishingBanner, action.payload.userHash],
      };
    case ActionTypes.ADD_USER_TO_USERS_WHO_SAW_STAKING_ROLLOUT_MODAL:
      return {
        ...state,
        usersWhoSawPhasedRolloutModal: [...state.usersWhoSawPhasedRolloutModal, action.payload.userHash],
      };
    case ActionTypes.SHOW_PHASED_ROLLOUT_MODAL_AGAIN:
      return { ...state, showPhasedRolloutModalAgain: action.payload.showPhasedRolloutModalAgain };
    case ActionTypes.SET_AFTER_LOGIN_FLAG:
      return { ...state, afterLoginFlag: action.payload };
    case ActionTypes.SET_PAGE_TITLE:
      return { ...state, pageTitle: action.payload };
    case ActionTypes.CHANGE_IS_SECOND_ATTEMPT_MODAL_OPENED:
      return { ...state, isSecondAttemptModalOpened: action.payload.isSecondAttemptModalOpened };
    case ActionTypes.SET_IS_STILL_IN_SECURITIES_KYC:
      return { ...state, isStillInStocksKyc: action.payload.isStillInStocksKyc };
    case ActionTypes.SET_WAS_AUDIT_PROOF_MODAL_CLOSED:
      return { ...state, wasAuditProofModalClosed: true };
    case ActionTypes.SET_IS_SOLARIS_ERROR_OCCURRED:
      return { ...state, isSolarisDownErrorOccurred: action.payload.isSolarisDownErrorOccurred };
    case DeviceMonitoringActionTypes.SUBMIT_LOGIN_USER_WITH_DEVICE_DATA:
      return { ...state, isSolarisDownErrorOccurred: false };
    case LOGOUT_USER:
      return { ...state, wasAuditProofModalClosed: false };
    default:
      return state;
  }
}

export default root;
