import styled from 'styled-components';
import { dimensions } from 'common/styles';
import { colors } from 'common/utils/theme/colors';
import { Theme } from 'types/theme';

export const Container = styled.div`
  border-radius: ${dimensions.newCheckboxSide / 8}px;
  background-color: ${({ theme, checked }: { theme: Theme; checked: boolean }) =>
    checked ? theme.color.foreground.positive : theme.color.foreground.primaryInverted};
  width: ${dimensions.newCheckboxSide}px;
  min-width: ${dimensions.newCheckboxSide}px;
  height: ${dimensions.newCheckboxSide}px;
  margin: 0 ${dimensions.btnMargin}px ${dimensions.btnMargin}px 0;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  cursor: pointer;

  ${({ theme, checked, error }: { theme: Theme; checked: boolean; error: boolean }) =>
    !checked &&
    `border: ${theme.border.width.large} solid ${
      error ? theme.color.foreground.critical : theme.color.border.standard
    }`}
`;

export const Text = styled.div``;
