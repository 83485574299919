import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeAndroidBanner, showAndroidInstallPrompt } from 'store/actions/androidBanner';
import { getIsBannerShown } from 'store/selectors/androidBanner';

import View from './view';
import { ModalProps } from 'types/modals';

const StateContainer = (props: ModalProps) => {
  const dispatch = useDispatch();

  const close = useCallback(() => dispatch(closeAndroidBanner()), [dispatch]);
  const showInstallPrompt = useCallback(() => dispatch(showAndroidInstallPrompt()), [dispatch]);

  return <View close={close} showInstallPrompt={showInstallPrompt} onClose={props.onClose} />;
};

export default StateContainer;
