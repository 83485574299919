/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Observable, from, of, EMPTY } from 'rxjs';
import { catchError, filter, map, pluck, switchMap, take } from 'rxjs/operators';
import { isPresent } from 'safetypings';
import { getAccessToken } from 'store/selectors/auth';
import {
  confirmTwoFaAuditProofRequest,
  confirmTwoFaAuditProofSuccess,
  submitAuditProofError,
  submitAuditProofRequest,
  submitAuditProofSuccess,
} from 'store/actions/auditProof';
import { RootAction } from 'types/common';
import { combineEpics } from 'redux-observable';
import { getDeviceData } from 'common/utils/seonSdk';
import { AuditProofData } from 'types/auditProof';
import { errorCodes } from 'common/apiErrors';

const submitAuditProof = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(submitAuditProofRequest.match),
    pluck('payload'),
    switchMap(({ employmentStatus, professionalField, birthDate, onError, onSuccess }) =>
      from(getDeviceData()).pipe(
        switchMap(({ deviceData }) =>
          state$.pipe(
            map(getAccessToken),
            filter(isPresent),
            take(1),
            switchMap(
              (accessToken) =>
                ajax({
                  method: 'POST',
                  url: api.getBaseUrlForUserServices(accessToken).concat(`/user/kyc/aml`),
                  withCredentials: true,
                  headers: {
                    ...api.getCommonHeaders(),
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                  },
                  body: {
                    amlConfirmed: true,
                    employmentStatus,
                    professionalField,
                    birthDate,
                    deviceData,
                  },
                }).pipe(
                  pluck('response'),
                  map((response: AuditProofData) => {
                    if (onSuccess) onSuccess(response);
                    return submitAuditProofSuccess(response);
                  }),
                ) as Observable<RootAction>,
            ),
            catchError((error) => {
              if (onError) onError(error);
              return of(submitAuditProofError({ error }));
            }),
          ),
        ),
      ),
    ),
  );

const confirmTwoFaAuditProof = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(confirmTwoFaAuditProofRequest.match),
    pluck('payload'),
    switchMap(({ data, code2fa, onError, onSuccess, onWrongTwoFa }) =>
      from(getDeviceData()).pipe(
        switchMap(({ deviceData }) =>
          state$.pipe(
            map(getAccessToken),
            filter(isPresent),
            take(1),
            switchMap(
              (accessToken) =>
                ajax({
                  method: 'PATCH',
                  url: api.getBaseUrlForUserServices(accessToken).concat(`/user/kyc/aml`),
                  withCredentials: true,
                  headers: {
                    ...api.getCommonHeaders(),
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                  },
                  body: {
                    data,
                    code2fa,
                    deviceData,
                  },
                }).pipe(
                  pluck('response'),
                  map((response) => {
                    if (onSuccess) onSuccess();

                    return confirmTwoFaAuditProofSuccess();
                  }),
                ) as Observable<RootAction>,
            ),
          ),
        ),
        catchError((error) => {
          if (error?.response?.code === errorCodes.CHANGEPROCESS_ERR_WRONG2FACODE) {
            onWrongTwoFa();
            return EMPTY;
          } else {
            if (onError) onError(error);
          }

          return of(submitAuditProofError({ error }));
        }),
      ),
    ),
  );

export default combineEpics(submitAuditProof, confirmTwoFaAuditProof);
