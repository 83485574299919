import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'common/components/Button';
import ModalTemplate from 'common/components/Modal';
import { kycRenewStatuses } from 'common/const';
import { paths } from 'common/urls';
import FailedRenewKycModal from 'scenes/Kyc/RenewKycDocs/Steps/FailedRenewKycModal';
import PendingRenewKycModal from 'scenes/Kyc/RenewKycDocs/Steps/PendingRenewKycModal';
import { Actions, Img, Title, Text } from 'scenes/Kyc/SecuritiesKyc/IntroModal/styles';
import { fetchUserKycStatusRenewInfoRequest } from 'store/actions/renewKycDocs';
import { getIsKycRenewRequired, getKycRenewStatus } from 'store/selectors/auth';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccessCallback?: () => void;
};

const IntroModal: FC<Props> = ({ open, setOpen, onSuccessCallback }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isKycRenewRequired = useSelector(getIsKycRenewRequired);
  const kycRenewStatus = useSelector(getKycRenewStatus);

  useEffect(() => {
    dispatch(fetchUserKycStatusRenewInfoRequest());
  }, []);

  if (
    kycRenewStatus === kycRenewStatuses.IdentificationCreated ||
    kycRenewStatus === kycRenewStatuses.Pending
  ) {
    // In case the kycRenewStatus is not finished yet, display pending modal of kyc renew proccess
    return <PendingRenewKycModal open={open} setOpen={setOpen} />;
  }
  if (kycRenewStatus === kycRenewStatuses.Invalid) {
    // In case the kycRenewStatus is not finished yet, display pending modal of kyc renew proccess
    return <FailedRenewKycModal open={open} setOpen={setOpen} />;
  }

  return (
    <ModalTemplate
      defaultOpen={open}
      header={<Img />}
      onCancel={() => setOpen(false)}
      actions={
        <Actions>
          <Button
            data-test-id="kyc.securities.intro.cancel"
            isFullWidth
            inverted
            onClick={() => setOpen(false)}
            title={t('kyc.securities.intro.cancel')}
          />
          <Button
            isFullWidth
            onClick={() => {
              if (onSuccessCallback) onSuccessCallback();
              navigate(isKycRenewRequired ? paths.RENEW_KYC_DOCS : paths.STOCKS_KYC);
            }}
            title={t('kyc.securities.intro.ok')}
            data-test-id="kyc.securities.intro.ok"
          />
        </Actions>
      }
    >
      <Title>{t('kyc.securities.intro.title')}</Title>
      <Text>{t('kyc.securities.intro.mainText')}</Text>
    </ModalTemplate>
  );
};

export default IntroModal;
