// @flow
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

import Button from 'common/components/Button';
import { ModalWrapper, ModalContent } from 'common/components/Modal/Modal';
import { Icon } from './styles';
import ModalTemplate from '../Modal';
import { useTracking } from 'common/tracking/hooks';

type Props = {
  header?: JSX.Element | string;
  markdown: string;
  children?: JSX.Element;
  iconName?: string;
  iconSizeValue?: string;
  color?: string;
  testIdIcon?: string;
  testIdButton?: string;
  testId?: string;
  modalId?: string;
  hideCloseButton?: boolean;
  closeButtonText?: string;
  onClose?: () => void;
  onOpen?: () => void;
};

const InfoButton = ({
  header,
  markdown,
  iconName,
  iconSizeValue,
  color,
  testIdIcon,
  testIdButton,
  children,
  testId,
  modalId,
  hideCloseButton,
  closeButtonText,
  onClose,
  onOpen,
}: Props) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(false);
  const trackAppEvent = useTracking();
  return (
    <ModalTemplate
      modalId={modalId ?? 'common.InfoBox'}
      header={header && <h2>{header}</h2>}
      trigger={
        <Icon
          name={iconName || 'custom-icon-info_inverted'}
          sizeValue={iconSizeValue || 'tiny'}
          onClick={() => {
            if (onOpen) onOpen();

            if (testId) {
              trackAppEvent('buttonComponentPress', {
                buttonText: '',
                componentId: testId,
                componentType: 'InfoButton',
              });
            }
            setModalOpen(true);
          }}
          customColor={color}
          data-test-id={testIdIcon}
        />
      }
      defaultOpen={modalOpen}
      onCancel={() => setModalOpen(false)}
      actions={
        hideCloseButton ? (
          <></>
        ) : (
          <Button
            size="modal"
            data-test-id={testIdButton}
            title={closeButtonText ?? t('common.close')}
            onClick={() => {
              setModalOpen(false);
              if (onClose) onClose();
            }}
          />
        )
      }
    >
      {markdown ? <ReactMarkdown linkTarget="_blank">{markdown}</ReactMarkdown> : children}
    </ModalTemplate>
  );
};

InfoButton.defaultProps = {
  hideCloseButton: false,
};

export default InfoButton;
