import { paths } from 'common/urls';
import { call, select } from 'redux-saga/effects';
import getCurrentPath from 'store/selectors/navigation';
import { trackAppEvent } from 'store/actions/tracking';
import {
  trackSavingsPlanDetailPressButtonobjectId,
  trackSavingsPlanPressButtonNewSavingsPlan,
  trackSavingsPlanScrollListSavingsPlan,
} from '../biEventDefinitions';

export function* logSavingPlansEvents(action: ReturnType<typeof trackAppEvent>): Generator<any, any, any> {
  const lastScreenId: string = yield select(getCurrentPath);
  const savingPlansViewScreenId = paths.SAVINGS_PLAN;
  const savingPlansDeleteScreenId = paths.SAVINGS_PLAN_DELETE;
  switch (action.eventName) {
    case 'savingPlansPressNewSavingPlan':
      if (lastScreenId.includes(savingPlansViewScreenId)) {
        yield call(
          trackSavingsPlanPressButtonNewSavingsPlan,
          savingPlansViewScreenId,
          action.payload.totalCount,
          action.payload.loadedCount,
        );
      }
      break;

    case 'savingPlansScrollSavingPlansList':
      yield call(
        trackSavingsPlanScrollListSavingsPlan,
        savingPlansViewScreenId,
        action.payload.totalCount,
        action.payload.loadedCount,
      );
      break;

    case 'savingPlansDetailPressDelete':
      yield call(
        trackSavingsPlanDetailPressButtonobjectId,
        savingPlansDeleteScreenId,
        action.payload.savingsPlanId,
      );
      break;

    default:
      break;
  }
}
