import React, { useState, ReactNode, Fragment, useEffect, cloneElement, isValidElement } from 'react';
import { ModalProps } from './types';

const ModalManager = ({ queue, removeModal }: { queue: ModalProps[]; removeModal: () => void }) => {
  const [currentModal, setCurrentModal] = useState<ReactNode>(null);

  useEffect(() => {
    if (queue.length === 0) {
      setCurrentModal(null);
    } else {
      const modal = queue[queue.length - 1];

      if (!modal) return;

      const isValid = isValidElement(modal.component);

      if (!isValid) return;

      const element = cloneElement(modal.component, {
        onClose: (payload: any) => {
          // Remove from queue
          removeModal();

          // Call callback if passed in.
          const onCloseCallback = modal.onCloseCallback;
          if (onCloseCallback) onCloseCallback(payload);

          // Remove from dom. So the next one can be set.
          setCurrentModal(null);
        },
      });

      setCurrentModal(element);
    }
  }, [queue]);

  return <Fragment>{currentModal}</Fragment>;
};

export default ModalManager;
