import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { accountTypes } from 'common/const';
import * as authActions from 'store/actions/auth';
import { getUser, getIsKycDone } from 'store/selectors/auth';
import { User } from 'types/state';
import { ThemeName } from 'types/theme';
import { State } from 'store/types/store';
import { AccountType } from 'types/auth';
import QuickSettingsView from './view';

type Props = {
  user: User;
  themeName: ThemeName;
  changeAccountType: (AccountType: AccountType, eventLabel: string) => void;
  changingAccount: boolean;
};

const QuickSettings = (props: Props) => {
  const {
    changeAccountType,
    themeName,
    user: { accountType },
  } = props;
  const handleToggleRealMoneyTrading = (eventLabel: string) => {
    const newAccountType = accountType === accountTypes.real ? accountTypes.paper : accountTypes.real;
    changeAccountType(newAccountType, eventLabel);
  };

  return <QuickSettingsView {...props} handleToggleRealMoneyTrading={handleToggleRealMoneyTrading} />;
};

const mapStateToProps = (state: State) => ({
  user: getUser(state),
  isKycDone: getIsKycDone(state),
  changingAccount: state.auth.changingAccount,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  changeAccountType: (newAccountType: AccountType, eventLabel: string) =>
    dispatch(authActions.changeAccount.request(newAccountType, eventLabel)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickSettings);
