import { call, fork, all, take } from 'redux-saga/effects';
import { Saga } from 'redux-saga';
import * as api from 'common/api';
import * as actions from 'store/actions/referral';
import { authFetchEntity } from 'store/sagas/auth';

export const fetchReferral = authFetchEntity.bind(null, actions.fetchReferral, api.referral, false);

function* watchInvalidateReferral(): Saga<void> {
  while (true) {
    yield take(actions.INVALIDATE_REFERRAL);
    yield call(fetchReferral, {});
  }
}

export default function* root(): Saga<void> {
  yield all([fork(watchInvalidateReferral)]);
}
