import styled from 'styled-components';
import bisonSuccessImg from 'assets/img/new_success_img.svg';

export const SuccessImg = styled.img.attrs({
  src: bisonSuccessImg,
})`
  display: block;
  text-align: center;
  width: 256px;
  height: auto;
  margin: 0 auto;
`;
