import styled from 'styled-components';
import { LayoutRegularWrapper } from '../LayoutContainer/LayoutRegularWrapper/LayoutRegularWrapper';
import { devices } from 'common/styles';

export const BannerLayoutWrapper = styled(LayoutRegularWrapper)`
  padding-top: 12px;
  background: transparent;
  @media ${devices.tablet} {
    padding-top: 24px;
  }
`;
