/* eslint-disable */
import * as React from 'react';
import styled from 'styled-components';
import { Sidebar } from '../../../common/lib/semantic-ui';
import { devices, dimensions } from 'common/styles';

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
`;

export const AppHeader = styled.div`
  position: sticky;
  width: 100%;
  top: 0px;
  z-index: 1000;
  background-color: ${({ theme }) => theme.color.background.secondary};
  ${({ withBoxShadow }) => withBoxShadow && 'box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);'}
`;

export const BackgroundDiv = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.background.primary};
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  @media${devices.tablet} {
    padding-bottom: 10vh;
  }
`;

export const Pushable = styled(Sidebar.Pushable)`
  width: 100%;
  background-color: ${({ theme }) => theme.color.background.primary};

  #pushContent {
    > div {
      min-height: ${window.innerHeight}px;
    }
  }

  #pushContent .dimmed {
  }

  .ui.wide.right.sidebar {
    max-width: 300px;
  }
`;

export const DefaultWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  justify-content: center;
`;

export const SideContent = styled.div`
  width: 40%;
  min-width: 300px;
  margin-right: ${dimensions.mediumPadding}px;
`;
export const MainContent = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.color.background.primary};
`;

export const PageHeader = styled.span.attrs({ className: 'screen-title' })`
  display: block;
  margin-top: ${dimensions.largePadding - dimensions.mediumPadding}px;
  margin-bottom: ${dimensions.largePadding}px;

  @media ${devices.maxTablet} {
    margin: ${dimensions.mediumPadding}px 0;
  }
`;

export const PageHeadline = styled.div`
  font-size: 30px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0em;
  padding-top: 40px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  @media ${devices.maxTablet} {
    display: none;
  }

  & > button {
    width: auto;
    margin-right: 16px;
  }
`;
