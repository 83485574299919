import { action } from './_common';

export const FETCH_ALERTS_REQUEST = 'FETCH_ALERTS_REQUEST';
export const fetchAlertsRequest = () => action(FETCH_ALERTS_REQUEST);

export const INVALIDATE_ALERTS_REQUEST = 'INVALIDATE_ALERTS_REQUEST';
export const invalidateAlertsRequest = () => action(INVALIDATE_ALERTS_REQUEST);

export const FETCH_ALERTS_SUCCESS = 'FETCH_ALERTS_SUCCESS';
export const fetchAlertsSuccess = (payload) => action(FETCH_ALERTS_SUCCESS, { payload });

export const FETCH_ALERTS_FAILURE = 'FETCH_ALERTS_FAILURE';
export const fetchAlertsFailure = (error) => action(FETCH_ALERTS_FAILURE, { error });

export const PUT_ALERT = 'PUT_ALERT';
export const putAlertRequest = (payload) => action(PUT_ALERT, { payload });

export const PUT_ALERT_SUCCESS = 'PUT_ALERT_SUCCESS';
export const putAlertSuccess = (payload) => action(PUT_ALERT_SUCCESS, { payload });

export const CREATE_ALERT = 'CREATE_ALERT';
export const createAlertRequest = (payload, actions, navigate) =>
  action(CREATE_ALERT, { payload, actions, navigate });

export const CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS';
export const createAlertSuccess = (payload) => action(CREATE_ALERT_SUCCESS, { payload });

export const CREATE_ALERT_FAILURE = 'CREATE_ALERT_FAILURE';
export const createAlertFailure = (payload) => action(CREATE_ALERT_FAILURE, { payload });

export const EDIT_ALERT = 'EDIT_ALERT';
export const editAlertRequest = (payload, actions, navigate) =>
  action(EDIT_ALERT, { payload, actions, navigate });

export const EDIT_ALERT_SUCCESS = 'EDIT_ALERT_SUCCESS';
export const editAlertSuccess = (payload) => action(EDIT_ALERT_SUCCESS, { payload });

export const EDIT_ALERT_FAILURE = 'EDIT_ALERT_FAILURE';
export const editAlertFailure = (payload) => action(EDIT_ALERT_FAILURE, { payload });

export const SELECT_EDITED_ALERT = 'SELECT_EDITED_ALERT';
export const selectEditedAlert = (payload) => action(SELECT_EDITED_ALERT, { payload });

export const DELETE_ALERT = 'DELETE_ALERT';
export const deleteAlertRequest = (payload, navigate) => action(DELETE_ALERT, { payload, navigate });

export const DELETE_ALERT_SUCCESS = 'DELETE_ALERT_SUCCESS';
export const deleteAlertSuccess = (payload) => action(DELETE_ALERT_SUCCESS, { payload });

export const DELETE_ALERT_FAILURE = 'DELETE_ALERT_FAILURE';
export const deleteAlertFailure = (payload) => action(DELETE_ALERT_FAILURE, { payload });
