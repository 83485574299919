import { TechIndicatorItem, TimePeriod } from 'types/currency';

const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

function createRequestTypes(base: string) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
}

function createControlTypes(base) {
  return ['START', 'STOP'].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
}

function createControlActions(base) {
  return ['START', 'STOP'].reduce((acc, type) => {
    acc[type] = { type: base[type] };
    return acc;
  }, {});
}

function action(type, payload = {}) {
  return { type, ...payload };
}

export const TECH_INDICATOR_DATA = createRequestTypes('TECH_INDICATOR_DATA');
export const TRADE_VOLUME_DATA = createRequestTypes('TRADE_VOLUME_DATA');

export const SYNC_TECH_INDICATOR_DATA = createControlTypes('SYNC_TECH_INDICATOR_DATA');
export const syncTechIndicatorData = createControlActions(SYNC_TECH_INDICATOR_DATA);

export const SELECT_TIME_PERIOD = 'SELECT_TIME_PERIOD';
export const UPDATE_CRYPTO_SELECTION = 'UPDATE_CRYPTO_SELECTION';
export const SELECT_ENTITY = 'SELECT_ENTITY';
export const INVALIDATE_TRADE_VOLUME_DATA = 'INVALIDATE_TRADE_VOLUME_DATA';

export const techIndicatorData = {
  request: ({
    pair,
    timePeriod,
    indicator,
  }: {
    pair: string;
    timePeriod: TimePeriod;
    indicator: TechIndicatorItem;
  }) => action(TECH_INDICATOR_DATA[REQUEST], { pair, timePeriod, indicator }),
  success: (
    {
      pair,
      timePeriod,
      indicator,
    }: {
      pair: string;
      timePeriod: TimePeriod;
      indicator: TechIndicatorItem;
    },
    response,
  ) =>
    action(TECH_INDICATOR_DATA[SUCCESS], {
      pair,
      timePeriod,
      indicator,
      response,
    }),
  failure: (
    {
      pair,
      timePeriod,
      indicator,
    }: {
      pair: string;
      timePeriod: TimePeriod;
      indicator: TechIndicatorItem;
    },
    error,
  ) =>
    action(TECH_INDICATOR_DATA[FAILURE], {
      pair,
      timePeriod,
      indicator,
      error,
    }),
};

export const tradeVolumeData = {
  request: (crypto: string) => action(TRADE_VOLUME_DATA[REQUEST], { crypto }),
  success: (crypto: string, response: any) => action(TRADE_VOLUME_DATA[SUCCESS], { crypto, response }),
  failure: (crypto: string, error: any) => action(TRADE_VOLUME_DATA[FAILURE], { crypto, error }),
};

export const selectEntity = (entity: string) => action(SELECT_ENTITY, { entity });

export const selectTimePeriod = (timePeriod: TimePeriod) => action(SELECT_TIME_PERIOD, { timePeriod });

export const updateCryptoSelection = (crypto: string) => action(UPDATE_CRYPTO_SELECTION, { crypto });

export const invalidateTradeVolumeData = (crypto: string) => action(INVALIDATE_TRADE_VOLUME_DATA, { crypto });
