import styled from 'styled-components';
import { devices, dimensions } from 'common/styles';
import Button from 'common/components/Button';
import { Theme } from 'types/theme';

export const welcomeContainerCustomStyles = `
  @media${devices.tablet} {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.primary};
  min-height: 100vh;

  @media ${devices.maxTablet} {
    width: 100%;
  }
`;

export const Label = styled.span.attrs({
  className: 'large-text',
})`
  display: inline-block;
  text-align: center;
  width: 100%;
`;

export const Header = styled(Label).attrs({
  className: 'screen-title',
})`
  margin-bottom: ${dimensions.largePadding}px;
  position: relative;
`;

export const HeaderWrapper = styled.div.attrs({ className: 'screen-title' })`
  width: inherit;
  margin-bottom: ${dimensions.largePadding}px;
`;

export const ButtonWrapper = styled.div`
  margin-top: auto;
  margin-bottom: -15px;
`;

export const Form = styled.form`
  flex-grow: 1;
  width: 100%;
  @media ${devices.maxTablet} {
    & > div:first-child {
      padding: 10px;
    }
  }
`;

export const Link = styled.a`
  text-decoration: underline;
`;

export const InfoContainer = styled.div.attrs({
  className: 'secondary-text',
})``;

export const InfoText = styled.div.attrs({
  className: 'secondary-color-text small-text',
})`
  text-align: left;

  & > a {
    color: ${({ theme }: { theme: Theme }) => theme.color.foreground.secondary};
  }
`;

export const LoginWrapper = styled.div`
  width: inherit;
  padding-bottom: 12px;
`;

export const PaddingWrapper = styled.div`
  padding-bottom: 12px;
  @media${devices.tablet} {
    padding-bottom: 32px;
  }
`;

export const LargePaddingWrapper = styled.div`
  padding-bottom: 30px;
  @media${devices.tablet} {
    padding-bottom: 50px;
  }
`;

export const InputGroupWrapper = styled.div``;

export const PasswordWrapper = styled.div``;

export const InputLabel = styled.label`
  display: block;
  padding-bottom: 12px;
  fontsize: ${({ theme }) => `${theme.typography.text.small}`};
  @media${devices.tablet} {
    padding-bottom: 16px;
    fontsize: ${({ theme }) => `${theme.typography.text.standard}`};
  }
`;

export const WideButton = styled(Button)`
  width: inherit;
`;

export const Text = styled.span.attrs({ className: 'small-text' })`
  margin-right: ${dimensions.mediumPadding}px;
`;

export const BetaBanner = styled.div`
  min-height: 50px;
  background-color: #fff3d7;
  color: #7a4a06;
  text-align: center;
  position: sticky;
  margin: -3vw;
  top: 0;
  z-index: 9;
  padding: 0 ${dimensions.largePadding}px;
  font-size: 1.3rem;

  @media ${devices.tablet} {
    width: calc(100% + 2 * ${dimensions.mediumPadding}px);
  }

  @media ${devices.laptop} {
    margin: 0;
    width: calc(100% + 2 * ${dimensions.largePadding}px);
  }
`;

export const ChangePageWrapper = styled.div.attrs({ className: 'background-secondary' })`
  max-width: 542px;
  width: calc(100% - 20px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  padding: 30px;
  margin-bottom: 40px;
  > div:nth-child(2) {
    font-weight: 700;
    margin: 0;
  }
  @media${devices.maxTablet} {
    & span {
      margin: 0;
    }
  }

  @media${devices.tablet} {
    flex-direction: column;
    display: none;
  }
  @media${devices.maxMobileL} {
    padding: 10px;
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: ${({ loading }: { loading: boolean }) => (loading ? 2 : 0)};
`;
