import { fileSizeFormatters } from 'common/utils/formatting';
import React, { ReactElement } from 'react';
import { Attachment, AttachmentName, ModalIcon } from './styles';

interface Props {
  fileName: string;
  size?: number;
  onItemClick?: () => void;
  iconName?: string;
}

const InboxAttachment = ({ fileName, size, onItemClick, iconName, ...rest }: Props): ReactElement => {
  return (
    <Attachment onClick={() => onItemClick && onItemClick()} {...rest}>
      <ModalIcon customColor="red" name={iconName} sizeValue="tiniest" />
      <AttachmentName title={fileName}>{fileName}</AttachmentName>
      {size && <span>&nbsp;{`(${fileSizeFormatters.format(size)})`}</span>}
    </Attachment>
  );
};

InboxAttachment.defaultProps = {
  size: null,
  onItemClick: () => {},
  iconName: 'icon-document-icon',
};

export default InboxAttachment;
