import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ModalTemplate from 'common/components/Modal/index';
import {
  ErrorImg,
  Content,
  ContentWrapper,
  Text,
  ErrorHeaderText,
} from 'common/components/TradingRulesCommonComponents/TradingRulesCreationStyles/errorStyles';

type Props = {
  infoText: string | ReactElement;
  headerText?: string | undefined;
  isOpen: boolean;
  actions: JSX.Element;
};

export const StopOrderFailModal = ({ infoText, headerText, isOpen, actions }: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <ModalTemplate defaultOpen={isOpen} actions={actions} persist>
      <Content>
        <ContentWrapper>
          <ErrorImg />

          {headerText && <ErrorHeaderText>{headerText}</ErrorHeaderText>}

          <Text className="primary-color-text">{infoText}</Text>
        </ContentWrapper>
      </Content>
    </ModalTemplate>
  );
};

StopOrderFailModal.defaultProps = {
  headerText: undefined,
};
