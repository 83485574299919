import styled from 'styled-components';
import Button from 'common/components/Button';
import { dimensions } from 'common/styles';
import bisonIcon from 'assets/img/bison_launcher.png';
import { Theme } from 'types/theme';

export const Banner = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  background: ${({ theme }: { theme: Theme }) => theme.color.background.info};
  z-index: 9998;
  overflow: hidden;
  -webkit-text-size-adjust: none;
`;

export const BannerContainer = styled.div`
  height: 100%;
  padding: 0 ${dimensions.mediumPadding}px 0 ${dimensions.mediumPadding}px;
  display: flex;
  align-items: center;
`;

export const BannerClose = styled.i.attrs({
  className: 'fas fa-times',
})`
  display: inline-block;
  position: relative;
  padding: ${dimensions.mediumPadding}px;
  margin: -${dimensions.mediumPadding}px;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryLight};
  cursor: pointer;
`;

export const BisonIcon = styled.img.attrs({
  src: bisonIcon,
})`
  margin: 0 ${dimensions.smallPadding}px;
  width: 57px;
  height: auto;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 100px;
  margin-right: ${dimensions.smallPadding}px;
`;

export const Heading1 = styled.div``;
export const Heading2 = styled.div.attrs({
  className: 'large-text',
})``;

export const BannerButton = styled(Button)`
  font-size: 2.2rem;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
  background-color: transparent;
  border: none;
  min-width: 0;
  padding: ${dimensions.mediumPadding}px;
  margin: -${dimensions.mediumPadding}px;
`;
