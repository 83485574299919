import styled from 'styled-components';
import textStyles from 'common/styles/text';
import { colors } from 'common/utils/theme/colors';
import { Theme } from 'types/theme';

export const SummaryTextDiv = styled.div`
  padding: 16px;
  width: 100%;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.emph};
  border-radius: 4px;
  color: ${colors.pureBlack};
`;

export const SummaryText = styled.span`
  ${textStyles.blueBoxNormal}
`;
