import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { paths } from 'common/urls';
import { generatePath } from 'react-router-dom';
import { Crypto } from 'types/currency';
import { NavItem, NavItems } from '../styles';

type Props = {
  location: any;
  selectedCrypto: string;
};

const MainTabs: FC<Props> = ({ selectedCrypto }: Props) => {
  const { t } = useTranslation();

  return (
    <NavItems>
      <NavItem to={generatePath(paths.PORTFOLIO_CRYPTO, { currency: selectedCrypto.toString() })} end>
        {t('mainScreenTitles.portfolio')}
      </NavItem>
      <NavItem to={paths.MARKET_OVERVIEW}>{t('mainScreenTitles.market')}</NavItem>
      <NavItem to={paths.TRADE_MANAGER}>{t('mainScreenTitles.tradeManager')}</NavItem>
      <NavItem to={paths.TRANSACTION_HISTORY}>{t('mainScreenTitles.transactionHistory')}</NavItem>
    </NavItems>
  );
};

export default MainTabs;
