import React from 'react';
import { StyledSvg } from 'common/components/Icon/AssetIcon/styles';
import defaultAssetIcon from 'assets/img/default_asset_icon.svg';
import { isDarkIconCodeInSet, addFailedDarkIcon } from 'common/utils/failedDarkIconsSessionStorage';
import { DimensionsProps } from 'common/components/Icon/AssetIcon/types';
import { DARK_THEME_SUFFIX } from 'common/components/Icon/AssetIcon/const';

export const SvgBase = ({
  src,
  fallback,
  width,
  height,
}: { src: string; fallback?: React.ElementType } & DimensionsProps) => {
  return (
    <StyledSvg
      width={width}
      height={height}
      $width={width}
      $height={height}
      src={src ?? defaultAssetIcon}
      fallback={
        fallback ||
        (() => <StyledSvg wrapper={'span'} src={defaultAssetIcon} width={width} height={height} />)
      }
      loading={() => <StyledSvg wrapper={'span'} src={defaultAssetIcon} width={width} height={height} />}
      wrapper={'span'}
    />
  );
};

export const SvgBaseWithTheme = ({
  src,
  code,
  width,
  height,
}: {
  src: string;
  code: string;
} & DimensionsProps) => {
  const isDarkThemeIcon = src.includes(DARK_THEME_SUFFIX);
  const lightThemeFallbackSrc = isDarkThemeIcon ? src.replace(DARK_THEME_SUFFIX, '') : src;

  if (isDarkThemeIcon && !isDarkIconCodeInSet(code)) {
    return (
      <SvgBase
        src={src}
        fallback={() => {
          addFailedDarkIcon(code);
          return (
            <SvgBase
              src={lightThemeFallbackSrc}
              fallback={() => (
                <StyledSvg wrapper={'span'} src={defaultAssetIcon} width={width} height={height} />
              )}
            />
          );
        }}
      />
    );
  }

  return (
    <SvgBase
      src={lightThemeFallbackSrc}
      fallback={() => <StyledSvg wrapper={'span'} src={defaultAssetIcon} width={width} height={height} />}
    />
  );
};
