import { ValuesType } from 'utility-types';

export type TwoFA = {
  createdAt: string;
  channelType: 'sms' | 'email';
  channel: string;
  expiresAt: string;
  submitToken: string;
  triesLeft: number;
};

export type KycStatus = 'NotStarted' | 'Submitted' | 'BankIdCreated' | 'Pending' | 'Confirmed' | 'Invalid';

export type AccountType = 'Paper' | 'Real' | null;

export type UserRole = 'User' | 'Confirmed' | 'KycDone';

export type User = {
  id?: string;
  externalId?: string;
  username?: string;
  isUsernameConfirmed: boolean;
  isFirstLogin: boolean;
  needsToRecheckCheckboxes: boolean;
  accountType: AccountType;
  kycStatus: KycStatus; // a default value is set in the reducer
} & UserProfile;

export type Tokens = {
  accessToken: string | null | undefined;
  clientKey: string | null | undefined;
  isRefreshing: boolean;
  clientToken?: string;
};

export type UserProfile = Partial<{
  username: string;
  name: string;
  firstName: string;
  lastName: string;
  address: Partial<{
    line1: string;
    line2: string;
    postalCode: string;
    city: string;
    country: string;
    state: string;
  }>;
  mobileNumber: string;
  roles: UserRole[];
  kycStatus: KycStatus;
  stocksUserAccountStatus: KycStatus;
  language: string;
  newsletter: boolean;
  taxId: string | null;
  taxIdCountry: string;
  createdAt: string;
  stocksKnowledgeLevel?: StocksKnowledgeLevel;
  stocksQuestionsLastAnsweredDate?: string;
}>;

export const STOCK_KNOWLEDGE_LEVELS = {
  UNKNOWN: 'Unknown',
  BASIC: 'Basic',
  INFORMED: 'Informed',
  ADVANCED: 'Advanced',
};

export type StocksKnowledgeLevel = ValuesType<typeof STOCK_KNOWLEDGE_LEVELS>;
