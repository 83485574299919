import React, { useEffect, useState } from 'react';
import IntroModal from 'common/components/Modal/AddPhoneNumberFlow/IntroModal';
import PhoneEditModal from 'scenes/Kyc/AuditProof/components/PhoneEditModal';
import { getKycStatus, getMobileNumber } from 'store/selectors/auth';
import { useDispatch, useSelector } from 'react-redux';
import { kycStatuses } from 'common/const';
import { getIsEIdKycMethod } from 'store/selectors/forms';
import { getHasClosedEidPopup } from 'store/selectors/settings';
import { userClosedEidPhonePopup } from 'store/actions/settings';
import { ModalProps } from 'types/modals';

const AddPhoneNumberFlow = ({ onClose }: ModalProps) => {
  const dispatch = useDispatch();
  /*   const cryptoKycStatus = useSelector(getKycStatus); */
  const mobilePhone = useSelector(getMobileNumber);
  /*   const isEIdKycMethod = useSelector(getIsEIdKycMethod);
  const hasClosedEidPopup = useSelector(getHasClosedEidPopup); */

  const [step, setStep] = useState<'intro' | 'phoneEdit' | null>('intro');
  const [oldMobileValue, setOldMobileValue] = useState<string | null>(null);

  /* useEffect(() => {
    if (cryptoKycStatus !== kycStatuses.Confirmed || mobilePhone || !isEIdKycMethod || hasClosedEidPopup)
      return;

    setStep('intro');
  }, [cryptoKycStatus, mobilePhone, isEIdKycMethod, hasClosedEidPopup]); */

  useEffect(() => {
    if (step === 'phoneEdit' && !oldMobileValue && mobilePhone) {
      setStep(null);
      return;
    }

    if (step === 'intro') {
      setOldMobileValue(mobilePhone);
    }
  }, [step, oldMobileValue]);

  const handleCancel = () => {
    if (mobilePhone) {
      setStep('intro');
      return;
    }
    setStep('phoneEdit');
  };

  const onConfirmAssignPhone = () => {
    dispatch(userClosedEidPhonePopup());
    setStep('intro');
    onClose();
  };

  return (
    <>
      <IntroModal open={step === 'intro'} onConfirm={() => setStep('phoneEdit')} />
      <PhoneEditModal
        open={step === 'phoneEdit'}
        onCancel={handleCancel}
        confirmAssignCallback={onConfirmAssignPhone}
      />
    </>
  );
};

export default AddPhoneNumberFlow;
