/* eslint-disable @typescript-eslint/no-unsafe-return */
import { all, call, delay, fork, put, race, select, take, takeEvery } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import * as api from 'common/api';
import { authFetchEntity } from 'store/sagas/auth';

import * as actions from 'store/actions/currency';
import * as authActions from 'store/actions/auth';
import { timePeriodToTimeIntervalMap } from 'common/const';
import {
  getSelectedEntity,
  getSelectedTimePeriod,
  getTechIndicatorDataForPairAndPeriod,
} from 'store/selectors/currency';
import { techIndicators, tiRSI, tiSMA } from 'common/const/techIndicators';
import { getSelectedTechIndicators } from '../selectors/settings';
import { SET_SELECTED_TECH_INDICATORS } from '../actions/settings';
import { TechIndicatorItem, TechIndicatorOption, TimePeriod } from 'types/currency';
import { getCryptoPairs } from 'store/slices/assets/selectors';
import { loadInitialActivityItems } from 'store/slices/activity/actions';
import { fetchAssetsSuccess, fetchAvailableCryptos } from 'store/slices/assets/actions';
import { invalidatePortfolio } from 'store/slices/portfolio/actions';

// ***************************** Subroutines ***********************************

// bind fetch functions
export const fetchTechIndicatorData = authFetchEntity.bind(
  null,
  actions.techIndicatorData,
  api.fetchTechIndicatorData,
  false,
);

export const fetchTradeVolumeData = authFetchEntity.bind(
  null,
  actions.tradeVolumeData,
  api.fetchTradeVolumeData,
  false,
);

function* loadActivity(): Saga<void> {
  yield put(loadInitialActivityItems());
}

// TECH INDICATORS **********************************************************************

function* bgSyncTechIndicatorData(
  pair: string,
  timePeriod: TimePeriod,
  indicator: TechIndicatorItem,
): Saga<void> {
  let secondaryFetch = false;

  while (true) {
    const data = yield select(getTechIndicatorDataForPairAndPeriod, pair, timePeriod, indicator);
    if (data?.items && data?.items.length > 0) {
      const timeSinceLastRequest = new Date().getTime() - (data?.lastUpdated ?? 0);
      const delayTime = Math.max(1000 * timePeriodToTimeIntervalMap[timePeriod] - timeSinceLastRequest, 1000);
      yield delay(delayTime);
    } else {
      if (secondaryFetch) {
        // In case API is down, we should not spam the server.
        yield delay(5000);
      }
    }

    switch (indicator.type) {
      case tiRSI:
      case tiSMA:
        yield call(fetchTechIndicatorData, { pair, timePeriod, indicator });
        break;
    }

    secondaryFetch = true;
  }
}

function* syncTechIndicatorData(crypto: Crypto, timePeriod: TimePeriod): Saga<void> {
  const pairs = yield select(getCryptoPairs);
  const pair = Object.values(pairs).find((key: string) => key?.includes(crypto));

  while (true) {
    const indicators = yield select(getSelectedTechIndicators);
    const indicatorObjects = indicators.map((indicator: TechIndicatorOption) =>
      techIndicators.find((item: TechIndicatorItem) => item.value === indicator),
    );

    const tasks = indicatorObjects.map((indicator: TechIndicatorItem) => {
      switch (indicator.type) {
        case tiSMA:
        case tiRSI:
          return pair ? call(bgSyncTechIndicatorData, pair, timePeriod, indicator) : undefined;
        default:
          return undefined;
      }
    });

    yield race({
      task: all(tasks),
      selectedTechIndicatorsChange: take(SET_SELECTED_TECH_INDICATORS),
    });

    // Each time user changes his selected tech indicators, we finish the previous bg sync tasks and start new background sync
    // tasks for tech indicator data. However there can be a problem if user selects 0 tech indicators. In this case
    // this loop would run too quickly and block everything. Therefore we add this 1 second delay here.
    yield delay(1000);
  }
}

export function* watchStartBgSyncTechIndicators(): Saga<void> {
  while (true) {
    yield take(actions.SYNC_TECH_INDICATOR_DATA.START);
    while (true) {
      const selectedTimePeriod = yield select(getSelectedTimePeriod);
      const selectedEntity = yield select(getSelectedEntity);

      const { timePeriodChange, selectedEntityChange } = yield race({
        task: call(syncTechIndicatorData, selectedEntity, selectedTimePeriod),
        timePeriodChange: take(actions.SELECT_TIME_PERIOD),
        selectedEntityChange: take(actions.SELECT_ENTITY),
        cancel: take(actions.SYNC_TECH_INDICATOR_DATA.STOP),
      });

      if (!timePeriodChange && !selectedEntityChange) {
        break; // begin listening for start action
      }
    }
  }
}

function* loadTradeVolumeData(action: { crypto: Crypto }): Saga<void> {
  yield call(fetchTradeVolumeData, action.crypto);
}

// prefetches initial data
function* prefetchData(): Saga<void> {
  yield put(fetchAvailableCryptos());
  yield take(fetchAssetsSuccess.type);
  yield put(invalidatePortfolio());
  yield all([fork(loadActivity)]);
}

// ****************************** WATCHERS ************************************

export function* watchInvalidateTradeVolumeData(): Saga<void> {
  yield takeEvery(actions.INVALIDATE_TRADE_VOLUME_DATA, loadTradeVolumeData);
}

export function* watchInvalidateAccountData(): Saga<void> {
  while (true) {
    yield take(authActions.INVALIDATE_ACCOUNT_DATA);
    yield put(loadInitialActivityItems());
    yield put(invalidatePortfolio());
  }
}

export default function* root(): Saga<void> {
  yield all([
    fork(prefetchData),
    fork(watchInvalidateAccountData),
    fork(watchInvalidateTradeVolumeData),
    fork(watchStartBgSyncTechIndicators),
  ]);
}
