import { action, createRequestTypes } from 'store/actions/_common';
import { TermsAndConditionsData } from 'types/termsAndConditions';
import { AnyAction } from 'redux';
import { ConsentNameType, ConsentType } from 'types/consent';

interface Action {
  type: string;
}

// Crypto TNCs

export const FETCH_CRYPTO_TERMS_AND_CONDITIONS = createRequestTypes('FETCH_CRYPTO_TERMS_AND_CONDITIONS');
export const ACCEPT_CRYPTO_TERMS_AND_CONDITIONS = createRequestTypes('ACCEPT_CRYPTO_TERMS_AND_CONDITIONS');
export const CRYPTO_TERMS_AND_CONDITIONS_CLICKED_AWAY = 'CRYPTO_TERMS_AND_CONDITIONS_CLICKED_AWAY';
export const SHOW_SOFT_BLOCK_POP_UP = 'SHOW_SOFT_BLOCK_POP_UP';
export const CLOSE_SOFT_BLOCK_POP_UP = 'CLOSE_SOFT_BLOCK_POP_UP';

export const fetchCryptoTermsAndConditions = {
  request: (): Action => action(FETCH_CRYPTO_TERMS_AND_CONDITIONS.REQUEST),
  success: (_: any, response: TermsAndConditionsData): Action & { payload: TermsAndConditionsData } =>
    action(FETCH_CRYPTO_TERMS_AND_CONDITIONS.SUCCESS, { payload: response }),
  failure: (_: any, error: string): Action & { error: string } =>
    action(FETCH_CRYPTO_TERMS_AND_CONDITIONS.FAILURE, { error }),
};

export const acceptCryptoTermsAndConditions = {
  request: (): Action => action(ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.REQUEST),
  success: (_: any, response: TermsAndConditionsData): Action & { payload: TermsAndConditionsData } =>
    action(ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.SUCCESS, { payload: response }),
  failure: (_: any, error: string): Action & { error: string } =>
    action(ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.FAILURE, { error }),
};

export const cryptoTermsAndConditionsClickedAway = (isTncClickedAway = true): AnyAction => ({
  type: CRYPTO_TERMS_AND_CONDITIONS_CLICKED_AWAY,
  payload: isTncClickedAway,
});

export const showSoftBlockPopUp = (): Action => ({
  type: SHOW_SOFT_BLOCK_POP_UP,
});

export const closeSoftBlockPopUp = (): Action => ({
  type: CLOSE_SOFT_BLOCK_POP_UP,
});

// Stocks TNCs

export const FETCH_STOCKS_TERMS_AND_CONDITIONS = createRequestTypes('FETCH_STOCKS_TERMS_AND_CONDITIONS');
export const ACCEPT_STOCKS_TERMS_AND_CONDITIONS = createRequestTypes('ACCEPT_STOCKS_TERMS_AND_CONDITIONS');
export const STOCKS_TERMS_AND_CONDITIONS_CLICKED_AWAY = 'STOCKS_TERMS_AND_CONDITIONS_CLICKED_AWAY';

export const fetchStocksTermsAndConditions = {
  request: (): Action => action(FETCH_STOCKS_TERMS_AND_CONDITIONS.REQUEST),
  success: (_: any, response: TermsAndConditionsData): Action & { payload: TermsAndConditionsData } =>
    action(FETCH_STOCKS_TERMS_AND_CONDITIONS.SUCCESS, { payload: response }),
  failure: (_: any, error: string): Action & { error: string } =>
    action(FETCH_STOCKS_TERMS_AND_CONDITIONS.FAILURE, { error }),
};

export const acceptStocksTermsAndConditions = {
  request: (): Action => action(ACCEPT_STOCKS_TERMS_AND_CONDITIONS.REQUEST),
  success: (_: any, response: TermsAndConditionsData): Action & { payload: TermsAndConditionsData } =>
    action(ACCEPT_STOCKS_TERMS_AND_CONDITIONS.SUCCESS, { payload: response }),
  failure: (_: any, error: string): Action & { error: string } =>
    action(ACCEPT_STOCKS_TERMS_AND_CONDITIONS.FAILURE, { error }),
};

export const stocksTermsAndConditionsClickedAway = (): AnyAction => ({
  type: STOCKS_TERMS_AND_CONDITIONS_CLICKED_AWAY,
});

// Feature specific TnCs
export const FETCH_FEATURE_TERMS_AND_CONDITIONS = 'FETCH_FEATURE_TERMS_AND_CONDITIONS';
export const FETCH_FEATURE_TERMS_AND_CONDITIONS_SUCCESS = 'FETCH_FEATURE_TERMS_AND_CONDITIONS_SUCCESS';
export const FETCH_FEATURE_TERMS_AND_CONDITIONS_FAILURE = 'FETCH_FEATURE_TERMS_AND_CONDITIONS_FAILURE';

export const ACCEPT_FEATURE_TERMS_AND_CONDITIONS = 'ACCEPT_FEATURE_TERMS_AND_CONDITIONS';
export const ACCEPT_FEATURE_TERMS_AND_CONDITIONS_SUCCESS = 'ACCEPT_FEATURE_TERMS_AND_CONDITIONS_SUCCESS';
export const ACCEPT_FEATURE_TERMS_AND_CONDITIONS_FAILURE = 'ACCEPT_FEATURE_TERMS_AND_CONDITIONS_FAILURE';

export const DECLINE_FEATURE_TERMS_AND_CONDITIONS = 'DECLINE_FEATURE_TERMS_AND_CONDITIONS';
export const DECLINE_FEATURE_TERMS_AND_CONDITIONS_SUCCESS = 'DECLINE_FEATURE_TERMS_AND_CONDITIONS_SUCCESS';
export const DECLINE_FEATURE_TERMS_AND_CONDITIONS_FAILURE = 'DECLINE_FEATURE_TERMS_AND_CONDITIONS_FAILURE';

export const SHOW_FEATURE_TERMS_AND_CONDITIONS = 'SHOW_FEATURE_TERMS_AND_CONDITIONS';
export const RESET_FEATURE_TERMS_AND_CONDITIONS = 'RESET_FEATURE_TERMS_AND_CONDITIONS';

export const FETCH_FEATURE_TERMS_AND_CONDITIONS_TOPUPS = 'FETCH_FEATURE_TERMS_AND_CONDITIONS_TOPUPS';

export const fetchFeatureTermsAndConditionsTopups = () => ({
  type: FETCH_FEATURE_TERMS_AND_CONDITIONS_TOPUPS,
});

export const fetchFeatureTermsAndConditions = (consentType: ConsentNameType) => ({
  type: FETCH_FEATURE_TERMS_AND_CONDITIONS,
  payload: consentType,
});

export const fetchFeatureTermsAndConditionsSuccess = (payload: ConsentType) => ({
  type: FETCH_FEATURE_TERMS_AND_CONDITIONS_SUCCESS,
  payload,
});

export const fetchFeatureTermsAndConditionsFailure = () => ({
  type: FETCH_FEATURE_TERMS_AND_CONDITIONS_FAILURE,
});

export const acceptFeatureTermsAndConditions = (consentType: ConsentNameType) => ({
  type: ACCEPT_FEATURE_TERMS_AND_CONDITIONS,
  payload: consentType,
});

export const acceptFeatureTermsAndConditionsSuccess = (consentType: ConsentNameType) => ({
  type: ACCEPT_FEATURE_TERMS_AND_CONDITIONS_SUCCESS,
  payload: consentType,
});

export const acceptFeatureTermsAndConditionsFailure = () => ({
  type: ACCEPT_FEATURE_TERMS_AND_CONDITIONS_FAILURE,
});

export const declineFeatureTermsAndConditions = (consentType: ConsentNameType) => ({
  type: DECLINE_FEATURE_TERMS_AND_CONDITIONS,
  payload: consentType,
});

export const declineFeatureTermsAndConditionsSuccess = (consentType: ConsentNameType) => ({
  type: DECLINE_FEATURE_TERMS_AND_CONDITIONS_SUCCESS,
  payload: consentType,
});

export const declineFeatureTermsAndConditionsFailure = () => ({
  type: DECLINE_FEATURE_TERMS_AND_CONDITIONS_FAILURE,
});

export const showFeatureTermsAndConditions = (newValue: boolean): AnyAction => ({
  type: SHOW_FEATURE_TERMS_AND_CONDITIONS,
  payload: newValue,
});

export const resetFeatureTermsAndConditions = (): AnyAction => ({
  type: RESET_FEATURE_TERMS_AND_CONDITIONS,
});
