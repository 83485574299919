import React from 'react';
import styled from 'styled-components';
import { Theme } from 'types/theme';
import * as formatting from 'common/utils/formatting';

type InputLabelComponentTypes = {
  labelText: string;
  amountLabel: string;
  amount: number;
  isErrorOccurred: boolean;
  isWarningOccurred: boolean;
  isFiat: boolean;
  handleMaxValueClick: () => void;
  /**
   * Formats the value to passed in precision.
   * Can be left out if isFiat is set to `true`.
   */
  cryptoPrecision?: number;
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;
const LeftColumn = styled.div``;
const RightColumn = styled.div.attrs({ className: 'xxsmall-text' })`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
`;
const LabelText = styled.div<{ isErrorOccurred: boolean; isWarningOccurred: boolean }>`
  color: ${({
    isErrorOccurred,
    theme,
    isWarningOccurred,
  }: {
    isErrorOccurred: boolean;
    theme: Theme;
    isWarningOccurred: boolean;
  }) => {
    if (isWarningOccurred) return theme.color.foreground.caution;
    if (isErrorOccurred) return theme.color.foreground.critical;
    return theme.color.foreground.primary;
  }};
`;
const AmountLabel = styled.div`
  color: #757575;
`;
const Amount = styled.div`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
  text-decoration: underline;
  cursor: pointer;
`;

const InputLabelComponent = ({
  labelText,
  amountLabel,
  amount,
  isErrorOccurred,
  isWarningOccurred,
  isFiat,
  handleMaxValueClick,
  cryptoPrecision,
}: InputLabelComponentTypes): JSX.Element => {
  return (
    <Container>
      <LeftColumn>
        <LabelText isErrorOccurred={isErrorOccurred} isWarningOccurred={isWarningOccurred}>
          {labelText}
        </LabelText>
      </LeftColumn>
      <RightColumn>
        <AmountLabel>{amountLabel}</AmountLabel>&nbsp;
        <Amount onClick={handleMaxValueClick}>
          {isFiat ? formatting.fiat(amount) : formatting.crypto(amount, cryptoPrecision)}
        </Amount>
      </RightColumn>
    </Container>
  );
};

export default InputLabelComponent;
