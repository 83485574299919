/* eslint-disable @typescript-eslint/no-unsafe-return */
import { AnyAction, combineReducers } from 'redux';
import * as ActionTypes from 'store/actions/alerting';
import { Alert } from 'types/alerts';

function isFetching(state = false, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.FETCH_ALERTS_REQUEST:
      return true;
    case ActionTypes.FETCH_ALERTS_SUCCESS:
      return false;
    case ActionTypes.FETCH_ALERTS_FAILURE:
      return false;
    default:
      return state;
  }
}

function alertsData(state = [], action: AnyAction) {
  switch (action.type) {
    case ActionTypes.FETCH_ALERTS_SUCCESS: {
      return action.payload.response;
    }
    case ActionTypes.FETCH_ALERTS_FAILURE:
      return state.map((item: Alert) => {
        return {
          ...item,
          updating: false,
        };
      });
    case ActionTypes.PUT_ALERT:
      return state.map((item: Alert) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            updating: true,
          };
        }
        return item;
      });
    case ActionTypes.PUT_ALERT_SUCCESS:
      return state.map((item: Alert) => {
        if (item.id === action.payload.response.id) {
          return {
            ...item,
            ...action.payload.response,
            updating: false,
          };
        }
        return item;
      });
    case ActionTypes.CREATE_ALERT_SUCCESS:
      return [...state, action.payload.response];
    default:
      return state;
  }
}

function alertToEdit(state = null, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.SELECT_EDITED_ALERT:
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({ isFetching, alertsData, alertToEdit });
