import React, { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import ModalTemplate from 'common/components/Modal/index';
import Button from 'common/components/Button';

import { ExitIntentTitle, Text } from './styles';

const AuditProofExitIntentModal = ({
  open,
  daysLeft,
  handleRejectExitIntentModal,
  handleAcceptAudit,
}: {
  open: boolean;
  daysLeft: undefined | number;
  handleRejectExitIntentModal: () => void;
  handleAcceptAudit: () => void;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      defaultOpen={open}
      modalDimensions="small"
      actions={
        <Button
          size="large"
          title={t('auditProof.postponeReviewModal.button')}
          isFullWidth
          onClick={handleAcceptAudit}
        />
      }
      header={<ExitIntentTitle>{t('auditProof.postponeReviewModal.title')}</ExitIntentTitle>}
      onCancel={handleRejectExitIntentModal}
    >
      <Text>
        <Trans i18nKey="auditProof.postponeReviewModal.text" values={{ daysLeft }} />
      </Text>
    </ModalTemplate>
  );
};

export default memo(AuditProofExitIntentModal);
