import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { State } from 'store/types/store';
import { addUserToUsersWhoSawPhishingBanner } from 'store/actions/localSettings';
import { getUser } from 'store/selectors/auth';
import { stringHashCode } from 'common/utils/hash';
import PhishingModal from './view';

const mapStateToProps = (state: State) => {
  const userName = getUser(state).username;
  const userHash = stringHashCode(userName ?? '');

  return {
    userHash,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addUserToUsersWhoSawPhishingBanner: (value: string): any =>
    dispatch(addUserToUsersWhoSawPhishingBanner(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhishingModal);
