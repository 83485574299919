import { catchError, filter, map, mapTo, mergeMap, pluck, switchMap, take, tap } from 'rxjs/operators';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import * as api from 'common/api';
import { from, of } from 'rxjs';
import { isOfType, isPresent } from 'safetypings';
import I18n from 'i18next';
import { State } from 'store/types/store';
import { paths } from 'common/urls';
import { SubmitLimitOrderFullPayload } from 'common/api-types';
import { handleAjaxError } from './auth';
import { getAccessToken } from '../selectors/auth';
import {
  deleteTradingRulesFailure,
  deleteTradingRulesSuccess,
  fetchTradingRulesFailure,
  fetchTradingRulesSuccess,
  SUBMIT_TRADING_RULES_FAILURE,
  SUBMIT_TRADING_RULES_SUCCESS,
  submitTradingRulesFailure,
  submitTradingRulesSuccess,
} from '../actions/orders';
import * as notificationBannerActions from '../actions/notificationBanner';

export const fetchTradingRulesEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType('FETCH_TRADING_RULES_REQUEST')),
    switchMap(() =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(api.fetchLimitOrder(accessToken)).pipe(
            map(fetchTradingRulesSuccess),
            catchError(handleAjaxError(action$, fetchTradingRulesFailure)),
          ),
        ),
      ),
    ),
  );

export const deleteTradingRuleEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): void =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType('DELETE_TRADING_RULE')),
    switchMap(({ navigate }) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      action$.pipe(
        filter(isOfType(['DELETE_TRADING_RULES_SUCCESS', 'DELETE_TRADING_RULES_FAILURE'])),
        take(1),
        switchMap((responseAction: { type: string }) =>
          responseAction.type === 'DELETE_TRADING_RULES_SUCCESS'
            ? of(
                notificationBannerActions.notifySuccess({
                  message: I18n.t('automation.deletePriceLimitSuccess'),
                }),
              ).pipe(
                tap(() => {
                  if (navigate) navigate(paths.LIMIT_ORDER);
                }),
              )
            : of(
                notificationBannerActions.notifyError({
                  message: I18n.t('automation.deletePriceLimitError'),
                }),
              ).pipe(
                tap(() => {
                  if (navigate) navigate(paths.LIMIT_ORDER);
                }),
              ),
        ),
      ),
    ),
  );

export const deleteTradingRulesEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType('DELETE_TRADING_RULE')),
    pluck('payload'),
    mergeMap((uniqueId: string) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          from(api.deleteLimitOrder(accessToken, uniqueId)).pipe(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            mapTo(deleteTradingRulesSuccess(uniqueId)),
            catchError(handleAjaxError(action$, deleteTradingRulesFailure, uniqueId)),
            map(({ type, error }) => {
              if (type === SUBMIT_TRADING_RULES_FAILURE && error?.code !== 'limitsubmit_err_limitreached') {
                return notificationBannerActions.notifyError({
                  message: I18n.t('automation.errors.generic'),
                  error,
                });
              }
              return { type, error };
            }),
          ),
        ),
      ),
    ),
  );

export const createTradingRuleEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType('SUBMIT_TRADING_RULE')),
    map((props: SubmitLimitOrderFullPayload) => ({
      payload: props.payload,
      onSuccess: props.onSuccess,
      onFail: props.onFail,
      onError: props.onError,
    })),
    switchMap(
      ({ payload: { auxiliary, ...rest }, onSuccess, onFail, onError }: SubmitLimitOrderFullPayload) =>
        state$.pipe(
          map(getAccessToken),
          filter(isPresent),
          take(1),
          switchMap((accessToken) =>
            from(api.submitLimitOrder(accessToken, rest)).pipe(
              map(submitTradingRulesSuccess),
              catchError(handleAjaxError(action$, submitTradingRulesFailure)),
              map(({ type, error }) => {
                if (
                  type === SUBMIT_TRADING_RULES_FAILURE &&
                  error?.errorCode !== 'limitsubmit_err_limitreached'
                ) {
                  onError();
                }
                return { type, error };
              }),
              tap(({ type, error }) => {
                if (type === SUBMIT_TRADING_RULES_SUCCESS) {
                  onSuccess();
                } else if (
                  type === SUBMIT_TRADING_RULES_FAILURE &&
                  error?.errorCode === 'limitsubmit_err_limitreached'
                ) {
                  onFail();
                }
              }),
            ),
          ),
        ),
    ),
  );
