/* eslint-disable import/order */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable spaced-comment */

import { combineEpics } from 'redux-observable';
import { catchError, filter, map, mapTo, mergeMap, pluck, switchMap, take } from 'rxjs/operators';
import { isOfType, isPresent } from 'safetypings';
import { queryArrayString } from 'common/utils/rxjs-ajax';
import { RootEpic } from 'types/common';
import { AjaxError } from 'rxjs/ajax';
import { ASSET_TAGS, Asset, AssetDetail, CostTransparencyFromApi } from 'types/assets';
import { backoff } from 'common/utils/rxjs-operators';
import I18n from 'i18next';
import { paths } from 'common/urls';
import { getAccessToken } from 'store/selectors/auth';
import {
  fetchAssetsFailure,
  fetchAssetsSuccess,
  fetchSingleAsset as fetchSingleAssetAction,
  fetchSingleAssetSuccess,
  fetchSingleAssetFailure,
  fetchAssetDetailRequest,
  fetchAssetDetailFailure,
  fetchAssetDetailSuccess,
  fetchAvailableCryptos,
  fetchAssets as fetchAssetsAction,
} from 'store/slices/assets/actions';
import { getAsset } from 'store/slices/assets/selectors';
import {
  AssetDetailRequestPayload,
  AssetDetailSuccessPayload,
  AssetsRequest,
  COST_TRANSPARENCY_GET,
  FETCH_ASSET_DETAIL_FAILURE,
  FetchSingleAssetPayload,
  fetchCostTransparencyDataFailure,
  fetchCostTransparencyDataSuccess,
} from '../actions/assets';
import { handleAjaxError } from './auth';
import {
  transformAssetClassInfoToAssetTag,
  transformAssetTagToAssetClassInfo,
} from 'common/utils/securities';
import { AppReduxEpicMiddleware } from 'store/types/store';

// fetches a single asset
const fetchSingleAsset: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(fetchSingleAssetAction.type)),
    mergeMap(({ payload }: { payload: FetchSingleAssetPayload }) =>
      state$.pipe(
        map((state) => {
          const accessToken = getAccessToken(state);

          if (!accessToken) return undefined;

          const asset = getAsset(state, payload.class, payload.code, payload.securityClass);

          // If we already have the asset then we can skip the fetch.
          // TODO: Maybe we can add an expiry date?
          if (asset) return undefined;

          return accessToken;
        }),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: api
              .getBaseUrlForTrading(accessToken)
              .concat('/assets')
              .concat(
                queryArrayString({
                  search: payload.code,
                  tag: transformAssetClassInfoToAssetTag(payload.class, payload.securityClass),
                }),
              ),
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map(({ assets }: { assets: Asset[] }) => {
              const firstAsset = assets[0];

              return fetchSingleAssetSuccess(firstAsset);
            }),
          ),
        ),
        // catch outside of ajax's switchMap to grab a fresh token when resubscribing to observable (on token refresh)
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, fetchSingleAssetFailure)),
      ),
    ),
  );

const fetchAvailableCryptosEpic: RootEpic = (action$: any) =>
  action$.pipe(
    filter(isOfType(fetchAvailableCryptos.type)),
    mapTo(fetchAssetsAction({ pageSize: 50, tag: ASSET_TAGS.CRYPTO })),
  );

const fetchAssetsEpic: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(fetchAssetsAction.match),
    switchMap(({ payload }: { payload: AssetsRequest }) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: api.getBaseUrlForTrading(accessToken).concat('/assets').concat(queryArrayString(payload)),
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map(({ assets }: { assets: Asset[] }) =>
              fetchAssetsSuccess({
                assets,
                reference: payload.reference,
                pageSize: payload.pageSize,
                ...transformAssetTagToAssetClassInfo(payload.tag),
              }),
            ),
          ),
        ),
        // catch outside of ajax's switchMap to grab a fresh token when resubscribing to observable (on token refresh)
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, fetchAssetsFailure)),
      ),
    ),
  );

const ASSET_DETAILS_TOKEN_ERROR = 'Valid token not returned';
const ASSET_DETAILS_STILL_VALID_ERROR = 'Details are still valid. No need to fetch again';
// TODO: Find a better solution for this problem.
let skipRedirection = false;

const assetDetail: RootEpic = (action$, state$, { ajax, api, navigate }: AppReduxEpicMiddleware) =>
  action$.pipe(
    filter(fetchAssetDetailRequest.match),
    mergeMap(({ payload }: { payload: AssetDetailRequestPayload }) =>
      state$.pipe(
        take(1),
        map((state) => {
          skipRedirection = false;
          const accessToken = getAccessToken(state);

          if (!accessToken) {
            skipRedirection = true;
            throw new Error(ASSET_DETAILS_TOKEN_ERROR);
          }

          const asset = getAsset(state, payload.class, payload.code, payload.securityClass);

          // If there is no detail yet, then we need to fetch it.
          if (!asset?.detail) return accessToken;

          // Is older than 6 hours
          const isStale = asset?.detailLastFetched
            ? (asset.detailLastFetched ?? 0) + 3600000 * 6 < new Date().getTime()
            : true;

          // If it is not stale then we need to skip the request
          if (!isStale) {
            skipRedirection = true;
            throw new Error(ASSET_DETAILS_STILL_VALID_ERROR);
          }

          return accessToken;
        }),
        filter(isPresent),
        switchMap((accessToken) => {
          return ajax({
            url: api
              .getBaseUrlForTrading(accessToken)
              ?.concat(`/stocks/master-data`)
              .concat(
                queryArrayString({
                  isin: payload.code,
                  language: payload.language,
                }),
              ),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }).pipe(
            pluck('response'),
            map(
              (response: AssetDetail): AssetDetailSuccessPayload => ({
                detail: response,
                code: payload.code,
                class: payload.class,
                securityClass: payload.securityClass,
              }),
            ), // tack on code
            map(fetchAssetDetailSuccess),
          );
        }),
        // catch outside of ajax's switchMap to grab a fresh token when resubscribing to observable (on token refresh)
        backoff(2, 500, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, fetchAssetDetailFailure)),
        map((response) => {
          // If an error is catched then we need to redirect to market overview page.
          if (!skipRedirection && response.type === FETCH_ASSET_DETAIL_FAILURE && navigate) {
            navigate(paths.MARKET_OVERVIEW);
          }

          return response;
        }),
      ),
    ),
  );

const getCostTransparency: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(COST_TRANSPARENCY_GET)),
    switchMap(({ payload }) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            method: 'GET',
            url: api
              .getBaseUrlForTrading(accessToken)
              .concat(`/stocks/cost-transparencies`)
              .concat(
                queryArrayString({
                  ...payload,
                  // If no quantity or 0 is passed in then we need to set it to 1 so the request can go through.
                  quantity: payload.quantity || 1,
                  expireDate: new Date(new Date().getFullYear(), 12, 31),
                }),
              ),
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map((response) =>
              fetchCostTransparencyDataSuccess({
                costTransparency: response as CostTransparencyFromApi,
                price: payload.price,
              }),
            ),
          ),
        ),
        // catch outside of ajax's switchMap to grab a fresh token when resubscribing to observable (on token refresh)
        backoff(5, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(
          handleAjaxError(action$, fetchCostTransparencyDataFailure, undefined, {
            title: I18n.t('stocksEtfsTradeOrder.costInformationModal.errors.title'),
            message: I18n.t('stocksEtfsTradeOrder.costInformationModal.errors.get'),
          }),
        ),
      ),
    ),
  );

export default combineEpics(
  // fetch small chart data for single asset

  fetchAvailableCryptosEpic,
  fetchAssetsEpic,

  // ASSET DETAIL
  // fetch stock detail
  assetDetail,

  // CostTransparency
  getCostTransparency,

  // Fetch single asset
  fetchSingleAsset,
);
