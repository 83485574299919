import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import { NOTIFICATION_EL_GLOBAL } from 'common/const';
import { useThemeContext } from 'common/utils/theme/ThemeContext';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { displayNotification } from 'store/actions/notificationBanner';
import { CloseButton, NotificationWrapper, StyledToast } from './styles';
import Icon from '../Icon';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/styles';
import { useTracking } from 'common/tracking/hooks';

const options = {
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  position: toast.POSITION.TOP_CENTER,
  newestOnTop: false,
  autoClose: true,
  className: 'notification-banner',
};

type Props = {
  notifications: Array<Notification>;
  banner?: string;
  options?: Notification;
  clearNotification: (id: number) => any;
  className: string;
  containerId?: string;
};

const NotificationBanner = (props: Props) => {
  const dispatch = useDispatch();
  const trackAppEvent = useTracking();
  const notificationSystemRef = React.useRef(null);
  const isMobile = useMedia([devices.maxTablet, devices.tablet], [true, false], false);
  const { notifications, banner } = props;
  const theme = useThemeContext();
  const isClosedByButton = useRef(false);

  React.useEffect(() => {
    const displayNotifications = props.notifications.filter(
      (item) => !toast.isActive(item.id) && !item.displayedOnce,
    );

    displayNotifications.forEach((notification) => {
      const { id, banner, message, onClick } = notification;
      const elOptions = {
        ...options,
        ...props.options,
        ...notification.options,
        toastId: id,
        containerId: props.containerId,
        className: props.className || '',
        position: isMobile ? toast.POSITION.TOP_CENTER : toast.POSITION.BOTTOM_CENTER,
        newestOnTop: isMobile,
        closeButton: (
          <CloseButton
            onClick={() => {
              trackAppEvent('closeNotification', {
                type: banner,
                contentId: null,
                closeType: 'closeButton',
                message: message,
              });
              isClosedByButton.current = true;
            }}
          >
            <Icon name="icon-outlined-close" sizeValue="tiniest" customColor={theme.color.icons.white} />
          </CloseButton>
        ),
        onClick: () => {
          if (onClick) onClick();
        },
        onClose: () => {
          props.clearNotification({ id });

          // no user interaction
          if (!isClosedByButton.current) {
            trackAppEvent('closeNotification', {
              type: banner,
              contentId: null,
              message: message,
              closeType: 'userIgnored',
            });
          }
          isClosedByButton.current = false;
        },
      };
      dispatch(displayNotification(id));

      trackAppEvent('showNotification', { type: banner, contentId: null, message });
      toast[banner](message, elOptions);
    });
  }, [notifications, props]);

  return (
    <NotificationWrapper>
      <StyledToast ref={notificationSystemRef} type={banner} limit={3} />
    </NotificationWrapper>
  );
};

/**
 * type:[ success, info, error, warn]
 */
NotificationBanner.defaultProps = {
  banner: '',
  options: {},
  containerId: NOTIFICATION_EL_GLOBAL,
};

export default NotificationBanner;
