import { ActionsObservable, Epic, ofType, StateObservable } from 'redux-observable';
import { Action } from 'redux';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { isOfType, isPresent } from 'safetypings';
import { from, of, race } from 'rxjs';
import I18n from 'i18next';
import { State } from 'store/types/store';
import { paths } from 'common/urls';
import { SavingPlanEditStatus } from 'types/savings';
import { SubmitLimitOrderFullPayload } from 'common/api-types';
import { getAccessToken } from '../selectors/auth';
import * as api from '../../common/api';
import { handleAjaxError } from './auth';
import {
  CREATE_SAVINGS_PLAN_FAILURE,
  CREATE_SAVINGS_PLAN_SUCCESS,
  createSavingsPlanFailure,
  createSavingsPlanSuccess,
  deleteSavingsPlanFailure,
  deleteSavingsPlanSuccess,
  FETCH_SAVINGS_PLANS_REQUEST,
  fetchSavingsPlansFailure,
  fetchSavingsPlansSuccess,
  INVALIDATE_SAVINGS_PLANS_REQUEST,
  invalidateSavingsPlansRequest,
  PUT_SAVINGS_PLAN,
  PUT_SAVINGS_PLAN_FAILURE,
  PUT_SAVINGS_PLAN_SUCCESS,
  putSavingsPlanFailure,
  putSavingsPlanSuccess,
} from '../actions/saving_old';
import * as notificationBannerActions from '../actions/notificationBanner';

export const fetchSavingsPlansEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType([FETCH_SAVINGS_PLANS_REQUEST, INVALIDATE_SAVINGS_PLANS_REQUEST])),
    switchMap(() =>
      race(
        state$.pipe(
          map(getAccessToken),
          filter(isPresent),
          take(1),
          switchMap((accessToken) =>
            from(api.fetchSavingsPlans(accessToken)).pipe(
              map(fetchSavingsPlansSuccess),
              catchError(handleAjaxError(action$, fetchSavingsPlansFailure)),
            ),
          ),
        ),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
        action$.pipe(ofType(PUT_SAVINGS_PLAN), take(1), ignoreElements()),
      ),
    ),
  );

export const putSavingsPlansEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(PUT_SAVINGS_PLAN)),
    pluck('payload'),
    mergeMap((savingsPlanEdit: { id: number; status: SavingPlanEditStatus }) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(api.postSavingsPlansStatus(savingsPlanEdit, accessToken)).pipe(
            mapTo(
              putSavingsPlanSuccess({
                id: savingsPlanEdit.id,
                status: savingsPlanEdit.status === 'pause' ? 'Paused' : 'Active',
              }),
            ),
            catchError(
              handleAjaxError(action$, putSavingsPlanFailure, {
                status: savingsPlanEdit.status,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const putSavingsPlanHandlerEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): void =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(PUT_SAVINGS_PLAN)),
    switchMap(() =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      action$.pipe(
        filter(isOfType([PUT_SAVINGS_PLAN_FAILURE])),
        take(1),
        switchMap((responseAction: { type: string; payload: { status: string } }) =>
          of(
            notificationBannerActions.notifyError({
              message: I18n.t(`savings.${responseAction.payload.status}.error`),
            }),
            invalidateSavingsPlansRequest(),
          ),
        ),
      ),
    ),
  );

export const createSavingsPlanEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType('CREATE_SAVINGS_PLAN')),
    map((props: SubmitLimitOrderFullPayload) => ({
      payload: props.payload,
      onSuccess: props.onSuccess,
      onFail: props.onFail,
    })),
    switchMap(({ payload, onSuccess, onFail }: SubmitLimitOrderFullPayload) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(api.createSavingsPlan(accessToken, payload)).pipe(
            map(createSavingsPlanSuccess),
            catchError(handleAjaxError(action$, createSavingsPlanFailure)),
            map(({ type, error }) => {
              if (type === CREATE_SAVINGS_PLAN_FAILURE) {
                onFail();
                return notificationBannerActions.notifyError({
                  message: I18n.t('savings.failText'),
                });
              }
              return { type, error };
            }),
            tap(({ type, error }) => {
              if (type === CREATE_SAVINGS_PLAN_SUCCESS) {
                onSuccess();
              }
            }),
          ),
        ),
      ),
    ),
  );

export const deleteSavingsPlanHandlerEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): void =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType('DELETE_SAVINGS_PLAN')),
    switchMap(({ navigate }) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      action$.pipe(
        filter(isOfType(['DELETE_SAVINGS_PLAN_SUCCESS', 'DELETE_SAVINGS_PLAN_FAILURE'])),
        take(1),
        switchMap((responseAction: { type: string }) =>
          responseAction.type === 'DELETE_SAVINGS_PLAN_SUCCESS'
            ? of(
                notificationBannerActions.notifySuccess({
                  message: I18n.t('savings.disable.success'),
                }),
              ).pipe(
                tap(() => {
                  if (navigate) navigate(paths.SAVINGS_PLAN);
                }),
              )
            : of(
                notificationBannerActions.notifyError({
                  message: I18n.t('savings.disable.error'),
                }),
              ).pipe(
                tap(() => {
                  if (navigate) navigate(paths.SAVINGS_PLAN);
                }),
              ),
        ),
      ),
    ),
  );

export const deleteSavingsPlanEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType('DELETE_SAVINGS_PLAN')),
    pluck('payload'),
    mergeMap((id: string) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          from(api.deleteSavingsPlan(accessToken, id)).pipe(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            mapTo(deleteSavingsPlanSuccess(id)),
            catchError(handleAjaxError(action$, deleteSavingsPlanFailure, id)),
          ),
        ),
      ),
    ),
  );
