import { devices } from 'common/styles';
import styled from 'styled-components';
import { Theme } from 'types/theme';

export const Title = styled.div`
  text-align: center;
  font-size: ${({ theme }: { theme: Theme }) => theme.typography.heading.size['2'].fontSize};
  line-height: ${({ theme }: { theme: Theme }) => theme.typography.heading.size['2'].lineHeight};
  font-weight: ${({ theme }: { theme: Theme }) => theme.typography.fontWeight.strong};
  padding-bottom: 24px;

  @media ${devices.tablet} {
    padding-bottom: 36px;
  }
`;

export const Text = styled.span.attrs({ className: 'small-text' })``;

export const ContainerCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ImageWrapper = styled.div`
  color: ${({ theme }: { theme: Theme }) => theme.color.icons.svgBg};
  display: flex;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  & > div {
    margin: 0;
    width: 343px;
  }
`;
