// @flow
import React from 'react';
import styled from 'styled-components';

import { dimensions } from 'common/styles';

const Content = styled.div`
  max-width: ${dimensions.maxContentWidth}px;
  width: 100%;
`;

export default ({
  children,
  showPaperTradingBanner,
  ...rest
}: {
  showPaperTradingBanner: boolean;
  children: React.Node;
}) => (
  <Content showPaperTradingBanner={showPaperTradingBanner}>
    {React.Children.map(children, (child) => React.cloneElement(child, { ...rest }))}
  </Content>
);
