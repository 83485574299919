import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './_common';

export const INIT_ORDER = createRequestTypes('INIT_ORDER');
export const initOrder = {
  request: ({ entity, isBuyOrder }: { isBuyOrder: boolean; entity: string }) =>
    action(INIT_ORDER[REQUEST], { entity, isBuyOrder }),
  success: (_: any, response: any) =>
    action(INIT_ORDER[SUCCESS], { orderPreview: response.orderPreview, auxInfo: response.auxInfo }),
  failure: (_: any, error: any) => action(INIT_ORDER[FAILURE], { error }),
};

export const PRICE_QUOTE = createRequestTypes('PRICE_QUOTE');
export const priceQuote = {
  request: ({
    entity,
    isBuyOrder,
    amount,
    amountType,
  }: {
    isBuyOrder: boolean;
    entity: string;
    amount: number;
    amountType: 'volume' | 'value';
  }) => action(PRICE_QUOTE[REQUEST], { entity, isBuyOrder, amount, amountType }),
  success: ({ amountType }: { amountType: 'volume' | 'value' }, response: any) =>
    action(PRICE_QUOTE[SUCCESS], { response, amountType }),
  failure: (_: any, error: any) => action(PRICE_QUOTE[FAILURE], { error }),
};

export const RESERVE_ORDER = createRequestTypes('RESERVE_ORDER');
export const reserveOrder = {
  request: ({
    isBuyOrder,
    currency,
    entity,
    amount,
    amountType,
  }: {
    isBuyOrder: boolean;
    currency: string;
    entity: string;
    amount: number;
    amountType: 'volume' | 'value';
  }) =>
    action(RESERVE_ORDER[REQUEST], {
      isBuyOrder,
      currency,
      entity,
      amount,
      amountType,
    }),
  success: (_: any, response: any) =>
    action(RESERVE_ORDER[SUCCESS], { orderPreview: response.orderPreview, auxInfo: response.auxInfo }),
  failure: (_: any, error: any) => action(RESERVE_ORDER[FAILURE], { error }),
};

export const SUBMIT_ORDER = createRequestTypes('SUBMIT_ORDER');
export const submitOrder = {
  request: ({
    reserveOrderData,
  }: {
    reserveOrderData: {
      reservationToken: string;
      confirmationFlowId: string;
      orderType: string;
      currency: string;
      entity: string;
      price: number;
      ratio: number;
      volume: number;
      value: number;
    };
  }) => action(SUBMIT_ORDER[REQUEST], { reserveOrderData }),
  success: (_: any, { orderDone }: { orderDone: boolean }) => action(SUBMIT_ORDER[SUCCESS], { orderDone }),
  failure: (_: any, error: any) => action(SUBMIT_ORDER[FAILURE], { error }),
};

// Actions triggered from the screen
export const START_ORDER_SESSION = 'START_ORDER_SESSION';
export const startOrderSession = (entity: string, isBuyOrder: boolean) =>
  action(START_ORDER_SESSION, { entity, isBuyOrder });

export const END_ORDER_SESSION = 'END_ORDER_SESSION';
export const endOrderSession = () => action(END_ORDER_SESSION);

export const SWITCH_ORDER_MODE = 'SWITCH_ORDER_MODE';
export const switchOrderMode = (entity: string, isBuyOrder: boolean) =>
  action(SWITCH_ORDER_MODE, { entity, isBuyOrder });

export const RETRY_ORDER = 'RETRY_ORDER';
export const retryOrder = () => action(RETRY_ORDER);

export const REFRESH_QUOTE = 'REFRESH_QUOTE';
export const refreshQuote = (amount: { value: string; volume: string }, amountType: 'volume' | 'value') =>
  action(REFRESH_QUOTE, { amount, amountType });

export const USER_INPUT_CHANGED = 'USER_INPUT_CHANGED';
export const userInputChanged = (
  amount: { value?: number; volume?: number },
  amountType: 'volume' | 'value',
  isSuggestion: boolean,
) => action(USER_INPUT_CHANGED, { amount, amountType, isSuggestion });

export const USER_INPUT_CHANGE_FINISHED = 'USER_INPUT_CHANGE_FINISHED';
export const userInputChangeFinished = () => action(USER_INPUT_CHANGE_FINISHED);

export const INVALID_USER_INPUT = 'INVALID_USER_INPUT';
export const invalidUserInput = (resetToZero = false) => action(INVALID_USER_INPUT, { resetToZero });

export const CHANGE_FIELD_IN_FOCUS = 'CHANGE_FIELD_IN_FOCUS';
export const changeFieldInFocus = (fieldInFocus) => action(CHANGE_FIELD_IN_FOCUS, { fieldInFocus });

export const CHANGE_LAST_EDITED_FIELD = 'LAST_EDITED_FIELD';
export const changeLastEditedField = (lastEditedField) =>
  action(CHANGE_LAST_EDITED_FIELD, { lastEditedField });

export const RESERVE_USER_ORDER = 'RESERVE_USER_ORDER';
export const reserveUserOrder = () => action(RESERVE_USER_ORDER);

export const RESERVATION_TIMEOUT = 'RESERVATION_TIMEOUT';
export const reservationTimeout = () => action(RESERVATION_TIMEOUT);

export const SUBMIT_USER_ORDER = 'SUBMIT_USER_ORDER';
export const submitUserOrder = () => action(SUBMIT_USER_ORDER);

export const CANCEL_USER_SUBMIT = 'CANCEL_USER_SUBMIT';
export const cancelUserSubmit = () => action(CANCEL_USER_SUBMIT);

export const FETCH_TRADING_RULES = 'FETCH_TRADING_RULES_REQUEST';
export const fetchTradingRules = () => action(FETCH_TRADING_RULES);

export const FETCH_TRADING_RULES_SUCCESS = 'FETCH_TRADING_RULES_SUCCESS';
export const fetchTradingRulesSuccess = (payload) => action(FETCH_TRADING_RULES_SUCCESS, { payload });

export const FETCH_TRADING_RULES_FAILURE = 'FETCH_TRADING_RULES_FAILURE';
export const fetchTradingRulesFailure = (error) => action(FETCH_TRADING_RULES_FAILURE, { error });

export const SAVE_DELETING_TRADING_RULE = 'SAVE_DELETING_TRADING_RULE';
export const saveDeletingTradingRules = (payload) => action(SAVE_DELETING_TRADING_RULE, { payload });

export const DELETE_TRADING_RULE = 'DELETE_TRADING_RULE';
export const deleteTradingRule = (payload, navigate) => action(DELETE_TRADING_RULE, { payload, navigate });

export const DELETE_TRADING_RULES_SUCCESS = 'DELETE_TRADING_RULES_SUCCESS';
export const deleteTradingRulesSuccess = (payload) => action(DELETE_TRADING_RULES_SUCCESS, { payload });

export const DELETE_TRADING_RULES_FAILURE = 'DELETE_TRADING_RULES_FAILURE';
export const deleteTradingRulesFailure = (payload) => action(DELETE_TRADING_RULES_FAILURE, { payload });

export const SUBMIT_TRADING_RULE = 'SUBMIT_TRADING_RULE';
export const submitTradingRule = (payload, onSuccess, onFail, onError) =>
  action(SUBMIT_TRADING_RULE, {
    payload,
    onSuccess,
    onFail,
    onError,
  });

export const SUBMIT_TRADING_RULES_SUCCESS = 'SUBMIT_TRADING_RULES_SUCCESS';
export const submitTradingRulesSuccess = (payload) => action(SUBMIT_TRADING_RULES_SUCCESS, { payload });

export const SUBMIT_TRADING_RULES_FAILURE = 'SUBMIT_TRADING_RULES_FAILURE';
export const submitTradingRulesFailure = (error) => action(SUBMIT_TRADING_RULES_FAILURE, { error });

export const LIMIT_ORDER_SELECT_CRYPTO = 'LIMIT_ORDER_SELECT_CRYPTO';
export const limitOrderSelectCrypto = (selectedCrypto) =>
  action(LIMIT_ORDER_SELECT_CRYPTO, { selectedCrypto });

export const LIMIT_ORDER_SEND = 'LIMIT_ORDER_SEND';
export const limitOrderSend = (selectedCrypto, isBuy) => action(LIMIT_ORDER_SEND, { selectedCrypto, isBuy });
