import styled from 'styled-components';
import { dimensions, devices } from '../../../styles/index';

// eslint-disable-next-line import/prefer-default-export
export const LayoutRegularWrapper = styled.div`
  max-width: ${dimensions.maxContentWidth}px;
  width: 100%;
  margin: 0 auto;
  padding-left: ${dimensions.standardPaddings}px;
  padding-right: ${dimensions.standardPaddings}px;

  @media${devices.maxTablet} {
    padding: ${dimensions.regularMobilePadding}px;
  }

  ${({ withMobileMenu }: { withMobileMenu: boolean }) =>
    withMobileMenu &&
    `
    @media ${devices.maxTablet} {
      margin-bottom: ${dimensions.mobileMenuTablet}px;
    }
    @media ${devices.maxMobileL} {
      margin-bottom: ${dimensions.mobileMenu}px;
    }
    @media ${devices.maxMobileS} {
      margin-bottom: 0;
    }
  `}
`;
