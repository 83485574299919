import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { FETCH_MARKET_OVERVIEW_PAGE_SIZE } from 'common/const/assets';
import {
  onMarketOverviewUnmount,
  setMarketOverviewCategory,
  setMarketOverviewOrderBy,
  setMarketOverviewSearchText,
} from '../filters/actions';
import { MARKET_OVERVIEW_SLICE_NAME } from 'common/const/slices';
import { initialState } from './initialState';
import { CHANGE_ACCOUNT } from 'store/actions/auth';
import { loadMarketOverviewAssets, loadMarketOverviewAssetsSuccess } from '../common/actions';

const marketOverviewSlice = createSlice({
  name: MARKET_OVERVIEW_SLICE_NAME,
  initialState,
  reducers: {
    loadMarketOverviewAssetsError(state) {
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loadMarketOverviewAssets, (state, { payload }) => {
        state.isLoading = true;
        if (payload?.forceReload) {
          // Reset the slice (used for time period changes on market page)
          state.isFullyLoaded = false;
          state.reference = undefined;
        }
      })
      .addCase(loadMarketOverviewAssetsSuccess, (state, { payload }) => {
        const totalLength = payload.assets.length;
        const lastElement = payload.assets.pop();

        state.isLoading = false;
        state.isFullyLoaded = totalLength < FETCH_MARKET_OVERVIEW_PAGE_SIZE;
        state.reference = lastElement?.reference;
      })
      .addMatcher(
        (action) => action.type === onMarketOverviewUnmount.type || action.type === CHANGE_ACCOUNT.REQUEST,
        (state) => {
          state.isFullyLoaded = false;
          state.isLoading = false;
          state.reference = undefined;
        },
      )
      .addMatcher(
        isAnyOf(setMarketOverviewOrderBy, setMarketOverviewSearchText, setMarketOverviewCategory),
        (state) => {
          // We need to reset the slice so the next load will get first page records.
          state.isFullyLoaded = false;
          state.reference = undefined;
        },
      );
  },
});

export default marketOverviewSlice;
