/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-template */
/* eslint-disable no-case-declarations */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { ActionType } from 'safetypings';
import * as actions from 'store/actions/assets';
import { LOGOUT_USER } from 'store/actions/auth';
import { portfolio, SELECT_TIME_PERIOD, selectTimePeriod } from '../actions/currency';
import { toggleStocksEnabled } from '../actions/environment';
import { combineReducers } from 'redux';
import assetsSlice from 'store/slices/assets';
import { MarketOverviewState } from 'store/types/assets';

const initialState: MarketOverviewState = {
  costTransparency: null,
  isCostTransparencyLoading: false,
};

const marketOverview = (
  state = initialState,
  action:
    | ActionType<typeof actions>
    | ActionType<typeof portfolio.success>
    | ActionType<typeof toggleStocksEnabled>
    | ActionType<typeof selectTimePeriod>,
): MarketOverviewState => {
  switch (action.type) {
    case actions.COST_TRANSPARENCY_GET:
      return { ...state, isCostTransparencyLoading: true };

    case actions.COST_TRANSPARENCY_GET_SUCCESS:
      return {
        ...state,
        costTransparency: {
          price: action.payload.price,
          completePrice: action.payload.costTransparency.completeAmount,
          provider: action.payload.costTransparency.marketMaker,
          buyAmount: action.payload.costTransparency.externalBuy,
          buyExternalFee: action.payload.costTransparency.externalBuy,
          buyThirdPartyFee: action.payload.costTransparency.externalBuyRv,
          yearlyAmount: action.payload.costTransparency.externalPa,
          yearlyExternalFee: action.payload.costTransparency.externalPaRv,
          yearlyThirdPartyFee: action.payload.costTransparency.externalPaRv,
          sellAmount: action.payload.costTransparency.externalPaRv,
          sellExternalFee: action.payload.costTransparency.externalPaRv,
          sellThirdPartyFee: action.payload.costTransparency.externalPaRv,
          totalCostFirstYear: action.payload.costTransparency.externalPaRv,
          totalCostFirstYearPercent: action.payload.costTransparency.externalPaRv,
          totalCostPerYear: action.payload.costTransparency.externalPaRv,
          totalCostPerYearPercent: action.payload.costTransparency.externalPaRv,
          totalCostSellingYear: action.payload.costTransparency.externalPaRv,
          totalCostSellingYearPercent: action.payload.costTransparency.externalPaRv,
          sumCostRv: action.payload.costTransparency.sumCostRv,
          relativeSumCostRv: action.payload.costTransparency.relativeSumCostRv,
          externalBuy: action.payload.costTransparency.externalBuy,
          externalPa: action.payload.costTransparency.externalPa,
          externalSell: action.payload.costTransparency.externalSell,
          relativeSumBuyRv: action.payload.costTransparency.relativeSumBuyRv,
          relativeSumPaRv: action.payload.costTransparency.relativeSumPaRv,
          externalSellRv: action.payload.costTransparency.externalSellRv,
          feeBuy: action.payload.costTransparency.feeBuy,
          feeSell: action.payload.costTransparency.feeSell,
        },
        isCostTransparencyLoading: false,
      };

    case actions.COST_TRANSPARENCY_GET_FAILURE:
      return { ...state, isCostTransparencyLoading: false };

    case LOGOUT_USER:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default combineReducers({ marketOverview, entities: assetsSlice });
