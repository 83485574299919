import * as Sentry from '@sentry/react';
import { all, call, fork, race, take } from 'redux-saga/effects';
import auth from 'store/sagas/auth';
import analytics from 'store/sagas/analytics';
import currency from 'store/sagas/currency';
import orders from 'store/sagas/orders';
import navigation, { changeScreenOnce } from 'store/sagas/navigation';
import money from 'store/sagas/money';
import referral from 'store/sagas/referral';
import forms from 'store/sagas/forms';
import status from 'store/sagas/status';
import misc from 'store/sagas/misc';
import formsKyc from 'store/sagas/formsKyc';
import { trackingEventHandler, trackingSystem } from 'store/sagas/tracking';
import * as authActions from 'store/actions/auth';
import * as statusActions from 'store/actions/status';

function* generatorStarter() {
  const alwaysRunningTasks = all([
    fork(trackingSystem),
    fork(trackingEventHandler),
    fork(auth),
    fork(navigation),
    fork(analytics),
    fork(forms),
    fork(misc),
    fork(status),
  ]);

  yield alwaysRunningTasks;

  while (true) {
    // wait for user to log in before starting
    yield take(authActions.TOKEN_VALIDATION.SUCCESS);
    yield race({
      // start tasks
      tasks: all([
        call(currency),
        call(orders),
        call(money),
        call(formsKyc),
        call(changeScreenOnce),
        call(referral),
      ]),
      // restart tasks on logout
      logout: take(authActions.LOGOUT_USER_SUCCESS),
      maintain: take(statusActions.BISON_MAINTAINANCE_STARTED),
    });
  }
}

function* rootSaga() {
  try {
    yield call(generatorStarter);
  } catch (error: any) {
    Sentry.captureException(error);

    //   yield put(
    //     notificationBannerActions.notifyError({
    //       message: (error && error.message) || I18n.t('error.genericNetwork'),
    //     }),
    //   );
  }
}

export default rootSaga;
