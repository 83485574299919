import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { State } from 'store/types/store';
import { NewsState } from 'store/types/news';

export const getNewsState = (state: State): NewsState => state.news;

export const getNewsPerCrypto = createCachedSelector(
  getNewsState,
  (_: any, crypto: string) => crypto,
  (news: NewsState, crypto: string) => news.data[crypto],
)((_: any, crypto: string) => crypto);

export const getIsFetchingNewsFeedForCrypto = createCachedSelector(
  getNewsState,
  (_: any, crypto: string) => crypto,
  (state: NewsState, crypto: string) => state.data[crypto]?.isFetching,
)((_: any, crypto: string) => crypto);

export const getNewsOfLanguage = (state: State): State['news']['lang'] => state.news.lang;
export const getLangOfNews = createSelector(getNewsState, (news: NewsState) => news.lang);

export const getNewsFeedIsEmpty = createCachedSelector(
  getNewsState,
  (_: any, crypto: string) => crypto,
  (state: NewsState, crypto: string) =>
    !state.data[crypto]?.isFetching && state.data[crypto]?.data.length === 0,
)((_: any, crypto: string) => crypto);

export const getNewsFeedIsLoading = createCachedSelector(
  getNewsState,
  (_: any, crypto: string) => crypto,
  (state: NewsState, crypto: string) =>
    state.data[crypto]?.isFetching && state.data[crypto]?.data.length === 0,
)((_: any, crypto: string) => crypto);
