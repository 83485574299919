import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'common/components/Button';
import CheckboxInput from '../../CheckboxInput';
import { CheckboxTextWrapper, CustomLink, Header, MainText } from '../Modal';
import ModalTemplate from '../index';
import TncExitIntent from './ExitIntent';
import { Terms, TermsAndConditionsType } from 'types/termsAndConditions';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

type Props = {
  terms: Terms;
  skipCheckbox2: boolean;
  type: TermsAndConditionsType;
  onAccept: (type: TermsAndConditionsType) => void;
  onCancel: (type: TermsAndConditionsType) => void;
};

const getValidationSchema = (skipCheckbox2: boolean) =>
  Yup.object().shape({
    checkbox1: Yup.boolean().required().oneOf([true]),
    checkbox2: skipCheckbox2 ? Yup.boolean() : Yup.boolean().required().oneOf([true]),
  });

const TermsAndConditionsModal = ({ terms, skipCheckbox2, type, onAccept, onCancel }: Props): ReactElement => {
  const { t } = useTranslation();

  // States for displaying tnc and exit modals.
  const [showTncModal, setShowTncModal] = useState(false);
  const [showExitIntent, setShowExitIntent] = useState(false);

  useEffect(() => {
    // Open modal on init
    setShowTncModal(true);
  }, []);

  return (
    <>
      <TncExitIntent
        isOpened={showExitIntent}
        onClose={() => {
          if (onCancel) onCancel(type);

          // Close modal
          setShowExitIntent(false);
        }}
        onBackToPopUp={() => {
          setShowTncModal(true);
          setShowExitIntent(false);
        }}
      />
      <ModalTemplate
        defaultOpen={showTncModal}
        onCancel={() => {
          setShowExitIntent(true);
          setShowTncModal(false);
        }}
        header={<Header>{t('updatedTermsAndConditions.title')}</Header>}
        actions={<></>}
      >
        <MainText>{t('updatedTermsAndConditions.header')}</MainText>
        <MainText>{t('updatedTermsAndConditions.mainText')}</MainText>
        <Formik
          initialValues={{ checkbox1: false, checkbox2: false }}
          validationSchema={getValidationSchema(skipCheckbox2)}
          onSubmit={() => {
            // Will trigger only after accepting all checkboxes.
            if (onAccept) onAccept(type);

            // Close modal
            setShowTncModal(false);
          }}
        >
          {({ isValid, errors, handleSubmit }) => (
            <Form>
              <CheckboxInput name="checkbox1" hideError>
                <CheckboxTextWrapper error={errors.checkbox1}>
                  {t('updatedTermsAndConditions.contentTerms.a')} &nbsp;
                  {!skipCheckbox2 ? (
                    <>
                      <CustomLink target="_blank" href={terms.termsUrl}>
                        {t('updatedTermsAndConditions.termsLinks.a')}
                      </CustomLink>
                      &nbsp;
                      {t('updatedTermsAndConditions.contentTerms.b')}
                      &nbsp;
                      <CustomLink target="_blank" href={terms.termsUrl}>
                        {t('updatedTermsAndConditions.termsLinks.b')}
                      </CustomLink>
                    </>
                  ) : (
                    <CustomLink target="_blank" href={terms.termsUrl}>
                      {t('updatedTermsAndConditions.termsLinks.d')}
                    </CustomLink>
                  )}
                  {t('updatedTermsAndConditions.contentTerms.c')}
                </CheckboxTextWrapper>
              </CheckboxInput>
              {!skipCheckbox2 && (
                <CheckboxInput name="checkbox2" hideError>
                  <CheckboxTextWrapper error={errors.checkbox2}>
                    {t('updatedTermsAndConditions.contentTerms2')}
                  </CheckboxTextWrapper>
                </CheckboxInput>
              )}
              <Button
                disabled={!isValid}
                onClick={handleSubmit}
                title={t('updatedTermsAndConditions.agree')}
                isFullWidth
                size="modal"
              />
            </Form>
          )}
        </Formik>
      </ModalTemplate>
    </>
  );
};

export default TermsAndConditionsModal;
