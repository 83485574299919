import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

export const StyledSvg = styled<{ $width?: number; $height?: number }>(ReactSVG)`
  span {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: ${({ $width }) => ($width ? `${$width}px` : '32px')};
      height: ${({ $height }) => ($height ? `${$height}px` : '32px')};
    }
  }
`;
