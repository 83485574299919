import { Theme } from 'types/theme';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { devices, dimensions } from 'common/styles';
import _Icon from 'common/components/Icon';
import { LayoutRegularWrapper } from 'common/components/LayoutContainer/LayoutRegularWrapper/LayoutRegularWrapper';
import { colors } from 'common/utils/theme/colors';
import { Dropdown as _Dropdown } from '../../lib/semantic-ui';
import _IconButton from '../IconButton/IconButton';

export const HeaderContainer = styled.div`
  position: relative;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.primary};
`;

export const TopBarContainer = styled.div`
  z-index: 1000;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.secondary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  height: ${dimensions.topBarHeight}px;
  margin: 0 auto;
  padding-left: ${dimensions.standardPaddings}px;
  padding-right: ${dimensions.standardPaddings}px;
  ${({ isBannerDisabled }: { isBannerDisabled: boolean }) =>
    isBannerDisabled && `margin-bottom: ${dimensions.paperTradingBannerHeight}px;`}
  position: relative;
  @media ${devices.maxTablet} {
    padding: 0;
  }
  @media ${devices.maxMobileL} {
    height: 55px;
  }
  @media ${devices.maxMobileS} {
    height: 45px;
  }
`;

export const BisonTextLogo = styled.img.attrs(({ theme }: { theme: Theme }) => ({
  src: theme.image.bisonLogoText,
}))`
  width: auto;
  height: 16px;
`;

export const NavItems = styled.div`
  display: none;
  @media ${devices.tablet} {
    height: 100%;
    display: flex;
  }
`;

export const NavItem = styled(NavLink).attrs({ className: 'large-text' })`
  display: flex;
  align-items: center;
  height: 100%;
  border: none;
  box-sizing: unset;
  cursor: pointer;
  padding-left: ${dimensions.mediumPadding}px;
  padding-right: ${dimensions.mediumPadding}px;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary} !important;
  &:not(.active):hover {
    color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary} !important;
    text-shadow: 0px 0px 0.5px black;
  }
  &.active {
    color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary} !important;
    border-bottom: 3px solid ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
    text-shadow: 0px 0px 0.5px black;
  }
`;

export const BisonLogo = styled(NavLink)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const BisonImg = styled.img.attrs(({ theme }: { theme: Theme }) => ({
  src: theme.image.bisonLogo,
}))`
  height: 40px;
  margin-right: ${dimensions.tinyPadding}px;
  width: 41px;
`;

export const UnreadMessagesIcon = styled.span.attrs({ className: 'xxsmall-text' })`
  opacity: ${({ theme, unReadMessages }: { theme: Theme; unReadMessages: number }) =>
    unReadMessages > 0 ? theme.opacity.none : theme.opacity.full};
  display: block;
  text-align: center;
  border-radius: 50%;
  color: ${colors.pureWhite};
  position: absolute;
  font-weight: 700;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.critical};
  ${({ mobile }: { mobile: boolean }) =>
    mobile
      ? `
    width: 17px;
    height: 17px;
    line-height: ${({ theme }: { theme: Theme }) => theme.typography.text.xsmall.lineHeight};
    overflow: hidden;
    right: 10px;
    bottom: 6px;
    
      @media ${devices.mobileM} {
        right: 9px;
        bottom: 12px;
      }
      @media ${devices.mobileL} {
        right: 13px;
        bottom: 16px;
      }
  `
      : `
   bottom: 16px;
    right: -6px;
    width: 15px;
    height: 15px;
    line-height: ${({ theme }: { theme: Theme }) => theme.typography.text.xxsmall.lineHeight};
    font-size: ${({ theme }: { theme: Theme }) => theme.typography.text.xxsmall.fontSize};
  `}
`;

export const InboxButton = styled(_IconButton)``;
export const InboxMobileButton = styled.div`
  height: 100%;
  margin-right: 16px;
  & > button {
    height: 100%;
    padding: 0 20px;
    position: relative;
    @media ${devices.maxMobileL} {
      padding: 0 16px;
    }
  }
  @media ${devices.maxMobileL} {
    margin-right: 0;
  }
`;
export const BackButton = styled.div`
  height: 100%;
  & > button {
    height: 100%;
    padding: 0 20px;
    @media ${devices.maxMobileL} {
      padding: 0 16px;
    }
  }
  & + h2 {
    margin-left: 0;
  }
`;

export const BurgerButton = styled(BackButton)`
  display: none;
  & + h2 {
    margin-left: 16px;
  }
  @media ${devices.maxTablet} {
    display: block;
    & + h2 {
      margin-left: 0;
    }
  }
`;

export const SettingsIcon = styled(_Icon)`
  z-index: 'inherit';
  position: relative;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
  cursor: pointer;
`;

export const MobileTitle = styled.h2`
  white-space: nowrap;
  margin-right: auto;
  margin-left: 36px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${devices.maxMobileL} {
    font-size: 20px;
    line-height: 24px;
    margin-left: 16px;
  }
`;

export const Icons = styled.div`
  display: flex;
  align-items: baseline;
  height: 100%;
  align-items: center;
  gap: 32px;

  & > * {
    position: relative;
  }
`;

export const IconWrapper = styled.div`
  border-bottom: 3px solid
    ${({ theme, isActive }: { theme: Theme; isActive: boolean }) =>
      isActive ? theme.color.foreground.focused : 'transparent'};
  height: 100%;
  display: flex;
  align-items: center;
`;

export const InboxIcon = styled(_Icon)`
  &:hover {
    opacity: ${({ theme }: { theme: Theme }) => theme.opacity.hover};
  }
`;

export const CustomTopBarWrapper = styled(LayoutRegularWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-width: ${dimensions.maxContentWidth}px;
  z-index: 1001;
`;

export const CustomLayoutWrapper = styled(LayoutRegularWrapper)`
  padding-top: 12px;
  background: transparent;
  @media ${devices.tablet} {
    padding-top: 24px;
  }
`;
