/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Header } from 'common/lib/semantic-ui';
import Button from 'common/components/Button';
import { PhoneNumberInput } from 'common/components/PhoneNumberInput';
import TwoFAModal from 'common/components/TwoFaFlow/2FAModal';
import SuccessResultModal from 'common/components/TwoFaFlow/ResultModal/SuccessResultModal';
import { validateInput } from 'common/components/TwoFaFlow/2FAModal/utils';
import { formatMobileNumber } from 'common/utils/math';
import { linkToSupportForm } from 'common/utils/mailAndHelpCenter';
import ModalTemplate from 'common/components/Modal';
import * as formActions from 'store/actions/forms';
import { useTwoFa } from 'common/hooks/useTwoFa';
import { User } from 'types/state';
import { BoldText, CurrentNumber, Details, Form, Line, LinkButton, ModalFooter, NoAccess } from '../styles';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import { useDispatch } from 'react-redux';

type ChangeMobileNumberProps = {
  user: User;
  startAssignMobile: typeof formActions.startAssignMobile;
  startDeleteMobile: typeof formActions.startDeleteMobile;
  confirmAssignMobile: typeof formActions.confirmAssignMobile;
  confirmDeleteMobile: typeof formActions.confirmDeleteMobile;
  mobileChangeCancel: typeof formActions.mobileChangeCancel;
  confirmAssignCallback: () => void;
  open: boolean;
  onClose: () => void;
  modalId?: string;
};

const initialValues = (user: User) => {
  const initialCountry = user?.address?.country?.toLowerCase() || 'de';
  return { initialCountry };
};

const ChangeMobileNumber = ({
  user,
  startDeleteMobile,
  startAssignMobile,
  confirmDeleteMobile,
  confirmAssignMobile,
  mobileChangeCancel,
  confirmAssignCallback,
  open,
  onClose,
  modalId,
}: ChangeMobileNumberProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showDeleteStep, setShowDeleteStep] = useState(false);
  const [showCustomerSupport, setShowCustomerSupport] = useState(false);
  const [is2faLoading, setIs2faLoading] = useState(false);
  const [showTwoFaSuccess, setShowTwoFaSuccess] = useState(false);
  const {
    twoFaValue: [twoFaValue, setTwoFaValue],
    twoFaError: [twoFaError, setTwoFaError],
    twoFaState: [showCodeInput, setShowCodeInput],
  } = useTwoFa();

  useEffect(() => {
    if (!showCodeInput) setTwoFaValue('');
  }, [showCodeInput]);

  const { initialCountry } = initialValues(user);
  const { mobileNumber } = user;
  const hasMobileNumber = !!mobileNumber;
  const encodedLinkToHelpCenter = linkToSupportForm(user);

  const removeSpacesFromMobileNumber = (values: { mobileNumber: string }) => {
    values.mobileNumber = values.mobileNumber.replace(/\s/g, '');
  };

  const concatPlusToMobileNumber = (values: { mobileNumber: string }) => {
    if (values.mobileNumber.charAt(0) !== '+') {
      values.mobileNumber = '+' + values.mobileNumber;
    }
  };

  const closeMobileChange = () => {
    setShowCodeInput(false);
    setShowDeleteStep(false);
    setShowCustomerSupport(false);
    setShowTwoFaSuccess(false);
    setTwoFaError(undefined);
    setTwoFaValue('');
    onClose();
  };

  const cancel = () => {
    mobileChangeCancel();
    closeMobileChange();
  };

  const proceedToAssign = (values: { mobileNumber: string }, actions: any) => {
    mobileChangeCancel();
    removeSpacesFromMobileNumber(values);
    concatPlusToMobileNumber(values);
    startAssignMobile(values, {
      ...actions,
      continue: () => setShowCodeInput(true),
    });
  };

  const confirmAssign = (values: any, actions: any) => {
    if (!validateInput(twoFaValue)) {
      setTwoFaError(t('settings.taxExemption.twoFaModal.error'));
      return;
    }
    setIs2faLoading(true);
    confirmAssignMobile(
      { ...values, code2fa: twoFaValue },
      {
        ...actions,
        continue: () => {
          setShowTwoFaSuccess(true);
          setShowCodeInput(false);
        },
        wrongTwoFaCode: () => {
          dispatch(
            notificationBannerActions.notifyError({
              message: t('common.twofa.wrongSecurityCode'),
            }),
          );
          cancel();
        },
        finished: () => {
          setIs2faLoading(false);
          confirmAssignCallback();
        },
      },
    );
  };

  const proceedToDelete = (values: any, actions: any, isResendCode = false) => {
    mobileChangeCancel();
    startDeleteMobile(
      values,
      {
        ...actions,
        continue: () => setShowCodeInput(true),
        finished: () => null,
      },
      isResendCode,
    );
  };

  const confirmDelete = (values: any, actions: any) => {
    if (!validateInput(twoFaValue)) {
      setTwoFaError(t('settings.taxExemption.twoFaModal.error'));
      return;
    }
    setIs2faLoading(true);
    confirmDeleteMobile(
      { ...values, code2fa: twoFaValue },
      {
        ...actions,
        continue: () => {
          setShowCodeInput(false);
          setShowTwoFaSuccess(true);
        },
        wrongTwoFaCode: () => {
          dispatch(
            notificationBannerActions.notifyError({
              message: t('common.twofa.wrongSecurityCode'),
            }),
          );
          cancel();
        },
        finished: () => {
          setIs2faLoading(false);
        },
      },
    );
  };

  if (showTwoFaSuccess)
    return (
      <SuccessResultModal
        open={showTwoFaSuccess}
        onClose={() => {
          cancel();
        }}
      />
    );

  if (hasMobileNumber) {
    if (showCustomerSupport) {
      return (
        <ModalTemplate
          modalId={modalId}
          defaultOpen={open}
          onCancel={cancel}
          actions={
            <ModalFooter>
              <Button
                inverted
                onClick={() => setShowCustomerSupport(false)}
                title={t('common.back')}
                size="large"
              />
              <Button
                href={encodedLinkToHelpCenter}
                target={'_blank'}
                title={t('settings.mobileNumberChange.supportScreen.contactSupport')}
                size="large"
              />
            </ModalFooter>
          }
          header={<Header as="h3" content={t('settings.personalInfo.changeMobileNumber')} />}
        >
          <Details>
            <Line>{t('settings.mobileNumberChange.supportScreen.a')}</Line>
            <br />
            <Line>{t('settings.mobileNumberChange.supportScreen.b')}</Line>
          </Details>
        </ModalTemplate>
      );
    }

    if (showDeleteStep) {
      return (
        <Formik onSubmit={proceedToDelete}>
          {({ handleSubmit, isSubmitting, values, setSubmitting }) => (
            <>
              <ModalTemplate
                defaultOpen={open}
                onCancel={cancel}
                actions={
                  <ModalFooter>
                    <Button
                      inverted
                      onClick={() => setShowDeleteStep(false)}
                      title={t('common.cancel')}
                      size="large"
                    />
                    <Button
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      inProgress={isSubmitting}
                      title={t('settings.mobileNumberChange.unpairNumber.sendCode')}
                      size="large"
                    />
                  </ModalFooter>
                }
                header={<Header as="h3" content={t('settings.personalInfo.changeMobileNumber')} />}
              >
                <Details>
                  <Line>
                    {t('settings.mobileNumberChange.unpairNumber.a')}
                    <BoldText>{t('settings.mobileNumberChange.unpairNumber.b')}</BoldText>
                    {t('settings.mobileNumberChange.unpairNumber.c', {
                      currentMobileNumber: formatMobileNumber(mobileNumber),
                    })}
                  </Line>
                </Details>
              </ModalTemplate>
              <TwoFAModal
                open={showCodeInput}
                resendSms={() => proceedToDelete(values, { setSubmitting }, true)}
                buttonTextOrAction={
                  <ModalFooter>
                    <Button title={t('common.cancel')} onClick={cancel} inverted size="large" />
                    <Button
                      title={t('common.update')}
                      onClick={() => confirmDelete(values, { setSubmitting })}
                      disabled={!twoFaValue || isSubmitting}
                      size="large"
                      inProgress={is2faLoading}
                    />
                  </ModalFooter>
                }
                handleSendCode={() => confirmDelete(values, { setSubmitting })}
                error={twoFaError}
                setError={setTwoFaError}
                handleClose={cancel}
                inputValue={twoFaValue}
                setInputValue={setTwoFaValue}
                mainTextOrElement={t('settings.personalInfo.smsCodePopupDescription')}
                customTitle={t('settings.personalInfo.changeMobileNumber')}
              />
            </>
          )}
        </Formik>
      );
    }

    return (
      <Formik initialValues={{ mobileNumber: formatMobileNumber(mobileNumber) }}>
        {() => (
          <ModalTemplate
            defaultOpen={open}
            onCancel={cancel}
            actions={
              <ModalFooter>
                <Button title={t('common.cancel')} onClick={cancel} inverted size="large" />
                <Button
                  onClick={() => setShowDeleteStep(true)}
                  title={t('settings.mobileNumberChange.startMobileChange')}
                  size="large"
                />
              </ModalFooter>
            }
            header={<Header as="h3" content={t('settings.personalInfo.changeMobileNumber')} />}
          >
            <Details>
              <Line>
                {t('settings.mobileNumberChange.changeMobileInfo.a')}
                <BoldText>{t('settings.mobileNumberChange.changeMobileInfo.b')}</BoldText>:<br />
                <br />
              </Line>
              <Line>
                <BoldText>{t('settings.mobileNumberChange.changeMobileInfo.c')}</BoldText>
                {t('settings.mobileNumberChange.changeMobileInfo.d')}
              </Line>
              <Line>
                <BoldText>{t('settings.mobileNumberChange.changeMobileInfo.e')}</BoldText>
                {t('settings.mobileNumberChange.changeMobileInfo.f')}
              </Line>
            </Details>
            <Form>
              <CurrentNumber>
                <PhoneNumberInput name="mobileNumber" disabled />
              </CurrentNumber>
            </Form>
            <NoAccess>
              <LinkButton onClick={() => setShowCustomerSupport(true)}>
                {t('settings.mobileNumberChange.noMobileNumberAccessMsg')}
              </LinkButton>
            </NoAccess>
          </ModalTemplate>
        )}
      </Formik>
    );
  }

  // If the user doesn't have a number set, just display the input field
  return (
    <Formik
      validationSchema={Yup.object().shape({
        mobileNumber: Yup.string().required(t('settings.mobileNumberChange.mobileNumberRequired')),
      })}
      onSubmit={proceedToAssign}
      initialValues={{ mobileNumber: '' }}
    >
      {({ handleSubmit, isSubmitting, isValid, values, setSubmitting }) => (
        <>
          <ModalTemplate
            defaultOpen={open}
            onCancel={cancel}
            actions={
              <ModalFooter>
                <Button title={t('common.cancel')} onClick={cancel} inverted size="large" />
                <Button
                  title={t('common.update')}
                  disabled={isSubmitting || !isValid}
                  inProgress={isSubmitting}
                  onClick={handleSubmit}
                  size="large"
                />
              </ModalFooter>
            }
            header={<Header as="h3" content={t('settings.personalInfo.changeMobileNumber')} />}
          >
            <Details>
              <Line>
                {t('settings.mobileNumberChange.assignMobileInfo.a')}
                <BoldText>{t('settings.mobileNumberChange.assignMobileInfo.b')}</BoldText>
                {t('settings.mobileNumberChange.assignMobileInfo.c')}
              </Line>
            </Details>
            <Form>
              <CurrentNumber>
                <PhoneNumberInput
                  name="mobileNumber"
                  keyboardType="number-pad"
                  initialCountry={initialCountry}
                  autoFormat={false}
                />
              </CurrentNumber>
            </Form>
          </ModalTemplate>
          <TwoFAModal
            open={showCodeInput}
            resendSms={() => {
              handleSubmit();
              dispatch(
                notificationBannerActions.notifySuccess({
                  message: t('common.twofa.resentCode'),
                }),
              );
            }}
            buttonTextOrAction={
              <ModalFooter>
                <Button title={t('common.cancel')} onClick={cancel} inverted size="large" />
                <Button
                  title={t('common.update')}
                  onClick={() => confirmAssign(values, { setSubmitting })}
                  disabled={!twoFaValue || isSubmitting}
                  size="large"
                  inProgress={is2faLoading}
                />
              </ModalFooter>
            }
            handleSendCode={() => confirmAssign(values, { setSubmitting })}
            error={twoFaError}
            setError={setTwoFaError}
            handleClose={cancel}
            inputValue={twoFaValue}
            setInputValue={setTwoFaValue}
            mainTextOrElement={t('settings.personalInfo.smsCodePopupDescription')}
            customTitle={t('settings.personalInfo.changeMobileNumber')}
          />
        </>
      )}
    </Formik>
  );
};

ChangeMobileNumber.defaultProps = {
  modalId: undefined,
  confirmAssignCallback: () => null,
};

export default ChangeMobileNumber;
