import React, { ReactElement, useContext, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Formik, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { OrderProps } from 'types/tradingRules';
import { stopOrderSend } from 'store/actions/stopOrders';
import { StopOrderCreationContext } from 'scenes/StopOrderCreation/StopOrderCreationContext';
import TypeSwitch from 'common/components/OrderTypeSelector/TypeSwitch';
import { Fiat } from 'common/const';
import { getInitialValues } from 'common/utils/tradingRulesUtils';
import TradingForm from 'common/components/TradingRulesCommonComponents/TradingForm/TradingForm';
import { State } from 'store/types/store';
import { getSelectedCryptoCurrency, getSelectedFiatCurrency } from 'store/selectors/currency';
import { getAvailableCrypto, getPortfolio } from 'store/slices/portfolio/selectors';
import useOrderTypes from 'common/hooks/useTradingTypes';
import { getCryptoPrecision } from 'store/slices/assets/selectors';
import Button from '../Button';
import StopOrderSummaryText from '../LayoutContainer/LayourNarrow/SummaryText/StopOrderSummaryText/StopOrderSummaryText';
import * as formatting from '../../utils/formatting';
import InfoButton from '../InfoButton';
import { SOFormikBag } from './types';
import {
  ButtonContainer,
  ButtonWrapper,
  CourseAmountText,
  CourseText,
  FormWrapper,
  Head,
  MainContentWrapper,
  Separator,
  SummaryWrapper,
  Text,
  TypeSwitchWrapper,
} from '../TradingRulesCommonComponents/TradingRulesCreationStyles';
import { getBuyLabels, getSellLabels, getValidationSchema } from './helpers';
import withRouter from 'common/hoc/WithRouter';
import { WithRouterType } from 'types/withRouter';
import { addDays } from 'common/utils/date';
import { getPriceByCode } from 'store/slices/prices/selectors';
import { LoadingWrapper } from '../Charts/MarketCharts/styles';
import { Loader } from 'semantic-ui-react';
import { useParams } from 'react-router';
import useTradingManagerCreationPriceSubscription from 'common/hooks/useTradingManagerCreationPriceSubscription';

const StopOrderForm = ({
  selectedCrypto,
  portfolio,
  cryptoInstruments,
  selectedFiatCurrency,
  isBuy,
  navigate,
  location,
  priceData,
  cryptoPrecision,
}: OrderProps & WithRouterType): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams();

  const buyPrice = priceData?.buyPrice ?? 0;
  const sellPrice = priceData?.sellPrice ?? 0;

  const price = isBuy ? buyPrice : sellPrice;

  useTradingManagerCreationPriceSubscription(params.currency ?? '');

  const { currencyLabels, labelTexts } = isBuy
    ? getBuyLabels(selectedFiatCurrency, selectedCrypto)
    : getSellLabels(selectedFiatCurrency, selectedCrypto);

  const { setStopPrice, setCryptoAmount, setSpendAmount, setIsConfirm, setCurrencyPrecision } =
    useContext(StopOrderCreationContext);

  const setValues = (values: FormikValues) => {
    const crypto = values.cryptoAmount as unknown as string;
    setCryptoAmount(crypto);
    const stopPrice: string = values.stopPrice as unknown as string;
    setStopPrice(stopPrice);
    const spendAmount = values.spendAmount as unknown as string;
    setSpendAmount(spendAmount);
    setIsConfirm(true);
  };

  const handleSubmit = (values: FormikValues, { setErrors }: SOFormikBag): void => {
    const requiredFieldsErrors = {
      stopPrice: values.stopPrice === '' ? t('automation.fieldErrors.web_required') : undefined,
      cryptoAmount: values.cryptoAmount === '' ? t('automation.fieldErrors.web_required') : undefined,
      spendAmount: values.spendAmount === '' ? t('automation.fieldErrors.web_required') : undefined,
    };

    if (Object.values(requiredFieldsErrors).some((err) => err !== undefined)) {
      setErrors(requiredFieldsErrors);
      return;
    }

    setValues(values);
  };

  const handleCancel = () => navigate(-1);

  const initialValues = getInitialValues(price, 'stopPrice');

  const isPrevScreenIsBuySell = location?.state?.isFromBuySellScreen;

  const orderTypes = useOrderTypes({ isBuy, selectedCrypto });

  const currencyPrecision = formatting.calculateAdaptivePrecision(price);

  useEffect(() => {
    setCurrencyPrecision(currencyPrecision);
  }, []);

  // If there is no priceData yet then its still fetching so we need to display loading indicator
  const isLoading = !priceData;

  if (isLoading)
    return (
      <LoadingWrapper $loading={true}>
        <Loader active />
      </LoadingWrapper>
    );

  return (
    <MainContentWrapper>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setErrors }) => {
            handleSubmit(values, { setErrors });
            dispatch(stopOrderSend(selectedCrypto, isBuy));
          }}
          validateOnMount={false}
          validationSchema={getValidationSchema(selectedCrypto, portfolio.cash, cryptoInstruments, isBuy)}
          validateOnBlur
          validateOnChange={false}
        >
          {(formik) => (
            <>
              <Head>
                <div>
                  <Text>
                    {isBuy ? t('automation.web_creationBuyPrice') : t('automation.web_creationSellPrice')}
                  </Text>
                  <CourseText>
                    1 {selectedCrypto.toUpperCase()} ={' '}
                    <CourseAmountText>
                      {' '}
                      {formatting.fiat(price, {
                        adaptivePrecision: true,
                        symbol: true,
                      })}
                    </CourseAmountText>
                  </CourseText>
                </div>
                <InfoButton
                  iconSizeValue="regular"
                  markdown={t('stopOrders.info.web_content')}
                  header={t('stopOrders.info.web_title')}
                />
              </Head>
              {isPrevScreenIsBuySell ? (
                <TypeSwitchWrapper>
                  <TypeSwitch typeValues={orderTypes} />
                </TypeSwitchWrapper>
              ) : (
                <Separator />
              )}
              <TradingForm
                formik={formik}
                currencyLabel={currencyLabels}
                labelTexts={labelTexts}
                portfolioCash={portfolio.cash}
                cryptoInstruments={cryptoInstruments}
                isBuy={isBuy}
                buyPrice={buyPrice}
                sellPrice={sellPrice}
                selectedCrypto={selectedCrypto}
                currencyPrecision={currencyPrecision}
                specificKey="stopPrice"
                cryptoPrecision={cryptoPrecision}
              />
              <SummaryWrapper>
                <StopOrderSummaryText
                  price={formatting.fiat(formik.values.stopPrice, { customPrecision: currencyPrecision })}
                  amount={`${formatting.crypto(formik.values.cryptoAmount, cryptoPrecision)} ${
                    currencyLabels.cryptoAmount
                  }`}
                  euroTotal={formatting.fiat(formik.values.spendAmount)}
                  date={addDays(new Date(), 90).toString()}
                  isBuy={isBuy}
                />
              </SummaryWrapper>
              <ButtonContainer>
                <ButtonWrapper>
                  <Button title={t('common.cancel')} onClick={handleCancel} inverted size="large" />
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button
                    title={t('common.next')}
                    onClick={formik.handleSubmit}
                    disabled={Object.values(formik.values).some((value) => value === '') || !formik.isValid}
                    size="large"
                  />
                </ButtonWrapper>
              </ButtonContainer>
            </>
          )}
        </Formik>
      </FormWrapper>
    </MainContentWrapper>
  );
};

const mapStateToProps = (state: State, ownProps: WithRouterType) => {
  const selectedCrypto = getSelectedCryptoCurrency(state, ownProps.params);
  return {
    selectedCrypto,
    priceData: getPriceByCode(state, ownProps.params.currency ?? ''),
    portfolio: getPortfolio(state),
    cryptoInstruments: getAvailableCrypto(state),
    selectedFiatCurrency: getSelectedFiatCurrency(state) as Fiat,
    cryptoPrecision: getCryptoPrecision(state, selectedCrypto),
  };
};

export default withRouter(connect(mapStateToProps)(StopOrderForm));
