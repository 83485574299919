import { catchError, filter, map, mapTo, mergeMap, pluck, switchMap, take, tap } from 'rxjs/operators';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import * as api from 'common/api';
import { from, of } from 'rxjs';
import { isOfType, isPresent } from 'safetypings';
import I18n from 'i18next';
import { getAccessToken } from 'store/selectors/auth';
import {
  deleteTradingStopRulesFailure,
  deleteTradingStopRulesSuccess,
  fetchTradingStopRulesFailure,
  fetchTradingStopRulesSuccess,
  SUBMIT_TRADING_STOP_RULES_FAILURE,
  SUBMIT_TRADING_STOP_RULES_SUCCESS,
  FETCH_TRADING_STOP_RULES,
  submitTradingStopRulesFailure,
  submitTradingStopRulesSuccess,
  DELETE_TRADING_STOP_RULE,
  DELETE_TRADING_STOP_RULES_FAILURE,
  DELETE_TRADING_STOP_RULES_SUCCESS,
  SUBMIT_TRADING_STOP_RULE,
} from 'store/actions/stopOrders';
import { State } from 'store/types/store';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import { paths } from 'common/urls';
import { SubmitStopOrderFullPayload } from 'common/api-types';
import { handleAjaxError } from './auth';

export const fetchTradingStopRulesEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(FETCH_TRADING_STOP_RULES)),
    switchMap(() =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(api.fetchStopOrders(accessToken)).pipe(
            map(fetchTradingStopRulesSuccess),
            catchError(handleAjaxError(action$, fetchTradingStopRulesFailure)),
          ),
        ),
      ),
    ),
  );

export const deleteTradingStopRuleEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): void =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(DELETE_TRADING_STOP_RULE)),
    switchMap(({ navigate }) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      action$.pipe(
        filter(isOfType([DELETE_TRADING_STOP_RULES_SUCCESS, DELETE_TRADING_STOP_RULES_FAILURE])),
        take(1),
        switchMap((responseAction: { type: string }) =>
          responseAction.type === DELETE_TRADING_STOP_RULES_SUCCESS
            ? of(
                notificationBannerActions.notifySuccess({
                  message: I18n.t('stopOrders.deleteStopOrderSuccess'),
                }),
              ).pipe(
                tap(() => {
                  if (navigate) navigate(paths.LIMIT_ORDER);
                }),
              )
            : of(
                notificationBannerActions.notifyError({
                  message: I18n.t('stopOrders.deleteStopOrderError'),
                }),
              ).pipe(
                tap(() => {
                  if (navigate) navigate(paths.LIMIT_ORDER);
                }),
              ),
        ),
      ),
    ),
  );

export const deleteTradingStopRulesEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(DELETE_TRADING_STOP_RULE)),
    pluck('payload'),
    mergeMap((uniqueId: string) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          from(api.deleteStopOrder(accessToken, uniqueId)).pipe(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            mapTo(deleteTradingStopRulesSuccess(uniqueId)),
            catchError(handleAjaxError(action$, deleteTradingStopRulesFailure, uniqueId)),
            map(({ type, error }) => {
              if (
                type === SUBMIT_TRADING_STOP_RULES_FAILURE &&
                error?.code !== 'limitsubmit_err_limitreached'
              ) {
                return notificationBannerActions.notifyError({
                  message: I18n.t('automation.errors.generic'),
                  error,
                });
              }
              return { type, error };
            }),
          ),
        ),
      ),
    ),
  );

export const createTradingStopRuleEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(SUBMIT_TRADING_STOP_RULE)),
    map((props: SubmitStopOrderFullPayload) => ({
      payload: props.payload,
      onSuccess: props.onSuccess,
      onFail: props.onFail,
      onError: props.onError,
    })),
    switchMap(({ payload, onSuccess, onFail, onError }: SubmitStopOrderFullPayload) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(api.submitStopOrder(accessToken, payload)).pipe(
            map(submitTradingStopRulesSuccess),
            catchError(handleAjaxError(action$, submitTradingStopRulesFailure)),
            map(({ type, error }) => {
              if (
                type === SUBMIT_TRADING_STOP_RULES_FAILURE &&
                error?.errorCode !== 'stopsubmit_err_limitreached'
              )
                onError();

              return { type, error };
            }),
            tap(({ type, error }) => {
              if (type === SUBMIT_TRADING_STOP_RULES_SUCCESS) {
                onSuccess();
              } else if (
                type === SUBMIT_TRADING_STOP_RULES_FAILURE &&
                error?.errorCode === 'stopsubmit_err_limitreached'
              ) {
                onFail();
              }
            }),
          ),
        ),
      ),
    ),
  );
