import { dateFormatters, timeFormatters } from 'common/utils/formatting';
import { SecurityMarketStatus } from 'types/status';
import { TFunction } from 'react-i18next';

const getIcon = (isWeekend: boolean, isHoliday: boolean, isOutOfTradingHours: boolean) => {
  if (isWeekend) return 'icon-calendar';
  if (isHoliday) return 'icon-earth';

  return 'icon-moon';
};

const getText = (
  isWeekend: boolean,
  isHoliday: boolean,
  isOutOfTradingHours: boolean,
  stockMarketStatus: SecurityMarketStatus,
  t: TFunction<'translation'>,
): string => {
  if (isWeekend) return t('stockMarketHours.weekend.banner') as string;
  if (isHoliday)
    return t('stockMarketHours.holiday.banner', {
      date: dateFormatters.formatMediumLocalDate(new Date()),
    }) as string;

  return t('stockMarketHours.night.banner', {
    start: timeFormatters.formatShortTime(stockMarketStatus.tradingHoursStartAt),
    close: timeFormatters.formatShortTime(stockMarketStatus.tradingHoursCloseAt),
  }) as string;
};

export const getBannerDetails = (
  isWeekend: boolean,
  isHoliday: boolean,
  isOutOfTradingHours: boolean,
  stockMarketStatus: SecurityMarketStatus,
  t: TFunction<'translation'>,
) => {
  return {
    icon: getIcon(isWeekend, isHoliday, isOutOfTradingHours),
    text: getText(isWeekend, isHoliday, isOutOfTradingHours, stockMarketStatus, t),
  };
};
