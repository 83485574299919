import React from 'react';
import Icon from 'common/components/Icon';

import { Container } from './styles';

const Checkbox = ({ value, onClick, disabled, hasErrors }) => {
  return (
    <Container onClick={onClick} disabled={disabled} checked={value} error={hasErrors}>
      <Icon name="custom-icon-checkmark" tone="primaryInverted" sizeValue="tiniest" />
    </Container>
  );
};

Checkbox.defaultProps = {
  disabled: false,
  hasErrors: false,
  onClick: () => null,
};

export default Checkbox;
