/* eslint-disable  @typescript-eslint/no-unsafe-return */
import { State } from 'store/types/store';

export const getUsersWhoSawPhishingBanner = (state: State) => state.localSettings.usersWhoSawPhishingBanner;

export const getUsersWhoSawPhasedRolloutModal = (state: State) =>
  state.localSettings.usersWhoSawPhasedRolloutModal;

export const getShowPhasedRolloutModalAgain = (state: State) =>
  state.localSettings.showPhasedRolloutModalAgain;

export const getShowPassword = (state: State) => state.localSettings.showPassword;

export const getAfterLoginFlag = (state: State) => state.localSettings.afterLoginFlag;

export const getPageTitle = (state: State) => state.localSettings.pageTitle;

export const getIsSecondAttemptModalOpened = (state: State) => state.localSettings.isSecondAttemptModalOpened;

export const getIsStillInStocksKyc = (state: State) => state.localSettings.isStillInStocksKyc;

export const getIsSolarisDownErrorOccurred = (state: State): boolean =>
  state.localSettings.isSolarisDownErrorOccurred;

export const getWasAuditProofModalClosed = (state: State): boolean =>
  state.localSettings.wasAuditProofModalClosed;
