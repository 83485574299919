import React from 'react';
import { useTranslation } from 'react-i18next';
import { accountTypes } from 'common/const';
import DisabledFeatures from 'common/components/Modal/DisabledFeatures';
import { SwitchGreen } from 'common/components/SwitchGreen/SwitchGreen';
import { User } from 'types/state';
import { ToggleTitle, Wrapper, ToggleSwitchWrapper } from './styles';

type Props = {
  user: User;
  isKycDone: boolean;
  handleToggleRealMoneyTrading: (eventLabel: string) => void;
  changingAccount: boolean;
};

const QuickSettings = ({ user, handleToggleRealMoneyTrading, isKycDone, changingAccount }: Props) => {
  const { t } = useTranslation();
  const [disabledFeature, setDisabled] = React.useState(false);
  const realMoneyAccountActivated = user.accountType === accountTypes.real;

  const onToggleRealMoneyClick = () => {
    if (!isKycDone) {
      setDisabled(true);
    } else {
      setDisabled(false);
      handleToggleRealMoneyTrading(t('settings.realMoney'));
    }
  };

  return (
    <Wrapper onClick={onToggleRealMoneyClick}>
      <ToggleTitle disabled={!isKycDone}>{t('settings.realMoney')}</ToggleTitle>
      <ToggleSwitchWrapper>
        <SwitchGreen onClick={() => ''} checked={realMoneyAccountActivated} />
      </ToggleSwitchWrapper>
      <DisabledFeatures open={disabledFeature} onClose={() => setDisabled(false)} />
    </Wrapper>
  );
};

export default QuickSettings;
