import { combineEpics, StateObservable } from 'redux-observable';
import { catchError, ignoreElements } from 'rxjs/operators';
import * as Sentry from '@sentry/react';
import formsEpics from './epics/forms';
import androidBannerEpic from './epics/androidBanner';
import remotePersistEpic from './epics/persist';
import {
  handleBisonPageStatus,
  handleBisonStatusLocation,
  handleLocationsChange,
  handlePageStatusChange,
  handleParentLocationChange,
} from './epics/notifications';
import sessionEpic from './epics/storage';

import analytics from './epics/analytics';
import {
  createTradingRuleEpic,
  deleteTradingRuleEpic,
  deleteTradingRulesEpic,
  fetchTradingRulesEpic,
} from './epics/LimitOrder';

import {
  createTradingStopRuleEpic,
  deleteTradingStopRuleEpic,
  deleteTradingStopRulesEpic,
  fetchTradingStopRulesEpic,
} from './epics/stopOrders';

import {
  createAlertEpic,
  createAlertsEpic,
  deleteAlertEpic,
  deleteAlertsEpic,
  editAlertEpic,
  editAlertsEpic,
  fetchAlertsEpic,
  putAlertEpic,
} from './epics/Alerts';

import userEpics from './epics/user';
import termsAndConditionsEpics from './epics/termsAndConditions';
import {
  createSavingsPlanEpic,
  deleteSavingsPlanEpic,
  deleteSavingsPlanHandlerEpic,
  fetchSavingsPlansEpic,
  putSavingsPlanHandlerEpic,
  putSavingsPlansEpic,
} from './epics/Savings_old';
import portfolioEpics from './epics/portfolio';
import portfolioChartEpics from './epics/portfolioChart';
import trackingConfigurationEpic from './epics/trackingConfiguration';
import news from './epics/news';
import assetsEpic from './epics/assets';
import statusEpic from './epics/status';
import stockEtfOrder from './epics/stockEtfOrder';
import renewKycDocs from './epics/renewKycDocs';
import deviceMonitoring from './epics/deviceMonitoring';
import { fetchStockRaffleTransationCount } from './epics/stockRaffle';
import resetPassword from './epics/resetPassword';
import inboxEpic from './epics/inbox';
import topUps from './epics/topUps';
import environmentEpic from './epics/environment';
import filtersEpic from './epics/filters';
import auditProofEpic from './epics/auditProof';
import activityEpic from './epics/activity';
import emailConfirmEpic from './epics/emailConfirm';
import experimentsEpic from './epics/experiments';
import marketOverviewEpic from './epics/marketOverview';
import pricesEpic from './epics/prices';
import priceChangesEpic from './epics/priceChanges';
import priceHistoryEpic from './epics/priceHistory';
import priceHistorySmallChartEpic from './epics/priceHistorySmallChart';
import taxExemptionEpics from './epics/taxExemption';
import authEpics from './epics/auth';
import devicesEpic from './epics/devices';
import lossTaxExemptionsEpic from './epics/lossTaxExemptions';
import { RootAction, RootEpic, RootState } from 'types/common';

export default (action$: RootAction, store$: StateObservable<RootState>, dependencies: any): RootEpic =>
  combineEpics(
    environmentEpic,
    androidBannerEpic,
    remotePersistEpic,
    sessionEpic,
    handlePageStatusChange,
    handleLocationsChange,
    handleBisonPageStatus,
    handleBisonStatusLocation,
    handleParentLocationChange,
    ...Object.values(analytics),
    ...Object.values(formsEpics),
    fetchTradingRulesEpic,
    deleteTradingRulesEpic,
    deleteTradingRuleEpic,
    createTradingRuleEpic,
    createTradingStopRuleEpic,
    deleteTradingStopRuleEpic,
    deleteTradingStopRulesEpic,
    fetchTradingStopRulesEpic,
    fetchAlertsEpic,
    putAlertEpic,
    createAlertEpic,
    createAlertsEpic,
    editAlertEpic,
    editAlertsEpic,
    deleteAlertEpic,
    deleteAlertsEpic,
    userEpics,
    fetchSavingsPlansEpic,
    putSavingsPlansEpic,
    putSavingsPlanHandlerEpic,
    createSavingsPlanEpic,
    deleteSavingsPlanEpic,
    deleteSavingsPlanHandlerEpic,
    trackingConfigurationEpic,
    news,
    assetsEpic,
    stockEtfOrder,
    statusEpic,
    renewKycDocs,
    deviceMonitoring,
    inboxEpic,
    fetchStockRaffleTransationCount,
    resetPassword,
    topUps,
    filtersEpic,
    activityEpic,
    auditProofEpic,
    emailConfirmEpic,
    termsAndConditionsEpics,
    experimentsEpic,
    marketOverviewEpic,
    pricesEpic,
    priceChangesEpic,
    priceHistoryEpic,
    priceHistorySmallChartEpic,
    portfolioEpics,
    portfolioChartEpics,
    authEpics,
    taxExemptionEpics,
    devicesEpic,
    lossTaxExemptionsEpic,
  )(action$, store$, dependencies).pipe(
    catchError((error) => {
      Sentry.captureException(error);

      return ignoreElements();
    }),
  ) as RootEpic;
