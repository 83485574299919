/* eslint-disable no-nested-ternary, @typescript-eslint/no-unsafe-return */
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { dimensions } from 'common/styles';
import { Theme } from 'types/theme';
import { ButtonWrapperProps } from './utils';

interface ButtonProps extends ButtonWrapperProps {
  theme: Theme;
}

export const ButtonWrapper = styled.div.attrs<ButtonProps>(({ weight, fontSize }) => ({
  className: `${fontSize}-text ${weight}-weight`,
}))`
  padding: 0 20px;
  margin: ${dimensions.btnMargin}px 0;
  margin: ${dimensions.btnMargin}px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ theme, isErrorType, inverted, disabled, size = 'standard', isFullWidth }: ButtonProps) =>
    `
   background-color: ${
     (isErrorType && theme.color.background.critical) ||
     (inverted && theme.color.background.formAccentInverted) ||
     theme.color.background.formAccent
   };
    border-color:  ${
      (isErrorType && theme.color.border.critical) ||
      (inverted && theme.color.border.formAccentInverted) ||
      theme.color.border.formAccent
    };
    border: ${(inverted && `${theme.border.width.large} solid`) || 'none'};
    color: ${
      (isErrorType && theme.color.foreground.critical) ||
      (inverted && theme.color.foreground.formAccentInverted) ||
      theme.color.foreground.formAccent
    };
    opacity: ${disabled ? theme.opacity.disabled : theme.opacity.none};
    border-radius: ${theme.border.radius.standard};
    height: ${theme.assets.button[size].height};
    min-width: ${(!isFullWidth && theme.assets.button[size].width) || ''};
    width: ${(isFullWidth && '100%') || ''};

    &:hover {
      opacity: ${disabled ? theme.opacity.disabled : theme.opacity.hover};
    }
  `}

  a {
    text-decoration: none !important;
  }
`;

export const LinkWrapper = styled.div.attrs<ButtonProps>({
  className: 'large-text',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  margin: ${dimensions.btnMargin}px 0;

  ${({ theme, disabled }: ButtonProps) => `
    color: ${theme.color.foreground.secondary};
    opacity: ${disabled ? theme.opacity.disabled : theme.opacity.none};
    cursor: ${disabled ? `not-allowed` : `pointer`};

    &:hover {
      opacity: ${(!disabled && theme.opacity.hover) || theme.opacity.disabled};
    }
  `}
`;

export const ForgotPasswordWrapper = styled(LinkWrapper)`
  padding-top: 13px;
  margin-bottom: -10px;
`;

export const Link = styled(RouterLink)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ theme }) => `
    color: ${theme.color.foreground.formAccent};
  `}
`;

export const TagLink = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.color.foreground.primary};
`;

export const TextContainer = styled.span<{ isErrorType?: boolean; inverted?: boolean }>`
  text-align: center;
  color: ${({ theme, isErrorType, inverted }) =>
    `${
      (isErrorType && theme.color.foreground.primaryInverted) ||
      (inverted && theme.color.foreground.formAccentInverted) ||
      theme.color.foreground.formAccent
    };
  `};
`;
