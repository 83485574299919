import styled from 'styled-components';
import { InfoText } from 'scenes/Trading/Crypto/OrderFinished/styles';
import { Theme } from 'types/theme';
import bisonSuccessImg from 'assets/img/new_success_img.svg';
import bisonSuccessBgImg from 'assets/img/new_success_background_img.svg';
import { devices } from 'common/styles';

export const Wrapper = styled.div`
  width: 100%;

  & > div {
    margin: 0;
  }

  @media ${devices.tablet} {
    padding: 0 20%;
    padding-bottom: 0;
  }
`;
export const Content = styled.div`
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.secondary} !important;
`;

export const ContentWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.div.attrs({ className: 'standard-text primary-color-text' })`
  text-align: center;
  &:not(:last-child) {
    padding-bottom: 20px;
  }
`;

export const SuccessTextHeader = styled(InfoText).attrs({ className: 'strong-weight' })``;

export const LimitOrderSuccessTextHeader = styled(SuccessTextHeader)`
  font-size: 24px;
  margin: 0 0 18px;
`;

export const SuccessImg = styled.img.attrs({
  src: bisonSuccessImg,
})`
  display: block;
  text-align: center;
  width: 200px;
  height: auto;
  margin: 0 auto;
  @media ${devices.tablet} {
    width: 256px;
  }
`;

export const SuccessBgImg = styled.img.attrs({
  src: bisonSuccessBgImg,
})`
  display: block;
  text-align: center;
  width: 200px;
  height: auto;
  margin: 0 auto;
  @media ${devices.tablet} {
    width: 256px;
  }
`;

export const LegalText = styled.div`
  text-align: center;
  letter-spacing: 0em;
  display: block;
  flex: 0 0 100%;
  font-size: ${({ theme }: { theme: Theme }) => theme.typography.text.small.fontSize};
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryLight};
`;
