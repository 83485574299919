import { PortfolioAsset, PortfolioCash, PortfolioInvested } from 'types/portfolio';
import { ASSET_CLASSES, CryptoAssets } from 'types/assets';
import { AssetInfo, currencies } from 'common/const';

export const TTL_PORTFOLIO = 10000;

export const instrumentComparator = (s1: PortfolioAsset, s2: PortfolioAsset) => {
  let comp = 0;
  if (s1.value < s2.value) {
    comp = 1;
  } else if (s1.value > s2.value) {
    comp = -1;
  }
  return comp;
};

export const getShort = (instrument: PortfolioAsset): string => instrument.entity.toLowerCase();

export const getName = (instrument: PortfolioAsset, cryptoAssets: CryptoAssets): string => {
  switch (instrument.assetClass) {
    case ASSET_CLASSES.CRYPTO:
      return cryptoAssets[instrument.entity]?.displayName;
    case ASSET_CLASSES.CURRENCY:
      return currencies.fiatInfo[getShort(instrument)].name;
    default:
      return instrument.entity;
  }
};

export const getValueSign = (value: number) => {
  if (value > 0) {
    return '+ ';
  }
  if (value <= 0) {
    return ' ';
  }

  return ' ';
};

export const getCrypto = (crypto: string, cryptoAssets: CryptoAssets): AssetInfo => {
  const cryptoInfo = cryptoAssets[crypto.toLowerCase()];
  return {
    name: cryptoInfo?.displayName,
    key: cryptoInfo?.code,
    symbol: cryptoInfo?.symbol,
    secondaryColor: '',
    icon: '',
    mainColor: cryptoInfo?.color,
  };
};

const makeSecurityInfo = (assets: PortfolioAsset): AssetInfo => ({
  name: assets.entity,
  symbol: assets.entity.toUpperCase(),
  key: assets.entity.toLowerCase(),
  mainColor: `#F1F2F3`,
  secondaryColor: 'black',
  icon: 'icon-euro-sign',
});

export const getAssetInfo = (asset: PortfolioAsset, cryptoAssets: CryptoAssets): AssetInfo => {
  if (asset.assetClass === ASSET_CLASSES.CURRENCY) return currencies.fiatInfo.eur;

  if (asset.assetClass === ASSET_CLASSES.CRYPTO) return getCrypto(asset.entity, cryptoAssets);

  return makeSecurityInfo(asset);
};

export const calcTotalValue = (cash: PortfolioCash, invested: PortfolioInvested) =>
  (cash.volume ?? 0) +
  (cash.reservedVolumeLimit ?? 0) +
  (cash.reservedVolumeStop ?? 0) +
  (cash.reservedVolumeStockSell ?? 0) +
  (cash.reservedVolumeStockBuy ?? 0) +
  (invested.value ?? 0) -
  (cash.debtVolume ?? 0);
