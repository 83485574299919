import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { devicesHeight } from 'common/styles/devices';
import { useMedia } from 'common/hooks/useMedia';
import { useThemeContext } from 'common/utils/theme/ThemeContext';
import { useTracking } from 'common/tracking/hooks';
import {
  MODAL_ACTIONS_MARGIN,
  MODAL_DIMENSIONS,
  ModalActionsMarginType,
  ModalDimensionsType,
} from 'common/const/modals';
import Button from '../Button';
import { BackIcon, CloseIcon, ModalActions, ModalContent, ModalHeader, ModalWrapper } from './styles';

interface Props {
  children: JSX.Element | JSX.Element[];
  header?: JSX.Element | string;
  defaultOpen?: boolean;
  trigger?: JSX.Element;
  actions?: JSX.Element;
  persist?: boolean;
  className?: string;
  onCancel?: () => void;
  invertedHeader?: boolean;
  modalDimensions?: ModalDimensionsType;
  disableActionsBlock?: boolean;
  fixedActionsBlock?: boolean;
  noRoundedBorders?: boolean;
  actionsMargin?: ModalActionsMarginType;
  modalId?: string;
  onBack?: () => void;
}

/**
 * Modal template should be the base for all modals on accross the platform. The wrapper is scrollable when content overflows height of modal.
 * Children provided to the component should be wrapped around ModalHeader and ModalContent.
 * If needed, use ModalContentSticky or ModalContentStickyBottom for part of the content that should not be scrollable
 * @param {JSX.Element} children ModalHeader and ModalContent should be used
 * @param {JSX.Element} [header] content in the ModalHeader
 * @param {boolean} [defaultOpen] Opens modal by default. It can also control opening and closing of modal via actions
 * @param {JSX.Element} [trigger] Button used for triggering the modal.
 * @param {JSX.Element} [actions] Action controls (buttons) at the bottom of modal.
 * @param {boolean} [persist] Disable option for closing the modal when clicking outside of it. The X button is also disabled. Modal is exclusively controled by actions
 * @param {function} [onCancel] onCancel action will fire up upon closing the modal.
 * @returns {JSX.Element}
 */
function ModalTemplate({
  children,
  header,
  defaultOpen,
  trigger,
  actions,
  persist,
  className,
  onCancel,
  invertedHeader,
  modalDimensions,
  actionsMargin,
  disableActionsBlock,
  fixedActionsBlock,
  noRoundedBorders,
  modalId,
  onBack,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = useState(defaultOpen);
  const isShortScreenHeight = useMedia([devicesHeight.maxSmall, devicesHeight.small], [true, false], false);
  const theme = useThemeContext();
  const trackAppEvent = useTracking();

  useEffect(() => {
    if (defaultOpen !== open) {
      setOpen(defaultOpen);
    }
  }, [defaultOpen]);

  useEffect(() => {
    if (open) {
      if (modalId) {
        trackAppEvent('modalPressOpenModal', { componentId: modalId });
      }
    } else {
      trackAppEvent('modalPressCloseModalUnknown');
    }
  }, [open]);

  const closeIconColor = invertedHeader
    ? theme.color.foreground.primaryInverted
    : theme.color.foreground.primary;

  return (
    <ModalWrapper
      open={open}
      onClose={() => {
        if (!persist) {
          setOpen(false);
          if (onCancel) onCancel();
        }
      }}
      onOpen={() => setOpen(true)}
      trigger={trigger} // This button will be displayed wherever ModalTemplate is used and it controls opening of modal
      className={className}
      $modalDimensions={modalDimensions}
      $actionsMargin={actionsMargin}
      $invertedHeader={invertedHeader}
      $noRoundedBorders={noRoundedBorders}
      $isHeaderHidden={!header}
    >
      {!persist && (
        <CloseIcon
          name="icon-crossIcon"
          sizeValue="regular"
          customColor={closeIconColor}
          $invertedHeader={invertedHeader}
          onClick={() => {
            setOpen(false);
            if (onCancel) onCancel();
          }}
        />
      )}
      {onBack && <BackIcon onClick={onBack} name="icon-left-arrow" sizeValue="regular" />}
      {header && <ModalHeader>{header}</ModalHeader>}
      <ModalContent>
        {children}
        {!disableActionsBlock && isShortScreenHeight && !fixedActionsBlock && (
          <ModalActions>
            {actions || <Button size="modal" onClick={() => setOpen(false)} title={t('common.close')} />}
          </ModalActions>
        )}
      </ModalContent>
      {!disableActionsBlock && (!isShortScreenHeight || fixedActionsBlock) && (
        <ModalActions>
          {actions || <Button size="modal" onClick={() => setOpen(false)} title={t('common.close')} />}
        </ModalActions>
      )}
    </ModalWrapper>
  );
}

ModalTemplate.defaultProps = {
  header: undefined,
  defaultOpen: false,
  trigger: undefined,
  actions: undefined,
  persist: false,
  className: '',
  onCancel: () => null,
  invertedHeader: false,
  modalDimensions: MODAL_DIMENSIONS.DEFAULT,
  actionsMargin: MODAL_ACTIONS_MARGIN.DEFAULT,
  disableActionsBlock: false,
  noRoundedBorders: false,
  modalId: undefined,
  onBack: undefined,
  fixedActionsBlock: undefined,
};

export default ModalTemplate;
