import styled from 'styled-components';
import { Theme } from 'types/theme';

export const Button = styled.button.attrs({ className: 'standard-text medium-weight' })`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }: { theme: Theme }) => theme.color.background.light};
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;

  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};

  & [class*='icon'] {
    &:before {
      color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
    }
  }
  /* &:hover:not([disabled]) {
    background: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
    color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryInverted};
    & [class*='icon'] {
      &:before {
        color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryInverted};
      }
    }
  }
  &:focus:not([disabled]) {
    background: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
    color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
    & [class*='icon'] {
      &:before {
        color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
      }
    }
  } */
  &:disabled {
    opacity: ${({ theme }: { theme: Theme }) => theme.opacity.disabled};
    cursor: auto;
    pointer-events: none;
    color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
    & [class*='icon'] {
      &:before {
        color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
      }
    }
  }
`;
