import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ModalTemplate from 'common/components/Modal/index';
import { ActionWrapper, Header, Note } from './styles';
import Button from 'common/components/Button';

type Props = {
  onDismiss: () => void;
  onConfirm: () => void;
};

export const KCCRaffleExitModal = ({ onConfirm, onDismiss }: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      defaultOpen
      actions={<></>}
      onCancel={onDismiss}
      modalDimensions="small"
      header={<Header>{t('kccRaffle.exitModal.title')}</Header>}
    >
      <Note>{t('kccRaffle.exitModal.note')}</Note>
      <ActionWrapper>
        <Button
          fontSize="small"
          isFullWidth
          size="large"
          title={t('kccRaffle.exitModal.actions.dismiss')}
          onClick={onDismiss}
        />
        <Button
          fontSize="small"
          isFullWidth
          size="large"
          inverted
          title={t('kccRaffle.exitModal.actions.confirm')}
          onClick={onConfirm}
        />
      </ActionWrapper>
    </ModalTemplate>
  );
};

KCCRaffleExitModal.defaultProps = {};
