import React, { Fragment, ReactElement, useState } from 'react';
import ModalTemplate from '../index';
import {
  Header,
  List,
  ListItem,
  ListItemRow,
  ListItemText,
  RowCenter,
  Subtitle,
  Text,
  WinnerLoserPriceChangeText,
} from './styles';
import Button from 'common/components/Button';
import { WinnerLoserItem, WinnerLoserType } from 'types/winnersLosers';
import { helpers } from 'common/utils/formatting';
import { useTranslation } from 'react-i18next';
import { ASSET_CLASSES, ASSET_SECURITY_CLASSES, MarketOverviewOrderBy } from 'types/assets';
import { TimePeriod } from 'types/currency';
import { WithRouterType } from 'types/withRouter';
import { paths } from 'common/urls';
import AssetIcon from 'common/components/Icon/AssetIcon';
import IntroModal from 'scenes/Kyc/SecuritiesKyc/IntroModal/IntroModal';
import { useSecurities } from 'common/hooks/useSecurities';
import useSearchParamsNavigate from 'common/hooks/useSearchParamsNavigate';
import {
  MARKET_OVERVIEW_SEARCH_PARAMETER_STOCKS,
  MARKET_OVERVIEW_SEARCH_PARAMS_CATEGORY_NAME,
} from 'common/const/marketOverivew';
import { ModalProps } from 'types/modals';

type Props = {
  first: WinnerLoserItem | undefined;
  second: WinnerLoserItem | undefined;
  third: WinnerLoserItem | undefined;
  type: WinnerLoserType | undefined;
  setMarketOverviewOrderBy: (sortBy: MarketOverviewOrderBy) => void;
  setMarketOverviewTimePeriod: (timePeriod: TimePeriod) => void;
  setWinnerLoserModalShown: () => void;
} & ModalProps;

const WinnerLoserModalView = ({
  first,
  second,
  third,
  type,
  setMarketOverviewOrderBy,
  setMarketOverviewTimePeriod,
  setWinnerLoserModalShown,
  onClose,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { canTradeStocks } = useSecurities();
  const navigate = useSearchParamsNavigate();

  const [showStocksIntroModal, setShowStocksIntroModal] = useState(false);

  return (
    <Fragment>
      {!showStocksIntroModal && (
        <ModalTemplate
          defaultOpen
          actions={
            <Button
              size="modal"
              title={t(`experiments.winnersLosers.${canTradeStocks ? 'securities' : 'crypto'}.button`)}
              onClick={() => {
                if (canTradeStocks) {
                  // Set market overview filters so displayed assets will be seen right away.
                  setMarketOverviewOrderBy(type === 'winner' ? 'Winner' : 'Loser');
                  setMarketOverviewTimePeriod('24H');

                  // Set modal seen to true - it will not be shown anymore
                  setWinnerLoserModalShown();

                  // Navigate to market overview and set stocks as a selected category
                  navigate(paths.MARKET_OVERVIEW, {
                    [MARKET_OVERVIEW_SEARCH_PARAMS_CATEGORY_NAME]: MARKET_OVERVIEW_SEARCH_PARAMETER_STOCKS,
                  });

                  onClose();
                } else {
                  // Display stocks intro popup
                  setShowStocksIntroModal(true);
                }
              }}
            />
          }
          onCancel={() => {
            setWinnerLoserModalShown();
            onClose();
          }}
        >
          <Header>{t(`experiments.winnersLosers.${type?.toString() ?? 'winner'}.title`)}</Header>

          <RowCenter>
            <List>
              <ListItem $order={1} $type={type}>
                <ListItemRow>
                  <ListItemText title={first?.displayName}>1. {first?.displayName}</ListItemText>
                  <WinnerLoserPriceChangeText $type={type}>
                    {helpers.toPercentageLocale(first?.changeInPercentage ?? 0, {
                      precision: 2,
                      prefixPositiveNumberWithPlusSign: true,
                    })}
                  </WinnerLoserPriceChangeText>
                </ListItemRow>
                <AssetIcon
                  code={first?.code ?? ''}
                  class={ASSET_CLASSES.SECURITY}
                  securityClass={ASSET_SECURITY_CLASSES.STOCK}
                ></AssetIcon>
              </ListItem>
              <ListItem $order={2} $type={type}>
                <ListItemRow>
                  <ListItemText title={second?.displayName}>2. {second?.displayName}</ListItemText>
                  <WinnerLoserPriceChangeText $type={type}>
                    {helpers.toPercentageLocale(second?.changeInPercentage ?? 0, {
                      precision: 2,
                      prefixPositiveNumberWithPlusSign: true,
                    })}
                  </WinnerLoserPriceChangeText>
                </ListItemRow>
                <AssetIcon
                  code={second?.code ?? ''}
                  class={ASSET_CLASSES.SECURITY}
                  securityClass={ASSET_SECURITY_CLASSES.STOCK}
                ></AssetIcon>
              </ListItem>
              <ListItem $order={3} $type={type}>
                <ListItemRow>
                  <ListItemText title={third?.displayName}>3. {third?.displayName}</ListItemText>
                  <WinnerLoserPriceChangeText $type={type}>
                    {helpers.toPercentageLocale(third?.changeInPercentage ?? 0, {
                      precision: 2,
                      prefixPositiveNumberWithPlusSign: true,
                    })}
                  </WinnerLoserPriceChangeText>
                </ListItemRow>
                <AssetIcon
                  code={third?.code ?? ''}
                  class={ASSET_CLASSES.SECURITY}
                  securityClass={ASSET_SECURITY_CLASSES.STOCK}
                ></AssetIcon>
              </ListItem>
            </List>
          </RowCenter>

          <Subtitle>
            {t(`experiments.winnersLosers.${canTradeStocks ? 'securities' : 'crypto'}.subtitle`)}
          </Subtitle>
          <Text>{t(`experiments.winnersLosers.${canTradeStocks ? 'securities' : 'crypto'}.text`)}</Text>
        </ModalTemplate>
      )}
      {showStocksIntroModal && (
        <IntroModal
          open
          setOpen={setShowStocksIntroModal}
          onSuccessCallback={() => {
            setWinnerLoserModalShown();
            onClose();
          }}
        />
      )}
    </Fragment>
  );
};

export default WinnerLoserModalView;
