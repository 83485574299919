import React, { ReactElement, memo } from 'react';
import Button from 'common/components/Button';
import { paths } from 'common/urls';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import ModalTemplate from '..';
import { ModalProps } from 'types/modals';

const InboxImportantMessages = ({ onClose }: ModalProps): ReactElement | null => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      defaultOpen={true}
      onCancel={onClose}
      header={<h2>{t('inboxImportantMessagesModal.title')}</h2>}
      actions={
        <Button
          isFullWidth
          onClick={onClose}
          title={t('inboxImportantMessagesModal.action')}
          to={generatePath(paths.INBOX)}
        />
      }
    >
      <p>{t('inboxImportantMessagesModal.text')}</p>
    </ModalTemplate>
  );
};

InboxImportantMessages.defaultProps = {};

export default memo(InboxImportantMessages);
