/* eslint-disable react/prop-types */
import { getBaseUrlForStaticAssets } from 'common/api';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'store/selectors/settings';
import { ASSET_CLASSES, ASSET_SECURITY_CLASSES } from 'types/assets';
import { ThemeName } from 'types/theme';
import defaultAssetIcon from 'assets/img/default_asset_icon.svg';
import defaultEtfIcon from 'assets/img/default_etf_icon.svg';
import currencyIcon from 'assets/img/currency_icon.svg';
import etcIcon from 'assets/img/etc_icon.svg';
import etnIcon from 'assets/img/etn_icon.svg';
import { SvgBase, SvgBaseWithTheme } from 'common/components/Icon/AssetIcon/components/SvgBases';
import { IconProps } from 'common/components/Icon/AssetIcon/types';
import { DARK_THEME_SUFFIX } from 'common/components/Icon/AssetIcon/const';

const AssetIcon = ({ code, class: assetClass, securityClass, height, width }: IconProps) => {
  const themeName: ThemeName = useSelector(getTheme);
  // TODO - Uncomment this line to enable dark mode icons
  //  const themeSuffix = themeName === 'dark' ? DARK_THEME_SUFFIX : '';
  const themeSuffix = '';
  const [iconsBaseUrl, setIconsBaseUrl] = useState('');

  useEffect(() => {
    const baseUrl = getBaseUrlForStaticAssets();

    setIconsBaseUrl(baseUrl);
  }, []);

  if (iconsBaseUrl === '') return <SvgBase src={defaultAssetIcon} height={height} width={width} />;

  if (assetClass === ASSET_CLASSES.CURRENCY)
    return <SvgBase src={currencyIcon} height={height} width={width} />;

  if (assetClass === ASSET_CLASSES.CRYPTO)
    return (
      <SvgBaseWithTheme
        src={`${iconsBaseUrl}icons/${code?.toUpperCase()}${themeSuffix}.svg`}
        code={code}
        height={height}
        width={width}
      />
    );

  switch (securityClass) {
    case ASSET_SECURITY_CLASSES.STOCK:
      return <SvgBase src={`${iconsBaseUrl}icons/${code.toUpperCase()}.svg`} height={height} width={width} />;
    case ASSET_SECURITY_CLASSES.ETC:
      return <SvgBase src={etcIcon} height={height} width={width} />;
    case ASSET_SECURITY_CLASSES.ETN:
      return <SvgBase src={etnIcon} height={height} width={width} />;
    case ASSET_SECURITY_CLASSES.ETF:
      return <SvgBase src={defaultEtfIcon} height={height} width={width} />;
    default:
      return <SvgBase src={defaultAssetIcon} height={height} width={width} />;
  }
};

AssetIcon.defaultProps = {
  width: 32,
  height: 32,
};

export default memo(AssetIcon);
