/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  all,
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  race,
  select,
  spawn,
  take,
  takeLatest,
} from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import I18n from 'i18next';
import { actions as remotePersistActions } from 'redux-remote-persist';
import { handleApiError } from 'store/sagas/errors';
import { codesSilentFail, errorCodes } from 'common/apiErrors';
import { getClientId, setWindowParams } from 'common/utils/auth';
import initGruffalo from 'common/utils/init';
import { AccountType, secretTypes } from 'common/const';
import { DEFAULT_LANG } from 'common/utils/language';
import * as api from 'common/api';
import { getDeviceData, setSeonSdkConfig } from 'common/utils/seonSdk';
import * as keychain from 'services/auth';
import * as authActions from 'store/actions/auth';
import * as formActions from 'store/actions/forms';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import * as environmentActions from 'store/actions/environment';
import * as statusActions from 'store/actions/status';
import getCurrentPath from 'store/selectors/navigation';
import { getFeatureStocksEnabled } from 'store/selectors/environment';
import { getAuthSecret, getLoggedIn, getTokens, getUser } from 'store/selectors/auth';
import { checkDeviceConsentNeeded } from './deviceMonitoring';
import {
  DEVICE_DATA_END,
  DEVICE_DATA_SUCCESS,
  deviceMonitoringPreinitCheck,
} from 'store/actions/deviceMonitoring';
import { ApiError } from 'types/error';
import { Saga } from 'redux-saga';
import { START_RESEND_CONFIRMATION_MAIL } from 'store/actions/auth';

const isSowalabs = (username) => /^.+@sowalabs.com$/.test(username);

// ****************************** Subroutines **********************************

type FetchResponse = { response: any } | { error: ApiError };

function* entityRequest(
  entity: any,
  apiFn: Array<any>,
  notifyOnError: boolean,
  id: any,
): Saga<FetchResponse> {
  try {
    yield put(entity.request(id));
    const { response, error } = yield call(...apiFn, id);
    if (!error) {
      yield put(entity.success(id, response));
      return { response };
    }
    // error, notify store
    yield put(entity.failure(id, error));
    if (notifyOnError) {
      yield spawn(handleApiError, apiFn, error);
    }
    return { error };
  } finally {
    if (yield cancelled()) {
      yield put(entity.failure(id, { message: 'Request cancelled' }));
    }
  }
}

// Access token refresh / response handler.
// If the app has already ask for new tokens, wait for original request to
// finish and return its result
function* refreshTokens(newAccountType?: AccountType, usernameChanged?: boolean): Generator<any, any, any> {
  const { isRefreshing } = yield select(getTokens);

  // wait if saga is already refreshing access token
  if (isRefreshing) {
    const { error } = yield race({
      success: take(authActions.TOKEN_REFRESH.SUCCESS),
      error: take(authActions.TOKEN_REFRESH.FAILURE),
    });

    return error;
  }

  const user = yield select(getUser);
  const { username } = user;
  const accountType = newAccountType || user.accountType;

  const { secret, secretType } = yield select(getAuthSecret);
  const credentials = { username, secret, type: secretType };
  const { deviceData, error: deviceDataError } = yield call(getDeviceData);

  const request = { credentials, accountType, deviceData };

  // notify the store we are going to refresh access token
  yield put(authActions.tokenRefresh.request(request));

  const { response, error } = yield call(api.login, request);

  if (error && error.code === errorCodes.GENERAL_DEVICEDATA_REQUIRED) {
    // clears keychain and root reducer resets state
    yield put(authActions.clearAuthTokens());
    yield put(authActions.logoutUser());
  }

  if (response) {
    yield put(authActions.tokenRefresh.success(request, response));
    return null; // signifies success
  }
  // error
  yield put(authActions.tokenRefresh.failure(request, error, usernameChanged));
  return error;
}

// Subroutine used for avoiding pinging the store for an auth request
// Handles token refreshing on expiration
export function* authRequest(apiFn: any, params: any): Saga<FetchResponse> {
  let { accessToken } = yield select(getTokens);
  // if we received Client Key from keychain, we need to get an access token first
  if (!accessToken) {
    yield call(refreshTokens);
    ({ accessToken } = yield select(getTokens));
    if (!accessToken) return { error: { message: 'Something went wrong while refreshing token' } };
  }

  const { response, error } = yield call(apiFn, params, accessToken);

  if (!error) {
    return { response };
  }
  if (error.code === 'auth_err_tokenexpired' || error.code === 'auth_err_tokeninvalid') {
    // request failed because of a stale access token
    const refreshError = yield call(refreshTokens);

    if (!refreshError) {
      // if the access token was refreshed successfully, retry failed request
      return yield call(authRequest, apiFn, params);
    }
    // if refresh failed, authFlow captures the action and logouts
  }
  // other error
  return { error };
}

// reusable fetch Subroutines
// entity : authActions.priceHistory | ...
// apiFn  : api.fetchPriceHistory | api.fetchPriceTicker ...
// id     : pair ...
export function* fetchEntity(entity, apiFn, notifyOnError: boolean, id: any): Saga<FetchResponse> {
  return yield call(entityRequest, entity, [apiFn], notifyOnError, id);
}

export function* authFetchEntity(
  entity: any,
  apiFn: any,
  notifyOnError: boolean,
  id: any,
): Saga<FetchResponse> {
  return yield call(entityRequest, entity, [authRequest, apiFn], notifyOnError, id);
}

// bind fetch functions
const requestAuth = fetchEntity.bind(null, authActions.auth, api.login, false);
const requestLocalAuth = fetchEntity.bind(null, authActions.localAuth, keychain.loadAuthTokens, true);
const storeAuthTokens = fetchEntity.bind(null, authActions.keychainSave, keychain.storeAuthTokens, true);
const clearAuthTokens = fetchEntity.bind(null, authActions.keychainClear, keychain.clearAuthTokens, true);

// ******************************* Auth Handlers *******************************

// Validates access token and/or retreives user object
export const validateToken = authFetchEntity.bind(
  null,
  authActions.tokenValidation,
  api.validateToken,
  false,
);

// Refreshes access token when it expires
function* refreshTokensInBackground() {
  while (true) {
    const { accessToken } = yield select(getTokens);
    const { exp } = jwtDecode(accessToken);
    // exp is in seconds epoch
    const delayTillRefresh = Math.max(exp * 1000 - Date.now(), 0);

    // delay refreshment until current access token expires
    // or we got a new access token, in which case we need to invalidate expiration time
    const { tokenExpired, tokenInvalidated } = yield race({
      tokenExpired: delay(delayTillRefresh),
      tokenInvalidated: take([authActions.TOKEN_REFRESH.SUCCESS, authActions.TOKEN_REFRESH.FAILURE]),
    });

    // refresh
    if (tokenExpired) {
      const refreshError = yield call(refreshTokens); // blocking
      if (refreshError) return refreshError; // stop refreshing
    } else if (tokenInvalidated.type === authActions.TOKEN_REFRESH.FAILURE) {
      return tokenInvalidated.error; // stop refreshing
    }
  }
}

function* authenticate() {
  const { username } = yield select(getUser);
  yield fork(validateToken, username);

  // wait for a successful login
  yield take(authActions.TOKEN_VALIDATION.SUCCESS);
  yield put(authActions.storeAuthTokens());

  // check if app should be in beta mode
  const isAppBeta = yield call(isSowalabs, username || '');
  if (isAppBeta) yield put(environmentActions.setBetaMode());

  const { id } = yield select(getUser);
  yield fork(initGruffalo, id);

  yield put(remotePersistActions.rehydrate());
  yield take(remotePersistActions.REHYDRATE_SUCCESS);

  yield put(authActions.loginUserSuccess());

  // start refreshing tokens in the background
  yield fork(refreshTokensInBackground);
}

// Retreives Client Key from the local keychain (biometric login) and obtains a fresh access token
function* localLogin() {
  const { username } = yield select(getUser);
  // successful localAuth action triggers authFlow
  const { error } = yield call(requestLocalAuth, username);
  if (error && error.code === 'no_client_key') {
    yield put(authActions.clearAuthTokens());
  }
}

// Calls the login api
function* login(
  newUsername: string,
  password: string,
  rememberMe = false,
  twofa: any = {},
): Generator<any, any, any> {
  // get account type from the store
  const user = yield select(getUser);
  const { clientToken } = yield select(getTokens);
  const { deviceData, error: deviceDataError } = yield call(getDeviceData);

  const { accountType } = user;
  const { username } = user;

  const { response, error } = yield call(requestAuth, {
    credentials: { username: newUsername, secret: password, type: secretTypes.password, rememberMe },
    accountType: username === newUsername ? accountType : '',
    ...({ deviceData } || {}),
    ...({ twofa } || {}),
    ...({ clientToken } || {}),
  });

  if (response) {
    yield call(authenticate);
  } else if (!codesSilentFail[error?.code]) {
    let { message } = error;
    if (error.code === errorCodes.LOGIN_ERR_2FAWRONGCODE) message = I18n.t('login.error2fa');
    else if (error.code === errorCodes.LOGIN_ERR_TOOMANYATTEMPTS)
      message = I18n.t('login.errorTooManyAttempts');

    yield put(
      notificationBannerActions.notifyError({
        message,
      }),
    );
  }
}

type AuthFlowActions = {
  logout: any;
  authFailure: { error: ApiError };
  validationFailure: { error: ApiError };
  refreshFailure: { error: ApiError };
};

// Function overseeing auth flow. It begins with either:
// - an authentication request (username and password) or
// - a successful local authentication (tokens retreived from the local keychain to the store)
export function* authFlow(): Saga<void> {
  while (true) {
    const { loginRequest, autoLogin } = yield race({
      loginRequest: take(authActions.LOGIN_USER), // login request
      tokens: take(authActions.LOCAL_AUTH.SUCCESS), // tokens were loaded into the store from the local keychain
      autoLogin: take(authActions.AUTO_LOGIN),
    });

    let task;

    if (loginRequest) {
      task = yield fork(
        login,
        loginRequest.username,
        loginRequest.password,
        loginRequest.rememberMe,
        loginRequest.additional,
      );
    } else if (autoLogin) {
      // regenerate the Access token
      yield call(refreshTokens);
      task = yield fork(authenticate);
    } else {
      task = yield fork(authenticate);
    }

    const { logout, authFailure, refreshFailure }: AuthFlowActions = yield race({
      logout: take(authActions.LOGOUT_USER_SUCCESS),
      authFailure: take(authActions.AUTH.FAILURE),
      validationFailure: take(authActions.TOKEN_VALIDATION.FAILURE),
      refreshFailure: take(authActions.TOKEN_REFRESH.FAILURE),
      maintain: take(statusActions.BISON_MAINTAINANCE_STARTED),
    });

    if (logout) {
      // logout request, watcher clears keychain and root reducer resets state
      yield cancel(task); // cancel any running tasks
    }
    // auth failure (not logged in)
    else if (authFailure && authFailure.error.code === errorCodes.LOGIN_ERR_NOTCONFIRM) {
      // user's email not confirmed yet
      if (loginRequest)
        yield put(
          authActions.waitConfirmation(
            loginRequest.username,
            loginRequest.password,
            secretTypes.password,
            true,
          ),
        );
    } else if (authFailure && authFailure.error.code === errorCodes.LOGIN_ERR_DEVICE_2FA) {
      const { detail } = authFailure.error;
      yield put(
        formActions.start2faProccess({
          username: loginRequest.username,
          accountType: loginRequest.accountType,
          secret: loginRequest.password,
          ...authFailure.error,
          twofa: detail,
        }),
      );
    } else if (authFailure && authFailure.error.code === errorCodes.LOGIN_ERR_2FAWRONGCODE) {
      if (loginRequest?.actions?.finished) yield call(loginRequest.actions.finished);
    }
    // refresh failure (could be logged in or not)
    else if (refreshFailure) {
      const isLoggedIn = yield select(getLoggedIn);
      if (isLoggedIn && !refreshFailure.usernameChanged) {
        yield put(
          notificationBannerActions.notifyError({
            message: I18n.t('login.errorClientKeyInvalid'),
          }),
        );
      }
      if (isLoggedIn || refreshFailure.error.code === 'login_err_loginfailed') {
        // clears keychain and root reducer resets state
        yield put(authActions.clearAuthTokens());
        yield put(authActions.logoutUser());
      } else {
        yield put(
          notificationBannerActions.notifyError({
            message: I18n.t('login.errorGeneric'),
          }),
        );
      }
    }
  }
}

function* invalidateTokens(accountType?: AccountType, usernameChanged?: boolean) {
  // first wait for any ongoing refresh to finish
  const { isRefreshing } = yield select(getTokens);
  if (isRefreshing) {
    const action = yield take([authActions.TOKEN_REFRESH.SUCCESS, authActions.TOKEN_REFRESH.FAILURE]);
    if (action.type === authActions.TOKEN_REFRESH.FAILURE) return; // exit if failure
  }

  // then initiate a refresh that will request a new account type
  const refreshError = yield call(refreshTokens, accountType, usernameChanged); // blocking
  if (refreshError) {
    if (!usernameChanged) {
      yield put(
        notificationBannerActions.notifyError({
          message: refreshError.message,
        }),
      );
    }
  } else {
    yield put(authActions.invalidateAccountData());
  }
}

// Changes account type
function* changeAccountType(newAccountType: AccountType) {
  const { accountType } = yield select(getUser);
  if (newAccountType !== accountType) {
    yield call(invalidateTokens, newAccountType);
  }
}

// ********************************** Keychain *********************************

// Clears tokens from the local keychain and notifies the store
function* clearTokens() {
  const { username } = yield select(getUser);
  yield call(clearAuthTokens, username);
}

// Stores tokens to the local keychain
function* storeTokens(resolve: (result?: Promise<empty>) => void) {
  const { clientKey } = yield select(getTokens);
  const { username } = yield select(getUser);

  const { error } = yield call(storeAuthTokens, { username, clientKey });
  if (error) {
    // clear keychain if there's an error storing
    yield call(clearTokens);
  }
  yield call(resolve);
}

// Makes sure there is only one keychain request in progress at a time
function* keychainFlow() {
  while (true) {
    const { store } = yield race({
      store: take(authActions.STORE_AUTH_TOKENS),
      clear: take(authActions.CLEAR_AUTH_TOKENS),
    });

    if (store) {
      yield call(storeTokens, store.resolve);
    } else {
      yield call(clearTokens);
    }
  }
}

// ********************************** Others ***********************************

// bind fetch function
const requestConfirmationMail = fetchEntity.bind(
  null,
  authActions.confirmationMail,
  api.resendConfirmationMail,
  false,
);

function* resendConfirmationMail({ sendSecurityCode, secret }): Saga<void> {
  for (let i = 1; i <= 2; i++) {
    // foor loop with try/catch logic is only ment for retrying init request when device consent id is missing

    try {
      const { username } = yield select(getUser);
      const { deviceData, error: deviceDataError } = yield call(getDeviceData);

      const { response, error } = yield call(requestConfirmationMail, {
        secret,
        username,
        deviceData,
      });

      if (error) {
        const { skip } = yield call(checkDeviceConsentNeeded, error);
        if (skip) i = 2;
      }

      // confirmResend endpoint is used for resending confirmation email after registration. In this case it has a success response
      // It is also used for resending 2FA security code on login. In this case it has an error response
      if (response || (error && error.code === errorCodes.LOGIN_ERR_DEVICE_2FA)) {
        i = 2;
        const channelTypeSms = error?.detail?.channelType;

        // At the moment the translations are the same for both channels
        const channelTypesToMessageMap = {
          sms: I18n.t('login.pairing.resentCode'),
          email: I18n.t('login.pairing.resentCode'),
        };

        const foundedMessage = channelTypesToMessageMap[channelTypeSms];
        let message = !foundedMessage
          ? I18n.t('registration.successResendConfirmationMailTitle') // for registration confirmation email resend
          : foundedMessage; // for login 2FA security code resend

        yield put(
          notificationBannerActions.notifySuccess({
            message,
          }),
        );
      } else {
        i = 2;
        yield put(
          notificationBannerActions.notifyError({
            message: sendSecurityCode ? I18n.t('registration.errorSecurityCode') : error.message,
          }),
        );
      }
    } catch (err) {
      // console.log('Error', err);
    }
  }
}

function* authInit(): Saga<void> {
  setWindowParams('lang', DEFAULT_LANG);

  yield put(authActions.authInitDone());
}

// ********************************* WATCHERS **********************************

function* watchLocallyLoginUser(): Saga<void> {
  while (true) {
    yield take(authActions.LOCALLY_LOGIN_USER);
    yield call(localLogin); // blocking
  }
}

function* watchLogoutUser() {
  // yield take(authActions.TOKEN_VALIDATION.SUCCESS);
  while (true) {
    yield take(authActions.LOGOUT_USER);

    yield put(authActions.clearAuthTokens());

    // remove SEON session_id when user logs out
    setSeonSdkConfig(undefined);

    // persist any pending changes to remote & local storage
    yield put(remotePersistActions.flush());
    yield take(remotePersistActions.FLUSH_SUCCESS);

    // pause persistence and purge (local storage), so we are clean on next login
    yield put(remotePersistActions.purge());
    yield take([
      remotePersistActions.LOCAL_STORAGE_PURGE_SUCCESS,
      remotePersistActions.LOCAL_STORAGE_PURGE_FAILURE,
    ]);

    // store isFeatureStocksEnabled in ENV variable just in case it has been tempered with
    window.env.FEATURE_STOCKS_ENABLED = yield select(getFeatureStocksEnabled);

    // finally, reset entire state
    yield put(remotePersistActions.resetReduxState());
    yield put(remotePersistActions.rehydrate({ manualPersist: true }));
    yield take(remotePersistActions.REHYDRATE_SUCCESS);

    // After purging local storage and reseting redux state, store the isFeatureStocksEnabled value from config.js back to redux state
    yield put(environmentActions.toggleStocksEnabled(window?.env?.FEATURE_STOCKS_ENABLED));

    // notify success
    yield put(authActions.logoutUserSuccess());
  }
}

function* watchChangeAccountType() {
  while (true) {
    const { newAccountType } = yield take(authActions.CHANGE_ACCOUNT.REQUEST);
    const { username } = yield select(getUser);

    yield fork(changeAccountType, newAccountType);

    const { logout } = yield race({
      refreshSuccess: take(authActions.TOKEN_REFRESH.SUCCESS),
      logout: take(authActions.LOGOUT_USER_SUCCESS),
    });
    if (logout) {
      yield put(authActions.changeAccount.failure(username, newAccountType));
    }
  }
}

function* watchInvalidateTokens() {
  while (true) {
    const { usernameChanged } = yield take(authActions.INVALIDATE_TOKENS);
    yield call(invalidateTokens, undefined, usernameChanged);
  }
}

function* watchAllowBetaAccess() {
  while (true) {
    const { allow } = yield take(authActions.ALLOW_BETA_ACCESS);
    if (allow) {
      yield put(authActions.logoutUser());
    }
  }
}

function* watchResendConfirmationMail(): Saga<void> {
  yield takeLatest(authActions.START_RESEND_CONFIRMATION_MAIL, resendConfirmationMail);
}

function* autoLoginFlow(): Saga<void> {
  yield take(authActions.AUTH_INIT_DONE);
  const { accessToken } = yield select(getTokens);
  const deviceId = yield call(getClientId);
  const user = yield select(getUser);

  if (user && user.username) {
    // Load deviceConsentId into SEON configuration if it exist
    yield put(deviceMonitoringPreinitCheck(user.username));
    yield race({
      success: take(DEVICE_DATA_SUCCESS),
      end: take(DEVICE_DATA_END),
    });
  }

  if (deviceId && accessToken) {
    yield put(authActions.autoLogin());
  } else {
    yield put(authActions.autoLoggingFailed());
  }
}

function* authViewFlow(): Generator<any, any, any> {
  while (true) {
    const { error } = yield take(authActions.AUTH.FAILURE);
    const { loggedIn } = yield select(getUser);
    if (loggedIn) {
      return;
    }
    const pathname = yield select(getCurrentPath);

    // TODO: Handle this if even needed
    /* if (
      error?.code === errorCodes.LOGIN_ERR_DEVICE_2FA &&
      [paths.LOGIN, paths.AUTH_LOGIN].indexOf(pathname) === -1
    ) {
      history.push(paths.LOGIN);
    } */
    /* if (
      error?.code === errorCodes.LOGIN_ERR_NOTCONFIRM &&
      [paths.SIGNUP, paths.AUTH_SIGNUP].indexOf(pathname) === -1
    )
      history.push(paths.SIGNUP); */
  }
}

export default function* root(): Saga<void> {
  yield all([
    fork(autoLoginFlow),
    fork(authInit),
    fork(authFlow),
    fork(keychainFlow),
    fork(watchLocallyLoginUser),
    fork(watchChangeAccountType),
    fork(watchInvalidateTokens),
    fork(watchResendConfirmationMail),
    fork(watchLogoutUser),
    fork(watchAllowBetaAccess),
    fork(authViewFlow),
  ]);
}
