import { $Values } from 'utility-types';

export const MODAL_DIMENSIONS = {
  DEFAULT: 'default',
  WIDE: 'wide',
  SMALL: 'small',
} as const;

export type ModalDimensionsType = $Values<typeof MODAL_DIMENSIONS>;

export const MODAL_ACTIONS_MARGIN = {
  DEFAULT: 'default',
  BIG: 'big',
};

export type ModalActionsMarginType = $Values<typeof MODAL_ACTIONS_MARGIN>;
