export const INVALIDATE_REFERRAL = 'INVALIDATE_REFERRAL';
export const invalidateReferral = () => ({ type: INVALIDATE_REFERRAL });

export const FETCH_REFERRAL_REQUEST = 'FETCH_REFERRAL_REQUEST';
export const FETCH_REFERRAL_SUCCESS = 'FETCH_REFERRAL_SUCCESS';
export const FETCH_REFERRAL_FAILURE = 'FETCH_REFERRAL_FAILURE';

export const fetchReferral = {
  request: () => ({ type: FETCH_REFERRAL_REQUEST }),
  success: (_: any, response: ReferralReward) => ({ type: FETCH_REFERRAL_SUCCESS, response }),
  failure: (_: any, error: Error) => ({ type: FETCH_REFERRAL_FAILURE, error }),
};

export const INVITE_FRIENDS_REQUEST = 'INVITE_FRIENDS_REQUEST';
export const INVITE_FRIENDS_SUCCESS = 'INVITE_FRIENDS_SUCCESS';
export const INVITE_FRIENDS_FAILURE = 'INVITE_FRIENDS_FAILURE';

export const inviteFriends = {
  request: (payload: {
    link: string;
    userHasKyc: boolean;
    referredUserRewardAmount: string;
    referredUserPayoutCurrency: string;
  }) => ({
    type: INVITE_FRIENDS_REQUEST,
    payload,
  }),
  success: (payload: ShareAction) => ({
    type: INVITE_FRIENDS_SUCCESS,
    payload,
  }),
  failure: (error: Error) => ({ type: INVITE_FRIENDS_FAILURE, error }),
};
