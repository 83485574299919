import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { CONSENT_TYPES, REDIRECT_AFTER_CONSENT_ACCEPTED } from 'common/const/consent';
import { TNC_POPUP_TYPES } from 'common/const/termsAndConditions';
import {
  acceptFeatureTermsAndConditions,
  declineFeatureTermsAndConditions,
  showFeatureTermsAndConditions,
} from 'store/actions/termsAndConditions';
import { getIsFeatureTermsAndConditionsShown } from 'store/selectors/termsAndConditions';
import { TnCPopUpType } from 'types/termsAndConditions';
import { ModalProps } from 'types/modals';
import { useTopUps } from './topUps/useTopUps';

export const useFeatureSpecificTncConsentModal = ({ onClose }: ModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTopUpsTermsAndConditionsShown = useSelector(getIsFeatureTermsAndConditionsShown);
  const { isFeatureTopUpsBlocked } = useTopUps();

  const [activePopUp, setActivePopUp] = useState<TnCPopUpType | undefined>();
  const [error, setError] = useState(false);
  const [checkBoxState, setCheckBoxState] = useState(false);

  const toggleCheckbox = () => setCheckBoxState((prevState) => !prevState);

  const declineTerms = () => {
    dispatch(declineFeatureTermsAndConditions(CONSENT_TYPES.TOP_UPS));
    onClose();
  };

  const agreeTerms = () => {
    if (!checkBoxState) {
      setError(true);
      return;
    }

    dispatch(acceptFeatureTermsAndConditions(CONSENT_TYPES.TOP_UPS));
    setActivePopUp(undefined);
  };

  useEffect(() => {
    if (!isTopUpsTermsAndConditionsShown) {
      setActivePopUp(undefined);
      return;
    }
    setActivePopUp(TNC_POPUP_TYPES.TNC);
  }, [isTopUpsTermsAndConditionsShown]);

  useEffect(() => {
    if (checkBoxState) setError(false);
  }, [checkBoxState]);

  useEffect(() => {
    if (!isFeatureTopUpsBlocked && isTopUpsTermsAndConditionsShown) {
      dispatch(showFeatureTermsAndConditions(false));
      navigate(REDIRECT_AFTER_CONSENT_ACCEPTED[CONSENT_TYPES.TOP_UPS]);
    }
  }, [isFeatureTopUpsBlocked]);

  return {
    activePopUp,
    setActivePopUp,
    error,
    checkBoxState,
    toggleCheckbox,
    declineTerms,
    agreeTerms,
  };
};
