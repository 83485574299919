export const requiredDocuments = [
  {
    title: 'featureTermsAndConditions.documents.1',
    link: 'https://bisonapp.com/static/documents/BISON_Solaris_Sonderleistungen_Preise.pdf',
  },
  {
    title: 'featureTermsAndConditions.documents.2',
    link: 'https://bisonapp.com/static/documents/BISON_Solaris_Sonderbedingungen.pdf',
  },
  {
    title: 'featureTermsAndConditions.documents.3',
    link: 'https://bisonapp.com/static/documents/BISON_Solaris_Sonderbedingungen_Soforteinzahlung.pdf',
  },
];
