import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import Button from 'common/components/Button';
import ModalTemplate from 'common/components/Modal';
import { Text } from 'scenes/Kyc/SecuritiesKyc/IntroModal/styles';
import { Title, Actions } from './styles';
import { linkToHelpCenter } from 'common/utils/mailAndHelpCenter';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const FailedRenewKycModal: FC<Props> = ({ open, setOpen }: Props) => {
  const { t } = useTranslation();
  const supportLink = linkToHelpCenter();

  return (
    <ModalTemplate
      defaultOpen={open}
      header={<Title>{t('kyc.renewDocs.fail.modal.title')}</Title>}
      onCancel={() => setOpen(false)}
      actions={
        <Actions>
          <Button isFullWidth size="modal" onClick={() => setOpen(false)} title={t('common.close')} />
        </Actions>
      }
    >
      <Text>
        <ReactMarkdown linkTarget="_blank">
          {t('kyc.renewDocs.fail.modal.text', { supportLink })}
        </ReactMarkdown>
      </Text>
    </ModalTemplate>
  );
};

export default FailedRenewKycModal;
