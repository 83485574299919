import { paths } from 'common/urls';
import { matchRoutes } from 'react-router-dom';

export const MAIN_ROUTES_WITH_BACK_ARROW_ROUTE_DEFINITIONS: Record<
  string,
  { path: string; skipDesktop?: boolean }[]
> = {
  [paths.MARKET_OVERVIEW]: [{ path: paths.MARKET_OVERVIEW_CRYPTO }, { path: paths.MARKET_OVERVIEW_SECURITY }],
  [paths.LIMIT_ORDER]: [
    { path: paths.LIMIT_ORDER_CREATION },
    { path: paths.LIMIT_ORDER_DELETE },
    { path: paths.STOP_ORDER_CREATION },
    { path: paths.STOP_ORDER_DELETE },
  ],
  [paths.PRICE_ALERTS]: [{ path: paths.PRICE_ALERTS_CREATION }],
  [paths.SAVINGS_PLAN]: [{ path: paths.SAVINGS_PLAN_CREATION }, { path: paths.SAVINGS_PLAN_DELETE }],
  [paths.TECH_INDICATORS]: [{ path: paths.TECH_INDICATORS_CRYPTO }],
  [paths.TRADE_CURRENCY]: [{ path: paths.TRADE_CURRENCY_CRYPTO }, { path: paths.TRADE_ASSET }],
  [paths.KYC]: [{ path: paths.KYC_STEP }, { path: paths.KYC_IDENT_OPTIONS }],
  [paths.SETTINGS]: [
    { path: paths.PERSONAL_INFO, skipDesktop: true },
    { path: paths.ACCOUNT_INFO, skipDesktop: true },
    { path: paths.INFO_REPORT, skipDesktop: true },
    { path: paths.DEPOSIT, skipDesktop: true },
    { path: paths.WITHDRAW, skipDesktop: true },
    { path: paths.WITHDRAW_CONFIRMATION, skipDesktop: true },
    { path: paths.WITHDRAW_SUCCESS, skipDesktop: true },
    { path: paths.CRYPTO_DEPOSIT, skipDesktop: true },
    { path: paths.CRYPTO_WITHDRAW, skipDesktop: true },
    { path: paths.CRYPTO_WITHDRAW_AMOUNT, skipDesktop: true },
    { path: paths.CRYPTO_WITHDRAW_PASSWORD, skipDesktop: true },
    { path: paths.CRYPTO_WITHDRAW_WALLET, skipDesktop: true },
    { path: paths.CRYPTO_WITHDRAW_CONFIRMATION, skipDesktop: true },
    { path: paths.CRYPTO_WITHDRAW_SUCCESS, skipDesktop: true },
    { path: paths.CRYPTO_WITHDRAW_LEGAL, skipDesktop: true },
    { path: paths.OFFSET_ACCOUNTS, skipDesktop: true },
    { path: paths.REDEEM_CODE, skipDesktop: true },
    { path: paths.PAIRED_DEVICES, skipDesktop: true },
    { path: paths.FEATURE_REQUEST, skipDesktop: true },
    { path: paths.DELETE_ACCOUNT, skipDesktop: true },
    { path: paths.DESIGN, skipDesktop: true },
    { path: paths.REFERRAL, skipDesktop: true },
    { path: paths.TAX_EXEMPTION, skipDesktop: true },
    { path: paths.HELP_SECTION, skipDesktop: true },
  ],
};

export const checkUrlInPathname = ({
  pathname,
  isDesktopLayout,
}: {
  pathname: string | undefined;
  isDesktopLayout: boolean;
}) => {
  if (!pathname) return undefined;

  return Object.keys(MAIN_ROUTES_WITH_BACK_ARROW_ROUTE_DEFINITIONS).find((key) => {
    const matchedRoutes = matchRoutes(MAIN_ROUTES_WITH_BACK_ARROW_ROUTE_DEFINITIONS[key], pathname);
    return (
      matchedRoutes !== null && matchedRoutes.find((item) => !isDesktopLayout || !item.route.skipDesktop)
    );
  });
};
