/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Asset, CryptoAssets } from 'types/assets';
import {
  CRYPTO_CURRENCIES_DEVIATIONS,
  cryptoColors,
  SPECIAL_CRYPTO_CURRENCIES_DEVIATIONS,
  SPECIAL_LIQUID_CRYPTO_CURRENCIES,
} from 'common/const/assets';
import { getItem, removeItem } from 'services/storage';

import { PortfolioAsset } from 'types/portfolio';

const ASSETS_KEY = 'persist:assets';

const getCurrenciesFromStorage = (): {
  ids: string[];
  entities: CryptoAssets;
} => {
  const { response } = getItem(ASSETS_KEY);

  if (!response || !response.item) return { ids: [], entities: {} };

  const assetsState = JSON.parse(response.item);

  // If old assets store structure saved in storage, we're removing this item and then replace with the new one
  if (!assetsState.entities) {
    removeItem(ASSETS_KEY);
    return { ids: [], entities: {} };
  }

  const assets = JSON.parse(assetsState.entities);

  return assets.crypto;
};

export const currencies = getCurrenciesFromStorage().entities;

export const cryptoCodes = getCurrenciesFromStorage().ids.reduce((acc, value) => {
  acc[value] = value;
  return acc;
}, {});

export const cryptoColor = (code: string) => (cryptoColors[code] ?? '#F1F2F3') as string;

export const calculateStockPriceChange = (relativePriceChange: number): number => {
  if (!relativePriceChange) return 0;

  if (relativePriceChange === 1) return 0;

  return relativePriceChange * 100;
};

export const sortAssetsAlphabetically = (a: Asset | PortfolioAsset, b: Asset | PortfolioAsset) =>
  a.displayName.localeCompare(b.displayName, undefined, { sensitivity: 'base' });

export const getCryptoMaxDeviation = (orderSize: number, code: string) => {
  let maxDeviation = 0;
  let deviationTriggerCondition = '';

  if (orderSize < 10000) {
    deviationTriggerCondition = `orderSize<10000`;
    maxDeviation = SPECIAL_LIQUID_CRYPTO_CURRENCIES[code.toLowerCase()]
      ? SPECIAL_CRYPTO_CURRENCIES_DEVIATIONS.MIN
      : CRYPTO_CURRENCIES_DEVIATIONS.MIN;
  }

  if (orderSize >= 10000 && orderSize < 50000) {
    deviationTriggerCondition = `10000<orderSize<50000`;
    maxDeviation = SPECIAL_LIQUID_CRYPTO_CURRENCIES[code.toLowerCase()]
      ? SPECIAL_CRYPTO_CURRENCIES_DEVIATIONS.MEDIUM
      : CRYPTO_CURRENCIES_DEVIATIONS.MEDIUM;
  }

  deviationTriggerCondition = `orderSize>50000`;
  maxDeviation = SPECIAL_LIQUID_CRYPTO_CURRENCIES[code.toLowerCase()]
    ? SPECIAL_CRYPTO_CURRENCIES_DEVIATIONS.MAX
    : CRYPTO_CURRENCIES_DEVIATIONS.MAX;

  return {
    maxDeviation,
    deviationTriggerCondition: {
      showCondition: deviationTriggerCondition,
      orderSize,
    },
  };
};
