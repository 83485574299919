import { NavigateFunction, NavigateOptions, To } from 'react-router';

let navigate: NavigateFunction | undefined = undefined;

export const setNavigate = (nav: NavigateFunction) => {
  navigate = nav;
};

export const getNavigate = (to: To, options?: NavigateOptions) => {
  if (!navigate) {
    console.error('Navigate function has not been set');
    return;
  }

  navigate(to, options);
};
