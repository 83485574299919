/* eslint-disable @typescript-eslint/no-unsafe-return */
import { of, fromEvent } from 'rxjs';
import { map, tap, exhaustMap, filter, ignoreElements } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { getLastClosedAt, getDeferredAndroidInstallPrompt } from '../selectors/androidBanner';
import * as actions from '../actions/androidBanner';

export const showAndroidBannerEpic = (action$, state$) =>
  // Listen for beforeinstallprompt event to indicate your app can be installed
  // https://developers.google.com/web/fundamentals/app-install-banners/native#trigger
  fromEvent(window, 'beforeinstallprompt').pipe(
    // Prevent Chrome 67 and earlier from automatically showing the prompt, and
    // prevent Chrome 76 and later from showing the mini-infobar
    tap((e) => e.preventDefault()),
    map((deferredPrompt) => ({
      deferredPrompt,
      lastClosedAt: getLastClosedAt(state$.value),
    })),
    filter(
      // only show banner if sufficient amount of time has passed since last dismissal (60 days)
      ({ lastClosedAt }) => lastClosedAt == null || (Date.now() - lastClosedAt) / (1000 * 60 * 60 * 24) > 60,
    ),
    map(({ deferredPrompt }) => actions.showAndroidBanner(deferredPrompt)),
  );

export const showNativeAppInstallPromptEpic = (action$, state$) =>
  action$.pipe(
    // user clicks install button on banner
    ofType(actions.SHOW_ANDROID_INSTALL_PROMPT),
    exhaustMap(() =>
      of(state$.value).pipe(
        map(getDeferredAndroidInstallPrompt),
        // Show the prompt
        tap((deferredPrompt) => deferredPrompt.prompt()),
        ignoreElements(),
      ),
    ),
  );

const rootEpic = combineEpics(showAndroidBannerEpic, showNativeAppInstallPromptEpic);
export default rootEpic;
