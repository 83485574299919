import styled from 'styled-components';
import bisonFailImg from 'assets/img/fail_img.svg';
import { Theme } from 'types/theme';
import { devices } from 'common/styles';

export const ErrorImg = styled.img.attrs({
  src: bisonFailImg,
})`
  display: block;
  text-align: center;
  width: 200px;
  height: auto;
  margin: 0 auto;

  @media ${devices.tablet} {
    width: 256px;
  }
`;

export const Content = styled.div`
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.secondary} !important;
`;

export const ContentWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.div.attrs({ className: 'small-text primary-color-text' })`
  padding-bottom: 100px;
  text-align: center;
`;

export const ErrorHeaderText = styled.h2`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.critical};
  padding-bottom: 24px;
  text-align: center;
`;
