/* eslint-disable @typescript-eslint/no-unsafe-return */
import { getIsUserInPaperTrading } from 'store/selectors/auth';
import { BLOCKED_FEATURES_VALUES } from 'common/const';
import { State } from 'store/types/store';
import { TermsAndConditionsData } from 'types/termsAndConditions';
import { $Values } from 'utility-types';
import { ConsentType } from 'types/consent';

// CRYPTO
export const getIsCryptoTermsAndConditionsDataInitiallyLoaded = (state: State): boolean =>
  state.termsAndConditions.crypto.isDataInitiallyLoaded;

export const getCryptoTermsAndConditionsData = (state: State): TermsAndConditionsData =>
  state.termsAndConditions.crypto.data;

export const getIsCryptoTermsAndConditionsClickedAway: (state: State) => boolean = (state) =>
  state.termsAndConditions.crypto.isTermsAndConditionsClickedAway;

export const getIsCryptoTermsAccepted = (state: State): boolean =>
  state.termsAndConditions.crypto.data.hasAcceptedLatestTerms;

export const getCryptoTermsAndConditionsBlockedFeatures = (state: State): string[] =>
  state.termsAndConditions.crypto.data.blockedFeatures || [];

export const getIsCryptoTradingDisabledWithoutAcceptingTandC = (state: State): boolean => {
  const isFeatureBlocked = (state.termsAndConditions.crypto.data.blockedFeatures || []).includes(
    BLOCKED_FEATURES_VALUES.TRADING,
  );

  return (
    !state.termsAndConditions.crypto.data.hasAcceptedLatestTerms &&
    isFeatureBlocked &&
    !getIsUserInPaperTrading(state)
  );
};

export const getIsCryptoFeatureDisabledWithoutAcceptingTandC =
  (state: State) =>
  (feature: $Values<typeof BLOCKED_FEATURES_VALUES>): boolean => {
    const isFeatureBlocked = (state.termsAndConditions.crypto.data.blockedFeatures || []).includes(feature);
    const isTradingFeatureBlocked = (state.termsAndConditions.crypto.data.blockedFeatures || []).includes(
      BLOCKED_FEATURES_VALUES.TRADING,
    );

    return (
      !state.termsAndConditions.crypto.data.hasAcceptedLatestTerms &&
      (isFeatureBlocked || isTradingFeatureBlocked) &&
      !getIsUserInPaperTrading(state)
    );
  };

export const getCryptoTermsAndConditionsFailedAttempts = (state: State) =>
  state.termsAndConditions.crypto.failedAttempts;

// STOCKS

export const getIsStocksTermsAndConditionsDataInitiallyLoaded = (state: State): boolean =>
  state.termsAndConditions.stocks.isDataInitiallyLoaded;

export const getStocksTermsAndConditionsData = (state: State): TermsAndConditionsData =>
  state.termsAndConditions.stocks.data;

export const getIsStocksTermsAccepted = (state: State): boolean =>
  state.termsAndConditions.stocks.data.hasAcceptedLatestTerms;

export const getIsStocksTermsAndConditionsClickedAway: (state: State) => boolean = (state) =>
  state.termsAndConditions.stocks.isTermsAndConditionsClickedAway;

export const getIsStocksTradingDisabledWithoutAcceptingTandC = (state: State): boolean => {
  const isFeatureBlocked = (state.termsAndConditions.stocks.data.blockedFeatures || []).includes(
    BLOCKED_FEATURES_VALUES.TRADING,
  );

  return (
    !state.termsAndConditions.crypto.data.hasAcceptedLatestTerms &&
    isFeatureBlocked &&
    !getIsUserInPaperTrading(state)
  );
};

export const getIsFeatureTermsAndConditionsShown = (state: State): boolean =>
  state.termsAndConditions.featureSpecificConsent?.isPopupShown ?? false;

export const getIsFeatureSpecificTncNotAccepted = (state: State): boolean => {
  if (
    !state.termsAndConditions?.featureSpecificConsent?.consent ||
    !state.termsAndConditions?.featureSpecificConsent.isDataInitiallyLoaded
  )
    return false;

  return (
    !state.termsAndConditions.featureSpecificConsent.consent.consentState ||
    state.termsAndConditions.featureSpecificConsent.consent.consentState === 'Declined'
  );
};

export const getFeatureSpecificTncConsent = (state: State): ConsentType | Record<string, never> =>
  state.termsAndConditions.featureSpecificConsent.consent;
