import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import I18n from 'i18next';
import { paths } from 'common/urls';
import { getUser } from 'store/selectors/auth';
import { setTermsAndConditionsClickedAwayTimestamp } from 'store/actions/settings';
import { deleteLastPaymentMethod } from 'store/actions/topUps';
import { resetFeatureTermsAndConditions } from 'store/actions/termsAndConditions';
import Button from '../Button';
import { BetaSettingsContainer, BettaSettingsElementsWrapper, BetaSettingsHeader } from './styles';

const BetaSettings = () => {
  const { username } = useSelector(getUser);
  const dispatch = useDispatch();
  const isBetaSettingsAllowed = username.includes('@sowalabs.com'); // only @sowalabs.com domain see beta settings in non PROD environment

  if (!isBetaSettingsAllowed) return null;

  const currentLang = I18n.language;

  return (
    <BetaSettingsContainer>
      <BetaSettingsHeader>🔥 Beta Settings 🔥</BetaSettingsHeader>
      <BettaSettingsElementsWrapper>
        <Button type="button" to={paths.RENEW_KYC_DOCS} title="Renew KYC flow" />
        <Button
          type="button"
          disabled={currentLang === 'de'}
          onClick={async () => {
            await I18n.changeLanguage('de');
          }}
          title="Switch to German language"
        />
        <Button
          type="button"
          disabled={currentLang === 'en'}
          onClick={async () => {
            await I18n.changeLanguage('en');
          }}
          title="Switch to English language"
        />
        <Button
          type="button"
          onClick={() => dispatch(setTermsAndConditionsClickedAwayTimestamp(true))}
          title="Reset TNC clicked away timestamp"
        />
        <Button
          type="button"
          onClick={() => dispatch(deleteLastPaymentMethod())}
          title="Delete last payment methods"
        />
        <Button
          type="button"
          onClick={() => dispatch(resetFeatureTermsAndConditions())}
          title="Reset Feature Specific TNC accepted"
        />
      </BettaSettingsElementsWrapper>
    </BetaSettingsContainer>
  );
};

export default BetaSettings;
