import { createTheme, iconButtonClasses, inputBaseClasses, outlinedInputClasses } from '@mui/material';
import { colors } from 'common/utils/theme/colors';
import { Theme } from 'types/theme';

export const useInputStyles = (theme: Theme) =>
  createTheme({
    typography: {
      fontFamily: theme.typography.fontFamily.openSans,
    },
    palette: { primary: { main: colors.main } },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: theme.typography.text.standard.fontSize,
            color: theme.color.foreground.primary,
            height: `${theme.assets.input.standard.height}`,
            width: '100%',
            [`& .${inputBaseClasses.input}`]: {
              padding: '0 10px',
              height: `${theme.assets.input.standard.height}`,
              color: theme.color.foreground.primary,
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}, &:hover .${outlinedInputClasses.notchedOutline}`]:
              {
                borderRadius: '4px',
                border: `1px solid ${theme.color.border.inputFocused}`,
              },
            [`& .${iconButtonClasses.root}`]: {
              color: theme.color.foreground.primaryLight,
            },
          },
          input: {
            [`&.Mui-disabled`]: {
              textFillColor: theme.color.foreground.disabledInput,
              color: theme.color.foreground.disabledInput,
              backgroundColor: theme.color.background.action,
              borderColor: theme.color.border.table,
            },
            [`&:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active`]: {
              fontFamily: theme.typography.fontFamily.openSans,
              fontSize: theme.typography.text.standard.fontSize,
              webkitTextFillColor: `${theme.color.foreground.primary}`,
              webkitBoxShadow: '0 0 0px 1000px transparent inset',
              transition: 'background-color 5000s ease-in-out 0s',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            paddingLeft: '0',
            backgroundColor: theme.color.background.input,
          },
          notchedOutline: {
            '&.Mui-error': {
              border: `1px solid ${colors.red}`,
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            padding: '0 0 0 10px',
          },
        },
      },
    },
  });
