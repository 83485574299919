import { AndroidBannerState } from 'store/types/androidBanner';
import * as actions from '../actions/androidBanner';
import { AnyAction } from 'redux';

const initialState: AndroidBannerState = {
  isBannerShown: false,
  lastClosedAt: 0,
  deferredPrompt: undefined,
};

export function androidBannerReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case actions.SHOW_ANDROID_BANNER:
      return {
        ...state,
        isBannerShown: true,
        deferredPrompt: action.deferredPrompt,
      };

    case actions.CLOSE_ANDROID_BANNER:
      return {
        ...state,
        isBannerShown: false,
        lastClosedAt: action.closedAt,
      };

    case actions.SHOW_ANDROID_INSTALL_PROMPT:
      return {
        ...state,
        isBannerShown: false,
      };

    default:
      return state;
  }
}

export default androidBannerReducer;
