import { TRADING_RULES_TYPES } from 'common/const';
import { isBefore } from 'date-fns';
import { createSelector } from 'reselect';
import { State } from 'store/types/store';
import { TradingRule } from 'types/limitOrders';
import { TradingRule as StopOrderTradingRule } from 'types/tradingRules';

const getLimitOrders = (state: State) => state.limitOrders.tradingRules;
const getStopOrders = (state: State) => state.stopOrders.tradingRules;

export const getLimitOrdersPageItems = createSelector(
  [getLimitOrders, getStopOrders],
  (limitOrders: TradingRule[], stopOrders: StopOrderTradingRule[]) =>
    [
      ...limitOrders.map((rule) => ({ ...rule, category: TRADING_RULES_TYPES.limit })),
      ...stopOrders.map((rule) => ({ ...rule, category: TRADING_RULES_TYPES.stop })),
    ].sort((a, b) => (isBefore(new Date(a.createdAt), new Date(b.createdAt)) ? 1 : -1)),
);
