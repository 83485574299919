import { paths } from 'common/urls';
import { call, select } from 'redux-saga/effects';
import getCurrentPath from 'store/selectors/navigation';
import { trackAppEvent } from 'store/actions/tracking';
import {
  trackTradingRulePressButtonNewTradingRule,
  trackTradingRuleScrollListTradingRule,
  trackTradingRuleDetailPressButtonDeleteTradingRule,
} from '../biEventDefinitions';

export function* logTradingRulesEvents(action: ReturnType<typeof trackAppEvent>): Generator<any, any, any> {
  const lastScreenId: string = yield select(getCurrentPath);
  const limitOrdersViewScreenId = paths.LIMIT_ORDER;
  const limitOrdersDeleteScreenId = paths.LIMIT_ORDER_DELETE;
  const stopOrdersDeleteScreenId = paths.STOP_ORDER_DELETE;

  switch (action.eventName) {
    case 'tradingRulePressNewTradingRule':
      if (lastScreenId.includes(limitOrdersViewScreenId)) {
        yield call(
          trackTradingRulePressButtonNewTradingRule,
          limitOrdersViewScreenId,
          action.payload.totalCount,
          action.payload.loadedCount,
          action.payload.type,
        );
      }
      break;

    case 'tradingRuleScrollTradingRule':
      yield call(
        trackTradingRuleScrollListTradingRule,
        limitOrdersViewScreenId,
        action.payload.totalCount,
        action.payload.loadedCount,
      );
      break;

    case 'tradingRuleDetailPressDelete':
      // eslint-disable-next-line no-case-declarations
      const { tradingRuleId, type } = action.payload;
      // eslint-disable-next-line no-case-declarations
      const screenId = type === 'limit' ? limitOrdersDeleteScreenId : stopOrdersDeleteScreenId;
      yield call(trackTradingRuleDetailPressButtonDeleteTradingRule, screenId, tradingRuleId, type);
      break;

    default:
      break;
  }
}
