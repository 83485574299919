import { ApiError } from 'types/error';
import { NewsItem } from 'types/news';

import { createControlActions, createControlTypes } from './_common';

export const SYNC_NEWS_FEED = createControlTypes('SYNC_NEWS_FEED');
export const syncNewsFeed = createControlActions(SYNC_NEWS_FEED);

export const fetchNewsFeedRequest = (crypto: string) =>
  ({
    type: 'FETCH_NEWS_FEED_REQUEST',
    payload: { crypto },
  } as const);

export const fetchNewsFeedSuccess = (payload: { newsFeed: NewsItem[]; crypto: string }) =>
  ({
    type: 'FETCH_NEWS_FEED_SUCCESS',
    payload,
  } as const);

export const fetchNewsFeedFailure = (error: ApiError, payload: { crypto: string }) =>
  ({
    type: 'FETCH_NEWS_FEED_FAILURE',
    error,
    payload,
  } as const);

export const loadNewsFeedIfNeeded = () => ({ type: 'LOAD_NEWS_FEED_IF_NEEDED' as const });

export const changeNewsLang = (payload: { lang: string }) =>
  ({
    type: 'CHANGE_NEWS_LANGUAGE',
    payload,
  } as const);

export const invalidateNewsFeed = () => ({ type: 'INVALIDATE_NEWS_FEED' as const });
