import {
  Asset,
  ASSET_CLASSES,
  ASSET_TAGS,
  AssetClass,
  AssetSecurityClass,
  Crypto,
  Security,
} from 'types/assets';
import { State } from 'store/types/store';
import { getMarketOverviewAssetTag } from '../filters/selectors';
import { createSelector, Dictionary, EntityId } from '@reduxjs/toolkit';
import { ASSETS_SLICE_NAME } from 'common/const/slices';
import { cryptoAdapter, securityAdapter } from './adapters';

// SLICE SELECTORS
const selectSlice = (state: State) => state[ASSETS_SLICE_NAME];

export const cryptoSelectors = cryptoAdapter.getSelectors();
export const securitySelectors = securityAdapter.getSelectors();

// ASSETS
/**
 * Retrieves an asset based on the provided asset class, code, and security class.
 *
 * @param {State} state - The current state of the store.
 * @param {AssetClass} assetClass - The class of the asset (e.g., CRYPTO, SECURITY).
 * @param {string} code - The unique code of the asset.
 * @param {AssetSecurityClass | undefined} securityClass - The security class of the asset, if applicable.
 * @returns {Asset | undefined} - The asset if found, otherwise undefined.
 */
export const getAsset = (
  state: State,
  assetClass: AssetClass,
  code: string,
  securityClass: AssetSecurityClass | undefined,
): Asset | undefined => {
  const selectedSlice = selectSlice(state);

  if (assetClass === ASSET_CLASSES.CRYPTO)
    return cryptoSelectors.selectById(selectedSlice.entities.crypto, code);

  if (!securityClass) return undefined;

  return securitySelectors.selectById(selectedSlice.entities.securities, code);
};

/**
 * Retrieves the IDs of assets based on the provided asset class.
 *
 * @param {State} state - The current state of the store.
 * @param {AssetClass} assetClass - The class of the asset (e.g., CRYPTO, SECURITY).
 * @returns {EntityId[]} - An array of asset IDs.
 */
export const getAssetsIds = (state: State, assetClass: AssetClass): EntityId[] => {
  const selectedSlice = selectSlice(state);

  if (assetClass === ASSET_CLASSES.CRYPTO) return cryptoSelectors.selectIds(selectedSlice.entities.crypto);

  return securitySelectors.selectIds(selectedSlice.entities.securities);
};

/**
 * Retrieves the entities of assets based on the provided asset class.
 *
 * @param {State} state - The current state of the store.
 * @param {AssetClass} assetClass - The class of the asset (e.g., CRYPTO, SECURITY).
 * @returns {Dictionary<Crypto | Security>} - A dictionary of asset entities (e.g., {btc: {...assetData}}).
 */
export const getAssetEntities = (state: State, assetClass: AssetClass): Dictionary<Crypto | Security> => {
  const selectedSlice = selectSlice(state);

  if (assetClass === ASSET_CLASSES.CRYPTO)
    return cryptoSelectors.selectEntities(selectedSlice.entities.crypto);

  return securitySelectors.selectEntities(selectedSlice.entities.securities);
};

/**
 * Retrieves sorted assets based on the provided asset class and comparator function.
 *
 * @param {State} state - The current state of the store.
 * @param {AssetClass} assetClass - The class of the asset (e.g., CRYPTO, SECURITY).
 * @param {(a: Asset, b: Asset) => number} comparator - The comparator function to sort the assets.
 * @returns {Asset[]} - An array of sorted assets.
 */
export const getSortedAssetEntities = createSelector(
  [
    (state: State, assetClass: AssetClass, comparator: (a: Asset, b: Asset) => number) => ({
      assets: getAssetEntities(state, assetClass) as Record<string, Asset>,
      comparator,
    }),
  ],
  ({ assets, comparator }) => {
    return Object.values(assets)
      .sort(comparator)
      .reduce((acc, asset) => {
        acc = { ...acc, [asset.code.toLowerCase()]: asset };
        return acc;
      }, {});
  },
);

/**
 * Retrieves the names of assets based on the provided asset class.
 *
 * @param {State} state - The current state of the store.
 * @param {AssetClass} assetClass - The class of the asset (e.g., CRYPTO, SECURITY).
 * @returns {Record<string, string>} - An object where the keys are asset codes (in lowercase) and the values are asset display names.
 */
export const getAssetNames = createSelector(
  (state: State, assetClass: AssetClass) => getAssetEntities(state, assetClass),
  (assets) => {
    return Object.fromEntries(
      Object.values(assets).map((asset) => [asset?.code.toLowerCase(), asset?.displayName]) as [
        string,
        string,
      ][],
    );
  },
);

export const getAllAssetsForCurrentAssetTag = createSelector(
  [
    getMarketOverviewAssetTag,
    (state: State) => cryptoSelectors.selectAll(state.assets.entities.crypto),
    (state: State) => securitySelectors.selectAll(state.assets.entities.securities),
  ],
  (assetTag, cryptos, securities) => (assetTag === ASSET_TAGS.CRYPTO ? cryptos : securities),
);

// CRYPTO
export const getCryptoPrecision = (state: State, code: string) =>
  cryptoSelectors.selectById(state.assets.entities.crypto, code)?.cryptoDetails?.decimalPlaces;

export const getCryptoMinWithdrawalAmount = (state: State, code: string) =>
  cryptoSelectors.selectById(state.assets.entities.crypto, code)?.cryptoDetails?.minWithdrawalAmount;

export const getCryptoPairs = createSelector(
  (state: State) => getAssetEntities(state, ASSET_CLASSES.CRYPTO),
  (cryptos: Dictionary<Crypto>) => {
    return Object.fromEntries(
      Object.values(cryptos).map((crypto) => [crypto?.code.toLowerCase(), crypto?.pair as string]) as [
        string,
        string,
      ][],
    );
  },
);

// SECURITIES
export const getIsMasterDataLoading = (state: State): boolean => {
  const selectedSlice = selectSlice(state);
  return selectedSlice.entities.isMasterDataLoading;
};
