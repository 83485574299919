import { useEffect, useState } from 'react';
import { ModalProps, ModalType } from './types';
import { useLocation } from 'react-router';
import { privatePaths } from 'common/urls';
import { matchPath } from 'react-router';

export const useModalQueueReset = (removeByType: (type: ModalType) => void) => {
  const location = useLocation();

  const [wasPreviousPagePrivate, setWasPreviousPagePrivate] = useState<boolean>(false);

  useEffect(() => {
    const isPrivatePage = Object.values(privatePaths).some((path: string) =>
      matchPath({ path }, location.pathname),
    );

    if (wasPreviousPagePrivate && !isPrivatePage) {
      removeByType('private');
    }

    setWasPreviousPagePrivate(isPrivatePage);
  }, [location]);
};
