/* eslint-disable @typescript-eslint/no-unsafe-return */
import { messagesAdapter, messagesWithDocumentsAdapter } from 'store/slices/inbox/adapters';
import { LocalRootState } from 'store/slices';
import { FILTERS_SLICE_NAME, INBOX_SLICE_NAME } from 'common/const/slices';

const selectSlice = (state: LocalRootState) => state[INBOX_SLICE_NAME];
const selectFiltersSlice = (state: LocalRootState) => state[FILTERS_SLICE_NAME];

export const messagesSelectors = messagesAdapter.getSelectors();
export const messagesWithDocumentsSelectors = messagesWithDocumentsAdapter.getSelectors();
export const selectAllMessages = (state: LocalRootState) => {
  const selectedSlice = selectSlice(state);
  return messagesSelectors.selectAll(selectedSlice.messages.items);
};

export const selectIsInboxLoading = (state: LocalRootState) => {
  const selectedSlice = selectSlice(state);
  return selectedSlice.messages.isLoading;
};

export const selectAllMessagesWithDocuments = (state: LocalRootState) => {
  const selectedSlice = selectSlice(state);
  return messagesWithDocumentsSelectors.selectAll(selectedSlice.messagesWithDocuments.items);
};

export const selectAllMessagesByCategory = (state: LocalRootState) => {
  const filtersSlice = selectFiltersSlice(state);
  return filtersSlice.inbox.selectedCategory === 'notifications'
    ? selectAllMessages(state)
    : selectAllMessagesWithDocuments(state);
};

export const selectIsPostboxLoading = (state: LocalRootState) => {
  const selectedSlice = selectSlice(state);
  return selectedSlice.messagesWithDocuments.isLoading;
};

export const selectNumOFUnreadMessages = (state: LocalRootState) => {
  const selectedSlice = selectSlice(state);
  const { postboxCount, inboxCount } = selectedSlice.numOfUnread;
  return postboxCount + inboxCount;
};

export const selectNumOfUnreadByCategory = (state: LocalRootState) => {
  const selectedSlice = selectSlice(state);
  const filtersSlice = selectFiltersSlice(state);
  const { postboxCount, inboxCount } = selectedSlice.numOfUnread;

  return filtersSlice.inbox.selectedCategory === 'notifications' ? inboxCount : postboxCount;
};

export const selectCountLastUpdated = (state: LocalRootState) => {
  const selectedSlice = selectSlice(state);
  return selectedSlice.countLastUpdated;
};

export const selectLastUpdated = (state: LocalRootState) => {
  const selectedSlice = selectSlice(state);
  const filtersSlice = selectFiltersSlice(state);
  const messagesByCategory =
    filtersSlice.inbox.selectedCategory === 'notifications'
      ? selectedSlice.messages
      : selectedSlice.messagesWithDocuments;
  return messagesByCategory.lastUpdated;
};

export const selectReadMessageWithDocumentsLoading = (state: LocalRootState) => {
  const selectedSlice = selectSlice(state);
  return selectedSlice.readMessageWithDocumentsLoading;
};

export const selectSingleMessageWithDocument = (state: LocalRootState) => {
  const selectedSlice = selectSlice(state);
  return selectedSlice.singleMessageWithDocument;
};

export const selectAreAllLoaded = (state: LocalRootState) => {
  const selectedSlice = selectSlice(state);
  return selectedSlice.messages.areAllLoaded;
};

export const selectMessagesWithDocumentsFullyLoaded = (state: LocalRootState) => {
  const selectedSlice = selectSlice(state);
  return selectedSlice.messagesWithDocuments.fullyLoaded;
};

export const selectIsAnyMessagesImportant = (state: LocalRootState) => {
  const selectedSlice = selectSlice(state);

  return (
    selectedSlice.numOfUnread.isNotificationRequired ||
    messagesSelectors
      .selectAll(selectedSlice.messages.items)
      .filter(({ isNotificationRequired }) => isNotificationRequired).length > 0
  );
};

export const selectIsOnInboxPage = (state: LocalRootState) => {
  const selectedSlice = selectSlice(state);
  return selectedSlice.isOnInboxPage;
};
