import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import InputLabelComponent from 'common/components/CryptoEuroInput/InputLabelComponent/InputLabelComponent';
import { useTradingFormData } from 'common/hooks/useTradingFormData';
import { Form } from 'common/components/TradingRulesCommonComponents/TradingRulesCreationStyles';
import CryptoEuroInput from 'common/components/CryptoEuroInput/CryptoEuroInput';
import { handleValidInput } from 'common/utils/tradingRulesUtils';
import { DefaultTradingKeys, TradingFormProps } from 'types/tradingRules';

export type ExtendedLOKeys = DefaultTradingKeys | 'limitPrice';
export type ExtendedSOKeys = DefaultTradingKeys | 'stopPrice';
export type LabelsBag = Record<ExtendedLOKeys | ExtendedSOKeys, string>;
export type Props = TradingFormProps<LabelsBag>;

const TradingForm = ({
  formik,
  currencyLabel,
  labelTexts,
  portfolioCash,
  cryptoInstruments,
  isBuy,
  selectedCrypto,
  currencyPrecision,
  specificKey,
  buyPrice,
  sellPrice,
  cryptoPrecision,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { errors } = formik;
  const {
    handleMaxValueClick,
    validatedValues: {
      spendAmount: { setSpendAmount, spendAmount },
      cryptoAmount: { setCryptoAmount, cryptoAmount },
      limitPrice: { setLimitPrice, limitPrice },
    },
    maxAmountAvailableToSell,
    limitPriceWarning,
    setIsActive: { setIsLimitPriceActive, setIsCryptoAmountActive, setIsSpendAmountActive },
  } = useTradingFormData({
    formik,
    portfolioCash,
    cryptoInstruments,
    isBuy,
    selectedCrypto,
    specificKey,
    buyPrice,
    sellPrice,
    cryptoPrecision,
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <CryptoEuroInput
        type="string"
        fieldValue={limitPrice}
        onChange={handleValidInput(setLimitPrice)}
        labelComponent={labelTexts[specificKey]}
        currencyLabel={currencyLabel[specificKey]}
        id="limitPrice"
        warning={!errors[specificKey] && limitPriceWarning}
        error={errors[specificKey]}
        setIsActive={setIsLimitPriceActive}
        currencyPrecision={currencyPrecision}
      />
      <CryptoEuroInput
        formatOnBlur
        type="string"
        fieldValue={cryptoAmount}
        onChange={handleValidInput(setCryptoAmount)}
        labelComponent={
          isBuy ? (
            labelTexts.cryptoAmount
          ) : (
            <InputLabelComponent
              handleMaxValueClick={handleMaxValueClick}
              labelText={labelTexts.cryptoAmount}
              amountLabel={t('automation.web_creationAvailableCrypto', {
                currency: selectedCrypto.toUpperCase(),
              })}
              amount={maxAmountAvailableToSell}
              isErrorOccurred={errors.cryptoAmount !== undefined}
              isFiat={false}
              selectedCrypto={selectedCrypto}
              cryptoPrecision={cryptoPrecision}
            />
          )
        }
        currencyLabel={currencyLabel.cryptoAmount}
        id="cryptoAmount"
        error={errors.cryptoAmount}
        setIsActive={setIsCryptoAmountActive}
        isCrypto
        cryptoName={selectedCrypto}
      />
      <CryptoEuroInput
        formatOnBlur
        type="string"
        fieldValue={spendAmount}
        onChange={handleValidInput(setSpendAmount)}
        currencyLabel={currencyLabel.spendAmount}
        id="spendAmount"
        error={errors.spendAmount}
        setIsActive={setIsSpendAmountActive}
        labelComponent={
          isBuy ? (
            <InputLabelComponent
              handleMaxValueClick={handleMaxValueClick}
              labelText={labelTexts.spendAmount}
              amountLabel={t('automation.web_creationAvailableFiat')}
              amount={maxAmountAvailableToSell}
              isErrorOccurred={errors.spendAmount !== undefined}
              isFiat
              selectedCrypto={selectedCrypto}
            />
          ) : (
            labelTexts.spendAmount
          )
        }
      />
    </Form>
  );
};

export default TradingForm;
