import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import stakingPhasedRolloutImage from 'assets/img/stakingPhasedRollout.svg';
import ModalTemplate from 'common/components/Modal/index';
import {
  ButtonWrapper,
  ContainerCenter,
  Text,
  Title,
  ImageWrapper,
} from 'common/components/Modal/StakingPhasedRolloutModal/styles';
import { ReactSVG } from 'react-svg';
import Button from 'common/components/Button';

type Props = {
  onClose: () => void;
};

const StakingPhasedRolloutModal = ({ onClose }: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      defaultOpen
      actions={
        <ButtonWrapper>
          <Button title={t('common.close')} onClick={onClose} size="large" />
        </ButtonWrapper>
      }
      onCancel={onClose}
    >
      <ContainerCenter>
        <ImageWrapper>
          <ReactSVG src={stakingPhasedRolloutImage} />
        </ImageWrapper>

        <Title>{t(`stakingRollout.banner.title`)}</Title>
        <Text>
          <Trans i18nKey={`stakingRollout.banner.text`} />
        </Text>
      </ContainerCenter>
    </ModalTemplate>
  );
};

export default StakingPhasedRolloutModal;
