import styled from 'styled-components';
import { ColorTokens, IconSize, Theme } from 'types/theme';

type IconTone = keyof ColorTokens['foreground'];

type IconProps = {
  name: string;
  tone?: IconTone;
  theme: Theme;
  customColor?: string;
  sizeValue: IconSize;
  rotationAngle?: number;
};

const Icon = styled.span.attrs(({ name }: { name: string }) => ({
  className: name,
}))`
  ${({ theme, sizeValue = 'standard', tone = 'primary', customColor, rotationAngle }: IconProps): string => `
    display: inline-block;
    font-size: ${theme.iconography?.sizes[sizeValue]};
    transform: rotate(${rotationAngle || 0}deg);
    &:before {
      color: ${!customColor ? theme.color?.foreground[tone] : customColor};
    }
  `}
`;

export default Icon;
