import { useTracking } from 'common/tracking/hooks';
import React, { ReactElement, ReactNode } from 'react';
import { Button } from './styles';

type IconButtonProps = {
  children: ReactNode;
  testId?: string;
  onClick: () => void;
};

const IconButton = ({ children, onClick, testId }: IconButtonProps): ReactElement => {
  const trackAppEvent = useTracking();

  return (
    <Button
      onClick={() => {
        if (testId)
          trackAppEvent('buttonComponentPress', {
            buttonText: '',
            componentId: testId,
            componentType: 'Button',
          });
        if (onClick) onClick();
      }}
    >
      {children}
    </Button>
  );
};

IconButton.defaultProps = {
  testId: '',
};

export default IconButton;
