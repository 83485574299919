import * as ActionTypes from 'store/actions/stopOrders';
import { StopOrdersActions } from 'types/stopOrders';
import { StopOrdersState } from 'store/types/stopOrders';

const initialState: StopOrdersState = {
  isFetching: false,
  tradingRules: [],
  deleteTradingRuleDetails: {
    uniqueId: '',
    type: null,
    entity: '',
    priceLimit: '',
    cryptoAmount: '',
    euroAmount: '',
    validUntil: null,
  },
};

export default function stopOrders(
  state: StopOrdersState = initialState,
  action: StopOrdersActions,
): StopOrdersState {
  switch (action.type) {
    case ActionTypes.FETCH_TRADING_STOP_RULES:
      return {
        ...state,
        isFetching: true,
      };
    case ActionTypes.FETCH_TRADING_STOP_RULES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        tradingRules: action.payload.response
          .filter(
            ({ status }: { status: TradingRuleStatus }) => status === 'Created' || status === 'Confirmed',
          )
          .slice() // copy
          .reverse(), // mutate in-place
      };
    }
    case ActionTypes.FETCH_TRADING_STOP_RULES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case ActionTypes.SAVE_DELETING_TRADING_STOP_RULE:
      return {
        ...state,
        deleteTradingRuleDetails: action.payload,
      };
    default:
      return state;
  }
}
