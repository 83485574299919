import { RootEpic } from 'types/common';
import { catchError, exhaustMap, filter, map, mergeMap, pluck, switchMap, take } from 'rxjs/operators';
import { isPresent } from 'safetypings';
import { getAccessToken } from 'store/selectors/auth';
import { backoff } from 'common/utils/rxjs-operators';
import { AjaxError } from 'rxjs/ajax';
import { handleAjaxError } from 'store/epics/auth';
import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { getDeviceData } from 'common/utils/seonSdk';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import I18n from 'i18next';
import { getEditedTaxExemption } from 'store/slices/taxExemption/selectors';
import {
  fetchTaxExemptionsFailure,
  fetchTaxExemptionsRequest,
  fetchTaxExemptionsSuccess,
  submitTaxExemptionFailure,
  submitTaxExemptionRequest,
  submitTaxExemptionSuccess,
  verifyTaxExemptionFailure,
  verifyTaxExemptionRequest,
  verifyTaxExemptionSuccess,
} from 'store/slices/taxExemption/actions';
import { EditTaxExemptionData, TaxExemptionData } from 'types/taxExemption';

const watchFetchTaxExemption: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(fetchTaxExemptionsRequest.match),
    exhaustMap(() =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: api.getBaseUrlForUserServices(accessToken)?.concat('/stocks/tax-exemptions'),
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(pluck('response'), map(fetchTaxExemptionsSuccess)),
        ),
        backoff(5, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, fetchTaxExemptionsFailure)),
      ),
    ),
  );

const watchSubmitTaxExemption: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(submitTaxExemptionRequest.match),
    exhaustMap(({ payload: { amount, onSuccess, onFail, isResendCode } }) =>
      from(getDeviceData()).pipe(
        switchMap(({ deviceData }) =>
          state$.pipe(
            map(getAccessToken),
            filter(isPresent),
            take(1),
            switchMap((accessToken) =>
              ajax({
                url: api.getBaseUrlForUserServices(accessToken)?.concat('/stocks/tax-exemptions'),
                method: 'POST',
                withCredentials: true, // include cookies
                headers: {
                  ...api.getCommonHeaders(),
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
                body: {
                  amount,
                  ...(deviceData ? { deviceData } : {}),
                },
              }).pipe(
                pluck('response'),
                mergeMap(({ result }: { result: EditTaxExemptionData }) => {
                  onSuccess();
                  if (isResendCode) {
                    return of(
                      notificationBannerActions.notifySuccess({
                        message: I18n.t('common.twofa.resentCode'),
                      }),
                      submitTaxExemptionSuccess(result),
                    );
                  }
                  return of(submitTaxExemptionSuccess(result));
                }),
              ),
            ),
            backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
            catchError(
              handleAjaxError(
                action$,
                (error) => {
                  onFail();
                  return submitTaxExemptionFailure(error);
                },
                {},
                { message: I18n.t('error.genericNetwork') },
              ),
            ),
          ),
        ),
      ),
    ),
  );

const watchVerifyTaxExemption: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(verifyTaxExemptionRequest.match),
    exhaustMap(({ payload: { code2fa, onSuccess, onFail } }) =>
      from(getDeviceData()).pipe(
        switchMap(({ deviceData }) =>
          state$.pipe(
            map((state) => ({
              accessToken: getAccessToken(state),
              data: getEditedTaxExemption(state),
            })),
            filter(({ accessToken }) => isPresent(accessToken)),
            take(1),
            switchMap(({ accessToken, data }) =>
              ajax({
                url: api.getBaseUrlForUserServices(accessToken)?.concat('/stocks/tax-exemptions'),
                method: 'PATCH',
                withCredentials: true, // include cookies
                headers: {
                  ...api.getCommonHeaders(),
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
                body: {
                  data,
                  code2fa,
                  ...(deviceData ? { deviceData } : {}),
                },
              }).pipe(
                pluck('response'),
                mergeMap((response: TaxExemptionData) => {
                  onSuccess();
                  return of(verifyTaxExemptionSuccess(response));
                }),
              ),
            ),
            backoff(5, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
            catchError(
              handleAjaxError(
                action$,
                (error) => {
                  onFail();
                  return verifyTaxExemptionFailure(error);
                },
                {},
                { message: I18n.t('error.genericNetwork') },
              ),
            ),
          ),
        ),
      ),
    ),
  );

export default combineEpics(watchFetchTaxExemption, watchSubmitTaxExemption, watchVerifyTaxExemption);
