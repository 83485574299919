import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import { ExpandedItem, ExpandedItemsContainer, IconWrapper, Text } from './styles';
import Icon from '../../Icon';

const defaultStyle = {
  transition: `all 300ms ease-in-out`,
  opacity: 0,
  height: 0,
  overflow: 'hidden',
};

const transitionStyles: { [id: string]: React.CSSProperties } = {
  entering: { opacity: 0, height: 0 },
  entered: { opacity: 1, height: 'unset', overflow: 'unset' },
};

function TransitionComponent({
  inProp,
  isMarketingChecked,
  setIsMarketingChecked,
}: {
  inProp: boolean;
  isMarketingChecked: boolean;
  setIsMarketingChecked: (prev: boolean) => void;
}): ReactElement {
  const { t } = useTranslation();
  return (
    <Transition in={inProp}>
      {(state) => (
        <ExpandedItemsContainer style={{ ...defaultStyle, ...transitionStyles[state] }}>
          <ExpandedItem>
            <IconWrapper isChecked isWithBorder={false}>
              <Icon sizeValue="tiniest" name="custom-icon-checkmark" />
            </IconWrapper>
            <Text>{t('cookieConsent.select.necessary')}</Text>
          </ExpandedItem>
          <ExpandedItem onClick={() => setIsMarketingChecked(!isMarketingChecked)}>
            <IconWrapper isWithBorder isChecked={isMarketingChecked}>
              {isMarketingChecked && <Icon sizeValue="tiniest" name="custom-icon-checkmark" />}
            </IconWrapper>
            <Text>{t('cookieConsent.select.marketing')}</Text>
          </ExpandedItem>
        </ExpandedItemsContainer>
      )}
    </Transition>
  );
}

export default TransitionComponent;
