import { createGlobalStyle } from 'styled-components';
import { colors } from 'common/utils/theme/colors';
import { HeadingSize, Theme, TextSize } from 'types/theme';

export const getHeaderFontStyles = (headerSize: HeadingSize, theme: Theme): string => `
    font-size: ${theme.typography.heading.size[headerSize].fontSize};
    line-height: ${theme.typography.heading.size[headerSize].lineHeight}`;

export const getBodyFontStyles = (theme: Theme): string => `
    font-size: ${theme.typography.text.standard.fontSize};
    line-height: ${theme.typography.text.standard.lineHeight};
    font-weight: ${theme.typography.fontWeight.regular};
    font-family: ${theme.typography.fontFamily.openSans}`;

export const getTextStyles = (textSize: TextSize, theme: Theme): string => `
    font-size: ${theme.typography.text[textSize].fontSize};
    line-height: ${theme.typography.text[textSize].lineHeight};
`;

const GlobalStyles = createGlobalStyle`

html {
    font-size: 50%;
    height: 100%;
  } 

${({ theme }: { theme: Theme }): any => `

    body {
        ${getBodyFontStyles(theme)};
        background-color: ${theme.color.background.primary};
        color: ${theme.color.foreground.primary};
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        height: 100%;
    } 


    p {
        ${getBodyFontStyles(theme)};
        color: ${theme.color.foreground.primary};
    }

    h1,h2,h3,h4,h5 {
        margin: 0;
        padding: 0;
    }

    *,
    *:before,
    *:after {    
        box-sizing: inherit;
    }

    h1, .h1 {
        ${getHeaderFontStyles('1', theme)};
        font-weight: ${theme.typography.heading.weight.regular};
        color: ${theme.color.foreground.primary};
    }

    h2, .h2 {
        ${getHeaderFontStyles('2', theme)}; 
        font-weight: ${theme.typography.heading.weight.regular};
        color: ${theme.color.foreground.primary};
    }

    h3, .h3 {
        ${getHeaderFontStyles('3', theme)}; 
        font-weight: ${theme.typography.heading.weight.regular};
        color: ${theme.color.foreground.primary};
    }

    h4, .h4 {
        ${getHeaderFontStyles('4', theme)}; 
        font-weight: ${theme.typography.heading.weight.regular};
    }

    .body-text {
        ${getBodyFontStyles(theme)};
        color: ${theme.color.foreground.primary};
    }

    .xxsmall-text {
        ${getTextStyles('xxsmall', theme)};
    }

    .xsmall-text {
        ${getTextStyles('xsmall', theme)};
    }

    .small-text {
        ${getTextStyles('small', theme)};
    }

    .standard-text {
        ${getTextStyles('standard', theme)};
    }

    .large-text {
        ${getTextStyles('large', theme)};
    }

    .xlarge-text {
        ${getTextStyles('xlarge', theme)};
    }

    .medium-weight {
        font-weight: ${theme.typography.fontWeight.medium};
    }

    .regular-weight {
        font-weight: ${theme.typography.fontWeight.regular};
    }

    .strong-weight {
        font-weight: ${theme.typography.fontWeight.strong};
    }

    .primary-color-text {
       color: ${theme.color.foreground.primary};
    }

    .secondary-color-text {
        color: ${theme.color.foreground.secondary};
     }

     .background-primary {
        background-color: ${theme.color.background.primary};
      }

     .background-secondary {
        background-color: ${theme.color.background.secondary};
      }

    .disabled {
      opacity: ${theme.opacity.disabled};
    }

    .addbutton {
        text-decoration: none !important;
      }
      
    .addbutton span:nth-child(2) {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 30px;
        background: ${theme.color.background.primaryInverted};
        color: ${theme.color.foreground.primaryInverted};
        border-radius: 5px;
        margin-right: 10px;
        
        &:after {
          content: '+';
          font-size: 30px;
          text-decoration: none;
          position: absolute;
          color: ${theme.color.foreground.primaryInverted};
          top: 44%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
    }
      
    .addbutton span:nth-child(3) {
    text-decoration: underline;
    }
      
    .recharts-rectangle.recharts-tooltip-cursor {
      fill: ${theme.color.background.emph};
    }
`};


a,
a:hover,
a:visited,
a:focus,
a:active {
  text-decoration: none;
  outline: 0;
  cursor: pointer;
}

/* Prevents ios-browser style overrides */
textarea,
input.text,
input[type='number'],
input[type='text'],
input[type='button'],
input[type='submit'],
.input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
}

.powered-by {
  font-size: 10px;
}


/* Notification banner custom styles */

.notification-banner {
  position: fixed !important;
  top: 0;
  width: 100%;
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 48em) {
  html {
    font-size: 56.25%;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 64em) {
  html {
    font-size: 62.5%;
  }
}

.shortfields input,
.shortfields select {
  max-width: 100%;
  min-width: 70%;
}


hr {
  border: none;
  background: #cbcbcb;
  height: 1px;
}

.invite-subtext {
  display: block;
  max-width: 220px;
}

.cut-padding form {
  padding: 0 !important;
  margin-top: 0 !important;
}

.MuiSvgIcon-root {
  width: 2em !important;
  height: 2em !important;
  top: calc(50% - 1em) !important;
}

.rsiChart .recharts-cartesian-grid-horizontal line:first-child,
.rsiChart .recharts-cartesian-grid-horizontal line:last-child,
.rsiChart .recharts-cartesian-grid-vertical line {
  stroke-dasharray: 0 !important;
}

.recharts-tooltip-label {
  color: ${colors.pureBlack};
}

.dimmed.dimmable > .ui.modals.dimmer.visible {
  display: flex !important;
}
.blurring.dimmable>.dimmer {
  backdrop-filter: blur(5px);
}
.blurring.dimmed.dimmable>:not(.dimmer) {
  filter: none;
}

.ui.loader {
  // Overrides the default semantic ui loader z-index of 1000. 
  z-index: 1 !important;
}

.ReactVirtualized__List { 
  &:focus-visible {
    outline: none;
  }
}

`;

export default GlobalStyles;
