/* eslint-disable no-nested-ternary */
// sources:
// https://gist.github.com/fisshy/319d88d904924861c46f057ae2ec006f
// https://stackoverflow.com/a/46495891
import { fromEvent, merge, Observable, of } from 'rxjs';
import {
  concatMap,
  debounceTime,
  filter,
  map,
  mergeMapTo,
  scan,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { isAjaxErrorTimeout } from 'common/utils/rxjs-ajax';
import {
  AUTH,
  CANCEL_WAIT_CONFIRMATION,
  loginUser,
  invalidateTokens,
  RESEND_CONFIRMATION_MAIL,
  startResendConfirmationMail,
  TOKEN_REFRESH,
  WAIT_CONFIRMATION,
} from 'store/actions/auth';
import { NotificationProps, notifyError } from 'store/actions/notificationBanner';
import { ApiError } from 'types/error';
import { RootEpic } from 'types/common';
import { isOfType } from 'safetypings';
import { PAIR_DEVICE_START } from 'store/actions/forms';
import { errorCodes } from 'common/apiErrors';
import { getLoginRetryDelayTime } from 'common/utils/auth';

// actions
export const httpError = (error: ApiError) => ({
  type: 'HTTP_ERROR',
  ...error,
});

// epics
export const refreshToken = (action$: any, _error: Error, source: any): Observable<any> =>
  // merge is similar to startWith, but be mindful that a
  // startWith is just shorthand for a concat, not a merge,
  // which means it would synchronously emit the action before
  // we have started to listen for a success one
  merge(
    action$.pipe(
      ofType(TOKEN_REFRESH.SUCCESS),
      takeUntil(action$.ofType(TOKEN_REFRESH.FAILURE)),
      take(1),
      // same as .mergeMap(() => source)
      mergeMapTo(source),
    ),
    of(invalidateTokens()),
  );

export const isAuthTokenExpiredError = (error: AjaxError): boolean =>
  error.response?.data?.code === 'auth_err_tokenexpired' || error.response?.code === 'auth_err_tokeninvalid';

const getResponseHeadersMap = (xhrHeaders: string): { [header: string]: string } =>
  xhrHeaders
    .trim()
    .split(/[\r\n]+/)
    .map((value) => value.split(': '))
    .reduce((acc, [header, value]) => ({ ...acc, [header.toLowerCase()]: value }), {});

export const normalizeAjaxErrorResponse = (error: AjaxError): ApiError => ({
  url: error?.request?.url,
  statusCode: error?.status,
  response: error?.response,
  errorCode: error?.response?.data?.code,
  requestHeaders: error?.request?.headers,
  responseHeaders: getResponseHeadersMap(error?.xhr?.getAllResponseHeaders() ?? ''),
  isTimeout: isAjaxErrorTimeout(error),
});

// enables you to pass your own action to dispatch in case of unhandled failure
export const handleAjaxError =
  (
    action$: any,
    errorAction: (error: ApiError, props?: any) => any = httpError,
    payload?: any,
    notify?: NotificationProps,
  ) =>
  (error: AjaxError, source: Observable<any>) =>
    isAuthTokenExpiredError(error)
      ? refreshToken(action$, error, source)
      : notify
      ? of(errorAction(normalizeAjaxErrorResponse(error), payload), notifyError(notify))
      : of(errorAction(normalizeAjaxErrorResponse(error), payload));

export const watchWaitConfirmationEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isOfType([WAIT_CONFIRMATION, PAIR_DEVICE_START])),
    filter(({ secret }) => !!secret),
    switchMap(({ secret }) =>
      action$.pipe(
        ofType(RESEND_CONFIRMATION_MAIL),
        switchMap(({ sendSecurityCode }) =>
          state$.pipe(() => of(startResendConfirmationMail({ sendSecurityCode, secret }))),
        ),
      ),
    ),
  );

/**
 * Creates an observable that emits a boolean value indicating whether the document is visible.
 *
 * This observable:
 * - Emits `true` if the document is visible.
 * - Emits `false` if the document is hidden.
 * - Replays the latest visibility status to new subscribers.
 */
const visibilitychange$ = fromEvent(document, 'visibilitychange').pipe(
  // Switch to a new observable that emits the opposite of document.hidden whenever the visibility changes
  map(() => !document.hidden),
  // Start the observable with the current visibility status
  startWith(!document.hidden),
  // Share the latest value with all subscribers and replay the last emitted value to new subscribers
  shareReplay(1),
);

// Epic to handle login with retry logic after registration (until email verification is done)
export const watchWaitEmailConfirmationEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    // Filter for AUTH_FAILURE action type and check for specific LOGIN_ERR_NOTCONFIRM error code
    filter(isOfType([AUTH.FAILURE])),
    filter(({ error }) => error.code === errorCodes.LOGIN_ERR_NOTCONFIRM),
    // Accumulate retry attempts and credentials
    scan(
      (acc, action) => ({
        count: acc.count + 1,
        credentials: action?.request?.credentials || {
          username: null,
          secret: null,
          secretType: null,
          displayWaitingScreen: null,
        },
      }),
      {
        count: 0,
        credentials: { username: null, secret: null, secretType: null, displayWaitingScreen: null },
      },
    ),
    // Handle visibility changes and retry login
    switchMap(({ count, credentials }) => {
      return visibilitychange$.pipe(
        filter((v) => v), // Only proceed if the tab is visible
        debounceTime(getLoginRetryDelayTime(count)), // Wait for a specific time before retrying
        take(1),
        concatMap(() => {
          const { username, secret, secretType, displayWaitingScreen } = credentials;
          // Dispatch login action with stored credentials
          return of(loginUser(username, secret, secretType, displayWaitingScreen));
        }),
        // Cancel the subscription if the cancel action is dispatched
        takeUntil(action$.ofType(CANCEL_WAIT_CONFIRMATION)),
      );
    }),
  );

export default combineEpics(watchWaitConfirmationEpic, watchWaitEmailConfirmationEpic);
