import styled from 'styled-components';
import { Theme } from 'types/theme';
import { devices } from 'common/styles';

export const LayoutNarrowDiv = styled.div<{
  isBottomMarginRemoved: boolean;
  isTopMarginRemoved: boolean;
  maxWidth: number;
}>`
  max-width: ${({ maxWidth }) => maxWidth ?? 562}px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 20px;

  @media${devices.maxTablet} {
    margin-bottom: ${(props) => (props.isBottomMarginRemoved ? '0' : '20px')};
  }

  @media${devices.tablet} {
    margin-top: ${(props) => (props.isTopMarginRemoved ? '0' : '40px')};
  }
`;

export const HeaderTextDiv = styled.h1`
  width: 100%;
  margin-bottom: 8px;
  padding-bottom: 8px;
`;

export const HeaderElementContainer = styled.div``;

export const MainContainer = styled.div.attrs({ className: 'background-secondary' })<{
  mainContainerCustomStyles: string;
}>`
  padding: 30px 30px 40px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  @media${devices.tablet} {
    padding: 32px 98px 43px;
  }
  @media${devices.maxMobileL} {
    padding: 10px 10px 40px;
  }
  ${(props: { mainContainerCustomStyles: string }) => props.mainContainerCustomStyles};
`;
