import { ActionsObservable, combineEpics, Epic, StateObservable } from 'redux-observable';
import { race, timer } from 'rxjs';
import {
  filter,
  withLatestFrom,
  switchMap,
  map,
  pluck,
  catchError,
  takeUntil,
  mapTo,
  take,
  distinctUntilChanged,
  repeatWhen,
} from 'rxjs/operators';
import { isOfType } from 'safetypings';

import { RootAction, RootEpic } from 'types/common';
import { NewsItem } from 'types/news';
import {
  fetchNewsFeedSuccess,
  fetchNewsFeedFailure,
  SYNC_NEWS_FEED,
  loadNewsFeedIfNeeded,
  fetchNewsFeedRequest,
  invalidateNewsFeed,
} from '../actions/news';
import { handleAjaxError } from './auth';
import { getIsFetchingNewsFeedForCrypto, getNewsOfLanguage } from '../selectors/news';
import { getLastSelectedCurrency } from '../selectors/currency';

const fetchNewsFeedForCryptoEpic: Epic<any> = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType('FETCH_NEWS_FEED_REQUEST')),
    withLatestFrom(state$),
    map(
      ([
        {
          payload: { crypto },
        },
        state,
      ]) => ({
        crypto,
        lang: getNewsOfLanguage(state),
      }),
    ),
    switchMap(({ crypto, lang }) =>
      race(
        ajax({
          url: api.getBaseUrlForNews().concat(`/${crypto}/${lang}`),
        }).pipe(
          pluck('response'),
          map((response: NewsItem[]) => fetchNewsFeedSuccess({ crypto, newsFeed: response })),
          catchError(handleAjaxError(action$, fetchNewsFeedFailure, { crypto })),
        ),
        action$.pipe(filter(isOfType('INVALIDATE_NEWS_FEED')), take(1)),
      ),
    ),
  );

const updateNewsFeedList: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isOfType(SYNC_NEWS_FEED.START)),
    take(1),
    switchMap(() => {
      return timer(0, 30000).pipe(
        takeUntil(action$.pipe(filter(isOfType(SYNC_NEWS_FEED.STOP)))),
        repeatWhen(() => action$.pipe(filter(isOfType(SYNC_NEWS_FEED.START)))),
        mapTo(loadNewsFeedIfNeeded()),
      );
    }),
  );

export const loadNewsFeedPlans: Epic<any> = (action$, state$) =>
  action$.pipe(
    filter(isOfType('LOAD_NEWS_FEED_IF_NEEDED')),
    withLatestFrom(state$),
    map(([_, state]) => {
      const selectedCrypto = getLastSelectedCurrency(state);
      return {
        crypto: selectedCrypto,
        isFetching: getIsFetchingNewsFeedForCrypto(state, selectedCrypto),
      };
    }),
    filter(({ isFetching }) => !isFetching),
    map(({ crypto }) => fetchNewsFeedRequest(crypto)),
  );

export const changeLanguageOfNews: Epic<any> = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<any>,
) => action$.pipe(filter(isOfType('CHANGE_NEWS_LANGUAGE')), mapTo(invalidateNewsFeed()));

export const newsfeedUpdateUponCryptoSelection: Epic<any> = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<any>,
) =>
  action$.pipe(
    filter(isOfType('SELECT_ENTITY')),
    take(1),
    switchMap(() =>
      state$.pipe(map(getLastSelectedCurrency), distinctUntilChanged(), mapTo(invalidateNewsFeed())),
    ),
  );

export const invalidateNewsFeedEpic: Epic<any> = (action$) =>
  action$.pipe(filter(isOfType('INVALIDATE_NEWS_FEED')), mapTo(loadNewsFeedIfNeeded()));

export default combineEpics(
  updateNewsFeedList,
  loadNewsFeedPlans,
  fetchNewsFeedForCryptoEpic,
  changeLanguageOfNews,
  invalidateNewsFeedEpic,
  newsfeedUpdateUponCryptoSelection,
);
