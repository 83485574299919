// @flow
import * as React from 'react';

import { ItemContainer, TagLink, RouteLink, DisabledLink, ActionLink } from './styles';

type Props = {
  title: string;
  to?: string;
  href: string;
  disabled?: boolean;
  onClick?: () => void;
};

const LinkMenuItem = ({ title, to, href, onClick, disabled }: Props) => {
  return (
    <>
      <ItemContainer>
        {href && !disabled && <TagLink href={href}>{title}</TagLink>}
        {to && !disabled && <RouteLink to={to}>{title}</RouteLink>}
        {onClick && <ActionLink onClick={onClick}>{title}</ActionLink>}
        {disabled && <DisabledLink> {title}</DisabledLink>}
      </ItemContainer>
    </>
  );
};

LinkMenuItem.defaultProps = {
  disabled: false,
  to: '',
  href: '',
};

export default LinkMenuItem;
