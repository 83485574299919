import React, { ReactElement } from 'react';
import { ListItemDiv, NameDiv, ValueDiv } from './styles';

type Props = {
  name: string;
  value?: string;
  valueElement?: JSX.Element;
  valueColor?: string;
};

const ListItem = ({ name, value, valueColor, valueElement }: Props): ReactElement => {
  return (
    <ListItemDiv>
      <NameDiv>{name}</NameDiv>
      <ValueDiv color={valueColor}>{valueElement || value}</ValueDiv>
    </ListItemDiv>
  );
};

ListItem.defaultProps = {
  valueColor: undefined,
  value: undefined,
  valueElement: undefined,
};

export default ListItem;
