import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAnyCryptoAssetsStaked } from 'store/slices/portfolio/selectors';
import { getUser } from 'store/selectors/auth';
import { stringHashCode } from 'common/utils/hash';
import {
  getShowPhasedRolloutModalAgain,
  getUsersWhoSawPhasedRolloutModal,
} from 'store/selectors/localSettings';
import {
  addUserToUsersWhoSawStakingRolloutModal,
  setShowPhasedRolloutModalAgain,
} from 'store/actions/localSettings';

export const useStakingPhasedRolloutModal = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const userHash = stringHashCode(user.username ?? '');

  const shouldDisplayRolloutModal = useSelector(getIsAnyCryptoAssetsStaked);
  const usersWhoSawStakingPhasedRollout = useSelector(getUsersWhoSawPhasedRolloutModal);
  const showPhasedRolloutModalAgain = useSelector(getShowPhasedRolloutModalAgain);
  const didUserSawBanner = usersWhoSawStakingPhasedRollout.includes(userHash);

  const [isStakingPhasedRolloutModalShown, setIsStakingPhasedRolloutShown] = useState(false);

  useEffect(() => {
    if (!userHash || didUserSawBanner || !shouldDisplayRolloutModal) return;

    setIsStakingPhasedRolloutShown(true);
  }, [shouldDisplayRolloutModal, didUserSawBanner, userHash]);

  useEffect(() => {
    if (!showPhasedRolloutModalAgain) return;

    setIsStakingPhasedRolloutShown(true);
    dispatch(setShowPhasedRolloutModalAgain(false));
  }, [showPhasedRolloutModalAgain]);

  const onCloseStakingPhasedRollout = () => {
    setIsStakingPhasedRolloutShown(false);
    if (didUserSawBanner) return;
    dispatch(addUserToUsersWhoSawStakingRolloutModal(userHash));
  };

  return {
    isStakingPhasedRolloutModalShown,
    onCloseStakingPhasedRollout,
  };
};
