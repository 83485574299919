import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SuccessImg } from 'common/components/Modal/SuccessModal/SuccessImg';
import Button from 'common/components/Button';
import { paths } from 'common/urls';
import {
  Wrapper,
  ContentWrapper,
  Text,
  Content,
  LimitOrderSuccessTextHeader,
} from 'common/components/TradingRulesCommonComponents/TradingRulesCreationStyles/successStyles';
import ModalTemplate from 'common/components/Modal/index';

type Props = {
  infoText: string | ReactElement;
  headerText?: string | undefined;
  isOpen: boolean;
  isBuy: boolean;
};

export const StopOrderSuccessModal = ({ infoText, headerText, isOpen, isBuy }: Props): ReactElement => {
  const { t } = useTranslation();

  const orderType = isBuy ? 'buy' : 'sell';
  return (
    <ModalTemplate
      defaultOpen={isOpen}
      persist
      actions={
        <Wrapper>
          <Button to={paths.LIMIT_ORDER} title={t('stopOrders.tradingManager')} size="large" isFullWidth />
        </Wrapper>
      }
    >
      <Content>
        <ContentWrapper>
          <SuccessImg />
          {headerText && (
            <LimitOrderSuccessTextHeader className="h2">{headerText}</LimitOrderSuccessTextHeader>
          )}

          <Text className="primary-color-text">{infoText}</Text>
          <Text>{t(`stopOrders.notificationInfo.${orderType}`)}</Text>
          <Text className="secondary-color-text">{t(`stopOrders.legalText`)}</Text>
        </ContentWrapper>
      </Content>
    </ModalTemplate>
  );
};

StopOrderSuccessModal.defaultProps = {
  headerText: undefined,
};
