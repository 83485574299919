import Button from 'common/components/Button';
import { devices } from 'common/styles';
import styled from 'styled-components';
import { Theme } from 'types/theme';
import { boolean } from 'yup';

export const RaffleHeader = styled.div`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
  text-align: center;
  font-size: ${({ theme }: { theme: Theme }) => theme.typography.heading.size['3'].fontSize};
  line-height: ${({ theme }: { theme: Theme }) => theme.typography.heading.size['3'].lineHeight};
  font-weight: ${({ theme }: { theme: Theme }) => theme.typography.fontWeight.strong};
  padding-bottom: 24px;

  @media ${devices.tablet} {
    padding-bottom: 16px;
  }
`;

export const BlackText = styled.span`
  color: black;
`;

export const RaffleSubtitle = styled.span.attrs({ className: 'small-text' })`
  text-align: center;
`;

export const RaffleTCTitle = styled.div.attrs({ className: 'small-text' })`
  ${({ $disableTopMargin }: { $disableTopMargin?: boolean }) =>
    `margin-top: ${$disableTopMargin ? '0px' : '24px'}`};

  margin-bottom: 16px;
`;

export const ContainerCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const RaffleTCList = styled.ol`
  margin: 0;
  padding-bottom: ${({ $withPadding }: { $withPadding?: boolean }) => ($withPadding ? '12px' : '0px')};

  li::marker {
    font-weight: bold;
  }
`;

export const RaffleTCListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 11px;
  }
  line-height: 19px !important;
  font-size: 14px !important;
`;
export const RaffleButton = styled(Button)`
  margin-bottom: 0;
  width: 100%;

  @media ${devices.tablet} {
    width: 343px;
  }
`;

export const MaybeLaterButton = styled(Button)`
  margin-bottom: 0;
  width: 100%;
  border: none;
  font-size: ${({ theme }: { theme: Theme }) => theme.typography.text.small.fontSize} !important;

  @media ${devices.tablet} {
    width: 343px;
  }
`;
