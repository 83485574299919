import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SwitchGreen } from 'common/components/SwitchGreen/SwitchGreen';
import { toggleStocksSwitch } from 'store/actions/settings';
import { getStocksSwitch } from 'store/selectors/settings';
import { ToggleTitle, Wrapper, ToggleSwitchWrapper } from '../QuickSettings/styles';

const ToggleStocks = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const stocksSwitch = useSelector(getStocksSwitch);

  return (
    <Wrapper onClick={() => dispatch(toggleStocksSwitch())}>
      <ToggleTitle>{t('settings.stocksEnabled')}</ToggleTitle>
      <ToggleSwitchWrapper>
        <SwitchGreen onClick={() => ''} checked={stocksSwitch} />
      </ToggleSwitchWrapper>
    </Wrapper>
  );
};

export default ToggleStocks;
