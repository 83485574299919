export const cryptoColors = Object.freeze({
  Btc: '#F7931A',
  Bch: '#0AC18E',
  Ada: '#0033ad',
  Link: '#2A5ADA',
  Eth: '#000000',
  Ltc: '#345D9D',
  Dot: '#E6007A',
  Xrp: '#23282D',
  Sol: '#DA21FE',
  Uni: '#FF007A',
  Eur: '#1D25E9',
  Shib: '#F00500',
  Doge: '#BA9F33',
  Pol: '#8247E5',
  Xlm: '#000000',
  Mana: '#FF2D55',
  Sand: '#0084FF',
  Aave: '#B6509E',
  Algo: '#000000',
  Avax: '#E84142',
  Axs: '#0055D5',
  Ape: '#073183',
  Bat: '#FF5000',
  Chz: '#FF1256',
  Atom: '#2E3148',
  Crv: '#000000',
  Dai: '#F5AC37',
  Enj: '#7866D5',
  Etc: '#3AB83A',
  Near: '#000000',
  Qnt: '#000000',
  Xtz: '#2C7DF7',
  Grt: '#6747ED',
  Usdc: '#2775CA',
} as const);

export const FETCH_MARKET_OVERVIEW_PAGE_SIZE = 20;

export const SPECIAL_LIQUID_CRYPTO_CURRENCIES: Record<string, string> = {
  btc: 'btc',
  eth: 'eth',
} as const;

export const SPECIAL_CRYPTO_CURRENCIES_DEVIATIONS = {
  MIN: 5,
  MEDIUM: 6,
  MAX: 12,
} as const;

export const CRYPTO_CURRENCIES_DEVIATIONS = {
  MIN: 5,
  MEDIUM: 12,
  MAX: 19,
} as const;
