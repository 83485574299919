import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { paths } from 'common/urls';
import { halloEmail } from 'common/const';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/styles';
import LinkMenuItem from 'common/components/LinkMenuItem';
import { User } from 'types/state';
import {
  BisonImg,
  BoerseImg,
  FooterContainer,
  FooterItems,
  LogoWrapper,
  MenuItem,
  Section,
  SectionHeader,
  SectionLogo,
  Sections,
  TwoSectionsWrapper,
} from './styles';
import { EmailContainer } from '../LinkMenuItem/styles';
import { LayoutRegularWrapper } from '../LayoutContainer/LayoutRegularWrapper/LayoutRegularWrapper';

type Props = {
  user: User;
  withMobileMenu?: boolean;
};

const Footer: FC<Props> = ({ user, withMobileMenu }: Props) => {
  const { t } = useTranslation();
  const isTablet = useMedia([`${devices.tablet} and ${devices.maxLaptop}`], [true], false);

  return (
    <FooterContainer withMobileMenu={withMobileMenu}>
      <LayoutRegularWrapper>
        <Sections>
          <FooterItems>
            <Section>
              <SectionHeader>{t('footer.relevantLinks')}</SectionHeader>
              <LinkMenuItem href={t('footer.boerse.url')} title={t('footer.boerse.text')} />
              <LinkMenuItem href={t('footer.solaris.url')} title={t('footer.solaris.text')} />
              <LinkMenuItem href={t('footer.impressum.url')} title={t('footer.impressum.text')} />
              <LinkMenuItem
                href={t('footer.datenschutzerklarung.url')}
                title={t('footer.datenschutzerklarung.text')}
              />
              <LinkMenuItem href={t('footer.disclaimer.url')} title={t('footer.disclaimer.text')} />
              <LinkMenuItem href={t('footer.riskInfo.url')} title={t('footer.riskInfo.text')} />
              <LinkMenuItem href={t('footer.agb.url')} title={t('footer.agb.text')} />
            </Section>
            {isTablet ? (
              <TwoSectionsWrapper>
                <Section>
                  <SectionHeader>{t('settings.supportSection')}</SectionHeader>
                  <LinkMenuItem href={paths.BISON_LANDING} title={t('settings.website')} />
                </Section>
                <Section>
                  <SectionHeader>{t('footer.contact')}</SectionHeader>
                  <EmailContainer>
                    <MenuItem>{halloEmail}</MenuItem>
                  </EmailContainer>
                  <MenuItem>Börsenstraße 4</MenuItem>
                  <MenuItem>70174 Stuttgart</MenuItem>
                  <MenuItem>{t('footer.de')}</MenuItem>
                </Section>
              </TwoSectionsWrapper>
            ) : (
              <>
                <Section>
                  <SectionHeader>{t('settings.supportSection')}</SectionHeader>
                  <LinkMenuItem href={paths.BISON_LANDING} title={t('settings.website')} />
                </Section>
                <Section>
                  <SectionHeader>{t('footer.contact')}</SectionHeader>
                  <EmailContainer>
                    <MenuItem>{halloEmail}</MenuItem>
                  </EmailContainer>
                  <MenuItem>Börsenstraße 4</MenuItem>
                  <MenuItem>70174 Stuttgart</MenuItem>
                  <MenuItem>{t('footer.de')}</MenuItem>
                </Section>
              </>
            )}
          </FooterItems>
          <LogoWrapper>
            <BisonImg />
            <SectionLogo>
              <div>{t('footer.poweredBy')}</div>
              <BoerseImg className="powered-by" />
            </SectionLogo>
          </LogoWrapper>
        </Sections>
      </LayoutRegularWrapper>
    </FooterContainer>
  );
};

Footer.defaultProps = {
  withMobileMenu: false,
};

export default Footer;
